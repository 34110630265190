/* Register global components */
import Vue from 'vue';

const iconComponents = require.context('./svg/', true, /[A-Z]\w+\.vue$/);

iconComponents.keys().forEach((file) => {
   const name = file.replace(/(^.\/)|(\.vue$)/g, '');
   const component = iconComponents(file).default;
   Vue.component(name, component);
});

// Global components
import CollapseCard from './CollapseCard';
Vue.component('CollapseCard', CollapseCard);

import LinkBtn from './LinkBtn';
Vue.component('LinkBtn', LinkBtn);

import ManagerMessage from './ManagerMessage';
Vue.component('ManagerMessage', ManagerMessage);

import PageTitle from './PageTitle';
Vue.component('PageTitle', PageTitle);

import TcpInputGroup from './forms/TcpInputGroup';
Vue.component('TcpInputGroup', TcpInputGroup);

import RdigStatusPill from './RdigStatusPill';
Vue.component('RdigStatusPill', RdigStatusPill);
