import {VendorData} from '../utils';
import {SpecialCategories} from './uploads';

const state = () => ({
   vendors: {},
   accountData: {},
   adHocData: {},
   cloudUploadCategoryId: null,
});

const getters = {
   isCompleted: (state, getters, rootState, rootGetters) => {
      return rootGetters['companies/study'].cloudcomputing.completedAt !== null;
   },

   declaration: (state, getters, rootState, rootGetters) => {
      return rootGetters['companies/study'].cloudcomputing.wasUsed;
   },

   accountData: (state) => state.accountData,

   adHocData: (state) => state.adHocData,

   documentsUploadCategory: (state, getters, rootState, rootGetters) => {
      if (state.cloudUploadCategoryId === null) {
         return null;
      }
      return rootGetters['uploads/uploadCategoryMap'][state.cloudUploadCategoryId];
   },

   /** The user currently assigned the cloud computing section */
   assignee: (state, getters, rootState, rootGetters) => {
      const assignee = rootGetters['companies/study'].cloudcomputingAssignments.find(
         (assignment) => assignment.completed === null
      );
      return assignee ? assignee.assigneeId : null;
   },

   /** Is the current user assigned the cloud computing section? */
   hasSectionAssignment: (state, getters, rootState, rootGetters) => {
      const userId = rootGetters.profile.id;
      return getters.assignee == userId;
   },
};

const mutations = {
   clearData(state) {
      state.accountData = {};
      state.adHocData = {};
   },

   deleteVendor(state, {vendorId}) {
      for (let year in state.accountData) {
         for (let account in state.accountData[year]) {
            state.accountData[year][account] = state.accountData[year][account].filter(
               (vendor) => vendor.id !== vendorId
            );
         }
      }
      for (let year in state.adHocData) {
         state.adHocData[year] = state.adHocData[year].filter((vendor) => vendor.id !== vendorId);
      }
   },

   /** Store the cloud computing file upload category id */
   setCloudUploadCategoryId(state, {uploadCategoryId}) {
      state.cloudUploadCategoryId = uploadCategoryId;
   },
};

const actions = {
   //
   // DECLARATION, COMPLETION
   //

   /** Update the cloud computing declaration */
   async saveDeclaration({dispatch}, {companyId, declaration}) {
      const payload = {wasUsed: declaration};
      await dispatch('_saveSectionData', {companyId, payload});
   },

   /** Submit the cloud computing section */
   async submit({dispatch}, {companyId}) {
      const payload = {completed: true};
      await dispatch('_saveSectionData', {companyId, payload});
   },

   /** Unlock the cloud computing section */
   async unlock({dispatch}, {companyId}) {
      const payload = {completed: false};
      await dispatch('_saveSectionData', {companyId, payload});
   },

   /** Save section data stored at the study level */
   async _saveSectionData({commit}, {companyId, payload}) {
      const response = await this._vm.$http.put(
         `/api/company/${companyId}/cloudcomputing`,
         payload
      );
      commit(
         'companies/setCloudComputing',
         {
            cloudcomputing: response.data,
         },
         {root: true}
      );
   },

   //
   // VENDORS
   //

   /** Load cloud computing data for a company */
   async loadData({dispatch}, {companyId = null}) {
      const params = {};
      if (companyId) {
         params.company_id = companyId;
      }
      const data = (await this._vm.$http.get('/api/cloudcomputing', {params})).data.results;
      dispatch('importCloudData', {data});
   },

   importCloudData({state, rootGetters}, {data}) {
      const accountData = {};
      const adHocData = {};
      const studyYears = rootGetters['companies/studyYears'];
      data.forEach((vendor) => {
         const isAdHoc = vendor.vendor.manuallyEntered;
         const dataStore = isAdHoc ? adHocData : accountData;
         studyYears.forEach((year) => {
            let vendorYearly = vendor.yearly.find((y) => y.year === year);
            if (!vendorYearly) {
               if (isAdHoc) {
                  // Ensure a dummy VendorData exists for every study year for ad hoc vendors
                  vendorYearly = {year, netAmount: 0};
               } else {
                  return;
               }
            }
            const vendorData = new VendorData({
               ...vendor.vendor,
               ...vendorYearly,
               vendor: vendor.vendor.name,
            });
            const account = vendorYearly.account;

            if (!(year in dataStore)) {
               dataStore[year] = isAdHoc ? [] : {};
            }
            if (isAdHoc) {
               dataStore[year].push(vendorData);
            } else {
               if (!(account in dataStore[year])) {
                  dataStore[year][account] = [];
               }
               dataStore[year][account].push(vendorData);
            }
         });
      });

      state.accountData = accountData;
      state.adHocData = adHocData;
   },

   async createVendor({dispatch}, {name, companyId}) {
      await this._vm.$http.post('/api/cloudcomputing/vendor', {
         companyId,
         name,
      });
      await dispatch('loadData', {companyId});
   },

   async deleteVendor({commit}, {vendorId, force = false}) {
      const params = {force};
      await this._vm.$http.delete(`/api/cloudcomputing/vendor/${vendorId}`, {params});
      commit('deleteVendor', {vendorId});
   },

   //
   // VENDOR DATA
   //

   /** Save a percentage value while managing the status of the corresponding Field */
   async savePercentage({state}, {companyId, year, account, vendorId, percentage}) {
      const vendors = account ? state.accountData[year][account] : state.adHocData[year];
      const vendorData = vendors.find((vd) => vd.id === vendorId);

      let netAmount = vendorData.netAmount.value;
      const payload = {
         companyId,
         yearly: [
            {
               vendorId,
               year,
               account,
               percentage,
               netAmount,
               companyId,
            },
         ],
      };

      vendorData.percentage.saveStarted();
      try {
         await this._vm.$http.post('/api/cloudcomputing/yearly', payload);
         vendorData.percentage.value = percentage;
         vendorData.percentage.saveSuccessful();
      } catch {
         vendorData.percentage.saveFailed();
      }
   },

   /** Save a yearly vendor amount while managing the status of the corresponding VendorQuantity */
   async saveNetAmount({state}, {companyId, year, account, vendorId, netAmount}) {
      const vendors = account ? state.accountData[year][account] : state.adHocData[year];
      const vendorData = vendors.find((vd) => vd.id === vendorId);

      let percentage = vendorData.percentage.value;
      const payload = {
         companyId,
         yearly: [
            {
               vendorId,
               year,
               netAmount,
               percentage,
               companyId,
            },
         ],
      };

      vendorData.netAmount.saveStarted();
      try {
         await this._vm.$http.post('/api/cloudcomputing/yearly', payload);
         vendorData.netAmount.value = netAmount;
         vendorData.netAmount.saveSuccessful();
      } catch {
         vendorData.netAmount.saveFailed();
      }
   },

   //
   // SECTION ASSIGNMENT
   //

   /** Load section assignment */
   async loadSectionAssignment({commit}, {companyId}) {
      const data = (await this._vm.$http.get(`/api/company/${companyId}/cloudcomputing/assignment`))
         .data;
      commit(
         'companies/setCloudComputingAssignments',
         {
            assignments: data,
         },
         {root: true}
      );
   },

   /** Assign the cloud computing section to a user */
   async assignSection({commit}, {companyId, assignerId, assigneeId}) {
      const newAssignments = (
         await this._vm.$http.put(`/api/company/${companyId}/cloudcomputing/assignment`, {
            assignerId,
            assigneeId,
         })
      ).data;
      commit(
         'companies/setCloudComputingAssignments',
         {
            assignments: newAssignments,
         },
         {root: true}
      );
   },

   /** Delete the current assignment without completing the assignment */
   async unassignSection({commit}, {companyId}) {
      const newAssignments = (
         await this._vm.$http.delete(`/api/company/${companyId}/cloudcomputing/assignment`)
      ).data;
      commit(
         'companies/setCloudComputingAssignments',
         {
            assignments: newAssignments,
         },
         {root: true}
      );
   },

   /** Complete section assignment */
   async completeSectionAssignment({commit}, {companyId}) {
      const data = (
         await this._vm.$http.put(`/api/company/${companyId}/cloudcomputing/assignment/complete`)
      ).data;
      commit(
         'companies/setCloudComputingAssignments',
         {
            assignments: data,
         },
         {root: true}
      );
   },

   //
   // DOCUMENTS
   //

   /** Load the UploadCategory for cloud computing documentation */
   async loadDocuments({dispatch, commit}, {companyId}) {
      const uploadCategory = await dispatch(
         'uploads/loadSpecialCategory',
         {
            companyId,
            category: SpecialCategories.CLOUDCOMPUTING_DOCS,
         },
         {root: true}
      );
      commit('setCloudUploadCategoryId', {
         uploadCategoryId: uploadCategory.id,
      });
   },

   /** Upload to the cloud computing documents upload category */
   async uploadDocuments({state, dispatch}, {files}) {
      if (files.length === 0) {
         return;
      }
      const validation = {
         type: SpecialCategories.CLOUDCOMPUTING_DOCS,
      };

      await dispatch(
         'uploads/uploadFiles',
         {
            uploadCategoryId: state.cloudUploadCategoryId,
            year: null,
            files,
            validation,
         },
         {root: true}
      );
   },

   /** Delete a cloud computing document */
   async deleteDocument({state, dispatch}, {fileId, force = false}) {
      await dispatch(
         'uploads/deleteFile',
         {
            fileId,
            uploadCategoryId: state.cloudUploadCategoryId,
            force,
         },
         {root: true}
      );
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
