const state = () => ({
   alertVisible: false,
   alertVisibleSeconds: 0,
   alertMessage: 'An error occurred.',
   alertVariant: 'danger',
});

const getters = {
   alertVisible(state) {
      return state.alertVisible;
   },

   alertMessage(state) {
      return state.alertMessage;
   },

   alertVariant(state) {
      return state.alertVariant;
   },
};

const mutations = {
   // Set the visibility of the boolean-toggled alert message.
   setAlertVisible(state, value) {
      state.alertVisible = value;
   },

   // Set the number of seconds to show the timed alert message.
   setAlertVisibleSeconds(state, value) {
      state.alertVisibleSeconds = value;
   },

   // Set the alert message.
   setAlertMessage(state, value) {
      state.alertMessage = value;
   },

   /* Display an error message.

   Setting the error message, by order of priority:
      1. `msg`: Provide the message as a string.
      2. `response`: Provide the response from an HTTP request.
         a. If `payload.override` is defined, and has a key matching the error code in the
            `response`, this message will be used.
         b. The error message provided by the server will be used.
         c. If the error message from the server can't be used, `fallbackMsg` will be used.
      3. If no message is provided, the default message 'An error occurred' will be displayed.

   Setting the alert visibility:
      * `seconds`: Sets the number of seconds the alert is to remain visible. The user
        also has the ability to manually dismiss the alert.
      * If no argument is provided, an alert will be shown until the user
        dismisses the alert, or a new alert is displayed.
   */
   showAlert(
      state,
      {
         msg = null,
         response = null,
         override = null,
         fallbackMsg = null,
         variant = 'danger',
         seconds = null,
      }
   ) {
      if (msg) {
         state.alertMessage = msg;
      } else if (response) {
         try {
            const errorCode = response.data.errors[0].code;
            if (errorCode && override && errorCode in override) {
               state.alertMessage = override[errorCode];
            } else {
               state.alertMessage = response.data.errors[0].detail;
            }
         } catch {
            if (fallbackMsg) {
               state.alertMessage = fallbackMsg;
            } else {
               state.alertMessage = 'An error occurred';
            }
         }
      } else {
         state.alertMessage = 'An error occurred';
      }

      state.alertVariant = variant;

      if (seconds) {
         state.alertVisibleSeconds = 0;
         state.alertVisibleSeconds = seconds;
      } else {
         state.alertVisible = true;
      }
   },
};

export default {
   namespaced: false,
   state,
   getters,
   mutations,
};
