<template>
   <b-container>
      <h1 class="mb-3">Edit Company Questionnaire</h1>
      <QuestionnaireForm :duplicateTitles="duplicateTitles" @submit="submit" />
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import QuestionnaireForm from './widgets/QuestionnaireForm';

export default {
   components: {
      QuestionnaireForm,
   },

   async created() {
      await this.fetchData();
   },

   data() {
      return {
         duplicateTitles: [],
      };
   },

   computed: {
      ...mapGetters({
         questions: 'questionnaire/questions',
         title: 'questionnaire/title',
      }),

      questionnaireId() {
         return this.$route.params.questionnaireId;
      },

      companyId() {
         return this.$route.params.id;
      },
   },

   methods: {
      /** Load the questionnaire data */
      async fetchData() {
         try {
            await this.blockingRequest('questionnaire/loadCompanyQuestionnaire', {
               questionnaireId: this.questionnaireId,
            });
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Failed to fetch questionnaire data',
               seconds: 5,
            });
         }
         if (0 === this.questions.length) {
            this.addQuestion();
         }
      },

      /** Submit the edit questionnaire request */
      async submit() {
         try {
            await this.blockingRequest('questionnaire/editQuestionnaire', {
               questionnaireId: this.questionnaireId,
            });
            this.$router.push({name: 'company-detail', params: {id: this.companyId}});
         } catch (err) {
            if (err.response && err.response.data && err.response.data.errors) {
               const error = err.response.data.errors[0];
               if (error.code == this.$errorCodes().DUPLICATE) {
                  this.duplicateTitles.push(this.title);
               }
            }
            this.$store.commit('showAlert', {
               response: err.response,
               override: {
                  [this.$errorCodes().DUPLICATE]:
                     'A company questionnaire with this title already exists in the current study.',
               },
               fallbackMsg: 'Submission failed',
               seconds: 5,
            });
         }
      },

      /** Add a blank question to the questionnaire */
      addQuestion(index = null) {
         this.$store.commit('questionnaire/addQuestion', {index});
      },
   },
};
</script>
