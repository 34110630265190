<template>
   <g>
      <path
         d="M10.8545 15.4439H13.1453V8.57149H16.5815L11.9999 2.84448L7.41827 8.57149H10.8545V15.4439Z"
      />
      <path
         d="M21.1634 18.8801H2.83694V10.8623H0.546143V18.8801C0.546143 20.1435 1.57357 21.1709 2.83694 21.1709H21.1634C22.4267 21.1709 23.4542 20.1435 23.4542 18.8801V10.8623H21.1634V18.8801Z"
      />
   </g>
</template>

<script>
export default {
   data() {
      return {
         name: 'icon-uploads',
         viewBox: '0 0 24 25',
      };
   },
};
</script>
