import ConfigureUploads from '@/views/uploads/ConfigureUploads';

export default [
   {
      path: '/company/:id/uploads',
      name: 'configure-uploads',
      component: ConfigureUploads,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Configure Categories'},
         ],
         authGroup: 'rndig',
      },
   },
];
