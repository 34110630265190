<template>
   <div class="dialog-container">
      <div class="message-window" @scroll="onScroll" ref="msgWindow">
         <Message
            ref="messages"
            v-for="(msg, idx) in messages"
            :key="msg.id"
            :message="msg"
            :first-unseen="firstUnseenMessageIdx === idx"
            :idx="idx"
         />
         <div class="anchor" ref="anchor"></div>
      </div>

      <div v-if="noConnection" class="msg-reconnect">
         <b-spinner variant="gray" small class="mr-2"></b-spinner>
         <span> Messaging connection lost. Reconnecting... </span>
      </div>

      <div class="input-container">
         <b-form-textarea
            ref="messageInput"
            v-model="messageContent"
            class="msg-input scrollbar"
            @keyup.ctrl.enter="send"
            :rows="lineCount"
            :disabled="noConnection"
            placeholder="Enter a message. Press ctrl+enter to send."
         ></b-form-textarea>

         <div class="send-container">
            <b-button
               class="msg-send"
               @click="send"
               :disabled="noConnection || messageContent.length === 0"
            >
               <custom-icon icon="icon-send" />
            </b-button>
            <span class="help-text">ctrl+enter</span>
         </div>
      </div>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Message from './Message';

export default {
   components: {
      Message,
   },

   data() {
      return {
         messageContent: '',
      };
   },

   computed: {
      ...mapGetters({
         threadId: 'messaging/threadId',
         messages: 'messaging/messages',
         thread: 'messaging/currentThread',
         clientMessagingCollapsed: 'messaging/clientMessagingCollapsed',
         wsOpen: 'wsOpen',
      }),

      noConnection() {
         // disabled if the WebSocket is not OPEN
         return !this.wsOpen;
      },

      lineCount() {
         let lines = this.messageContent.split('\n').length;
         lines = Math.max(2, lines);
         lines = Math.min(lines, 10);
         return lines;
      },

      anyUnread() {
         return !!this.thread?.unread;
      },

      firstUnseenMessageIdx() {
         return this.messages.findIndex((msg) => !msg.seen);
      },
   },

   methods: {
      setInitialScroll() {
         let el;
         if (this.firstUnseenMessageIdx >= 0) {
            el = this.$refs.messages[this.firstUnseenMessageIdx].$el;
         } else {
            el = this.$refs.anchor;
         }
         el.scrollIntoView();
      },

      focusMessageInput() {
         this.$refs.messageInput.focus();
      },

      seeAllMessagesIfScrolledToBottom() {
         // Consider messages seen if the user has scrolled to the bottom of the log
         const {scrollTop, offsetHeight, scrollHeight} = this.$refs.msgWindow;
         const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 1;
         if (this.anyUnread && scrolledToBottom) {
            this.$store.dispatch('messaging/seeAllMessages');
         }
      },

      onScroll() {
         if (this.clientMessagingCollapsed) {
            return;
         }
         this.seeAllMessagesIfScrolledToBottom();
      },

      isScrollable() {
         const el = this.$refs.msgWindow;
         if (!el) {
            return null;
         }
         return el.scrollHeight > el.clientHeight;
      },

      send() {
         if (this.messageContent.length === 0) {
            return;
         }
         this.$store.dispatch('messaging/postMessage', {
            threadId: this.threadId,
            content: this.messageContent,
         });
         this.messageContent = '';
      },
   },

   mounted() {
      this.$emit('mounted');
   },
};
</script>

<style lang="scss" scoped>
.dialog-container {
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 100%;
   width: 100%;

   .message-window {
      flex-shrink: 2;
      padding: 0.5rem 1rem;
      overflow-y: scroll;
      background-color: $gray-100;
      &::-webkit-scrollbar {
         display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;

      /* keep the message window scrolled to the bottom
      https://css-tricks.com/books/greatest-css-tricks/pin-scrolling-to-bottom/ */
      * {
         overflow-anchor: none;
      }

      .anchor {
         overflow-anchor: auto;
         height: 1px;
      }
   }

   .msg-reconnect {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
   }

   .input-container {
      flex-shrink: 0;
      padding: 1rem;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .msg-input {
         flex-shrink: 2;
         resize: none;
         border: none;
         background-color: white;
         border: solid 1px $primary-800;
      }

      .send-container {
         display: flex;
         flex-direction: column;
         align-items: center;
         margin-left: 1rem;

         .help-text {
            display: inline-block;
            font-size: 0.75rem;
            color: $gray-650;
         }
      }

      .msg-send {
         background-color: $primary;
         border-color: $primary;
         flex-shrink: 0;
         border-radius: 50%;
         width: 40px;
         height: 40px;
         display: flex;
         align-items: center;
         justify-content: center;
         padding: 0;
         transition: all 0.3s ease;

         svg {
            margin-left: 4px;
         }
      }

      .msg-send:disabled {
         background-color: $btn-disabled-bg;
         border-color: $btn-disabled-bg;
      }
   }
}
</style>
