<template>
   <p :id="id" v-if="show">
      {{
         percentageToProjectSumDiff > 0
            ? `You still have ${percentageToProjectSumDiff}% left to allocate to qualified R&D projects.`
            : `Project time total is greater than total R&D time by ${
                 -1 * percentageToProjectSumDiff
              }%.`
      }}
   </p>
</template>

<script>
import {mapGetters} from 'vuex';

import EmployeeTimeData from '../mixins/employeeTimeData';

export default {
   mixins: [EmployeeTimeData],

   props: {
      employeeId: [String, Number],
      ident: {
         type: [String, Number],
         default: null,
      },
      employeeValidation: Object,
   },

   computed: {
      ...mapGetters({
         _percentageToProjectSumDiff: 'timesurvey/percentageToProjectSumDiff',
      }),

      id() {
         let id = 'msg-total-percentage';
         if (this.ident) {
            id += `-${this.ident}`;
         }
         return id;
      },

      /** Is the employee data in a state to display this message? */
      show() {
         return (
            this.$percentage !== null &&
            this.$percentage !== '' &&
            !this.employeeValidation.$invalid &&
            this.percentageToProjectSumDiff !== 0
         );
      },

      /** The difference between the declared total percentage and the project sum */
      percentageToProjectSumDiff() {
         return this._percentageToProjectSumDiff(this.employeeId);
      },
   },
};
</script>
