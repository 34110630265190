import Vue from 'vue';

import ErrorCodes from '@/helpers/errorCodes';
import {sortBy} from '../../helpers/utils';

const state = () => ({
   activities: {},
});

const getters = {
   /** Activities sorted by name */
   activities: (state) => Object.values(state.activities).sort(sortBy('name')),

   activitiesMap: (state) => state.activities,
};

const mutations = {
   clear: (state) => {
      state.activities = {};
   },

   setActivities: (state, {activities}) => {
      activities.forEach((activity) => {
         Vue.set(state.activities, activity.id, activity);
      });
   },

   updateActivity: (state, {activity}) => {
      Vue.set(state.activities, activity.id, activity);
   },

   deleteActivity: (state, {activityId}) => {
      if (activityId in state.activities) {
         Vue.delete(state.activities, activityId);
      }
   },
};

const actions = {
   // Import default activities from an industry
   async importFromIndustry({commit}, {companyId, industryId, activities}) {
      const results = (
         await this._vm.$http.post(`/api/company/${companyId}/activity/import`, {
            industryId,
            activities,
         })
      ).data.results;
      commit('clear');
      commit('setActivities', {activities: results});
   },

   // Load the activities for a company
   async loadActivities({commit}, {companyId}) {
      const activities = (await this._vm.$http.get(`/api/company/${companyId}/activity`)).data
         .results;
      commit('clear');
      commit('setActivities', {activities});
   },

   // Save an activity
   async saveNewActivity({commit}, {companyId, activity}) {
      const newActivity = (await this._vm.$http.post('/api/activity', {...activity, companyId}))
         .data;
      commit('updateActivity', {activity: newActivity});
   },

   // Edit an activity
   async editActivity({commit}, {activity}) {
      const newActivity = (await this._vm.$http.put(`/api/activity/${activity.id}`, activity)).data;
      commit('updateActivity', {activity: newActivity});
   },

   /** Delete an activity */
   async deleteActivity({commit}, {activityId, force = false}) {
      const params = {force};
      try {
         await this._vm.$http.delete(`/api/activity/${activityId}`, {params});
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.CONFIRMATION_REQUIRED) {
            return err.response.data.errors[0].detail;
         }
         throw err;
      }
      commit('deleteActivity', {activityId});
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
