<template>
   <b-modal
      id="modal-edit-contractor"
      title="Edit Contract Researcher"
      centered
      size="xl"
      ok-title="Save"
      ok-variant="success"
      @ok="submit"
      :ok-disabled="$v.$invalid || isComplete"
      ok-only
   >
      <p v-if="!isComplete">All fields are required to mark this section complete.</p>

      <ContractorForm :contractorId="contractor.id" edit :validation="$v.contractor" />
   </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import {MAX_INTERNAL_FILE_SIZE} from '@/helpers/constants';
import ContractorForm from './ContractorForm';

export default {
   components: {
      ContractorForm,
   },

   computed: {
      ...mapGetters({
         isComplete: 'contractors/isComplete',
         contractor: 'contractors/editContractor',
         contractors: 'contractors/contractors',
         contractorMap: 'contractors/contractorMap',
         financialData: 'contractors/financialData',
         timeData: 'contractorTime/data',
      }),
   },

   methods: {
      show(contractorId) {
         this.$store.commit('contractors/loadContractorForEdit', {contractorId});
         this.$bvModal.show('modal-edit-contractor');
      },

      async submit(event) {
         const contractor = this.contractor;
         const financialData = this.financialData[contractor.id];
         const timeData = this.timeData[contractor.id];
         const savedContractor = this.contractorMap[contractor.id];

         // Changing the year range on a contractor can cause related data to be deleted. We'll
         // do our best to warn the user before that happens, though the user may not have
         // read permissions for the data that would be deleted.
         let isFinancialDataTruncated;
         let isTimeDataTruncated;

         if (financialData) {
            // If the user has access to financial data, check for any data outside the
            // new year range for the contractor.
            isFinancialDataTruncated = Object.entries(financialData).some(([year, data]) => {
               return (
                  (year < contractor.yearsLower || year > contractor.yearsUpper) &&
                  data.invoiced !== null
               );
            });
         } else {
            // The user has no access to financial data, so just check for a shrinking year range
            isFinancialDataTruncated =
               contractor.years.lower > savedContractor.years.lower ||
               contractor.years.upper < savedContractor.years.upper;
         }

         if (timeData) {
            // If the user has access to time data, check for any data outside the
            // new year range for the contractor.
            isTimeDataTruncated = Object.entries(timeData).some(([year, data]) => {
               if (year < contractor.yearsLower || year > contractor.yearsUpper) {
                  return Object.values(data.projects).some((percentage) => percentage !== null);
               }
               return false;
            });
         } else {
            // The user has no access to time data, so just check for a shrinking year range
            isTimeDataTruncated =
               contractor.years.lower > savedContractor.years.lower ||
               contractor.years.upper < savedContractor.years.upper;
         }

         if (isFinancialDataTruncated || isTimeDataTruncated) {
            event.preventDefault();

            const dataStrs = [];
            if (isFinancialDataTruncated) {
               dataStrs.push('financial data');
            }
            if (isTimeDataTruncated) {
               dataStrs.push('R&D time data');
            }

            const proceedWithSubmit = await this.$bvModal.msgBoxConfirm(
               `Changing the Years Active on this contract researcher will result in ${dataStrs.join(
                  ' and '
               )} being lost. Do you wish to proceed?`,
               {
                  id: 'modal-truncate-financial-data',
                  title: 'Truncate Data?',
                  centered: true,
                  okTitle: 'Proceed',
               }
            );

            if (!proceedWithSubmit) {
               return;
            }

            this.$bvModal.hide('modal-edit-contractor');
         }

         await this.blockingRequest('contractors/updateContractor');
      },
   },

   validations: {
      contractor: {
         fullname: {
            required,
            minLength(value) {
               if (value === null) {
                  return false;
               }
               return value.trim().length >= 3;
            },
            unique(fullname, thisContractor) {
               return !this.contractors
                  .filter((contractor) => {
                     return contractor.id !== thisContractor.id && contractor.fullname !== null;
                  })
                  .some((contractor) => {
                     return contractor.fullname === fullname;
                  });
            },
         },
         uploadFiles: {
            sizeLimit(files) {
               const fileSize = files.map((file) => file.size).reduce((sum, item) => sum + item, 0);
               return fileSize < MAX_INTERNAL_FILE_SIZE;
            },
         },
      },
   },
};
</script>
