<template>
   <b-container>
      <h1 v-if="project" class="mb-3">Project Questionnaire for {{ project.name }}</h1>
      <QuestionnaireForm is-project is-edit :duplicateTitles="duplicateTitles" @submit="submit" />
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import QuestionnaireForm from './widgets/QuestionnaireForm';

export default {
   components: {
      QuestionnaireForm,
   },

   async created() {
      await this.fetchData();
   },

   data() {
      return {
         duplicateTitles: [],
      };
   },

   computed: {
      ...mapGetters({
         questions: 'questionnaire/questions',
         project: 'questionnaire/project',
         title: 'questionnaire/title',
      }),

      companyId() {
         return this.$route.params.id;
      },

      questionnaireId() {
         return this.$route.params.questionnaireId;
      },
   },

   methods: {
      /** Load the project questionnaire data */
      async fetchData() {
         await this.blockingRequest('questionnaire/loadProjectQuestionnaire', {
            questionnaireId: this.questionnaireId,
            joinProject: true,
            addProjectYearsQuestion: false,
         });
         if (0 === this.questions.length) {
            this.addQuestion();
         }
      },

      /** Submit an edit questionnaire request */
      async submit() {
         try {
            await this.blockingRequest('questionnaire/editQuestionnaire', {
               questionnaireId: this.questionnaireId,
            });
            this.$router.push({name: 'company-detail', params: {id: this.companyId}});
         } catch (err) {
            if (err.response && err.response.data && err.response.data.errors) {
               const error = err.response.data.errors[0];
               if (error.code == this.$errorCodes().DUPLICATE) {
                  this.duplicateTitles.push(this.title);
               }
            }
            this.$store.commit('showAlert', {
               response: err.response,
               override: {
                  [this.$errorCodes().DUPLICATE]:
                     'A project questionnaire with this title already exists in the current study.',
               },
               fallbackMsg: 'Submission failed',
               seconds: 5,
            });
         }
      },

      /** Add a blank question to the questionnaire */
      addQuestion(index = null) {
         this.$store.commit('questionnaire/addQuestion', {index});
      },
   },
};
</script>
