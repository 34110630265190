<template>
   <div>
      <b-form-radio-group
         id="radios-answer"
         :checked="answerValue"
         :options="options"
         @change="onChange"
         buttons
         button-variant="outline-primary"
         v-if="!readonly"
      ></b-form-radio-group>
      <div v-else :id="`answer-${index + 1}`">
         <div v-if="answerValue !== null">
            {{ answerValue ? 'Yes' : 'No' }}
         </div>
         <div v-else>
            <b>Not Answered</b>
         </div>
      </div>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   props: {
      index: Number,
      readonly: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      ...mapGetters({
         questions: 'questionnaire/questions',
      }),

      question() {
         return this.questions[this.index];
      },

      answer() {
         return this.question.answer;
      },

      options() {
         return [
            {text: 'No', value: false},
            {text: 'Yes', value: true},
         ];
      },

      answerValue() {
         if (this.answer) {
            return this.answer.answer;
         } else {
            return null;
         }
      },
   },

   methods: {
      onChange(value) {
         this.$store.commit('questionnaire/setAnswerValue', {
            questionIndex: this.index,
            value,
         });
      },
   },

   watch: {
      answerValue: {
         immediate: true,
         handler() {
            this.$emit('validate', true);
         },
      },
   },
};
</script>
