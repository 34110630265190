<template>
   <div>
      <b-container>
         <PageTitle title="Questionnaires" />
      </b-container>
      <QuestionnaireAnswerForm v-if="loaded" @refresh="fetchData" is-project />
   </div>
</template>

<script>
import QuestionnaireAnswerForm from './widgets/QuestionnaireAnswerForm.vue';

export default {
   components: {
      QuestionnaireAnswerForm,
   },

   async created() {
      await this.fetchData();
      this.loaded = true;
   },

   data() {
      return {
         loaded: false,
      };
   },

   computed: {
      projectId() {
         return this.$route.params.projectId;
      },

      questionnaireId() {
         return this.$route.params.questionnaireId;
      },

      companyId() {
         return this.$route.params.id;
      },
   },

   methods: {
      // Load the questionnaire data
      async fetchData() {
         const requests = [];

         requests.push(
            this.$store.dispatch('questionnaire/loadProjectQuestionnaire', {
               questionnaireId: this.questionnaireId,
               joinProject: true,
            })
         );

         requests.push(this.$store.dispatch('users/loadCompanyUsers', {companyId: this.companyId}));

         await this.blockUntilAllSettled(requests);
         this.loaded = true;
      },
   },
};
</script>
