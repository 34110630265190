<template>
   <b-container v-if="ready">
      <h2 class="mt-3 mb-4">Export Data</h2>

      <!-- STATUS: NONE -->
      <b-alert variant="primary" :show="status.state === 'NONE'">
         <h6 class="font-weight-normal mb-3">
            No Data Exports Have Been Prepared For This Company
         </h6>

         <div class="text-dark">
            <p>
               Click <b>Prepare Data Export</b> to start preparing a data export for this company.
               This may take several minutes. Once prepared, all data currently available for this
               company may be downloaded.
            </p>
         </div>

         <div>
            <b-button
               id="btn-prepare-export"
               variant="white"
               size="sm"
               v-b-modal.modal-confirm-export
            >
               Prepare Data Export
            </b-button>
         </div>
      </b-alert>

      <!-- STATUS: PENDING -->
      <b-alert variant="warning" :show="status.state === 'PENDING'">
         <h6 class="font-weight-normal mb-3">Data Export is Being Prepared</h6>

         <div class="text-dark">
            <p>
               A data export was requested at <b> {{ exportedAt }} </b> with data from
               <b>{{ yearRange }}</b
               >, and is currently being prepared by the server.
            </p>
         </div>

         <div class="d-flex justify-content-center">
            <b-spinner></b-spinner>
         </div>
      </b-alert>

      <!-- STATUS: COMPLETED -->
      <b-alert variant="success" :show="status.state === 'COMPLETED'">
         <h6 class="font-weight-normal mb-3">Data Export is Ready For Download</h6>

         <div class="text-dark">
            <p>
               Data was exported at <b> {{ exportedAt }} </b> with data from
               <b>{{ yearRange }}</b> and is ready for download. If any data has changed or been
               added since the data export was prepared, a new data export must be prepared.
            </p>
            <p class="text-muted">File size: {{ formatBytes(status.size) }}</p>
         </div>

         <div class="d-flex justify-content-between">
            <div>
               <b-button
                  id="btn-download-export"
                  variant="white"
                  size="sm"
                  class="mr-3"
                  @click="downloadData"
               >
                  Download Data Export
               </b-button>
               <b-button
                  id="btn-prepare-export"
                  variant="white"
                  size="sm"
                  class="mr-3"
                  v-b-modal.modal-confirm-export
               >
                  Prepare a New Data Export
               </b-button>
            </div>

            <b-button
               id="btn-delete-export"
               v-if="isAdmin"
               size="sm"
               variant="white"
               @click="deleteExport"
               >Delete Current Export</b-button
            >
         </div>
      </b-alert>

      <b-alert variant="danger" :show="status.state === 'ERROR'">
         <h6 class="font-weight-normal mb-3">Error</h6>

         <div class="text-dark">
            <p>An error occurred while preparing your data export:</p>
            <b-alert class="company-export-error-window" :show="status.error != null">
               {{ status.error }}
            </b-alert>
         </div>

         <b-button
            id="btn-prepare-export"
            variant="white"
            size="sm"
            class="mr-3"
            v-b-modal.modal-confirm-export
         >
            Prepare a New Data Export
         </b-button>
      </b-alert>

      <b-modal
         id="modal-confirm-export"
         title="Prepare Data Export"
         centered
         @ok="prepareExport"
         ok-title="Submit"
      >
         <p v-if="status.state === 'COMPLETED'">
            Are you sure you want to prepare a new data export? The existing data export will be
            overwritten.
         </p>
         <p>
            Select the years for which you'd like to export data. Leave these fields blank to export
            all data.
         </p>
         <b-row>
            <b-col>
               <b-form-group label="Earliest Year" class="mb-0">
                  <YearSelect
                     index="select-from-year"
                     v-model="fromYear"
                     emptyText="----"
                     tax-years
                     :maxYear="toYear"
                  />
               </b-form-group>
            </b-col>
            <b-col>
               <b-form-group label="Latest Year" class="mb-0">
                  <YearSelect
                     index="select-to-year"
                     v-model="toYear"
                     emptyText="----"
                     tax-years
                     :minYear="fromYear"
                  />
               </b-form-group>
            </b-col>
         </b-row>
      </b-modal>
   </b-container>
</template>

<script>
import {MIN_TAX_YEAR} from '@/helpers/constants';
import {formatBytes} from '@/helpers/utils';
import {mapGetters} from 'vuex';
import YearSelect from '@/components/forms/YearSelect';

export default {
   props: ['companyId'],

   components: {
      YearSelect,
   },

   async created() {
      this.ready = false;
      await this.blockingRequest('exports/loadStatus', {companyId: this.companyId});
      this.startPolling();
      this.ready = true;
   },

   // Stop polling when this component is destroyed
   beforeDestroy() {
      this.stopPolling();
   },

   data() {
      return {
         ready: false,
         polling: null,
         fromYear: null,
         toYear: null,
         MIN_TAX_YEAR,
      };
   },

   computed: {
      ...mapGetters(['isAdmin']),

      /** The current export status */
      status() {
         return this.$store.getters['exports/statusByCompanyId'](this.companyId);
      },

      /** When the export was requested */
      exportedAt() {
         return new Date(this.status.exportedAt).toLocaleString();
      },

      /** The current year */
      currentYear() {
         return new Date().getFullYear();
      },

      yearRange() {
         if (this.status.fromYear && this.status.toYear) {
            return `${this.status.fromYear} to ${this.status.toYear}`;
         } else if (this.status.fromYear) {
            return `${this.status.fromYear} to present`;
         } else if (this.status.toYear) {
            return `years prior to ${this.status.toYear}`;
         } else {
            return `all years`;
         }
      },
   },

   methods: {
      /**  Format an int representing bytes */
      formatBytes,

      /** Start polling for export status */
      startPolling() {
         this.polling = setInterval(() => {
            this.$store.dispatch('exports/loadStatus', {companyId: this.companyId});
         }, 3000);
      },

      /** Stop polling for export status */
      stopPolling() {
         clearInterval(this.polling);
      },

      /** Request the server to start preparing a data export */
      async prepareExport() {
         await this.blockingRequest('exports/prepareExport', {
            companyId: this.companyId,
            fromYear: this.fromYear,
            toYear: this.toYear,
         });
      },

      /** Request that the current export be deleted */
      async deleteExport() {
         await this.blockingRequest('exports/deleteExport', {companyId: this.companyId});
      },

      /** Download a data export */
      async downloadData() {
         await this.$store.dispatch('exports/download', {companyId: this.companyId});
      },
   },

   watch: {
      status(status) {
         if (status.state === 'PENDING') {
            this.startPolling();
         } else {
            this.stopPolling();
         }
      },
   },
};
</script>

<style>
.company-export-error-window {
   color: #e9e5e0 !important;
   background-color: #2b2b2c !important;
   border: none !important;
   font-family: 'PT Mono', monospace;
}
</style>
