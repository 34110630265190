<template>
   <b-card variant="white" class="mx-auto standard-width">
      <b-form id="company-form" @submit.prevent>
         <h2 class="mb-3">General Information</h2>
         <!-- Company Name -->
         <b-form-group
            label="Company Name"
            label-for="input-company-name"
            invalid-feedback="Required"
         >
            <b-form-input
               id="input-company-name"
               ref="name"
               v-model="form.companyName"
               trim
               :state="!$v.form.companyName.$invalid"
            />
         </b-form-group>

         <b-form-row>
            <b-col md="6">
               <!-- FEIN -->
               <b-form-group
                  label="FEIN#"
                  label-for="input-fein"
                  invalid-feedback="Please enter a 9 digit number"
               >
                  <b-form-input
                     id="input-fein"
                     v-model="form.fein"
                     trim
                     :formatter="formatFein"
                     :state="form.fein ? !$v.form.fein.$invalid : null"
                  ></b-form-input>
               </b-form-group>
            </b-col>

            <b-col md="6">
               <!-- Incorporation Date -->
               <b-form-group label="Date of Incorporation" label-for="input-incorporation-date">
                  <v-date-picker v-model="form.incorporated" :input-debounce="500">
                     <template v-slot="{inputValue, inputEvents}">
                        <input
                           id="input-incorporation-date"
                           class="form-control"
                           :class="{
                              'is-valid': form.incorporated && !$v.form.incorporated.$invalid,
                              'is-invalid': form.incorporated && $v.form.incorporated.$invalid,
                           }"
                           :value="inputValue"
                           v-on="inputEvents"
                           placeholder="mm/dd/yyyy"
                        />
                     </template>
                  </v-date-picker>
               </b-form-group>
            </b-col>
         </b-form-row>

         <b-form-row>
            <b-col md="6">
               <!-- Entity Type -->
               <b-form-group label="Entity Type" label-for="input-entity-type">
                  <b-form-select
                     id="input-entity-type"
                     v-model="form.entityType"
                     :options="entityTypes"
                     :state="form.entityType ? !$v.form.entityType.$invalid : null"
                  ></b-form-select>
               </b-form-group>
            </b-col>

            <b-col md="6">
               <!-- Industry -->
               <b-form-group label="Industry" label-for="input-industry">
                  <b-form-select
                     id="input-industry"
                     v-model="form.industryId"
                     :options="industryOptions"
                     :state="form.industryId ? !$v.form.industryId.$invalid : null"
                  >
                     <template #first>
                        <b-form-select-option :value="null" disabled>
                           Please select one
                        </b-form-select-option>
                     </template>
                  </b-form-select>
               </b-form-group>
            </b-col>
         </b-form-row>

         <b-form-row>
            <b-col md="6">
               <!-- Filing Deadline -->
               <b-form-group label="Filing Deadline" label-for="input-filing-deadline">
                  <v-date-picker v-model="form.filingDeadline" :input-debounce="500">
                     <template v-slot="{inputValue, inputEvents}">
                        <input
                           id="input-filing-deadline"
                           class="form-control"
                           :class="{
                              'is-valid': form.filingDeadline && !$v.form.filingDeadline.$invalid,
                              'is-invalid': form.filingDeadline && $v.form.filingDeadline.$invalid,
                           }"
                           :value="inputValue"
                           v-on="inputEvents"
                           placeholder="mm/dd/yyyy"
                        />
                     </template>
                  </v-date-picker>
               </b-form-group>
            </b-col>

            <b-col md="6">
               <!-- Tax Year End -->
               <b-form-group label="Tax Year End" label-for="input-tax-year-end">
                  <v-date-picker v-model="form.taxYearEnd" :input-debounce="500">
                     <template v-slot="{inputValue, inputEvents}">
                        <input
                           id="input-tax-year-end"
                           class="form-control"
                           :class="{
                              'is-valid': form.taxYearEnd && !$v.form.taxYearEnd.$invalid,
                              'is-invalid': form.taxYearEnd && $v.form.taxYearEnd.$invalid,
                           }"
                           :value="inputValue"
                           v-on="inputEvents"
                           placeholder="mm/dd/yyyy"
                        />
                     </template>
                  </v-date-picker>
               </b-form-group>
            </b-col>
         </b-form-row>

         <!-- Payroll Tax Election -->
         <b-form-checkbox
            id="cb-payroll-tax-election"
            class="mb-4"
            v-model="form.payrollTaxElection"
         >
            Payroll Tax Election
         </b-form-checkbox>

         <!-- Federal Credit Years -->
         <b-form-row class="mb-3">
            <b-col class="d-flex align-items-center">
               <h3 class="d-inline-block mb-0">Federal Credit Years</h3>
            </b-col>
            <b-col class="d-flex align-items-center justify-content-end">
               <div id="btn-add-federal-year" class="add-btn" @click="addFederalYear">
                  <b-icon icon="plus-circle" v-b-tooltip.left="'Add a year'"></b-icon>
               </div>
            </b-col>
         </b-form-row>
         <CreditYearsSelect v-model="form.federalCreditYears.years" class="mb-3" index="fcy-" />

         <!-- State Credit Years -->
         <b-form-row class="mb-3">
            <b-col class="d-flex align-items-center">
               <h3 class="d-inline-block mb-0">State Credit Years</h3>
            </b-col>
            <b-col class="d-flex align-items-center justify-content-end">
               <div id="btn-add-state" class="add-btn" @click="addState">
                  <b-icon icon="plus-circle" v-b-tooltip.left="'Add a state'"></b-icon>
               </div>
            </b-col>
         </b-form-row>
         <StateForm
            v-for="(item, index) in form.stateCreditYears"
            :key="'sf' + index"
            :index="index"
            v-model="form.stateCreditYears[index]"
            @remove="removeState(index)"
            class="ml-3"
         />

         <h2 class="mb-3">History</h2>
         <!-- Year of 1st R&D Effort -->
         <b-form-group label="Year of First R&D Effort">
            <YearSelect
               tax-years
               or-earlier
               index="first-effort"
               v-model="form.firstRndEffort"
               :state="form.firstRndEffort ? !$v.form.firstRndEffort.$invalid : null"
            />
         </b-form-group>

         <!-- Latest Credit Generation Year -->
         <b-form-group label="Year of Latest Credit Generation">
            <YearSelect
               tax-years
               or-earlier
               index="latest-credit-generation"
               v-model="form.latestCredit"
               :state="form.latestCredit ? !$v.form.latestCredit.$invalid : null"
            />
         </b-form-group>

         <h2 class="mb-3">Company Contacts</h2>
         <!-- CPA Name -->
         <b-form-group label="CPA Name" label-for="input-cpa-name">
            <b-form-input
               id="input-cpa-name"
               v-model="form.cpaInfo.name"
               trim
               :state="form.cpaInfo.name ? !$v.form.cpaInfo.name.$invalid : null"
            ></b-form-input>
         </b-form-group>

         <!-- CPA Firm -->
         <b-form-group label="CPA Firm Name" label-for="input-cpa-firm">
            <b-form-input
               id="input-cpa-firm"
               v-model="form.cpaInfo.firm"
               trim
               :state="form.cpaInfo.firm ? !$v.form.cpaInfo.firm.$invalid : null"
            ></b-form-input>
         </b-form-group>

         <!-- CPA Email -->
         <b-form-group label="CPA Email" label-for="input-cpa-email">
            <b-form-input
               id="input-cpa-email"
               v-model="form.cpaInfo.email"
               trim
               :state="form.cpaInfo.email ? !$v.form.cpaInfo.email.$invalid : null"
            ></b-form-input>
         </b-form-group>

         <!-- Client Contact Name -->
         <b-form-group label="Client Contact Name" label-for="input-contact-name">
            <b-form-input
               id="input-contact-name"
               v-model="form.contactInfo.name"
               trim
               :state="form.contactInfo.name ? !$v.form.contactInfo.name.$invalid : null"
            ></b-form-input>
         </b-form-group>

         <!-- Client Contact Email -->
         <b-form-group label="Client Contact Email" label-for="input-contact-email">
            <b-form-input
               id="input-contact-email"
               v-model="form.contactInfo.email"
               trim
               :state="form.contactInfo.email ? !$v.form.contactInfo.email.$invalid : null"
            ></b-form-input>
         </b-form-group>

         <b-form-row class="mt-4">
            <b-col class="text-right">
               <b-button
                  id="btn-submit"
                  variant="primary"
                  :disabled="$v.form.$invalid"
                  @click="onSubmit"
               >
                  Submit
               </b-button>
            </b-col>
         </b-form-row>
      </b-form>
   </b-card>
</template>

<script>
import {required, email} from 'vuelidate/lib/validators';
import {mapGetters} from 'vuex';

import StateForm from '@/views/company/widgets/StateForm';
import CreditYearsSelect from '@/views/company/widgets/CreditYearsSelect';
import YearSelect from '@/components/forms/YearSelect';

export default {
   name: 'CompanyForm',

   components: {
      StateForm,
      CreditYearsSelect,
      YearSelect,
   },

   props: {
      company: {
         type: Object,
      },

      isCreate: {
         type: Boolean,
         default: false,
      },
   },

   async mounted() {
      await this.blockingRequest('industries/loadIndustries');
      if (this.isCreate) {
         this.$refs.name.focus();
         this.form.industryId = this.defaultIndustryId;
      }
   },

   data() {
      return {
         form: this.company,
         entityTypes: [
            {value: null, text: 'Please select one'},
            {value: 'S_CORP', text: 'S Corp'},
            {value: 'C_CORP', text: 'C Corp'},
            {value: 'PARTNERSHIP', text: 'Partnership'},
         ],
      };
   },

   computed: {
      ...mapGetters({
         industries: 'industries/industries',
         defaultIndustryId: 'industries/defaultIndustryId',
      }),

      industryOptions() {
         return this.industries.map((industry) => ({value: industry.id, text: industry.name}));
      },
   },

   methods: {
      onSubmit() {
         this.$emit('submit', this.form);
      },

      formatFein(value) {
         value = value.replace(/\D/g, '');
         if (value.length >= 3 && '-' !== value[2]) {
            return value.substring(0, 2) + '-' + value.substring(2);
         }
         return value;
      },

      addFederalYear() {
         this.form.federalCreditYears.years.push(null);
      },

      addState() {
         this.form.stateCreditYears.push({
            state: null,
            years: [null],
         });
      },

      removeState(index) {
         this.form.stateCreditYears.splice(index, 1);
      },
   },

   validations: {
      form: {
         companyName: {
            required,
         },
         filingDeadline: {},
         fein: {
            format(value) {
               if (value) {
                  const re = /^\d{2}-?\d{7}$/;
                  return re.test(value);
               }
               return true;
            },
         },
         entityType: {},
         taxYearEnd: {},
         industryId: {
            required,
         },
         federalCreditYears: {},
         stateCreditYears: {
            $each: {
               state: {},
               years: {},
            },
         },
         incorporated: {},
         firstRndEffort: {},
         latestCredit: {},
         cpaInfo: {
            name: {},
            firm: {},
            email: {
               email,
            },
         },
         contactInfo: {
            name: {},
            email: {
               email,
            },
         },
      },
   },
};
</script>
