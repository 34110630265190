<template>
   <div>
      <b-alert :show="assignee != null" variant="primary" class="mt-3">
         <div class="d-flex align-items-center justify-content-between" v-if="hasSectionAssignment">
            <span id="msg-assignment">This section is currently assigned to you.</span>
            <b-button
               v-if="isSME"
               id="btn-complete-assignment"
               variant="white"
               size="sm"
               @click="completeSection"
            >
               I'm Done
            </b-button>
            <b-button
               v-else
               id="btn-unassign-section"
               variant="white"
               size="sm"
               @click="unassignSection"
            >
               Unassign
            </b-button>
         </div>
         <div class="d-flex align-items-center justify-content-between" v-else>
            <span id="msg-assignment">
               This section is currently assigned to
               <b>{{ assigneeName }}</b
               >.
            </span>
            <b-button id="btn-unassign-section" variant="white" size="sm" @click="unassignSection">
               Unassign
            </b-button>
         </div>
      </b-alert>

      <div
         v-if="!isSME && assignee == null && !isCompleted"
         class="d-flex align-items-center justify-content-end mb-4"
      >
         <div>
            <b-button
               id="btn-assign-section"
               size="sm"
               variants="primary"
               class="mr-2"
               v-b-toggle.collapse-assignments
            >
               Assign Section
            </b-button>
         </div>
      </div>

      <b-collapse id="collapse-assignments">
         <b-card class="mb-4">
            <h2 class="mb-3">Assign this section to another user</h2>

            <b-row class="mb-3">
               <b-col>
                  <b-form-select id="select-user" v-model="selectedUser">
                     <template #first>
                        <b-form-select-option :value="null" disabled>
                           Select a User
                        </b-form-select-option>
                     </template>

                     <b-form-select-option v-for="user in users" :key="user.id" :value="user">
                        {{ formatUserName(user) }}
                     </b-form-select-option>
                  </b-form-select>
               </b-col>

               <b-col class="d-flex align-items-center justify-content-end">
                  <b-button id="btn-add-user" variant="primary" @click="addUserModal">
                     Invite User
                     <b-icon-plus />
                  </b-button>
               </b-col>
            </b-row>

            <b-button
               id="btn-assign-user"
               variant="primary"
               @click="assignSection"
               :disabled="!selectedUser"
            >
               Assign User
            </b-button>
         </b-card>
         <CreateClientModal></CreateClientModal>
      </b-collapse>
   </div>
</template>

<script>
// DATA REQUIREMENTS:
//   * Load the cloud computing section assignment
//   * Load company users

import {mapGetters} from 'vuex';

import CreateClientModal from '@/views/user/widgets/CreateClientModal';

export default {
   components: {
      CreateClientModal,
   },

   async created() {
      await this.fetchData();
   },

   props: {
      section: {
         type: String,
      },
   },

   data() {
      return {
         selectedUser: null,
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         isSME: 'isSME',
         _users: 'users/companyUsers',
      }),

      hasSectionAssignment() {
         return this.$store.getters[`${this.section}/hasSectionAssignment`];
      },

      isCompleted() {
         return this.$store.getters[`${this.section}/isCompleted`];
      },

      assignee() {
         const assignee = this.$store.getters[`${this.section}/assignee`];

         if (assignee === null) {
            return null;
         }

         const user = this.users.find((user) => user.id === assignee);

         return user;
      },

      assigneeName() {
         if (!this.assignee) {
            return '';
         }

         return `${this.assignee.firstName} ${this.assignee.lastName}`;
      },

      companyId() {
         return this.$route.params.id ? this.$route.params.id : this.profile.companyId;
      },

      /** Users of the current company, sorted alphabetically by lastName, firstName */
      users() {
         return Object.values(this._users).sort((a, b) => {
            const nameA = `${a.lastName}${a.firstName}`.toUpperCase();
            const nameB = `${b.lastName}${b.firstName}`.toUpperCase();
            if (nameA < nameB) {
               return -1;
            }
            if (nameA > nameB) {
               return 1;
            }
            return 0;
         });
      },
   },

   methods: {
      /** Display the create client user modal */
      addUserModal() {
         this.$bvModal.show('modal-create-client');
      },

      /** Assign the supplies section to the selected user */
      async assignSection() {
         if (!this.selectedUser) {
            return;
         }

         await this.blockingRequest(`${this.section}/assignSection`, {
            companyId: this.companyId,
            assigneeId: this.selectedUser.id,
            assignerId: this.profile.id,
         });
      },

      async completeSection() {
         await this.blockingRequest(`${this.section}/completeSectionAssignment`, {
            companyId: this.companyId,
         });

         if (this.isSME) {
            this.$router.push({name: 'Home'});
         }
      },

      async fetchData() {
         let requests = [
            this.$store.dispatch(`${this.section}/loadSectionAssignment`, {
               companyId: this.companyId,
            }),
            this.$store.dispatch('users/loadCompanyUsers', {companyId: this.companyId}),
         ];

         await this.blockUntilAllSettled(requests);
      },

      /**
       * Format a user name with title and department
       * @param {Object} user - A user object
       */
      formatUserName(user) {
         let text = `${user.firstName} ${user.lastName}`;
         if (user.title) {
            text += ` - ${user.title}`;
         }
         if (user.department) {
            text += ` - ${user.department}`;
         }
         return text;
      },

      /** Unassign the supplies section */
      async unassignSection() {
         console.log(this.assignee);
         if (!this.assignee) {
            return;
         }

         await this.blockingRequest(`${this.section}/unassignSection`, {companyId: this.companyId});
      },
   },
};
</script>
