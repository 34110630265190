import {downloadFile} from '../../helpers/utils';
import {SuppliesDecl} from './supplies';
import {SpecialCategories} from './uploads';
import {dataFileUploadModuleFactory} from '../utils';

export default dataFileUploadModuleFactory(SpecialCategories.SUPPLIES, {
   actions: {
      async downloadTemplate({rootGetters}) {
         const oneState = rootGetters['supplies/declaration'] === SuppliesDecl.ONE;
         const response = await this._vm.$http.get('/api/supplies/excel-template', {
            params: {with_location: !oneState},
            responseType: 'blob',
         });
         downloadFile(response);
      },
   },
});
