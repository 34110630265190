export const caseTransformer = {
   // A walk in the park
   UCFIRST: (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase(),

   // A Walk In The Park
   UCFIRSTALL: (txt) => txt.split(' ').map(this.UCFIRST).join(' '),

   // A WALK IN THE PARK
   UPPER: (txt) => txt.toUpperCase(),

   // a walk in the park
   LOWER: (txt) => txt.toLowerCase(),
};

// Format a date string as yyyy-mm-dd
export const formatDate = (date) => {
   // convert to UTC so we can use toISOString without potentially rolling over a day
   const offset = date.getTimezoneOffset() * 60 * 1000; // minutes -> milliseconds
   const utcDate = new Date(date.getTime() - offset);
   return utcDate.toISOString().split('T')[0];
};

// Convert a date string to a Date object, avoiding off-by-one due to timezone
export const formatAsDate = (dateStr) => {
   dateStr = dateStr.split('T')[0];
   let [year, month, day] = dateStr.split('-');
   month = +month - 1; // convert to 0-based index
   return new Date(year, month, day);
};

// Format an integer representing a number of bytes
export const formatBytes = (bytes, decimals = 2) => {
   // based on https://stackoverflow.com/a/18650828
   if (bytes === 0) return '0 Bytes';

   const k = 1024;
   const dm = decimals < 0 ? 0 : decimals;
   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

   const i = Math.floor(Math.log(bytes) / Math.log(k));

   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

/**
 * Formats a value as USD. Accepts either a number or a string. When
 * passing in a string, any character that is not a decimal or period
 * is ignored, so it can accept, for example, a USD currency string
 * like '$1,234.56'.
 * @param {(string|number)} value - The value to be formated
 * @returns {string}
 */
export const formatCurrency = (value) => {
   if (value === null) {
      return value;
   }
   if (typeof value === 'number') {
      value = value.toString();
   }
   let amount = value.replace(/[^\d.]/g, '');
   if (amount) {
      const currencyCode = 'USD';
      const formatter = new Intl.NumberFormat('en-US', {
         style: 'currency',
         currency: currencyCode,
         currencyDisplay: 'code',
      });
      amount = formatter.format(amount).replace(currencyCode, '').trim();
   }
   return amount;
};

/**
 * Formats a string or number as an integer.
 * @param {(string|number)} value - The value to be formatted
 * @returns {string}
 */
export const formatInt = (value) => {
   if (value === null) {
      return value;
   }
   if (typeof value === 'number') {
      value = value.toString();
   }
   return value.replace(/[^\d]/g, '');
};

/**
 * Returns a sort function that sorts an array of objects
 * by a key, provided the values can be compared using `<` and `>`.
 * @param {int|string|function} key - Key to sort objects by, or function for deriving sort value
 * @param {function} [formatter] - Optional function to format values before they're compared
 */
export const sortBy = (key, formatter = null) => {
   if (formatter === null) {
      formatter = (x) => x;
   }

   let accessor = key;
   if (typeof key !== 'function') {
      accessor = (x) => x[key];
   }

   return (a, b) => {
      const aValue = formatter(accessor(a));
      const bValue = formatter(accessor(b));

      if (aValue.localeCompare && bValue.localeCompare) {
         // only use locale-aware sorting if both values are locale-comparison-friendly
         return aValue.localeCompare(bValue);
      } else {
         if (aValue < bValue) {
            return -1;
         } else if (aValue > bValue) {
            return 1;
         }
         return 0;
      }
   };
};

/**
 * process response that is expected to contain a file download.
 * This will use Content-Disposition header to determine the filename
 * and initiate the download.
 *
 * @example
 *    const response = await $http.get(url, {responseType: 'blob', ...});
 *    downloadFile(response);
 *
 * @param {Object} response - an axios response object
 */
export const downloadFile = (response) => {
   let attachment, filename, ext_filename;

   // determine filename from Content-Disposition - split the field on
   // ";" and look for each "attachment", "filename=", and
   // "filename*=" field
   (response.headers['content-disposition'] || '').split(';').forEach((comp) => {
      let match;

      // attachment
      if (comp.indexOf('attachment') !== -1) {
         attachment = true;
         return;
      }
      // filename="..."  NB: quoted filename; note the non-greedy regex
      match = comp.match(/filename="(.*?)"/i);
      if (match) {
         filename = match[1].trim();
         return;
      }
      // filename=...  NB: no quotes around filename
      match = comp.match(/filename=(.*)/i);
      if (match) {
         filename = match[1].trim();
         return;
      }
      // filename*=UTF-8''...
      match = comp.match(/filename\*=utf-?8''(.*)/i);
      if (match) {
         // decode percent-encoding
         ext_filename = decodeURIComponent(match[1].trim());
         return;
      }
   });

   // preference is ext_filename, then filename
   filename = ext_filename || filename;

   if (!attachment || !filename) {
      console.log('No attachment found.');
      return;
   }

   // Content-Type
   const type = response.headers['content-type'];
   // create the blob
   const blob = new Blob([response.data], {type});
   // create an anchor: not displayed, href set to the blob, download
   // set to computed filename
   const link = document.createElement('a');
   link.style.display = 'none';
   link.href = window.URL.createObjectURL(blob);
   link.download = filename;
   // "click" the link
   link.click();
   // release the blob (the Kraken?)
   window.URL.revokeObjectURL(link.href);
};

/**
 * Timer - execute a function some time in the future and allow
 *         waiting for completion.
 *
 * Constructor - same as setTimeout, with same semantics:
 *
 *    timer = new Timer(func, interval, ...params)
 *
 * func may be null, to create a timer that only waits interval
 * microseconds, as with a typical sleep() function.
 *
 * The timer is started when `.wait()` is called.  The timer can be
 * canceled with .cancel().
 *
 * Methods:
 *
 *   async wait() - Start the timer and execute the function after
 *                  interval microseconds.  Resolve to `true` if
 *                  function is called.  Resolves to `false` if
 *                  cancel() is called before function can be called.
 *
 *   cancel() - Cancel the timer.  An existing promise from a call to
 *              wait() resolves to `false`.
 *
 * NOTE: A Timer instance can only be used once.  If .wait() is called
 *       a second time TypeError is raised. If .cancel() is called
 *       after the promise from wait() is resolved (or if wait was
 *       never called), it is a no-op.
 *
 * Example:
 *
 *   timer = new Timer(myfunc, 16000)
 *   if (await timer.wait()) {
 *      // this is executed if myfunc is called after 16 seconds
 *   } else {
 *      // this is executed if timer.cancel() is called before 16
 *      // seconds has passed; myfunc will not be called
 *   }
 */
export class Timer {
   constructor(fn, interval, ...args) {
      // args to setTimeout
      this.fn = fn;
      this.interval = interval;
      this.args = args;

      // keep track of Promise resolve function and the current timer
      this.resolve = null;
      this.timer = null;
      this.resolved = false;
   }

   /**
    * wait() - start timer and wait interval microseconds before
    * running function.  Reslove to true if function is called.
    * Resolve to false if canceled prior to executing function.
    *
    * If already waiting, raise TypeError.
    */
   async wait() {
      const self = this;
      if (this.resolve !== null) {
         throw TypeError('Timer already waited');
      }
      // the promise that gets resolved
      const sleep = new Promise((resolve) => {
         // save reference to the promise's resolve function
         self.resolve = resolve;
         // call setTimeout, saving the reference
         self.timer = setTimeout(() => {
            // call provided function with args, then resolve to true
            if (self.fn) {
               self.fn(...self.args);
            }
            resolve(true);
         }, self.interval);
      });

      // await the Promise
      let result = await sleep;
      this.resolved = true;
      return result;
   }

   /**
    * cancel() - Cancel the timer and resolve a current wait() to false
    */
   cancel() {
      // no-op?
      if (this.resolved || this.resolve === null) {
         return;
      }
      // clear timer
      clearTimeout(this.timer);
      // resolve false
      this.resolve(false);
      this.resolved = true;
   }
}

/**
 * unmungeEmail - remove the munging added to the emails of disabled accounts
 */
const unmungeEmailRegex = /\+__([0-9a-f]{8})_disabled__(?=@)/;

export function unmungeEmail(email) {
   return email.replace(unmungeEmailRegex, '');
}
