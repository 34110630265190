<template>
   <b-container>
      <PageTitle title="Contract Researchers" />

      <ManagerMessage v-if="manager">
         <template #short>
            <span v-if="isComplete">This section is complete!</span>
            <span v-else>Invite other users to help with this.</span>
         </template>

         <h2 v-if="isComplete">This section is complete!</h2>
         <h2 v-else>Invite other users to help with this.</h2>

         <p v-if="isComplete">Visit your dashboard to see what's next.</p>
         <p v-else class="mb-0">
            Assign this section to one or more Onboard users to collaborate on the data entry.
            Information about contract researchers' payments is sensitive, so you can choose which
            assigned users get permission to edit payment information for all or just some
            contractors.
         </p>

         <div v-if="isComplete" class="d-flex align-items-center justify-content-end">
            <b-button variant="success" :to="{name: 'Home'}">Dashboard</b-button>
         </div>
      </ManagerMessage>

      <div class="form-section">
         <h2>Unassigned Users</h2>
         <div class="d-flex align-items-center justify-content-start">
            <div class="w-50">
               <b-form-select
                  id="select-unassigned-user"
                  :options="unassignedUserOptions"
                  v-model="unassignedUserSelection"
               >
                  <template #first>
                     <b-form-select-option :value="null" disabled>
                        Please select one
                     </b-form-select-option>
                  </template>
               </b-form-select>
            </div>

            <b-button id="btn-assign-user" variant="primary" class="ml-3" @click="assignUser">
               Assign User to Section</b-button
            >
            <b-button id="btn-add-user" variant="primary" class="ml-3" @click="addUserModal">
               Invite User <b-icon-plus />
            </b-button>
         </div>
      </div>

      <div class="form-section mb-3">
         <b-row class="mb-3">
            <b-col cols="12">
               <h2>Assigned Users</h2>

               <div class="d-flex align-items-center justify-content-start mb-3">
                  <div class="w-50">
                     <b-form-select
                        id="select-assigned-user"
                        :options="assignedUserOptions"
                        v-model="assignedUserSelection"
                        @change="focusUser"
                     >
                        <template #first>
                           <b-form-select-option :value="null" disabled>
                              Please select one
                           </b-form-select-option>
                        </template>
                     </b-form-select>
                  </div>

                  <span class="d-inline-block" tabindex="0" v-b-tooltip="tooltipMsg">
                     <b-button
                        :disabled="isImmutable"
                        id="btn-unassign-user"
                        variant="primary"
                        class="ml-3"
                        @click="unassignUser"
                     >
                        Unassign User
                     </b-button>
                  </span>
               </div>
            </b-col>
         </b-row>

         <b-form-row v-if="focusedUser" class="mb-2">
            <b-col> Grant permission to view and edit payment information for: </b-col>
            <b-col>
               <span class="d-inline-block" tabindex="0" v-b-tooltip="tooltipMsg">
                  <b-form-checkbox
                     :disabled="isImmutable"
                     id="checkbox-financial"
                     :checked="
                        permissions != null && permissions[ContractorPermissionTypes.FINANCIAL]
                     "
                     @change="
                        (val) => setGeneralPermission(ContractorPermissionTypes.FINANCIAL, val)
                     "
                  >
                     All contract researchers.
                  </b-form-checkbox>
               </span>
            </b-col>
         </b-form-row>

         <div v-if="focusedUser && showContractorPerms">
            <b-card no-body class="no-shadow">
               <b-table
                  id="table-permissions"
                  :fields="contractorPermissionFields"
                  sort-by="fullname"
                  :items="contractors"
                  class="mb-1"
               >
                  <template #cell(fullname)="data">
                     <b>{{ data.value }}</b>
                  </template>

                  <template #head(financial)="">
                     <span class="d-inline-block" tabindex="0" v-b-tooltip="tooltipMsg">
                        <b-form-checkbox
                           :checked="
                              (permissions != null &&
                                 permissions[ContractorPermissionTypes.FINANCIAL]) ||
                              selectAll[ContractorPermissionTypes.FINANCIAL]
                           "
                           :disabled="
                              isImmutable ||
                              (permissions != null &&
                                 permissions[ContractorPermissionTypes.FINANCIAL])
                           "
                           @change="(val) => onSelectAll(ContractorPermissionTypes.FINANCIAL, val)"
                           id="checkbox-contractors-financial-all"
                        >
                           Select all
                        </b-form-checkbox>
                     </span>
                  </template>
                  <template #cell(financial)="data">
                     <span class="d-inline-block" tabindex="0" v-b-tooltip="tooltipMsg">
                        <b-form-checkbox
                           :disabled="
                              isImmutable ||
                              (permissions != null &&
                                 permissions[ContractorPermissionTypes.FINANCIAL])
                           "
                           :checked="
                              contractorPermission(
                                 data.item.id,
                                 ContractorPermissionTypes.FINANCIAL
                              )
                           "
                           @change="
                              (val) =>
                                 setContractorPermission(
                                    data.item.id,
                                    ContractorPermissionTypes.FINANCIAL,
                                    val
                                 )
                           "
                           :id="`checkbox-contractors-financial-${data.item.id}`"
                        ></b-form-checkbox
                     ></span>
                  </template>
               </b-table>
            </b-card>
         </div>

         <div class="d-flex justify-content-end mt-3">
            <span class="d-inline-block" tabindex="0" v-b-tooltip="tooltipMsg">
               <b-button id="btn-submit" variant="success" @click="submit" :disabled="isImmutable">
                  Save
               </b-button>
            </span>
         </div>

         <div v-if="loading" class="text-center">
            <b-spinner id="spinner-loading" variant="primary"></b-spinner>
         </div>
      </div>

      <div class="d-flex align-items-center justify-content-between">
         <b-button
            id="btn-contractors-home"
            class="d-flex align-items-center"
            :to="$clientStaffRoute({name: 'contractors-home'})"
         >
            <b-icon-arrow-left-short class="mr-1" />
            Back
         </b-button>
      </div>

      <CreateClientModal></CreateClientModal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {ContractorPermissionTypes} from '@/store/modules/contractor-permissions';
import {UserGroups} from '@/store/modules/session';
import {sortBy} from '@/helpers/utils';

import CreateClientModal from '@/views/user/widgets/CreateClientModal';

export default {
   components: {
      CreateClientModal,
   },

   data() {
      return {
         assignedUserSelection: null,
         unassignedUserSelection: null,

         loading: false,
         focusedUser: null,

         ContractorPermissionTypes,
         contractorPermissionFields: [
            {key: 'fullname', label: 'Contract Researcher Name', sortable: true},
            {key: 'financial', label: ''},
         ],
         selectAll: {
            [ContractorPermissionTypes.FINANCIAL]: false,
         },
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         manager: 'manager',
         users: 'users/companyUsers',
         isComplete: 'contractors/isComplete',
         contractors: 'contractors/contractors',
         currentCompany: 'companies/currentCompany',
         permissions: 'contractorPermissions/userPermissions',
         permissionsDirty: 'contractorPermissions/userPermissionsDirty',
         makePartitionedList: 'contractorPermissions/makePartitionedList',
      }),

      assignedUserOptions() {
         return this._constructDropdownUserlist(true);
      },

      unassignedUserOptions() {
         return this._constructDropdownUserlist(false);
      },

      companyId() {
         return this.$route.params.id ? this.$route.params.id : this.profile.companyId;
      },

      companyName() {
         return this.currentCompany.companyName;
      },

      /** Is there a user focused, and can we edit it? */
      isImmutable() {
         return this.focusedUser == null || this.isCustomer;
      },

      /** Is the user a customer? */
      isCustomer() {
         return (this.focusedUser?.groups || []).includes(UserGroups.CUSTOMER);
      },

      /** Tooltip warning about unmodifiable customers */
      tooltipMsg() {
         return this.isCustomer
            ? `This user's access to ${this.companyName} data cannot be changed.`
            : '';
      },

      /** Can the contractor permission table be displayed? */
      showContractorPerms() {
         return this.permissions !== null && this.contractors.length > 0;
      },
   },

   methods: {
      _constructDropdownUserlist(hasPermissions) {
         return this.makePartitionedList(Object.values(this.users), hasPermissions)
            .map((user) => ({value: user.id, text: `${user.firstName} ${user.lastName}`}))
            .sort(sortBy('text', (x) => x.toUpperCase()));
      },

      /** Display the create client user modal */
      addUserModal() {
         this.$bvModal.show('modal-create-client');
      },

      /** Select a user to edit their permissions */
      async assignUser() {
         if (this.permissionsDirty) {
            const proceed = await this.checkSave();
            if (!proceed) {
               return;
            }
         }

         if (this.unassignedUserSelection) {
            this.loading = true;

            // the call to focusUser() below clears the selection so we cache it first
            const unassignedUserId = this.unassignedUserSelection;

            await this.blockingRequest('contractorPermissions/assignUser', {
               companyId: this.currentCompany.id,
               assigneeId: unassignedUserId,
            });
            await this.focusUser(unassignedUserId);

            this.loading = false;
         }
      },

      /** Load the selected user into the permissions editor */
      async focusUser(userId) {
         // Only set and un-set the loading flag if it hasn't already been set
         let didSetLoading = false;
         if (this.loading) {
            this.loading = didSetLoading = true;
         }

         this.$store.commit('contractorPermissions/clearUserPermissions');
         for (let key in this.selectAll) {
            this.selectAll[key] = false;
         }

         this.focusedUser = this.users[userId];

         const requests = [
            this.$store.dispatch('contractorPermissions/loadUserPermissions', {
               userId: this.focusedUser.id,
            }),
            this.$store.dispatch('contractors/loadContractors', {
               companyId: this.companyId,
            }),
         ];
         await Promise.allSettled(requests);

         // Make sure the focused item is selected in the dropdown menu
         this.assignedUserSelection = userId;
         this.unassignedUserSelection = null;

         if (didSetLoading) {
            this.loading = false;
         }
      },

      /** Unassign a user */
      async unassignUser() {
         this.loading = true;

         await this.blockingRequest('contractorPermissions/unassignUser', {
            companyId: this.currentCompany.id,
            assigneeId: this.assignedUserSelection,
         });

         // Unload the focused user from the permissions editor
         this.focusedUser = null;
         this.assignedUserSelection = null;

         this.loading = false;
      },

      /**
       * Ask the user if they want to save their changes before proceeding
       * @returns {Boolean} - If `true`, proceed with the action that prompted the call to this method
       */
      async checkSave() {
         const save = await this.$bvModal.msgBoxConfirm(
            'Are you sure you want to leave? Any unsaved changes will be lost.',
            {
               centered: true,
               okTitle: 'Save',
               cancelTitle: 'Proceed without saving',
            }
         );
         if (save) {
            await this.submit();
            return true;
         } else if (save === false) {
            return true;
         }
         return false;
      },

      /** Update a general permission */
      setGeneralPermission(type, value) {
         this.$store.commit('contractorPermissions/setGeneralPermissions', [
            {
               type,
               value,
            },
         ]);
      },

      /** Returns the value of a contractor specific permission */
      contractorPermission(contractorId, type) {
         const perm =
            this.permissions[ContractorPermissionTypes.FINANCIAL] ||
            this.permissions.contractors[contractorId]?.[type];
         return perm === undefined ? false : perm;
      },

      /** Update a contractor-specific permission */
      setContractorPermission(contractorId, type, value) {
         this.$store.commit('contractorPermissions/setContractorPermission', {
            contractorId,
            type,
            value,
         });
         if (!value) {
            this.selectAll[type] = false;
         }
      },

      /** Update all contractor-specific permissions of the same type */
      onSelectAll(type, value) {
         this.contractors.forEach((contractor) => {
            this.setContractorPermission(contractor.id, type, value);
         });
      },

      /** Save changes to a user's permissions */
      async submit() {
         await this.blockingRequest('contractorPermissions/saveUserPermissions');
         this.$store.commit('showAlert', {
            msg: 'Settings were saved',
            variant: 'success',
            seconds: 5,
         });
         this.focusedUser = null;
         this.assignedUserSelection = null;
      },
   },

   async created() {
      this.$store.commit('contractorPermissions/clearUserPermissions');
      this.$store.commit('contractorPermissions/clearUserPermissionsPartition');
      const requests = [
         this.$store.dispatch('companies/loadCompany', {
            companyId: this.companyId,
         }),
         this.$store.dispatch('users/loadCompanyUsers', {
            companyId: this.companyId,
         }),
         this.$store.dispatch('contractors/loadContractors', {
            companyId: this.companyId,
         }),
         this.$store.dispatch('contractorPermissions/loadUserPermissionsPartition', {
            companyId: this.companyId,
         }),
      ];
      await this.blockUntilAllSettled(requests);
   },

   /** Before leaving the route, check for any unsaved changes */
   async beforeRouteLeave(to, from, next) {
      if (this.permissionsDirty) {
         const proceed = await this.checkSave();
         if (!proceed) {
            // Cancel the navigation
            return next(false);
         }
      }
      next();
   },
};
</script>

<style lang="scss" scoped>
.general-perms {
   margin-top: 2rem;
}
</style>
