<template>
   <b-container>
      <PageTitle title="Cloud Computing" />

      <SectionAssignment section="cloud" />

      <template v-if="isCompleted">
         <ManagerMessage v-if="manager">
            <h2>You're all done!</h2>
            <p>
               This data has been submitted, so as long as everything still looks correct, you're
               all done here!
            </p>
            <p>
               If you need to upload any additional accounts you'll need to unlock this section
               first. If you do that, make sure you proceed to the final screen to submit this data
               again.
            </p>
            <div class="d-flex justify-content-end">
               <b-button variant="secondary" @click="unlock">Unlock</b-button>
            </div>
         </ManagerMessage>

         <b-alert
            :show="!manager"
            variant="primary"
            class="d-flex align-items-center justify-content-between mb-5"
         >
            <span>
               The cloud computing section has been submitted. Click <b>Unlock</b> to make
               additional changes.
            </span>
            <b-button variant="white" @click="unlock">Unlock</b-button>
         </b-alert>
      </template>

      <template v-else>
         <ManagerMessage v-if="manager">
            <h2>First, upload any cloud computing transaction files you might have.</h2>

            <p>
               If your company has any
               <strong>expense accounts</strong> that were used to purchase cloud computing
               services, upload those files here, one export file per account. Onboard will organize
               your purchases by year and vendor. <b>XLSX or CSV formats</b> are accepted, and the
               files may contain any number of additional columns but must include the following:
            </p>

            <p>
               <b> Vendor Name, Transaction Date, Transaction Amount (Debit). </b>
            </p>

            <p>You will also be able to manually add cloud computing vendors on the next screen.</p>
         </ManagerMessage>

         <div class="d-flex justify-content-end">
            <b-button
               id="btn-upload-file"
               variant="primary"
               class="d-flex align-items-center my-4"
               @click="startImport"
            >
               {{
                  uploadedFiles.length === 0
                     ? 'Upload cloud computing transactions'
                     : "Upload another account's transactions"
               }}
               <custom-icon icon="icon-uploads" width="20" height="20" class="ml-2" />
            </b-button>
         </div>
      </template>

      <DataFileList section="cloud" />

      <div class="d-flex align-items-center justify-content-between mt-4">
         <b-button
            class="d-flex align-items-center"
            :to="$clientStaffRoute({name: 'cloud-declaration'})"
         >
            <b-icon-arrow-left-short class="mr-1" />
            Back
         </b-button>
         <b-button
            id="btn-cloud-vendors"
            class="d-flex align-items-center"
            variant="primary"
            :to="$clientStaffRoute({name: 'cloud-vendors'})"
         >
            Next
            <b-icon-arrow-right-short class="ml-1" />
         </b-button>
      </div>

      <DataFileImport
         ref="fileImport"
         section="cloud"
         section-label="Cloud Computing"
         template-download
         :columns="fileImportColumns"
      >
         <template #file-select-title> Upload your cloud computing purchase transactions </template>
         <template #intro>
            Cloud computing expenses can be imported from the expense accounts in your company's
            bookkeeping software. Export any accounts which may have been used to purchase cloud
            computing services for R&D from
            {{
               study.years.lower === study.years.upper
                  ? study.years.lower
                  : `${study.years.lower} to ${study.years.upper}`
            }}
            and import them here. You can also download a template file containing the importable
            fields.
         </template>
      </DataFileImport>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

import SectionAssignment from '../components/SectionAssignment';
import DataFileImport from '../components/DataFileImport';
import DataFileList from '../components/DataFileList';

export default {
   components: {
      SectionAssignment,
      DataFileImport,
      DataFileList,
   },

   data() {
      return {
         fileImportColumns: [
            {key: 'vendor', label: 'Vendor Name', required: true},
            {key: 'purchased', label: 'Transaction Date', required: true},
            {key: 'debit', label: 'Transaction Amount (Debit)', required: true},
            {key: 'credit', label: 'Refund Amount (Credit)', required: false},
            {key: 'description', label: 'Description', required: false},
         ],
      };
   },

   computed: {
      ...mapGetters({
         manager: 'manager',
         study: 'companies/study',
         uploadedFiles: 'cloudUploads/uploadedFiles',
         isCompleted: 'cloud/isCompleted',
      }),
   },

   methods: {
      startImport() {
         this.$refs.fileImport.startNewImport();
      },

      /** Unlock the section */
      async unlock() {
         const msg =
            "Unlocking this section may delay your credit calculation. You will have to assign users again and will have to mark the section complete again once you're done.";
         const title = 'Unlock this section?';
         const proceed = await this.$bvModal.msgBoxConfirm(msg, {
            title,
            centered: true,
         });
         if (proceed) {
            await this.blockingRequest('cloud/unlock', {
               companyId: this.$companyId,
            });
         }
      },
   },

   async created() {
      this.$store.commit('cloudUploads/clearUploads');
      let requests = [
         this.$store.dispatch('cloudUploads/loadUploadCategory', {
            companyId: this.$companyId,
         }),

         this.$store.dispatch('users/loadCompanyUsers', {
            companyId: this.$companyId,
         }),

         // Refresh company data to update study
         this.$store.dispatch('companies/loadCompany', {companyId: this.$companyId, force: true}),
      ];

      await this.blockUntilAllSettled(requests);
   },
};
</script>
