<template>
   <b-navbar variant="transparent" class="container">
      <b-navbar-brand :to="{name: 'Home'}" v-if="sidebar === SidebarStates.HIDDEN">
         <img src="@/assets/img/logo/logo-dark.svg" alt="Onboard.tax" />
      </b-navbar-brand>

      <b-navbar-nav class="ml-auto d-flex align-items-center text-secondary text-small">
         <b-link id="nav-logout" @click="logout">Logout</b-link>
         <div class="separator"></div>
         <b-avatar
            id="nav-profile"
            :to="{name: 'profile'}"
            variant="white"
            size="2rem"
            :badge="profileBadge"
            badge-variant="danger"
         >
            <img v-auth-src="profileImageUrl" style="width: 100%" />
         </b-avatar>
         <div class="separator"></div>
      </b-navbar-nav>
   </b-navbar>
</template>

<script>
import {mapGetters} from 'vuex';
import {SidebarStates} from '@/store/modules/session';

export default {
   data() {
      return {
         SidebarStates,
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         profileImageUrl: 'profileImageUrl',
         sidebar: 'sidebar',
         isProfileQuestionnaireComplete: 'progress/isProfileQuestionnaireComplete',
      }),

      profileBadge() {
         return !this.isProfileQuestionnaireComplete;
      },
   },
};
</script>

<style lang="scss" scoped>
.container {
   padding: 2rem 3rem 2rem 2rem;
}

.separator {
   background-color: $gray-600;
   border-radius: 2px;
   height: 26px;
   width: 2px;
   margin-left: 1rem;
   margin-right: 1rem;
}
</style>
