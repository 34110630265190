<template>
   <div>
      <b-row>
         <b-col cols="6">
            <b-form-group label="Start Year">
               <YearSelect
                  index="start"
                  v-model="lowerYear"
                  :minYear="project.years.lower"
                  :maxYear="upperYear || Math.min(project.years.upper, study.years.upper)"
                  :disabled="readonly"
               />
            </b-form-group>
         </b-col>
         <b-col cols="6">
            <b-form-group label="End Year">
               <YearSelect
                  index="end"
                  v-model="upperYear"
                  :minYear="lowerYear || project.years.lower"
                  :maxYear="
                     project.years.upper
                        ? Math.min(project.years.upper, study.years.upper)
                        : study.years.upper
                  "
                  :disabled="readonly"
                  or-later
               />
            </b-form-group>
         </b-col>
      </b-row>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';
import YearSelect from '@/components/forms/YearSelect';

export default {
   components: {
      YearSelect,
   },

   props: {
      readonly: {
         type: Boolean,
         default: false,
      },

      index: Number,
   },

   data() {
      return {
         yearsBack: {lower: null, upper: null},
      };
   },

   computed: {
      ...mapGetters({
         study: 'companies/study',
         years: 'questionnaire/years',
         project: 'questionnaire/project',
      }),

      questionnaireId() {
         return this.$route.params.questionnaireId;
      },

      lowerYear: {
         get() {
            return this.years.lower;
         },
         set(value) {
            this.$store.commit('questionnaire/setLowerYear', value);
            this.$store.commit('questionnaire/setAnswerValue', {
               questionIndex: this.index,
               value: this.years,
            });
         },
      },

      upperYear: {
         get() {
            return this.years.upper;
         },
         set(value) {
            this.$store.commit('questionnaire/setUpperYear', value);
            this.$store.commit('questionnaire/setAnswerValue', {
               questionIndex: this.index,
               value: this.years,
            });
         },
      },
   },

   methods: {
      /** Submit the updated year range for the questionnaire */
      async save() {
         try {
            await this.blockingRequest('questionnaire/editQuestionnaire', {
               questionnaireId: this.questionnaireId,
            });
            this.yearsBack = {...this.years};
            this.$store.commit('questionnaire/setAnswerClean', {
               questionIndex: this.index,
            });
            this.$store.commit('showAlert', {
               msg: 'Project year range saved',
               seconds: 5,
               variant: 'success',
            });
         } catch (err) {
            this.$store.commit('showAlert', {
               msg: 'Failed to save project year range',
               seconds: 5,
            });
         }
      },

      revertAnswer() {
         this.lowerYear = this.yearsBack.lower;
         this.upperYear = this.yearsBack.upper;
      },
   },

   mounted() {
      this.yearsBack = {...this.years};
      this.$emit('validate', true);
   },
};
</script>
