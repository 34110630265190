<template>
   <b-container v-if="loaded">
      <PageTitle title="Supplies" />

      <transition name="fade" mode="out-in" v-if="loaded">
         <!-- INTRO -->
         <div v-if="displayIntro" key="intro-content">
            <p class="mb-3">
               The costs of supplies consumed during research and development qualify for the R&D
               credit too, as long as they were consumed exclusively as a part of your R&D projects.
               In this section, you will export the accounting records that document supplies
               purchases, and the percent of spending with each vendor that
               <b-link id="nav-help" :to="{name: 'get-started-stage'}">qualifies</b-link> as true
               R&D supply expenses.
            </p>

            <p id="manager-message-description" v-for="(paragraph, idx) in suppliesCopy" :key="idx">
               {{ paragraph }}
            </p>

            <div class="d-flex align-items-center justify-content-end">
               <b-button
                  id="btn-begin"
                  variant="success"
                  class="d-flex align-items-center"
                  @click="hideIntro"
               >
                  Begin
                  <b-icon-arrow-right-short class="ml-1" />
               </b-button>
            </div>
         </div>

         <!-- MAIN CONTENT -->
         <div v-else key="main-content">
            <SectionAssignment section="supplies" />

            <ManagerMessage>
               <template #short>
                  <span v-if="completed">This section is complete.</span>
                  <span v-else>Did any of these projects use qualified supplies?</span>
               </template>

               <div v-if="completed">
                  <h2>This section is complete.</h2>
                  <p>
                     This section has been completed, so as long as everything still looks correct,
                     you're all done here! To make any changes to this section, you'll need to
                     unlock it first.
                  </p>

                  <div class="d-flex justify-content-end">
                     <b-button variant="secondary" @click="unlock">Unlock</b-button>
                  </div>
               </div>
               <div v-else-if="usedSupplies === false">
                  <h2>This section is complete.</h2>

                  <p>
                     Since your company didn't consume any qualified supplies for R&D, we can just
                     skip this section. Visit the dashboard to see what other info you can provide.
                  </p>
                  <div class="d-flex justify-content-end">
                     <b-button id="btn-supplies-dashboard" variant="success" :to="{name: 'Home'}"
                        >Dashboard</b-button
                     >
                  </div>
               </div>
               <div v-else>
                  <h2>Did any of these projects use qualified supplies?</h2>

                  <p>
                     Supply costs that qualify for credit are any supplies that were used
                     exclusively for your R&D experimentation.
                  </p>

                  <p
                     id="manager-message-description"
                     v-for="(paragraph, idx) in suppliesCopy"
                     :key="idx"
                  >
                     {{ paragraph }}
                  </p>
               </div>
            </ManagerMessage>

            <b-card class="mb-5">
               <h5 class="mb-3">Projects</h5>
               <b-table
                  id="table-projects"
                  small
                  class="mb-0"
                  :fields="[{key: 'name', sortable: true}]"
                  sort-by="name"
                  :items="projects"
               >
               </b-table>
            </b-card>

            <p id="label-study-years" class="mb-3">
               <b>Did any of these projects require any qualified supplies {{ studyYearsStr }}?</b>
            </p>

            <div class="declaration-container">
               <div
                  id="declaration-none"
                  class="declaration-item"
                  :class="{
                     active: declaration === SuppliesDecl.NONE,
                     disabled: completed || hasUploadedFiles,
                  }"
                  @click="declareSupplies(SuppliesDecl.NONE)"
               >
                  <div class="icon-container">
                     <svg
                        width="86"
                        height="86"
                        viewBox="0 0 86 86"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M38.9084 47.1265H20.4875L19.7695 61.2411L22.2815 63.6334H33.6449H37.2334L38.9084 61.2411V47.1265Z"
                           fill="#D2EBE4"
                        />
                        <path
                           d="M19.5254 45.9883H39.4473M40.269 38.0226L37.3306 38.0195H20.5215C17.141 38.0195 16.5371 40.2421 16.5371 41.0078C18.4235 41.2693 19.5254 41.5059 19.5254 43.498V59.9336C19.5254 60.9903 19.9452 62.0038 20.6924 62.751C21.4396 63.4982 22.453 63.918 23.5098 63.918H35.4629C36.5196 63.918 37.5331 63.4982 38.2803 62.751C39.0275 62.0038 39.4473 60.9903 39.4473 59.9336V41.0078C39.4473 39.825 40.1632 38.6203 40.2317 38.5176C40.3064 38.3993 40.4434 38.2437 40.4434 38.1752C40.4434 38.1067 40.4247 38.0226 40.269 38.0226Z"
                           stroke="#178ECE"
                           stroke-width="2"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                        />
                        <path
                           d="M49.293 52.1622L52.8525 44.8671H66.0604L69.9559 52.1622H49.293Z"
                           fill="#E2BD4D"
                        />
                        <path
                           d="M72.1051 51.4163L64.3495 36.3713V28.4026H66.3417V26.4104H64.3375V26.3984L63.4291 26.4084H54.3886V28.3906H56.3807V36.2638L48.6232 51.4223C48.4723 51.7258 48.4015 52.0628 48.4172 52.4013C48.4329 52.7398 48.5348 53.0688 48.7131 53.357C48.8914 53.6452 49.1403 53.8831 49.4362 54.0483C49.7321 54.2135 50.0653 54.3005 50.4042 54.301H70.3261C70.6656 54.3007 70.9994 54.2135 71.2959 54.0479C71.5923 53.8822 71.8414 53.6436 72.0197 53.3546C72.1979 53.0655 72.2993 52.7358 72.3143 52.3965C72.3292 52.0573 72.2572 51.7199 72.1051 51.4163ZM58.1598 37.1722L58.3729 36.7439V28.4623L62.3573 28.4145V36.8554L62.5764 37.2838L66.2162 44.3401H54.4862L58.1598 37.1722V37.1722ZM50.4042 52.3307L53.4721 46.3323H67.2501L70.332 52.3088L50.4042 52.3307Z"
                           fill="#178ECE"
                        />
                        <g clip-path="url(#clip0_12620_98026)">
                           <path
                              d="M43.1 0.944946C66.2303 0.944946 85.1551 19.8697 85.1551 43C85.1551 66.1303 66.2303 85.0551 43.1 85.0551C19.9697 85.0551 1.04492 66.1303 1.04492 43C1.04492 19.8697 19.9697 0.944946 43.1 0.944946ZM43.1 9.35596C35.1095 9.35596 27.9602 11.8793 22.493 16.5053L69.5947 63.607C73.8002 57.7193 76.7441 50.5699 76.7441 43C76.7441 24.4958 61.6042 9.35596 43.1 9.35596M63.707 69.4947L16.6053 22.393C11.9792 27.8602 9.45594 35.0096 9.45594 43C9.45594 61.5043 24.5958 76.6441 43.1 76.6441C51.0905 76.6441 58.2398 74.1208 63.707 69.4947V69.4947Z"
                              fill="#BBC7D5"
                           />
                        </g>
                        <defs>
                           <clipPath id="clip0_12620_98026">
                              <rect
                                 width="85"
                                 height="85"
                                 fill="white"
                                 transform="translate(0.599609 0.5)"
                              />
                           </clipPath>
                        </defs>
                     </svg>
                  </div>
                  <p class="description"><b>No, none</b> of these projects required supplies.</p>
               </div>
               <div
                  id="declaration-outside"
                  class="declaration-item"
                  :class="{
                     active: declaration === SuppliesDecl.NOT_USA,
                     disabled: completed || hasUploadedFiles,
                  }"
                  @click="declareSupplies(SuppliesDecl.NOT_USA)"
               >
                  <div class="icon-container">
                     <svg
                        width="132"
                        height="86"
                        viewBox="0 0 132 86"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <g clip-path="url(#clip0_10215_4932)">
                           <path
                              d="M66.375 0.798767C55.1033 0.798767 44.2933 5.27643 36.323 13.2467C28.3527 21.217 23.875 32.0271 23.875 43.2988C23.875 54.5705 28.3527 65.3805 36.323 73.3508C44.2933 81.3211 55.1033 85.7988 66.375 85.7988C77.6467 85.7988 88.4567 81.3211 96.427 73.3508C104.397 65.3805 108.875 54.5705 108.875 43.2988C108.875 32.0271 104.397 21.217 96.427 13.2467C88.4567 5.27643 77.6467 0.798767 66.375 0.798767V0.798767ZM34.7125 23.7806C36.4391 30.841 48.1637 37.2744 54.4591 40.7275C57.0091 42.1247 58.6719 43.0331 58.4062 43.2988C57.9813 43.7238 57.5456 44.1381 57.1206 44.5419C54.9106 46.6456 52.9397 48.521 53.0938 51.2675C53.2797 54.6356 56.3769 55.645 59.5431 56.6703C62.6084 57.6691 65.7481 58.6891 66.375 61.8925C66.8106 64.1078 65.9766 67.1413 65.2062 69.9675C64.0641 74.1219 63.0494 77.83 66.375 77.83C74.3438 75.1738 86.5519 60.9044 87.625 51.2675C88.3581 44.6641 77 40.6425 69.0312 37.9863C66.4919 37.1363 65.0203 38.4166 63.7719 39.495C62.8581 40.2919 62.0612 40.9772 61.0625 40.6425C58.6984 39.8563 47.7812 30.0175 53.0938 27.3613C57.2375 25.2894 58.1513 26.4528 59.6122 28.3281C60.0266 28.8541 60.4781 29.4331 61.0625 30.0175C64.2978 31.6325 64.5794 29.3163 64.9034 26.6547C65.1106 24.9441 65.3338 23.09 66.375 22.0488C68.6806 19.7431 73.0953 17.8466 77.1488 16.0988C80.9312 14.4731 84.4003 12.9803 85.5372 11.4238C91.855 15.2229 96.901 20.8141 100.034 27.4871C103.168 34.1602 104.247 41.614 103.135 48.9017C102.023 56.1895 98.7703 62.9823 93.7896 68.4174C88.8089 73.8525 82.3252 77.6846 75.1619 79.4269C67.9987 81.1693 60.4792 80.7432 53.5587 78.2029C46.6381 75.6625 40.6287 71.1226 36.2938 65.1597C31.959 59.1967 29.4942 52.0799 29.2126 44.7132C28.9311 37.3465 30.8454 30.0624 34.7125 23.786V23.7806Z"
                              fill="#BBC7D5"
                           />
                        </g>
                        <path
                           d="M26.1838 61.4252H7.76292L7.04492 75.5399L9.55685 77.9322H20.9203H24.5088L26.1838 75.5399V61.4252Z"
                           fill="#D2EBE4"
                        />
                        <path
                           d="M7.4375 61.6113H26.1875M26.9609 54.1142L24.1953 54.1113H8.375C5.19336 54.1113 4.625 56.2031 4.625 56.9238C6.40039 57.1699 7.4375 57.3925 7.4375 59.2675V74.7363C7.4375 75.7308 7.83259 76.6847 8.53585 77.3879C9.23911 78.0912 10.1929 78.4863 11.1875 78.4863H22.4375C23.4321 78.4863 24.3859 78.0912 25.0891 77.3879C25.7924 76.6847 26.1875 75.7308 26.1875 74.7363V56.9238C26.1875 55.8105 26.8613 54.6767 26.9258 54.58C26.9961 54.4687 27.125 54.3222 27.125 54.2578C27.125 54.1933 27.1074 54.1142 26.9609 54.1142Z"
                           stroke="#178ECE"
                           stroke-width="2"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                        />
                        <path
                           d="M108.378 71.6995H118.202L118.585 79.2274L117.245 80.5032H111.185H109.271L108.378 79.2274V71.6995Z"
                           fill="#178ECE"
                        />
                        <path
                           d="M108.378 71.6995H118.202L118.585 79.2274L117.245 80.5032H111.185H109.271L108.378 79.2274V71.6995Z"
                           fill="white"
                           fill-opacity="0.5"
                        />
                        <path
                           d="M118.375 71.7988H108.375M107.962 67.8003L109.438 67.7988H117.875C119.572 67.7988 119.875 68.9144 119.875 69.2988C118.928 69.43 118.375 69.5488 118.375 70.5488V78.7988C118.375 79.3292 118.164 79.8379 117.789 80.213C117.414 80.5881 116.905 80.7988 116.375 80.7988H110.375C109.845 80.7988 109.336 80.5881 108.961 80.213C108.586 79.8379 108.375 79.3292 108.375 78.7988V69.2988C108.375 68.705 108.016 68.1003 107.981 68.0488C107.944 67.9894 107.875 67.9113 107.875 67.8769C107.875 67.8425 107.884 67.8003 107.962 67.8003Z"
                           stroke="#178ECE"
                           stroke-width="1.5"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                        />
                        <path
                           d="M106.568 45.461L110.128 38.1658H123.336L127.231 45.461H106.568Z"
                           fill="#E2BD4D"
                        />
                        <path
                           d="M127.924 43.72L120.625 29.56V22.06H122.5V20.185H120.614V20.1738L119.759 20.1831H111.25V22.0488H113.125V29.4588L105.824 43.7256C105.682 44.0113 105.615 44.3284 105.63 44.6471C105.645 44.9657 105.741 45.2753 105.908 45.5465C106.076 45.8178 106.31 46.0417 106.589 46.1972C106.868 46.3527 107.181 46.4345 107.5 46.435H126.25C126.57 46.4347 126.884 46.3527 127.163 46.1968C127.442 46.0409 127.676 45.8162 127.844 45.5442C128.012 45.2722 128.107 44.9618 128.121 44.6426C128.135 44.3233 128.068 44.0057 127.924 43.72ZM114.799 30.3138L115 29.9106V22.1163L118.75 22.0713V30.0156L118.956 30.4188L122.382 37.06H111.342L114.799 30.3138ZM107.5 44.5806L110.388 38.935H123.355L126.256 44.56L107.5 44.5806Z"
                           fill="#178ECE"
                        />
                        <path
                           d="M8.16016 20.3123L10.6518 15.2057H19.8973L22.6242 20.3123H8.16016Z"
                           fill="#B6305B"
                        />
                        <path
                           d="M8.16016 20.3123L10.6518 15.2057H19.8973L22.6242 20.3123H8.16016Z"
                           fill="white"
                           fill-opacity="0.75"
                        />
                        <path
                           d="M23.1095 19.0936L17.9999 9.18164V3.93164H19.3124V2.61914H17.9921V2.61127L17.3936 2.61783H11.4374V3.92377H12.7499V9.11077L7.63907 19.0976C7.53971 19.2975 7.493 19.5195 7.50337 19.7426C7.51373 19.9656 7.58082 20.1823 7.6983 20.3722C7.81577 20.5621 7.97975 20.7188 8.17471 20.8277C8.36967 20.9365 8.58917 20.9938 8.81245 20.9941H21.9374C22.1612 20.9939 22.3811 20.9365 22.5764 20.8274C22.7717 20.7182 22.9358 20.561 23.0532 20.3706C23.1707 20.1802 23.2375 19.9629 23.2473 19.7394C23.2572 19.5159 23.2097 19.2936 23.1095 19.0936ZM13.922 9.70927L14.0624 9.42708V3.97102L16.6874 3.93952V9.50058L16.8318 9.78277L19.2298 14.4316H11.5018L13.922 9.70927ZM8.81245 19.6961L10.8337 15.7441H19.9109L21.9414 19.6816L8.81245 19.6961Z"
                           fill="#178ECE"
                        />
                        <defs>
                           <clipPath id="clip0_10215_4932">
                              <rect
                                 width="85"
                                 height="85"
                                 fill="white"
                                 transform="translate(23.875 0.798767)"
                              />
                           </clipPath>
                        </defs>
                     </svg>
                  </div>
                  <p class="description">
                     <b>All</b> supplies were consumed <b>outside</b> the US.
                  </p>
               </div>
               <div
                  id="declaration-one"
                  class="declaration-item"
                  :class="{
                     active: declaration === SuppliesDecl.ONE,
                     disabled: completed || hasUploadedFiles,
                  }"
                  @click="startStateSelection"
               >
                  <div class="icon-container">
                     <svg
                        width="128"
                        height="86"
                        viewBox="0 0 128 86"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <g clip-path="url(#clip0_10215_5047)">
                           <path
                              d="M74.3557 11.2428L75.5403 12.4274H77.4707L74.7242 15.1738H79.5306L81.3228 13.3816H82.685V14.8294L84.4005 16.5449L86.1423 14.8031H89.9285L92.0958 16.9682H87.1842L82.6894 21.4653L83.9003 22.6762V29.176L85.9909 31.2665L87.7699 29.4875V25.1769L85.9799 23.3869L90.8191 18.5477L94.4189 22.1453L92.1617 24.4048H95.5969V27.1578L92.9887 29.7639L95.3315 32.1067L101.132 26.3067H104.205L107.888 22.6257H110.141V18.4841L111.97 16.6546H116.538L120.581 12.6116V6.75675H123.167L125.253 8.84073V11.6859L127.201 13.6361L125.6 15.2374H123.878V18.5806L120.817 21.6407L123.296 24.1196L120.611 26.8046H117.773L114.954 29.6213V35.0616H112.848L115.371 37.5843L113.488 39.4686L110.413 36.3931V39.6507L114.73 43.9656V48.8356L109.209 54.357H106.758L102.597 58.5206V63.86L108.467 69.7302V79.0927H105.552L101.948 75.4907V70.7546L97.1588 65.9659H86.502H80.6405L83.6766 69.0019L81.597 71.0815L78.0345 67.519H70.8524L62.1502 76.2212V79.6872H57.5062L54.2947 76.4757V72.7706L48.6811 67.157L46.0662 69.7697L41.6987 65.4021V62.4429V60.8897H34.6811H26.648L19.4549 53.6945H12.0096L4.67843 46.3611V36.5752L0.0498047 31.9466V24.3148L3.28326 21.0814V13.6317L8.219 8.69595V2.91346H10.8843L12.5646 4.59161V0.798767L43.9252 7.51795L74.3557 11.2428Z"
                              fill="#BBC7D5"
                           />
                           <rect
                              x="41.5928"
                              y="34.7553"
                              width="13.8955"
                              height="17.0012"
                              fill="#E2BD4D"
                           />
                           <path
                              d="M41.5889 36.1989C41.5889 35.5397 42.1233 35.0052 42.7825 35.0052H54.8993C55.5585 35.0052 56.093 35.5397 56.093 36.1989V50.3987C56.093 51.0579 55.5585 51.5923 54.8993 51.5923H42.7825C42.1233 51.5923 41.5889 51.0579 41.5889 50.3987V36.1989Z"
                              stroke="#178ECE"
                              stroke-width="2"
                           />
                        </g>
                        <defs>
                           <clipPath id="clip0_10215_5047">
                              <rect
                                 width="127.151"
                                 height="85"
                                 fill="white"
                                 transform="translate(0.0498047 0.798767)"
                              />
                           </clipPath>
                        </defs>
                     </svg>
                  </div>
                  <p v-if="declaredState" class="description">
                     Supplies were <b>only</b> consumed in <b>{{ declaredState }}</b
                     >.
                  </p>
                  <p v-else class="description">
                     Supplies were <b>only</b> consumed in <b>one</b> US state.
                  </p>
               </div>
               <div
                  id="declaration-many"
                  class="declaration-item"
                  :class="{
                     active: declaration === SuppliesDecl.MANY,
                     disabled: completed || hasUploadedFiles,
                  }"
                  @click="declareSupplies(SuppliesDecl.MANY)"
               >
                  <div class="icon-container">
                     <svg
                        width="128"
                        height="86"
                        viewBox="0 0 128 86"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <g clip-path="url(#clip0_10215_5063)">
                           <path
                              d="M74.6057 11.2428L75.7903 12.4274H77.7207L74.9742 15.1738H79.7806L81.5728 13.3816H82.935V14.8294L84.6505 16.5449L86.3923 14.8031H90.1785L92.3458 16.9682H87.4342L82.9394 21.4653L84.1503 22.6762V29.176L86.2409 31.2665L88.0199 29.4875V25.1769L86.2299 23.3869L91.0691 18.5477L94.6689 22.1453L92.4117 24.4048H95.8469V27.1578L93.2387 29.7639L95.5815 32.1067L101.382 26.3067H104.455L108.138 22.6257H110.391V18.4841L112.22 16.6546H116.788L120.831 12.6116V6.75675H123.417L125.503 8.84073V11.6859L127.451 13.6361L125.85 15.2374H124.128V18.5806L121.067 21.6407L123.546 24.1196L120.861 26.8046H118.023L115.204 29.6213V35.0616H113.098L115.621 37.5843L113.738 39.4686L110.663 36.3931V39.6507L114.98 43.9656V48.8356L109.459 54.357H107.008L102.847 58.5206V63.86L108.717 69.7302V79.0927H105.802L102.198 75.4907V70.7546L97.4088 65.9659H86.752H80.8905L83.9266 69.0019L81.847 71.0815L78.2845 67.519H71.1024L62.4002 76.2212V79.6872H57.7562L54.5447 76.4757V72.7706L48.9311 67.157L46.3162 69.7697L41.9487 65.4021V62.4429V60.8897H34.9311H26.898L19.7049 53.6945H12.2596L4.92843 46.3611V36.5752L0.299805 31.9466V24.3148L3.53326 21.0814V13.6317L8.469 8.69595V2.91346H11.1343L12.8146 4.59161V0.798767L44.1752 7.51795L74.6057 11.2428Z"
                              fill="#BBC7D5"
                           />
                           <path
                              d="M73.5576 58.8077H87.4521V75.1551H73.5576V58.8077Z"
                              fill="#D2EBE4"
                           />
                           <path
                              d="M105.271 29.546H119.82V46.3837H105.271V29.546Z"
                              fill="#178ECE"
                           />
                           <path
                              d="M105.271 29.546H119.82V46.3837H105.271V29.546Z"
                              fill="white"
                              fill-opacity="0.5"
                           />
                           <path
                              d="M41.6797 35.2675H56.3926V51.6148H41.6797V35.2675Z"
                              fill="#E2BD4D"
                           />
                           <path
                              d="M1.46484 3.88055H15.8506V19.9009H1.46484V3.88055Z"
                              fill="#B6305B"
                           />
                           <path
                              d="M1.46484 3.88055H15.8506V19.9009H1.46484V3.88055Z"
                              fill="white"
                              fill-opacity="0.75"
                           />
                           <path
                              d="M1.2998 5.04461C1.2998 4.38537 1.83422 3.85095 2.49347 3.85095H14.6102C15.2695 3.85095 15.8039 4.38537 15.8039 5.04461V19.2444C15.8039 19.9036 15.2695 20.438 14.6102 20.438H2.49347C1.83422 20.438 1.2998 19.9036 1.2998 19.2444V5.04461ZM41.8389 36.1989C41.8389 35.5397 42.3733 35.0052 43.0326 35.0052H55.1493C55.8086 35.0052 56.343 35.5397 56.343 36.1989V50.3987C56.343 51.0579 55.8086 51.5923 55.1493 51.5923H43.0326C42.3733 51.5923 41.8389 51.0579 41.8389 50.3987V36.1989ZM105.291 31.1404C105.291 30.4811 105.826 29.9467 106.485 29.9467H118.602C119.261 29.9467 119.795 30.4811 119.795 31.1404V45.3401C119.795 45.9994 119.261 46.5338 118.602 46.5338H106.485C105.826 46.5338 105.291 45.9994 105.291 45.3401V31.1404ZM73.2447 60.2116C73.2447 59.5524 73.7791 59.018 74.4383 59.018H86.5551C87.2143 59.018 87.7488 59.5524 87.7488 60.2116V74.4114C87.7488 75.0706 87.2143 75.605 86.5551 75.605H74.4383C73.7791 75.605 73.2447 75.0706 73.2447 74.4114V60.2116Z"
                              stroke="#178ECE"
                              stroke-width="2"
                           />
                        </g>
                        <defs>
                           <clipPath id="clip0_10215_5063">
                              <rect
                                 width="127.151"
                                 height="85"
                                 fill="white"
                                 transform="translate(0.299805 0.798767)"
                              />
                           </clipPath>
                        </defs>
                     </svg>
                  </div>
                  <p class="description">
                     Supplies were consumed in <b>more than one</b> US state.
                  </p>
               </div>
            </div>

            <div class="d-flex justify-content-between mb-2">
               <b-button
                  variant="secondary"
                  class="d-flex align-items-center"
                  @click="displayIntro = true"
               >
                  <b-icon-arrow-left-short class="mr-1" />
                  View Introduction
               </b-button>
               <b-button
                  id="btn-supplies-upload"
                  class="d-flex align-items-center"
                  v-if="usedSupplies === true"
                  variant="primary"
                  :to="$clientStaffRoute({name: 'supplies-uploads'})"
               >
                  Next
                  <b-icon-arrow-right-short class="ml-1" />
               </b-button>
            </div>
         </div>
      </transition>

      <b-modal
         id="modal-select-state"
         title="Select a US State"
         centered
         @ok="declareSupplies(SuppliesDecl.ONE, stateSelection)"
         :ok-disabled="stateSelection === null"
         ok-variant="success"
      >
         <p>Select the US state in which all of your qualified R&D Supplies were consumed.</p>

         <b-form-select id="select-state" :options="stateOptions" v-model="stateSelection">
            <template #first>
               <b-form-select-option :value="null" disabled>
                  Please select one
               </b-form-select-option>
            </template>
         </b-form-select>
      </b-modal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {SuppliesDecl} from '@/store/modules/supplies';
import SectionAssignment from '../components/SectionAssignment';

export default {
   data() {
      return {
         loaded: false,
         displayIntro: true,
         SuppliesDecl,
         stateSelection: null,
         hasUploadedFiles: false,
      };
   },

   components: {
      SectionAssignment,
   },

   computed: {
      ...mapGetters({
         isRndig: 'isRndig',
         usStates: 'usStates',
         previousStage: 'previousStage',
         nextStage: 'nextStage',
         clientState: 'clientState',
         company: 'companies/currentCompany',
         study: 'companies/study',
         completed: 'supplies/isCompleted',
         declaration: 'supplies/declaration',
         industries: 'industries/industryMap',
         location: 'supplies/location',
         uploadedFiles: 'suppliesUploads/uploadedFiles',
         projects: 'projects/projectsInStudy',
      }),

      /** Has the current user seen the section intro? */
      seenIntro() {
         return this.clientState?.sectionIntros.supplies;
      },

      /** The location for the ONE declaration */
      declaredState() {
         if (this.location) {
            return this.usStates[this.location];
         }
         return null;
      },

      suppliesCopy() {
         const copy = this.company.prompts.supplies;
         return copy ? copy.split('\n') : [];
      },

      /** Study years has a human-readable string */
      studyYearsStr() {
         const upper = this.study.years.upper;
         const lower = this.study.years.lower;
         if (upper === lower) {
            return `in ${upper}`;
         }
         return `in the years ${lower} to ${upper}`;
      },

      /** Has the user has made a declaration that allows them to proceed with the supplies section? */
      usedSupplies() {
         if (this.declaration === null) {
            return null;
         }
         return [SuppliesDecl.ONE, SuppliesDecl.MANY].includes(this.declaration);
      },

      /** US States formatted for a b-form-select options attribute */
      stateOptions() {
         const stateOptions = Object.entries(this.usStates).map((entry) => {
            const [key, value] = entry;
            return {text: value, value: key};
         });
         return stateOptions;
      },
   },

   methods: {
      /** Hide the intro and update the client state indicating this user has seent the intro */
      hideIntro() {
         if (!this.seenIntro) {
            this.$store.dispatch('setSectionIntro', {
               section: 'supplies',
               value: true,
            });
         }
         this.displayIntro = false;
      },

      /** Unlock the supplies section */
      async unlock() {
         const msg =
            "Unlocking this section may delay your credit calculation. You will have to assign users again and will have to mark the section complete again once you're done.";
         const title = 'Unlock this section?';
         const proceed = await this.$bvModal.msgBoxConfirm(msg, {
            title,
            centered: true,
         });
         if (proceed) {
            await this.blockingRequest('supplies/completeSupplies', {
               companyId: this.$companyId,
               completed: false,
            });
         }
      },

      /** Display the state selection modal for the 'ONE' declaration */
      startStateSelection() {
         if (this.completed || this.hasUploadedFiles) {
            this.$store.commit('showAlert', {
               msg: "This answer can't be changed when uploaded supplies data exists",
               seconds: 5,
            });
            return;
         }
         this.stateSelection = null;
         this.$bvModal.show('modal-select-state');
      },

      /** Update the supplies declaration */
      async declareSupplies(value, location = null) {
         if (this.completed || this.hasUploadedFiles) {
            this.$store.commit('showAlert', {
               msg: "This answer can't be changed when uploaded supplies data exists",
               seconds: 5,
            });
            return;
         }
         try {
            await this.$store.dispatch('supplies/declareSuppliesUsed', {
               companyId: this.$companyId,
               value,
               location,
            });
         } catch (err) {
            const errCode = err.response.data.errors[0].code;
            if (errCode === this.$errorCodes.SUPPLIES_DATA_EXISTS) {
               this.hasUploadedFiles = true;
            }
            this.$store.commit('showAlert', {
               response: err.response,
               override: {
                  [this.$errorCodes().SUPPLIES_DATA_EXISTS]:
                     "This answer can't be changed when uploaded supplies data exists",
               },
               fallbackMsg: 'Failed to save your answer',
               seconds: 5,
            });
         }
      },
   },

   async created() {
      let requests = [
         this.$store.dispatch('projects/loadProjects', {companyId: this.$companyId}),

         this.$store.dispatch('industries/loadIndustries'),

         this.$store.dispatch('suppliesUploads/loadUploadCategory', {
            companyId: this.$companyId,
         }),

         // Refresh company data to update study
         this.$store.dispatch('companies/loadCompany', {companyId: this.$companyId, force: true}),
      ];
      await this.blockUntilAllSettled(requests);
      if (this.uploadedFiles.length > 0) {
         this.hasUploadedFiles = true;
      }
      this.displayIntro = !this.seenIntro && !this.isRndig;
      this.loaded = true;
   },
};
</script>

<style lang="scss" scoped>
.declaration-item {
   max-width: calc(50% - 1.5rem);

   @include lg {
      max-width: calc(25% - 1.5rem);
   }
}
</style>
