/* eslint-disable no-empty-pattern */

import Vue from 'vue';
import {UserGroups} from './session';

let userKey = 0;

const state = () => ({
   users: {},
   companyUsers: {},
   newUsers: [],
});

const getters = {
   users: (state) => Object.values(state.users),
   userMap: (state) => state.users,

   adminUsers: (state) => {
      return Object.values(state.users).filter((user) => user.groups.includes(UserGroups.ADMIN));
   },
   staffUsers: (state) => {
      return Object.values(state.users).filter((user) => user.groups.includes(UserGroups.STAFF));
   },
   customerUsers: (state) => {
      return Object.values(state.users).filter((user) => user.groups.includes(UserGroups.CUSTOMER));
   },
   smeUsers: (state) => {
      return Object.values(state.users).filter((user) => user.groups.includes(UserGroups.SME));
   },
   companyUsers: (state) => state.companyUsers,
   newUsers: (state) => state.newUsers,
};

const mutations = {
   clear: (state) => {
      state.companyUsers = {};
      state.newUsers = [
         {
            firstName: null,
            lastName: null,
            email: null,
            title: null,
            department: null,
            subsidiary: null,
            key: userKey++,
         },
      ];
   },

   /** Store users associated with a company */
   setCompanyUsers: (state, {users}) => {
      users.forEach((user) => {
         Vue.set(state.companyUsers, user.id, user);
      });
   },

   setCompanyUser: (state, {user}) => {
      Vue.set(state.companyUsers, user.id, user);
   },

   /** Store users */
   setUsers: (state, {users}) => {
      users.forEach((user) => {
         Vue.set(state.users, user.id, user);
      });
   },

   setUser: (state, {user}) => {
      Vue.set(state.users, user.id, user);
   },

   /** Add a blank user to the newUsers array */
   addNewUser: (state) => {
      state.newUsers.push({
         firstName: null,
         lastName: null,
         email: null,
         title: null,
         department: null,
         subsidiary: null,
         key: userKey++,
      });
   },

   /** Remove a user from newUsers */
   removeNewUser: (state, {index}) => {
      state.newUsers.splice(index, 1);
   },

   /** Update a value on a new user */
   updateNewUser: (state, {index, field, value}) => {
      state.newUsers[index][field] = value;
   },
};

const actions = {
   // Fetch the list of users
   async fetchUsers() {
      return (await this._vm.$http.get('/api/user')).data.results;
   },

   async loadUsers({commit}) {
      const users = (await this._vm.$http.get('/api/user')).data.results;
      commit('setUsers', {users});
   },

   // Fetch a user profile
   async fetchProfile({}, {id}) {
      return (await this._vm.$http.get(`/api/user/${id}`)).data;
   },

   // Create a user
   async createUser({}, payload) {
      return (await this._vm.$http.post('/api/user', payload)).data;
   },

   // Update a user profile
   async updateProfile({rootGetters, commit}, {id, profile}) {
      const newProfile = (await this._vm.$http.put(`/api/user/${id}`, profile)).data;
      if (id === rootGetters.profile.id) {
         commit('setProfile', newProfile, {root: true});
      }

      return newProfile;
   },

   // Enable a user
   async enableUser({commit}, {id}) {
      const userData = (await this._vm.$http.put(`/api/user/${id}/enable`)).data;
      commit('setUser', {user: userData});
      return userData;
   },

   // Disable a user
   async disableUser({commit}, {id}) {
      const userData = (await this._vm.$http.put(`/api/user/${id}/disable`)).data;
      commit('setUser', {user: userData});
      return userData;
   },

   // Reset a user's password
   async resetPassword({}, payload) {
      return (await this._vm.$http.post(`/api/user/${payload.id}/reset-password`)).data;
   },

   // Fetch a staff user's company roles
   async fetchUserRoles({}, payload) {
      return (await this._vm.$http.get(`/api/user/${payload.id}/roles`)).data;
   },

   /**
    * Load the client users of a company
    * @param {Object} payload
    * @param {string} payload.companyId - the ID of a company
    */
   async loadCompanyUsers({commit}, {companyId}) {
      const users = (await this._vm.$http.get(`/api/company/${companyId}/user`)).data.results;
      commit('clear');
      commit('setCompanyUsers', {users});
   },

   /**
    * POST the users in the newUsers array
    * @param {Object} payload
    */
   async saveNewUsers({commit, state}, {companyId, group = UserGroups.SME}) {
      const requests = state.newUsers.map((user, index) => {
         const payload = {
            ...user,
            companyId,
            groups: [group],
         };
         delete payload['key'];
         return this._vm.$http
            .post(`/api/user`, payload)
            .then((response) => {
               commit('setCompanyUser', {user: response.data});
               commit(
                  'showAlert',
                  {
                     msg: `Successfully saved user ${user.firstName} ${user.lastName}`,
                     variant: 'success',
                     seconds: 5,
                  },
                  {root: true}
               );
               return {user: response.data, index, response, error: null};
            })
            .catch((error) => {
               return {user, index, error, response: error.response};
            });
      });

      const results = await Promise.allSettled(requests);
      results
         .filter((result) => result.value.error === null)
         .reverse()
         .forEach((result) => {
            commit('removeNewUser', {index: result.value.index});
         });
      return results;
   },

   async uploadProfileImage({commit}, {canvas, userId}) {
      if (canvas) {
         const formData = new FormData();
         const blob = await new Promise((resolve) => canvas.toBlob(resolve));
         formData.append('image', blob);
         await this._vm.$http.post(`/api/user/${userId}/profile/image`, formData, {
            headers: {'Content-Type': 'multipart/form-data'},
         });
         commit('refreshProfileImage', null, {root: true});
      }
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
