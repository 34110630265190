export default {
   // The action would invalidate a completed time survey
   // Resubmit with query param force=True to override
   VALIDATED_TIMESURVEY: 120,

   // The action is prohibited due to the year being part
   // of a closed study
   CLOSED_STUDY: 121,

   // The action requires a query param force=true
   CONFIRMATION_REQUIRED: 120,

   // The object cannot be deleted
   CANNOT_DELETE: 122,

   // The action violates a uniqueness constraint
   DUPLICATE: 150,

   // The upload category is locked
   UPLOAD_CATEGORY_LOCKED: 125,

   // Supplies data exists
   SUPPLIES_DATA_EXISTS: 125,

   // Data is locked
   LOCKED: 125,

   // A user with the same email address already exists
   CONFLICTING_EMAIL: 125,

   // The file failed a validation check
   FILE_VALIDATION_FAILED: 180,

   // PERMISSION DENIED
   PERMISSION_DENIED: 75,

   // The upload failed because no appropriate character
   // encoding could be identified.
   NO_ENCODING_FOUND: 200,

   // Data couldn't be saved due to being stale
   STALE_VALUE: 130,
};
