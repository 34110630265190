<template>
   <div>
      <b-form-group invalidFeedback="Please enter a valid email" v-if="!readonly">
         <b-form-input
            v-model="answerValue"
            id="input-answer"
            type="email"
            :state="dirty ? !$v.answerValue.$invalid : null"
         ></b-form-input>
      </b-form-group>
      <div v-else :id="`answer-${index + 1}`">
         <div v-if="answerValue !== null">
            {{ answerValue }}
         </div>
         <div v-else>
            <b>Not Answered</b>
         </div>
      </div>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {email} from 'vuelidate/lib/validators';

export default {
   props: {
      index: Number,
      readonly: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      ...mapGetters({
         questions: 'questionnaire/questions',
      }),

      question() {
         return this.questions[this.index];
      },

      answer() {
         return this.question.answer;
      },

      answerValue: {
         get() {
            if (this.answer) {
               return this.answer.answer;
            } else {
               return null;
            }
         },
         set(value) {
            this.$store.commit('questionnaire/setAnswerValue', {
               questionIndex: this.index,
               value,
            });
         },
      },

      dirty() {
         if (this.answer) {
            return this.answer.dirty;
         } else {
            return false;
         }
      },
   },

   validations: {
      answerValue: {
         email,
      },
   },

   watch: {
      answerValue: {
         immediate: true,
         handler() {
            this.$emit('validate', !this.$v.$invalid);
         },
      },
   },
};
</script>
