// Add Keycloak instance to Vue as global and its prototype

export default function install(Vue, config) {
   // create keycloak instance
   const _keycloak = new window.Keycloak(config);

   // add as global attribute
   Vue.$keycloak = _keycloak;

   // global async update function, returning new token
   Vue.updateToken = async () => {
      // await update
      const refreshed = await _keycloak.updateToken(70);
      console.log('Refreshed token:', refreshed);
      // return possibly new token
      return _keycloak.token;
   };

   // add getter to prototype
   Object.defineProperties(Vue.prototype, {
      $keycloak: {
         get() {
            return _keycloak;
         },
      },

      isAuthenticated: {
         get() {
            return _keycloak.authenticated;
         },
      },
   });

   // logout user; this will return them to the keycloak login
   Vue.prototype.logout = () => {
      // clear the cookie so that users can access landing page after logging out
      const COOKIE = 'rdig_auth_at';

      if (document.cookie.split(';').some((item) => item.trim().startsWith(`${COOKIE}=`))) {
         // Determine the domain name without the 'app' subdomain
         let domainparts = location.hostname.split('.');
         if (domainparts[0] === 'app') {
            domainparts.shift();
         }
         const domain = domainparts.join('.');

         // Set the expiration date in the past so the cookie immediately expires
         document.cookie = `${COOKIE}=; Path=/; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      }

      _keycloak.logout({redirectUri: location.origin});
   };
}
