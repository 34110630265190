/**
 * A mixin implementing some shared behavior among the employee time inputs
 *
 * Usage:
 *
 * Esc to restore:
 *    - This requires `keydown` events to be handled by `$onKeydown`
 *    - The component calls `$storeValue(value)` to save the value e.g. on focus
 *    - The component must implement `restoreValue(value)` to update data
 *
 * Vertical navigation:
 *    - This requires `keydown` events to be handled by `$onKeydown`
 *    - The component must implement `nextVerticalInputId(offset)`, which recieves
 *      a non-zero integer offset and returns the ID of the next input to focus
 */
export default {
   data() {
      return {
         _storedValue: null,
      };
   },

   methods: {
      $totalPercentageInputId(ident, employeeIndex = null) {
         let id = `input-total-percentage-${ident}`;
         if (employeeIndex !== null) {
            id += `-${employeeIndex}`;
         }
         return id;
      },

      $projectPercentageInputId(ident, projectIndex, employeeIndex = null) {
         if (employeeIndex === null) {
            return `input-project-percentage-${ident}-${projectIndex}`;
         }
         return `input-project-percentage-${ident}-${employeeIndex}-${projectIndex}`;
      },

      $activityInputId(ident, activityIndex, employeeIndex = null) {
         if (employeeIndex === null) {
            return `cb-activity-${ident}-${activityIndex}`;
         }
         return `cb-activity-${ident}-${employeeIndex}-${activityIndex}`;
      },

      $storeValue(value) {
         this._storedValue = value;
      },

      $onKeydown(event) {
         const keycodes = ['Escape', 'ArrowUp', 'ArrowDown', 'Enter', 'NumpadEnter'];

         if (!keycodes.includes(event.code)) {
            return;
         }

         if ('Escape' === event.code) {
            this.restoreValue(this._storedValue);
            return;
         }

         if ((event.code === 'Enter' || event.code === 'NumpadEnter') && event.shiftKey) {
            this.useRefValue();
         } else if (['ArrowUp', 'ArrowDown', 'Enter', 'NumpadEnter'].includes(event.code)) {
            event.preventDefault();
            const direction = event.code === 'ArrowUp' ? -1 : 1;
            let offset = direction;
            let nextElement;

            const getNextElement = (offset) => {
               const nextId = this.nextVerticalInputId(offset);
               return document.getElementById(nextId);
            };

            // Search for the next input element, skipping disabled inputs
            while ((nextElement = getNextElement(offset)) && nextElement.disabled) {
               offset += direction;
            }

            if (nextElement) {
               nextElement.focus();
               nextElement.select();
            }
         }
      },
   },
};
