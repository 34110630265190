<template>
   <b-form-row class="multi-user-select">
      <b-col md="5">
         <!-- unassigned staff -->
         <b-form-select
            :id="`select-staff-${role.toLowerCase()}-left`"
            v-model="leftSelection"
            :options="leftSelectOptions"
            class="user-select"
            multiple
         ></b-form-select>
      </b-col>

      <b-col md="1" class="d-flex flex-column align-items-center justify-content-center">
         <button
            id="btn-staff-assignment-add"
            class="icon-btn icon-btn-secondary mb-2"
            @click="addUsers"
         >
            <b-icon icon="chevron-right" />
         </button>
         <button
            id="btn-staff-assignment-remove"
            class="icon-btn icon-btn-secondary"
            @click="removeUsers"
         >
            <b-icon icon="chevron-left" />
         </button>
      </b-col>

      <b-col md="5">
         <!-- assigned staff -->
         <b-form-select
            :id="`select-staff-${role.toLowerCase()}-right`"
            v-model="rightSelection"
            :options="rightSelectOptions"
            class="user-select"
            multiple
         ></b-form-select>
      </b-col>
   </b-form-row>
</template>

<script>
export default {
   props: {
      users: Array,
      value: Array,
      companyId: [String, Number],
      role: String,
   },

   data() {
      return {
         selection: this.value,
         leftSelection: [],
         rightSelection: [],
      };
   },

   methods: {
      onInput() {
         // This method needs to be called any time the selection changes
         // to communicate that change with the parent component.
         this.$emit('input', this.selection);
      },
      addUsers() {
         if (this.leftSelection.length === 0) {
            return;
         }

         // Make a request for each new assignment
         const requests = this.leftSelection.map((userId) => {
            const payload = {
               companyId: this.companyId,
               userId,
               role: this.role,
            };
            return this.$store.dispatch('companies/addStaffAssignment', payload).then(() => {
               return userId;
            });
         });

         // Once all requests are settled, update the selection
         Promise.allSettled(requests).then((results) => {
            const updated = results
               .filter((item) => 'fulfilled' === item.status)
               .map((item) => item.value);
            this.selection = [...this.selection, ...updated];
            this.onInput();
         });
      },
      removeUsers() {
         if (this.rightSelection.length === 0) {
            return;
         }

         // Make a request for each removed assignment
         const requests = this.rightSelection.map((userId) => {
            const payload = {
               companyId: this.companyId,
               userId,
            };
            return this.$store.dispatch('companies/removeStaffAssignment', payload).then(() => {
               return userId;
            });
         });

         // Once all requests are settled, update the selection
         Promise.allSettled(requests).then((results) => {
            const updated = results
               .filter((item) => 'fulfilled' === item.status)
               .map((item) => item.value);
            this.selection = this.selection.filter((val) => {
               return !updated.includes(val);
            });
            this.onInput();
         });
      },
   },

   computed: {
      userOptions() {
         // Updating this.selection triggers an update on this array,
         // which in turn triggers an update on the left and right
         // select options.
         return this.users.map((user) => {
            return {
               value: user.id,
               text: `${user.firstName} ${user.lastName}`,
               selected: this.selection.includes(user.id),
            };
         });
      },
      leftSelectOptions() {
         return this.userOptions.filter((user) => !user.selected);
      },
      rightSelectOptions() {
         return this.userOptions.filter((user) => user.selected);
      },
   },
};
</script>

<style lang="scss" scoped>
.multi-user-select {
   .user-select {
      height: 100% !important;
   }
}
</style>
