<template>
   <b-container>
      <div class="standard-width mx-auto">
         <h1 class="page-title">Create a Client Company</h1>
      </div>
      <CompanyForm ref="form" :company="newCompany()" @submit="onSubmit" is-create />
   </b-container>
</template>

<script>
import CompanyForm from '@/views/company/widgets/CompanyForm';
import {Company} from '@/helpers/types';

export default {
   name: 'CompanyCreate',

   components: {
      CompanyForm,
   },

   methods: {
      async onSubmit(company) {
         // Sanity check that the form is validated
         if (!this.$refs.form.$v.$invalid) {
            // Create a copy so we don't modify the form's copy of the data
            let payload = {...company};

            //filter out nulls in credit year attributes
            payload.federalCreditYears.years = payload.federalCreditYears.years.filter(
               (year) => year !== null
            );
            payload.stateCreditYears = payload.stateCreditYears.filter(
               (item) => item.state !== null
            );
            payload.stateCreditYears.forEach((item) => {
               item.years = item.years.filter((year) => year !== null);
            });

            try {
               const response = await this.blockingRequest('companies/createCompany', payload);
               const newId = response.data.id;
               if (newId) {
                  this.$router.push({name: 'company-detail', params: {id: newId}});
               } else {
                  this.$router.push({name: 'Home'});
               }
            } catch (err) {
               this.$store.commit('showAlert', {
                  response: err.response,
                  fallbackMsg: 'Submission failed',
                  seconds: 5,
               });
            }
         }
      },

      newCompany() {
         return new Company();
      },
   },
};
</script>
