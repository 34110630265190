export const FieldStatus = Object.freeze({
   NONE: 'NONE',
   PENDING: 'PENDING',
   SAVED: 'SAVED',
   ERROR: 'ERROR',
});

export class Field {
   constructor({value = null, status = FieldStatus.NONE}) {
      this.value = value;
      this._status = status;
      this._lastUpdated = new Date();
   }

   get status() {
      return this._status;
   }

   get lastUpdated() {
      return this._lastUpdated;
   }

   saveStarted() {
      this._status = FieldStatus.PENDING;
   }

   saveSuccessful() {
      this._status = FieldStatus.SAVED;
      this._lastUpdated = new Date();
   }

   saveFailed() {
      this._status = FieldStatus.ERROR;
   }
}
