<template>
   <g>
      <path
         d="M7.39185 17.3101C8.00947 17.3101 8.62104 17.4289 9.19165 17.6597C9.76226 17.8906 10.2807 18.229 10.7175 18.6555C11.1542 19.0821 11.5006 19.5885 11.737 20.1459C11.9733 20.7032 12.095 21.3005 12.095 21.9038C12.095 22.5071 11.9733 23.1044 11.737 23.6618C11.5006 24.2191 11.1542 24.7255 10.7175 25.1521C10.2807 25.5787 9.76226 25.917 9.19165 26.1479C8.62104 26.3787 8.00947 26.4976 7.39185 26.4976C6.1445 26.4976 4.94824 26.0136 4.06623 25.1521C3.18423 24.2906 2.68872 23.1221 2.68872 21.9038C2.68872 20.6855 3.18423 19.517 4.06623 18.6555C4.94824 17.794 6.1445 17.3101 7.39185 17.3101ZM21.5012 17.3101C22.1188 17.3101 22.7304 17.4289 23.301 17.6597C23.8716 17.8906 24.3901 18.229 24.8268 18.6555C25.2636 19.0821 25.61 19.5885 25.8463 20.1459C26.0827 20.7032 26.2043 21.3005 26.2043 21.9038C26.2043 22.5071 26.0827 23.1044 25.8463 23.6618C25.61 24.2191 25.2636 24.7255 24.8268 25.1521C24.3901 25.5787 23.8716 25.917 23.301 26.1479C22.7304 26.3787 22.1188 26.4976 21.5012 26.4976C20.2539 26.4976 19.0576 26.0136 18.1756 25.1521C17.2936 24.2906 16.7981 23.1221 16.7981 21.9038C16.7981 20.6855 17.2936 19.517 18.1756 18.6555C19.0576 17.794 20.2539 17.3101 21.5012 17.3101ZM35.6106 17.3101C36.2282 17.3101 36.8398 17.4289 37.4104 17.6597C37.981 17.8906 38.4995 18.229 38.9362 18.6555C39.3729 19.0821 39.7194 19.5885 39.9557 20.1459C40.1921 20.7032 40.3137 21.3005 40.3137 21.9038C40.3137 22.5071 40.1921 23.1044 39.9557 23.6618C39.7194 24.2191 39.3729 24.7255 38.9362 25.1521C38.4995 25.5787 37.981 25.917 37.4104 26.1479C36.8398 26.3787 36.2282 26.4976 35.6106 26.4976C34.3632 26.4976 33.167 26.0136 32.285 25.1521C31.403 24.2906 30.9075 23.1221 30.9075 21.9038C30.9075 20.6855 31.403 19.517 32.285 18.6555C33.167 17.794 34.3632 17.3101 35.6106 17.3101Z"
         fill="url(#paint0_linear_10205_5878)"
      />
      <defs>
         <linearGradient
            id="paint0_linear_10205_5878"
            x1="-9.17922"
            y1="26.4976"
            x2="11.1473"
            y2="48.4605"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0.265625" stop-color="#1C72B9" />
            <stop offset="1" stop-color="#01498C" />
         </linearGradient>
      </defs>
   </g>
</template>

<script>
export default {
   data() {
      return {
         name: 'icon-dashboard',
         viewBox: '0 0 44 43',
         width: 43,
         height: 42,
      };
   },
};
</script>
