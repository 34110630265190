<template>
   <b-container v-if="loaded">
      <b-row class="title-row mt-3 mb-4">
         <b-col class="d-flex align-items-center justify-content-between">
            <h1 class="d-inline-block mb-0" style="text-transform: none">
               {{ company.companyName }}
            </h1>

            <div class="d-flex align-items-center">
               <b-button
                  v-if="isAdmin"
                  class="mr-2"
                  :to="{name: 'admin-company', params: {id: companyId}}"
               >
                  Admin View
               </b-button>
               <b-button
                  id="btn-export-data"
                  variant="primary"
                  :to="{name: 'company-export', params: {id: companyId}}"
               >
                  Export Data
               </b-button>
            </div>
         </b-col>
      </b-row>

      <b-alert variant="warning" :show="!canActAsCustomer && hasStaff">
         Some actions are unavailable to users not assigned to this company.
      </b-alert>

      <b-alert variant="danger" :show="!hasStaff">
         This company has no assigned staff. This will limit some functionality for Customer and SME
         users associated with this company.
      </b-alert>

      <!-- Company Information -->
      <CollapseCard title="Company Information" ident="company-info">
         <b-table
            id="table-company-info"
            :items="infoTableItems"
            :fields="info.tableFields"
            class="mt-3 mb-0"
         >
            <!-- Customize last header to add edit button -->
            <template #head(value)="data">
               <div class="cell-w-buttons">
                  {{ data.label }}
                  <b-button
                     id="btn-edit-info"
                     variant="primary"
                     class="ml-2"
                     size="sm"
                     :to="{name: 'company-edit', params: {id: companyId}}"
                  >
                     Edit Info
                  </b-button>
               </div>
            </template>
            <template #cell(key)="data">
               <b>{{ data.value }}</b>
            </template>
         </b-table>
      </CollapseCard>

      <!-- Study -->
      <CollapseCard title="Study" ident="study">
         <template #subtitle>
            <span v-if="study"> {{ study.years.lower }}-{{ study.years.upper }} </span>
            <span class="text-danger" v-else> None </span>
         </template>

         <b-row class="mb-2">
            <b-col cols="4" md="3" lg="2">
               <h6 class="heavy">Current Study</h6>
            </b-col>
            <b-col>
               <span v-if="study" id="study-years">
                  {{ study.years.lower }}-{{ study.years.upper }}
               </span>
               <span v-else class="text-danger" id="study-years">
                  <b>None</b>
               </span>
            </b-col>
         </b-row>

         <b-row>
            <b-col cols="4" md="3" lg="2">
               <h6 class="heavy">Description</h6>
            </b-col>
            <b-col>
               <span v-if="study && study.description" id="study-description">
                  {{ study.description }}
               </span>
               <span v-else id="study-description"> -- </span>
            </b-col>
         </b-row>

         <div class="d-flex align-items-center justify-content-end mt-3" v-if="canActAsCustomer">
            <b-button
               id="btn-close-study"
               v-if="study"
               size="sm"
               variant="danger"
               @click="$bvModal.show('modal-close-study')"
            >
               Close
            </b-button>
            <b-button
               id="btn-open-study"
               v-if="!study"
               size="sm"
               variant="primary"
               class="mr-2"
               @click="$bvModal.show('modal-open-study')"
            >
               Open
            </b-button>
         </div>
      </CollapseCard>

      <!-- Employees -->
      <CollapseCard title="Employees" ident="employees">
         <div v-if="study">
            <div class="mb-3 px-3 d-flex align-items-center justify-content-between">
               <YearSelect
                  study
                  emptyText="Select a year"
                  v-model="employeeCard.year"
                  @change="loadEmployees"
               />
               <b-button
                  id="btn-configure-employees"
                  variant="primary"
                  size="sm"
                  class="action-btn ml-2"
                  @click="toEmployeeConfig"
               >
                  Configure Employees
               </b-button>
            </div>
            <b-table
               v-if="employeeCard.year"
               id="table-employees"
               class="mb-0 scrollbar"
               :fields="employeeTableFields"
               :items="employees"
               sort-by="fullname"
               responsive
               sticky-header="500px"
            >
            </b-table>
         </div>

         <b-alert :show="study === null" variant="primary" class="m-3">
            This company does not have an open study. Open a study to configure employees.
         </b-alert>
      </CollapseCard>

      <!-- Activities -->
      <CollapseCard
         title="Qualified R&D Activities"
         :subtitle="`${activities.length} Qualified Activities`"
         ident="activities"
      >
         <b-table
            id="table-activities"
            thead-class="table-head"
            :class="[activities.length > 0 ? 'mb-0' : 'mb-5']"
            :fields="[{key: 'name', sortable: true}]"
            sort-by="name"
            :items="activities"
         >
            <template #head(name)="data">
               <div class="d-flex align-items-center justify-content-between cell-w-buttons">
                  {{ data.label }}
                  <b-button
                     id="btn-configure-activities"
                     variant="primary"
                     size="sm"
                     class="action-btn ml-2"
                     :to="{name: 'activities-configure', params: {id: companyId}}"
                  >
                     Configure Qualified Activities
                  </b-button>
               </div>
            </template>
         </b-table>
      </CollapseCard>

      <!-- Projects -->
      <CollapseCard
         title="R&D Business Components"
         :subtitle="`${projects.length} Business Components`"
         ident="projects"
      >
         <div v-if="canActAsCustomer">
            <div class="d-flex justify-content-between my-2 align-items-center">
               <h3>Business Components</h3>
               <b-button
                  id="btn-configure-projects"
                  size="sm"
                  variant="primary"
                  class="action-btn ml-2"
                  :to="{
                     name: 'projects-configure',
                     params: {id: companyId},
                  }"
               >
                  Configure Business Components
               </b-button>
            </div>
            <b-table
               id="table-projects"
               thead-class="table-head"
               :fields="[
                  {key: 'name', sortable: true},
                  {key: 'years.lower', label: 'Start Year', sortable: true},
                  {
                     key: 'years.upper',
                     label: 'End Year',
                     sortable: true,
                     formatter: (value) => value || 'Ongoing',
                  },
               ]"
               sort-by="name"
               :items="projects"
            >
            </b-table>

            <div v-if="study">
               <div class="d-flex align-items-center justify-content-between mt-5 mb-2">
                  <h3>Project Questionnaires</h3>
                  <b-button
                     id="btn-add-project-questionnaire"
                     size="sm"
                     variant="primary"
                     class="action-btn ml-2"
                     :to="{
                        name: 'project-questionnaire-create',
                        params: {
                           id: companyId,
                        },
                     }"
                  >
                     Add New Questionnaire
                  </b-button>
               </div>
               <b-table
                  id="table-project-questionnaires"
                  class="mb-0"
                  :fields="projectCard.questionnaireFields"
                  :items="projectQuestionnaires"
                  sort-by="projectId"
               >
                  <template #cell(title)="data">
                     <div class="d-flex align-items-center">
                        <b-icon-circle-fill
                           class="mr-2"
                           :variant="questionnaireStatus(data.item).variant"
                        ></b-icon-circle-fill>
                        {{ data.value }}
                     </div>
                  </template>

                  <template #cell(actions)="data">
                     <div class="cell-w-buttons d-flex align-items-center justify-content-end">
                        <b-button
                           v-if="isStaff && questionnaireStatus(data.item).text === 'Not Started'"
                           id="btn-delete-project-questionnaire"
                           variant="danger"
                           size="sm"
                           @click="deleteQuestionnaireModal(data.item)"
                        >
                           Delete
                        </b-button>
                        <b-button
                           id="btn-edit-project-questionnaire"
                           variant="primary"
                           size="sm"
                           class="ml-2"
                           :to="{
                              name: 'project-questionnaire-edit',
                              params: {id: companyId, questionnaireId: data.item.id},
                           }"
                        >
                           Edit
                        </b-button>
                        <b-button
                           id="btn-view-project-questionnaire"
                           variant="primary"
                           size="sm"
                           class="ml-2"
                           :to="{
                              name: 'project-questionnaire-complete',
                              params: {id: companyId, questionnaireId: data.item.id},
                           }"
                        >
                           View
                        </b-button>
                     </div>
                  </template>
               </b-table>
            </div>

            <b-alert :show="study === null" variant="primary" class="m-3 mt-5">
               This company does not have an open study. Open a study to configure project
               questionnaires.
            </b-alert>
         </div>
         <div v-else>
            <b-alert variant="warning" class="m-3" show>
               Only users associated with this company can access business components.
            </b-alert>
         </div>
      </CollapseCard>

      <!-- Company Questionnaires -->
      <CollapseCard
         title="Company Questionnaires"
         :subtitle="study ? `${companyQuestionnaires.length} Questionnaires` : null"
         ident="company-questionnaire"
      >
         <div v-if="study">
            <div class="px-3 d-flex justify-content-end">
               <b-button
                  id="btn-add-company-questionnaire"
                  size="sm"
                  variant="primary"
                  class="action-btn ml-2"
                  :to="{
                     name: 'company-questionnaire-create',
                     params: {id: companyId},
                  }"
               >
                  Add New Questionnaire
               </b-button>
            </div>

            <b-table
               id="table-company-questionnaires"
               class="mt-3 mb-0"
               thead-class="table-head"
               :fields="questionnaire.tableFields"
               :items="companyQuestionnaires"
               sort-by="title"
            >
               <template #cell(title)="data">
                  <div class="d-flex align-items-center">
                     <b-icon-circle-fill
                        class="mr-2"
                        :variant="questionnaireStatus(data.item).variant"
                     ></b-icon-circle-fill>
                     {{ data.value }}
                  </div>
               </template>

               <template #cell(actions)="data">
                  <div class="cell-w-buttons justify-content-end">
                     <b-button
                        v-if="isStaff && questionnaireStatus(data.item).text === 'Not Started'"
                        id="btn-delete-company-questionnaire"
                        variant="danger"
                        size="sm"
                        @click="deleteQuestionnaireModal(data.item)"
                     >
                        Delete
                     </b-button>
                     <b-button
                        id="btn-edit-company-questionnaire"
                        variant="primary"
                        size="sm"
                        class="ml-2"
                        :to="{
                           name: 'company-questionnaire-edit',
                           params: {questionnaireId: data.item.id},
                        }"
                     >
                        Edit
                     </b-button>
                     <b-button
                        id="btn-view-company-questionnaire"
                        variant="primary"
                        size="sm"
                        class="ml-2"
                        :to="{
                           name: 'company-questionnaire-complete',
                           params: {id: companyId, questionnaireId: data.item.id},
                        }"
                     >
                        View
                     </b-button>
                  </div>
               </template>
            </b-table>
         </div>

         <b-alert :show="study === null" variant="primary" class="m-3">
            This company does not have an open study. Open a study to configure company
            questionnaires.
         </b-alert>
      </CollapseCard>

      <!-- Time Surveys -->
      <CollapseCard title="Time Surveys" ident="time-survey">
         <template #subtitle>
            <span v-if="timeSurvey.error === null && study !== null">
               {{ timeSurveyTableItems.length }} Surveys
            </span>
            <span class="text-danger" v-else> Not Configured </span>
         </template>

         <div class="mt-2 d-flex align-items-center justify-content-end">
            <b-button
               id="btn-assign-employees"
               size="sm"
               variant="primary"
               class="action-btn ml-2"
               :to="{
                  name: 'time-survey-assignments-staff',
               }"
            >
               Assign Employees
            </b-button>
         </div>

         <b-table
            v-if="timeSurveyTableItems.length > 0"
            id="table-time-surveys"
            class="mb-0"
            :fields="timeSurvey.tableFields"
            :items="timeSurveyTableItems"
            sort-by="year"
            sort-desc
         >
            <template #cell(actions)="data">
               <div class="cell-w-buttons d-flex justify-content-end">
                  <b-button
                     :id="`btn-ts-${data.item.year}`"
                     variant="primary"
                     size="sm"
                     :to="{
                        name: 'time-survey-review-staff',
                        params: {year: data.item.year, id: companyId},
                     }"
                  >
                     View
                  </b-button>
               </div>
            </template>
         </b-table>

         <b-alert :show="!timeSurveyConfigured && study !== null" variant="primary" class="m-3">
            <p>No time surveys have been fully configured.</p>
            <p class="mb-0">
               <b v-if="timeSurvey.error === 'NOACTIVITIES'">
                  This company has no qualified R&D activities.
               </b>
               <b v-else-if="timeSurvey.error === 'NOPROJECTS'">
                  This company has no R&D business components.
               </b>
               <b v-else-if="timeSurvey.error === 'NOEMPLOYEES'">
                  This company has no employees entered in the years corresponding to its R&D
                  projects.
               </b>
            </p>
         </b-alert>

         <b-alert
            :show="timeSurveyConfigured && timeSurveyTableItems.length === 0 && study !== null"
            variant="primary"
            class="m-3"
         >
            No time surveys are eligible for data entry. A time survey for a given year is only
            accessible after the tax year has ended. Check the Company Information section above to
            see this company's Tax Year End date.
         </b-alert>

         <b-alert :show="study === null" variant="primary" class="m-3">
            This company doesn't have an open study.
         </b-alert>
      </CollapseCard>

      <!-- Contractors -->
      <b-card style="margin-bottom: 2rem">
         <div class="d-flex align-items-center justify-content-between">
            <h2 class="mb-0">Contract Research</h2>

            <div class="d-flex align-items-center">
               <span id="contractor-status" class="mr-2">{{ contractorStatus }}</span>
               <b-button
                  id="btn-contractors"
                  variant="primary"
                  :to="{name: 'contractors-home-staff', params: {id: companyId}}"
               >
                  View
               </b-button>
            </div>
         </div>
      </b-card>

      <!-- Supplies -->
      <b-card style="margin-bottom: 2rem">
         <div class="d-flex align-items-center justify-content-between">
            <h2 class="mb-0">Supplies</h2>

            <div class="d-flex align-items-center">
               <span id="supplies-declaration" class="mr-2">{{ suppliesDeclaration }}</span>
               <b-button
                  id="btn-supplies"
                  variant="primary"
                  :to="{name: 'supplies-projects-staff', params: {id: companyId}}"
                  :disabled="_suppliesDeclaration === undefined"
               >
                  View
               </b-button>
            </div>
         </div>
      </b-card>

      <!-- Cloud Computing -->
      <b-card style="margin-bottom: 2rem">
         <div class="d-flex align-items-center justify-content-between">
            <h2 class="mb-0">Cloud Computing</h2>

            <div class="d-flex align-items-center">
               <b-button
                  id="btn-cloud-computing"
                  variant="primary"
                  :to="{name: 'cloud-declaration-staff', params: {id: companyId}}"
               >
                  View
               </b-button>
            </div>
         </div>
      </b-card>

      <!-- Copy Configuration -->
      <CollapseCard title="Company-Specific Text" ident="client-copy">
         <div class="d-flex align-items-center justify-content-end mb-2">
            <b-button
               id="btn-configure-copy"
               variant="primary"
               :to="{name: 'company-copy-config', params: {id: companyId}}"
            >
               Configure Company-Specific Text
            </b-button>
         </div>

         <b-list-group>
            <b-list-group-item v-for="(prompt, key, index) in PromptTypes" :key="key">
               <b :id="`prompt-title-${index + 1}`" class="mb-1">{{ prompt.title }}</b>

               <p :id="`prompt-${index + 1}`">{{ company.prompts[key] }}</p>
            </b-list-group-item>
         </b-list-group>
      </CollapseCard>

      <!-- Staff Assigments -->
      <CollapseCard title="Staff Assignments" ident="staff-assignments">
         <template #subtitle>
            <span :class="{'text-danger': !hasStaff}">
               {{ businessManagers.length + projectManagers.length + projectAssociates.length }}
               Users
            </span>
         </template>

         <h3 class="mt-3 mb-2">Business Managers</h3>
         <b-table
            id="table-business-managers"
            fixed
            :fields="accountFields"
            sort-by="firstName"
            :items="businessManagers"
         >
            <template #head(actions)="">
               <b-button
                  id="btn-edit-business-managers"
                  size="sm"
                  variant="primary"
                  class="action-btn ml-2"
                  @click="staff.editing = 'BM'"
                  v-b-modal.modal-staff-edit
               >
                  Edit
               </b-button>
            </template>
            <template #cell(email)="data"> {{ clarifyEmail(data.item) }} </template>
            <template #cell(enabled)="data">
               <b-icon v-if="!data.value" icon="x-circle"></b-icon>
            </template>
         </b-table>

         <h3 class="mt-5 mb-2">Project Managers</h3>
         <b-table
            id="table-project-managers"
            fixed
            :fields="accountFields"
            sort-by="firstName"
            :items="projectManagers"
         >
            <template #head(actions)="">
               <div class="cell-w-buttons">
                  <b-button
                     id="btn-edit-project-managers"
                     size="sm"
                     variant="primary"
                     class="action-btn ml-2"
                     @click="staff.editing = 'PM'"
                     v-b-modal.modal-staff-edit
                  >
                     Edit
                  </b-button>
               </div>
            </template>
            <template #cell(email)="data"> {{ clarifyEmail(data.item) }} </template>
            <template #cell(enabled)="data">
               <b-icon v-if="!data.value" icon="x-circle"></b-icon>
            </template>
         </b-table>

         <h3 class="mt-5 mb-2">Project Associates</h3>
         <b-table
            id="table-project-associates"
            fixed
            :fields="accountFields"
            sort-by="firstName"
            :items="projectAssociates"
            :class="[projectAssociates.length > 0 ? 'mb-0' : 'mb-5']"
         >
            <template #head(actions)="">
               <div class="cell-w-buttons">
                  <b-button
                     id="btn-edit-project-associates"
                     size="sm"
                     variant="primary"
                     class="action-btn ml-2"
                     @click="staff.editing = 'PA'"
                     v-b-modal.modal-staff-edit
                  >
                     Edit
                  </b-button>
               </div>
            </template>
            <template #cell(email)="data"> {{ clarifyEmail(data.item) }} </template>
            <template #cell(enabled)="data">
               <b-icon v-if="!data.value" icon="x-circle"></b-icon>
            </template>
         </b-table>
      </CollapseCard>

      <!-- Customer Accounts -->
      <CollapseCard
         title="User Accounts"
         :subtitle="`${customerAccounts.length + smeAccounts.length} Accounts`"
         ident="user-accounts"
      >
         <div class="d-flex align-items-center justify-content-between mt-3 mb-2">
            <h3 class="mb-0">Customer Accounts</h3>
            <b-button
               id="btn-add-customer"
               size="sm"
               variant="primary"
               class="action-btn ml-2"
               :to="{name: 'company-create-customer', params: {id: companyId}}"
            >
               Add Customer
            </b-button>
         </div>
         <b-table
            id="table-customer-accounts"
            fixed
            :fields="clientAccountFields"
            sort-by="firstName"
            :items="customerAccounts"
         >
            <template #cell(email)="data"> {{ clarifyEmail(data.item) }} </template>
            <template #cell(enabled)="data">
               <b-icon v-if="!data.value" icon="x-circle"></b-icon>
            </template>
            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button
                     :id="`btn-disable-user-customer-${data.index}`"
                     class="ml-2"
                     size="sm"
                     variant="danger"
                     @click="disableUser(data.item)"
                  >
                     <b-icon icon="trash" class="mt-1"></b-icon>
                  </b-button>
               </div>
            </template>
         </b-table>

         <!-- SME Accounts -->
         <div class="d-flex align-items-center justify-content-between mt-5 mb-2">
            <h3 class="mb-0">SME Accounts</h3>
            <b-button
               id="btn-add-sme"
               variant="primary"
               size="sm"
               class="ml-2"
               :to="{name: 'company-create-sme', params: {id: companyId}}"
            >
               Add SME
            </b-button>
         </div>
         <b-table
            id="table-sme-accounts"
            fixed
            :fields="clientAccountFields"
            sort-by="firstName"
            :items="smeAccounts"
            :class="[smeAccounts.length > 0 ? 'mb-0' : 'mb-5']"
         >
            <template #cell(enabled)="data">
               <b-icon v-if="!data.value" icon="x-circle"></b-icon>
            </template>
            <template #cell(email)="data"> {{ clarifyEmail(data.item) }} </template>
            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button
                     :id="`btn-disable-user-sme-${data.index}`"
                     class="ml-2"
                     size="sm"
                     variant="danger"
                     @click="disableUser(data.item)"
                  >
                     <b-icon icon="trash" class="mt-1"></b-icon>
                  </b-button>
               </div>
            </template>
         </b-table>
      </CollapseCard>

      <!-- Uploads -->
      <CollapseCard
         ident="uploads"
         title="Uploads"
         :subtitle="`${uploadCategories.length} Categories`"
      >
         <div class="mt-3" v-if="canActAsCustomer">
            <h3 class="mb-2">Documents</h3>
            <b-table
               id="table-categories"
               class="mb-3"
               thead-class="table-head"
               :fields="uploads.tableFields"
               sort-by="name"
               sort-icon-left
               :items="externalUploadCategories"
            >
               <template #head(status)="data">
                  <div class="cell-w-buttons">
                     {{ data.label }}
                     <b-button
                        id="btn-configure-categories"
                        variant="primary"
                        size="sm"
                        :to="{name: 'configure-uploads', params: {id: companyId}}"
                     >
                        Configure Categories
                     </b-button>
                  </div>
               </template>
               <template #cell(name)="data">
                  <b-icon-circle-fill
                     class="mr-2"
                     :variant="uploadStatus(data.item).variant"
                  ></b-icon-circle-fill>
                  {{ data.value }}
               </template>
            </b-table>

            <h3 class="mb-2">Data Files</h3>

            <b-table
               id="table-internal-categories"
               class="mb-0"
               thead-class="table-head"
               :fields="uploads.tableFields"
               sort-by="name"
               sort-icon-left
               :items="internalUploadCategories"
            >
               <template #cell(name)="data">
                  <b-icon-circle-fill
                     class="mr-2"
                     :variant="uploadStatus(data.item).variant"
                  ></b-icon-circle-fill>
                  {{ data.value }}
               </template>
               <template #cell(status)="data">
                  {{ uploadStatus(data.item).text }}
               </template>
            </b-table>
         </div>
         <div v-else>
            <b-alert variant="warning" class="m-3" show>
               Only staff associated with this company can access document uploads.
            </b-alert>
         </div>
      </CollapseCard>

      <b-modal
         id="modal-open-study"
         title="Open New Study"
         centered
         size="lg"
         @ok="openStudy"
         ok-title="Submit"
         :ok-disabled="$v.newStudy.$invalid || studyOverlap.length > 0"
      >
         <b-form-row class="mb-0">
            <b-col cols="6">
               <b-form-group label="Start Year" label-class="h6" class="mb-0">
                  <YearSelect
                     index="start"
                     v-model="newStudy.years.lower"
                     tax-years
                     :maxYear="newStudy.years.upper"
                     :state="studyOverlap.length > 0 ? false : null"
                  />
               </b-form-group>
            </b-col>
            <b-col cols="6">
               <b-form-group label="End Year" label-class="h6" class="mb-0">
                  <YearSelect
                     index="end"
                     v-model="newStudy.years.upper"
                     tax-years
                     :minYear="newStudy.years.lower"
                     :state="studyOverlap.length > 0 ? false : null"
                  />
               </b-form-group>
            </b-col>
         </b-form-row>
         <b-form-text v-if="studyOverlap.length === 0" class="mb-3">
            Select the year range on which this study is to be conducted.
         </b-form-text>

         <div v-if="studyOverlap.length > 0">
            <p class="text-danger mb-1">
               The selected year range overlaps the following closed studies for this company:
            </p>
            <ul class="text-danger">
               <li v-for="oStudy in studyOverlap" :key="oStudy.id">
                  {{ oStudy.years.lower }}-{{ oStudy.years.upper }}
               </li>
            </ul>
         </div>

         <h5>Description</h5>
         <b-form-textarea v-model="newStudy.description" id="input-description"></b-form-textarea>
      </b-modal>

      <b-modal id="modal-close-study" title="Close Current Study" centered @ok="closeStudy">
         <p>
            Are you sure you want to close the current study? Doing so will lock any study-bound
            data and prevent customer and SME users associated with this company from using the
            application until a new study is opened and configured.
         </p>
      </b-modal>

      <b-modal
         id="modal-delete-questionnaire"
         title="Delete Questionnaire?"
         centered
         @ok="submitDeleteQuestionnaire"
         ok-title="Delete"
         ok-variant="danger"
      >
         Are you sure you want to delete questionnaire <b>{{ deleteQuestionnaire.title }}</b
         >?
      </b-modal>

      <b-modal id="modal-staff-edit" :title="staffEditTitle" hide-footer centered size="lg">
         <StaffAssignmentEdit
            v-if="'BM' === staff.editing"
            v-model="company.businessManagers"
            :users="[...unassignedStaff, ...businessManagers]"
            role="BM"
            :companyId="company.id"
         />
         <StaffAssignmentEdit
            v-if="'PM' === staff.editing"
            v-model="company.projectManagers"
            :users="[...unassignedStaff, ...projectManagers]"
            role="PM"
            :companyId="company.id"
         />
         <StaffAssignmentEdit
            v-if="'PA' === staff.editing"
            v-model="company.projectAssociates"
            :users="[...unassignedStaff, ...projectAssociates]"
            role="PA"
            :companyId="company.id"
         />
      </b-modal>
   </b-container>
</template>

<script>
import {MIN_TAX_YEAR} from '@/helpers/constants';
import {mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import {unmungeEmail} from '@/helpers/utils';

import {PromptTypes} from '@/store/modules/companies';
import {UserGroups} from '@/store/modules/session';
import {SuppliesDecl} from '@/store/modules/supplies';
import ErrorCodes from '@/helpers/errorCodes';
import StaffAssignmentEdit from '@/views/company/widgets/StaffAssignmentEdit';
import YearSelect from '@/components/forms/YearSelect';
import CollapseCard from '@/components/CollapseCard';

export default {
   name: 'CompanyDetail',

   components: {
      CollapseCard,
      StaffAssignmentEdit,
      YearSelect,
   },

   async created() {
      this.$store.commit('employees/clear');
      this.$store.commit('activities/clear');
      this.$store.commit('projects/clear');
      this.$store.commit('timesurvey/clearYears');

      await this.fetchData();
      this.loaded = true;
   },

   data() {
      return {
         loaded: false,
         PromptTypes,
         info: {
            tableFields: [{key: 'key', label: 'Field', sortable: true}, 'value'],
         },
         newStudy: {
            years: {
               upper: null,
               lower: null,
            },
            description: null,
         },
         questionnaire: {
            tableFields: [
               {key: 'title', sortable: true},
               {
                  key: 'status',
                  formatter: (value, key, item) => this.questionnaireStatus(item).text,
                  sortable: true,
                  sortByFormatted: true,
                  class: 'no-wrap',
               },
               {key: 'actions', label: ''},
            ],
         },
         projectCard: {
            questionnaireFields: [
               {key: 'title', label: 'Project', sortable: true},
               {
                  key: 'projectId',
                  label: 'Business Component',
                  formatter: (value) => this.projectName(value),
                  sortable: true,
                  sortByFormatted: true,
               },
               {
                  key: 'status',
                  formatter: (value, key, item) => this.questionnaireStatus(item).text,
                  sortable: true,
                  sortByFormatted: true,
                  class: 'no-wrap',
               },
               {key: 'actions', label: ''},
            ],
         },
         employeeCard: {
            year: null,
         },
         timeSurvey: {
            tableFields: [
               {key: 'year', sortable: true},
               {key: 'actions', label: ''},
            ],
            error: null,
         },
         uploads: {
            tableFields: [
               {key: 'name', label: 'Category', sortable: true},
               {
                  key: 'status',
                  class: 'no-wrap',
                  formatter: (value, key, item) => this.uploadStatus(item).text,
                  sortable: true,
                  sortByFormatted: true,
               },
            ],
         },
         staff: {
            editing: 'BM',
         },
         accountFields: [
            {key: 'enabled', label: '', sortable: false, thStyle: {width: '3em'}},
            {key: 'firstName', sortable: true},
            {key: 'lastName', sortable: true},
            {key: 'email', sortable: true},
            {key: 'actions', label: '', thStyle: {width: '120px'}},
         ],
         deleteQuestionnaire: {
            title: null,
         },
         clarifyEmail: ({enabled, email}) => (enabled ? email : unmungeEmail(email)),
      };
   },

   computed: {
      ...mapGetters({
         company: 'companies/currentCompany',
         study: 'companies/study',
         studies: 'companies/studies',
         industryMap: 'industries/industryMap',
         isAdmin: 'isAdmin',
         isStaff: 'isStaff',
         profile: 'profile',
         projects: 'projects/projects',
         projectMap: 'projects/projectMap',
         companyQuestionnaires: 'questionnaire/companyQuestionnaires',
         projectQuestionnaires: 'questionnaire/projectQuestionnaires',
         activities: 'activities/activities',
         employees: 'employees/employees',
         uploadCategories: 'uploads/uploadCategories',
         externalUploadCategories: 'uploads/externalUploadCategories',
         internalUploadCategories: 'uploads/internalUploadCategories',
         timeSurveyYears: 'timesurvey/years',
         _suppliesDeclaration: 'supplies/declaration',
         contractorsCompleted: 'contractors/isComplete',
         users: 'users/users',
      }),

      // The items property for the company info table. Defines the order and
      // format of the table data.
      infoTableItems() {
         let items = [];

         // Define the order to display company fields
         const fields = [
            'companyName',
            'fein',
            'incorporated',
            'entityType',
            'industryId',
            'filingDeadline',
            'taxYearEnd',
            'payrollTaxElection',
            'federalCreditYears',
            'stateCreditYears',
            'firstRndEffort',
            'latestCredit',
            'cpaInfo',
            'contactInfo',
         ];

         const infoKeyMap = {
            fein: 'FEIN#',
            firstRndEffort: 'Year of First R&D Effort',
            latestCredit: 'Year of Latest Credit Generation',
            industryId: 'Industry',
            incorporated: 'Date of Incorporation',
         };

         for (const key of fields) {
            const value = this.company[key];
            let tableKey;
            let tableValue;

            // Convert data keys to human-readable table keys
            if (Object.keys(infoKeyMap).includes(key)) {
               // Keys that require special handling defined by infoKeyMap
               tableKey = infoKeyMap[key];
            } else {
               // convert camelcase to sentence
               tableKey = key.replace(/[A-Z]/g, ' $&');
               tableKey = tableKey.charAt(0).toUpperCase() + tableKey.slice(1);
            }

            // Format values
            if ('industryId' === key) {
               if (null === this.industryMap) {
                  tableValue = value;
               } else {
                  tableValue = this.industryMap[value]?.name;
               }
            } else if ('entityType' === key) {
               if (value) {
                  const entityTypeMap = {
                     S_CORP: 'S Corp',
                     C_CORP: 'C Corp',
                     PARTNERSHIP: 'Partnership',
                  };
                  tableValue = entityTypeMap[value];
               } else {
                  tableValue = value;
               }
            } else if ('federalCreditYears' === key) {
               if (value.years) {
                  tableValue = value.years.join(', ');
               } else {
                  tableValue = '';
               }
            } else if ('stateCreditYears' === key) {
               let stateStrings = [];
               value.forEach((item) => {
                  let stateString = `${item.state.toUpperCase()}: `;
                  stateString += `[${item.years.join(', ')}]`;
                  stateStrings.push(stateString);
               });
               tableValue = stateStrings.join(' ');
            } else if ('firstRndEffort' === key || 'latestCredit' === key) {
               if (MIN_TAX_YEAR === value) {
                  tableValue = `${MIN_TAX_YEAR} or prior`;
               } else {
                  tableValue = value;
               }
            } else if ('payrollTaxElection' === key) {
               tableValue = value ? 'yes' : 'no';
            } else if ('cpaInfo' === key) {
               items.push({key: 'CPA Name', value: value.name});
               items.push({key: 'CPA Firm', value: value.firm});
               items.push({key: 'CPA Email', value: value.email});
               continue;
            } else if ('contactInfo' === key) {
               items.push({key: 'Contact Name', value: value.name});
               items.push({key: 'Contact Email', value: value.email});
               continue;
            } else if (['incorporated', 'filingDeadline', 'taxYearEnd'].includes(key)) {
               // Format dates
               if (value) {
                  tableValue = value.toLocaleDateString();
               } else {
                  tableValue = value;
               }
            } else if (['firstRndEffort', 'latestCredit'].includes(key)) {
               if (value === this.$constants().MIN_TAX_YEAR) {
                  tableValue = `${value} or prior`;
               } else {
                  tableValue = value;
               }
            } else {
               tableValue = value;
            }

            items.push({key: tableKey, value: tableValue});
         }

         return items;
      },

      /** Any studies that overlap the selected year range in the new study form */
      studyOverlap() {
         if (!this.newStudy.years.lower || !this.newStudy.years.upper) {
            return [];
         }

         let overlap = {};
         for (let year = this.newStudy.years.lower; year <= this.newStudy.years.upper; year++) {
            this.studies.forEach((study) => {
               if (year >= study.years.lower && year <= study.years.upper) {
                  overlap[study.id] = study;
               }
            });
         }

         return Object.values(overlap);
      },

      // Employee table fields
      employeeTableFields() {
         let fields = [
            {key: 'fullname', label: 'Name', stickyColumn: true, sortable: true},
            {key: 'location', sortable: true},
            {key: 'ident', label: 'Employee ID', sortable: true},
            {key: 'title', label: 'Job Title', sortable: true},
            {key: 'department', sortable: true},
         ];

         // Only include the subsidiary col if at least one employee has one
         if (this.employees.some((employee) => employee.subsidiary)) {
            fields.push({key: 'subsidiary', sortable: true});
         }

         return fields;
      },

      // Items for the time survey table
      timeSurveyTableItems() {
         return this.timeSurveyYears.POSSIBLE.map((year) => ({year}));
      },

      // Is there at least one project, activity, and employee?
      timeSurveyConfigured() {
         return this.timeSurvey.error === null;
      },

      /** Fields for the client user account tables */
      clientAccountFields() {
         const fields = [
            {key: 'enabled', label: '', sortable: false, thStyle: {width: '3em'}},
            {key: 'firstName', sortable: true},
            {key: 'lastName', sortable: true},
            {key: 'email', sortable: true},
            {key: 'title', label: 'Job Title', sortable: true},
            {key: 'department', label: 'Department', sortable: true},
            {key: 'actions', label: ''},
         ];

         if (
            this.customerAccounts.some((user) => user.subsidiary) ||
            this.smeAccounts.some((user) => user.subsidiary)
         ) {
            fields.push({key: 'subsidiary', sortable: true});
         }
         return fields;
      },

      /** The company id from the route params */
      companyId() {
         return this.$route.params.id;
      },

      /** The company's supplies declaration */
      suppliesDeclaration() {
         return {
            [undefined]: 'Unconfigured',
            [null]: 'No Declaration',
            [SuppliesDecl.NONE]: 'No Supplies',
            [SuppliesDecl.NOT_USA]: 'Outside US',
            [SuppliesDecl.ONE]: 'One State',
            [SuppliesDecl.MANY]: 'Multiple States',
         }[this._suppliesDeclaration];
      },

      /** Status of the contractor section */
      contractorStatus() {
         return this.contractorsCompleted ? 'Completed' : 'Incomplete';
      },

      // An array of all staff users
      staffAccounts() {
         if (!this.users) {
            return [];
         }
         return this.users.filter((user) => user.groups.includes(UserGroups.STAFF) && user.enabled);
      },

      // An array of customer users associated with this company
      customerAccounts() {
         if (!this.users) {
            return [];
         }
         return this.users.filter(
            (user) =>
               user.groups.includes(UserGroups.CUSTOMER) &&
               user.companyId == this.companyId &&
               user.enabled
         );
      },

      // An array of SME accounts associated with this company
      smeAccounts() {
         if (!this.users) {
            return [];
         }
         return this.users.filter(
            (user) =>
               user.groups.includes(UserGroups.SME) &&
               user.companyId == this.companyId &&
               user.enabled
         );
      },

      // The staff accounts assigned to this company as business managers
      businessManagers() {
         return this.staffAccounts.filter((user) => {
            return this.company.businessManagers.includes(user.id);
         });
      },

      // The staff accounts assigned to this company as project managers
      projectManagers() {
         return this.staffAccounts.filter((user) => {
            return this.company.projectManagers.includes(user.id);
         });
      },

      // The staff accounts assigned to this company as project associates
      projectAssociates() {
         return this.staffAccounts.filter((user) => {
            return this.company.projectAssociates.includes(user.id);
         });
      },

      // The staff users not assigned to this company
      unassignedStaff() {
         return this.staffAccounts.filter((user) => {
            return (
               !this.company.businessManagers.includes(user.id) &&
               !this.company.projectManagers.includes(user.id) &&
               !this.company.projectAssociates.includes(user.id)
            );
         });
      },

      // The title for the staff assignment modal
      staffEditTitle() {
         return this.staffTitle(this.staff.editing);
      },

      // Only staff who are associated with the company can act on it. All admins can.
      canActAsCustomer() {
         if (null === this.company) {
            return false;
         }
         const id = this.profile.id;
         if (this.isAdmin) {
            return true;
         } else if (
            this.businessManagers.map((user) => user.id).includes(id) ||
            this.projectManagers.map((user) => user.id).includes(id) ||
            this.projectAssociates.map((user) => user.id).includes(id)
         ) {
            return true;
         } else {
            return false;
         }
      },

      /** True if any staff members have an assigned role with this company */
      hasStaff() {
         return (
            this.businessManagers.length > 0 ||
            this.projectManagers.length > 0 ||
            this.projectAssociates.length > 0
         );
      },
   },

   methods: {
      async disableUser({id}) {
         const proceed = await this.$bvModal.msgBoxConfirm(
            'If you disable this user, only an Onboard Administrator can restore them. Are you sure?',
            {
               centered: true,
               title: 'Disable this user?',
               okVariant: 'danger',
               okTitle: 'Disable',
               cancelVariant: 'secondary',
            }
         );

         if (!proceed) return;

         await this.$store.dispatch('users/disableUser', {
            id,
         });
      },

      async fetchData() {
         const requests = [];

         // fetch company info
         requests.push(
            this.$store
               .dispatch('companies/loadCompany', {companyId: this.companyId, force: true})
               .then(() => {
                  if (this.canActAsCustomer) {
                     requests.push(this.fetchRestrictedData());
                  }
               })
               .catch((err) => {
                  this.$store.commit('showAlert', {
                     response: err.response,
                     fallbackMsg: 'Failed to fetch company information',
                     seconds: 5,
                  });
               })
         );

         // fetch industries
         requests.push(this.$store.dispatch('industries/loadIndustries'));

         // fetch users
         requests.push(this.$store.dispatch('users/loadUsers'));

         await this.blockUntilAllSettled(requests);
      },

      // Fetch data restricted to staff associated with this company
      async fetchRestrictedData() {
         const companyId = this.companyId;
         const requests = [];

         if (this.study) {
            // fetch company questionnaires
            requests.push(
               this.$store
                  .dispatch('questionnaire/loadQuestionnaires', {companyId})
                  .catch((err) => {
                     this.$store.commit('showAlert', {
                        response: err.response,
                        fallbackMsg: 'Failed to load questionnaires',
                        seconds: 5,
                     });
                  })
            );

            // Fetch fully configured time survey years
            requests.push(
               this.$store.dispatch('timesurvey/loadPossibleYears', {companyId}).then((data) => {
                  this.timeSurvey.error = data.error;
               })
            );
         }

         // Fetch studies
         requests.push(this.$store.dispatch('companies/loadStudies', {companyId: this.companyId}));

         // fetch upload categories
         requests.push(
            this.$store.dispatch('uploads/loadCompanyUploadCategories', {
               companyId,
               summary: true,
               force: true,
            })
         );

         requests.push(this.$store.dispatch('projects/loadProjects', {companyId}));

         // fetch activities
         requests.push(this.$store.dispatch('activities/loadActivities', {companyId}));

         return await Promise.allSettled(requests);
      },

      /** Open a new study */
      async openStudy() {
         if (this.$v.newStudy.$invalid) {
            return;
         }

         await this.$store.dispatch('companies/openStudy', {
            companyId: this.companyId,
            ...this.newStudy,
         });
         this.newStudy = {
            years: {
               upper: null,
               lower: null,
            },
            description: null,
         };
         await this.fetchData();
      },

      /** Close the current study */
      async closeStudy() {
         await this.$store.dispatch('companies/closeStudy', {
            companyId: this.companyId,
         });
         await this.fetchData();
         this.$store.commit('timesurvey/clearYears');
      },

      // Load employees for a selected year
      async loadEmployees() {
         await this.blockingRequest('employees/loadEmployees', {
            companyId: this.companyId,
            year: this.employeeCard.year,
         });
      },

      /** The name of the project with the given ID */
      projectName(id) {
         if (id in this.projectMap) {
            return this.projectMap[id].name;
         }
         return '';
      },

      // Maps a staff role abbreviation to the role name
      staffTitle(abbrev) {
         if ('BM' === abbrev) {
            return 'Business Managers';
         } else if ('PM' === abbrev) {
            return 'Project Managers';
         } else if ('PA' === abbrev) {
            return 'Project Associates';
         }
         return '';
      },

      /** Send the initial delete request to determine if this object can be deleted */
      async deleteQuestionnaireModal(questionnaire) {
         try {
            await this.blockingRequest('questionnaire/deleteQuestionnaire', {
               questionnaireId: questionnaire.id,
            });
            this.deleteQuestionnaire = questionnaire;
            this.$bvModal.show('modal-delete-questionnaire');
         } catch (err) {
            const errCode = err.response.data.errors[0].code;
            if (errCode === ErrorCodes.CANNOT_DELETE) {
               this.$bvModal.msgBoxOk(
                  'The selected questionnaire has related data, and cannot be deleted by a staff user. Contact an admin user to delete this questionnaire.',
                  {
                     title: 'Questionnaire Has Related Data',
                     centered: true,
                  }
               );
            }
         }
      },

      /** Confirm the questionnaire deletion. Send the delete request with the `force` flag */
      async submitDeleteQuestionnaire() {
         await this.blockingRequest('questionnaire/deleteQuestionnaire', {
            questionnaireId: this.deleteQuestionnaire.id,
            force: true,
         });
      },

      // Determine the status of a questionnaire
      questionnaireStatus(questionnaire) {
         if (questionnaire.completed !== null) {
            return {
               text: 'Completed',
               variant: 'success',
            };
         }

         let hasAnswer = false;
         questionnaire.questions.forEach((question) => {
            hasAnswer = hasAnswer || question.answers.length > 0;
         });

         if (hasAnswer) {
            return {
               text: 'In Progress',
               variant: 'warning',
            };
         } else {
            return {
               text: 'Not Started',
               variant: 'danger',
            };
         }
      },

      // Redirect to employee config
      toEmployeeConfig() {
         const route = {name: 'employees-configure', params: {id: this.companyId}};
         if (this.employeeCard.year) {
            route.query = {year: this.employeeCard.year};
         }
         this.$router.push(route);
      },

      // Variant for the upload status indicator
      uploadStatus(item) {
         if (item.completedAt) {
            return {
               text: 'Completed',
               variant: 'success',
            };
         } else if (item.summary && item.summary.length > 0) {
            return {
               text: 'In Progress',
               variant: 'warning',
            };
         } else {
            return {
               text: 'Not Started',
               variant: 'danger',
            };
         }
      },
   },

   watch: {
      canActAsCustomer(value) {
         if (value) {
            this.fetchRestrictedData();
         }
      },
   },

   validations: {
      newStudy: {
         years: {
            lower: {
               required,
            },
            upper: {
               required,
            },
         },
      },
   },
};
</script>
