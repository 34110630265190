<template>
   <div class="d-flex align-items-center justify-content-between">
      <b-form-group :state="state" :invalid-feedback="invalidFeedback" class="mb-0">
         <tcp-input-group :prepend="prepend" :append="append" class="vendor-input" size="sm">
            <b-input
               :id="ident"
               v-model="value"
               :debounce="debounceTime"
               :state="state"
               :disabled="disabled"
               class="mb-0"
               :class="{'input-warning': noValue && required}"
               ref="input"
            ></b-input>
         </tcp-input-group>
      </b-form-group>

      <div v-if="required" class="status-indicator">
         <b-spinner
            :id="`${ident}-spinner`"
            v-if="status === FieldStatus.PENDING"
            variant="primary"
            small
         ></b-spinner>
         <transition name="fade-out">
            <b-icon-check
               :id="`${ident}-check`"
               variant="success"
               class="success-check"
               font-scale="2"
               v-if="status === FieldStatus.SAVED"
            />
         </transition>
      </div>
   </div>
</template>

<script>
import {FieldStatus} from '@/store/utils';

export default {
   props: {
      ident: {
         type: [String, Number],
      },
      field: {
         type: Object,
         default: null,
      },
      formatter: {
         type: Function,
         default: null,
      },
      required: {
         type: Boolean,
         default: false,
      },
      disabled: {
         type: Boolean,
         default: false,
      },
      append: {
         type: String,
         default: null,
      },
      prepend: {
         type: String,
         default: null,
      },
   },

   data() {
      return {
         FieldStatus,
         debounceTime: 600,
         state: null,
         invalidFeedback: '',
      };
   },

   computed: {
      value: {
         get() {
            if (this.field) {
               let value = this.field.value;
               if (this.formatter) {
                  value = this.formatter(value);
               }
               return value;
            }
            return null;
         },
         async set(value) {
            this.$emit('input', value);
         },
      },

      status() {
         return this.field?.status;
      },

      noValue() {
         return [null, ''].includes(this.value);
      },

      lastUpdated() {
         return this.field?.lastUpdated;
      },
   },

   methods: {
      /** Update the validation state */
      setState(state, feedback = '') {
         this.state = state;
         this.invalidFeedback = feedback;
      },
   },

   watch: {
      lastUpdated() {
         // Handle edge case where the underlying input has an error (and therefore
         // wasn't saved and doesn't hold the same value as `this.value`), then
         // the value is updated via the 'set all vendors' input to the same
         // value already stored in `this.value`. As `this.value` is set
         // to the same value it already holds, reactivity doesn't trigger so we have
         // to manually update the value in the input.
         this.$refs.input.$refs.input.value = this.value;
         this.$nextTick(() => {
            this.setState(null);
         });
      },
   },
};
</script>

<style lang="scss" scoped>
.status-indicator {
   min-width: 3rem;
   display: flex;
   justify-content: flex-end;
   padding-right: 0.25rem;
}

.success-check {
   opacity: 0;
}

.fade-out-enter-active {
   transition: opacity 0.5s 1.5s;
}

.fade-out-enter {
   opacity: 1;
}
</style>
