import {
   required,
   integer,
   minLength,
   maxLength,
   minValue,
   maxValue,
} from 'vuelidate/lib/validators';

export const passwordValidation = {
   required,
   minLength: minLength(8),
   maxLength: maxLength(255),
   hasUpperCase(value) {
      const re = /[A-Z]/;
      return re.test(value);
   },
   hasLowerCase(value) {
      const re = /[a-z]/;
      return re.test(value);
   },
   hasSpecial(value) {
      const re = /[!@#$%^&*?<>.~_=`\-+]/;
      return re.test(value);
   },
   hasNumber(value) {
      const re = /[0-9]/;
      return re.test(value);
   },
};

export const employeeTimeValidation = () => ({
   percentage: {
      integer,
      minValue: minValue(0),
      maxValue: maxValue(100),
   },
   projects: {
      $each: {
         integer,
         minValue: minValue(0),
         maxValue: maxValue(100),
      },
   },
});
