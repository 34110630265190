/* eslint-disable no-empty-pattern */

import Vue from 'vue';

import {Question, Answer, AnswerTypes, QuestionnaireTypes} from '@/helpers/types';
import {formatDate, formatAsDate} from '@/helpers/utils';
import ErrorCodes from '@/helpers/errorCodes';

let optionKey = 0;

const emptyQuestionnaireConfig = () => ({
   id: null,
   title: null,
   completed: null,
   assignments: [],
   years: {
      lower: null,
      upper: null,
   },
   projectId: null,
});

const emptyTemplateConfig = () => ({
   id: null,
   name: null,
   industries: [],
   qtype: null,
});

//
// STATE
//
const state = () => ({
   questionnaireConfig: emptyQuestionnaireConfig(),
   templateConfig: emptyTemplateConfig(),
   questions: [],
   deletedQuestions: [],
   questionnaires: {},
   templates: {},
   dirty: false,
});

//
// GETTERS
//
const getters = {
   questions: (state) => state.questions,
   id: (state) => state.questionnaireConfig.id,
   title: (state) => state.questionnaireConfig.title,
   years: (state) => state.questionnaireConfig.years,
   projectId: (state) => state.questionnaireConfig.projectId,
   project: (state, getters, rootState, rootGetters) => {
      if (getters.projectId === null) {
         return null;
      }
      return rootGetters['projects/projectMap'][getters.projectId] || null;
   },
   isCompleted: (state) => state.questionnaireConfig.completed !== null,
   assignee: (state) => {
      const assignee = state.questionnaireConfig.assignments.find(
         (assignment) => assignment.completed === null
      );
      return assignee ? assignee.assigneeId : null;
   },
   dirty: (state) => state.dirty,

   questionnaires: (state) => Object.values(state.questionnaires),
   companyQuestionnaires: (state) => {
      return Object.values(state.questionnaires).filter((q) => q.projectId === null);
   },
   projectQuestionnaires: (state) => {
      return Object.values(state.questionnaires).filter((q) => q.projectId !== null);
   },

   templateConfig: (state) => state.templateConfig,
   templateConfigValid: (state) => {
      return !!state.templateConfig.name && !!state.templateConfig.qtype;
   },

   templateMap: (state) => state.templates,
   templates: (state) => Object.values(state.templates),
};

//
// MUTATIONS
//
const mutations = {
   /** Reset the questionnaire state */
   clearQuestionnaire: (state) => {
      Object.assign(state.questionnaireConfig, emptyQuestionnaireConfig());
      state.questions = [];
      state.deletedQuestions = [];
      state.dirty = false;
   },

   /** Clear the template state */
   clearTemplate: (state) => {
      Object.assign(state.templateConfig, emptyTemplateConfig());
      state.questions = [];
      state.deletedQuestions = [];
      state.dirty = false;
   },

   /** Clear the template config, preserving question data */
   clearTemplateConfig: (state) => {
      Object.assign(state.templateConfig, emptyTemplateConfig());
   },

   setQuestionnaireConfig: (state, {id, title, completed, assignments, years, projectId}) => {
      Object.assign(state.questionnaireConfig, {
         id,
         title,
         completed,
         assignments,
         years,
         projectId,
      });
   },

   setTemplateConfig: (state, {id = null, name = null, industries = [], qtype = null}) => {
      Object.assign(state.templateConfig, {
         id,
         name,
         industries,
         qtype,
      });
   },

   setDirty: (state, {value}) => {
      state.dirty = value;
   },

   /** Set the questionnaire title */
   setTitle: (state, title) => {
      Vue.set(state.questionnaireConfig, 'title', title);
      state.dirty = true;
   },

   /** Set the questionnaire year data */
   setYears: (state, {lower, upper}) => {
      Vue.set(state.questionnaireConfig.years, 'upper', upper);
      Vue.set(state.questionnaireConfig.years, 'lower', lower);
      state.dirty = true;
   },

   /** Set the lower questionnaire year */
   setLowerYear: (state, year) => {
      Vue.set(state.questionnaireConfig.years, 'lower', year);
      state.dirty = true;
   },

   /** Set the upper questionnaire year */
   setUpperYear: (state, year) => {
      Vue.set(state.questionnaireConfig.years, 'upper', year);
      state.dirty = true;
   },

   /** Set the completed date */
   setCompleted: (state, completed) => {
      Vue.set(state.questionnaireConfig, 'completed', completed);
   },

   /** Set the assignee of the current questionnaire */
   setAssignments: (state, assignments) => {
      Vue.set(state.questionnaireConfig, 'assignments', assignments);
   },

   /** Set the project ID */
   setProjectId: (state, projectId) => {
      Vue.set(state.questionnaireConfig, 'projectId', projectId);
      state.dirty = true;
   },

   /** Add a question. If no question data is provided, a blank question will be added */
   addQuestion: (state, {index = null, question = null, fromUserInput = false}) => {
      const newQuestion = new Question();
      if (question) {
         Object.assign(newQuestion, question);
      }

      if (question && 'answers' in question && question.answers.length > 0) {
         const answer = formatAnswerFromServer(question.answers[0], newQuestion);
         newQuestion.answer = answer;
      }

      if (null !== index) {
         state.questions.splice(index + 1, 0, newQuestion);
      } else {
         state.questions.push(newQuestion);
      }

      if (fromUserInput) {
         state.dirty = true;
      }
   },

   /** Delete a question */
   deleteQuestion: (state, index) => {
      state.deletedQuestions.push(state.questions[index]);
      state.questions.splice(index, 1);
      state.dirty = true;
   },

   /** Move a question one space forward in the array */
   reorderQuestionUp: (state, index) => {
      if (index > 0) {
         const question = state.questions[index];
         state.questions.splice(index, 1);
         state.questions.splice(index - 1, 0, question);
         state.dirty = true;
      } else {
         console.log('Index must be greater than 0');
      }
   },

   /** Move a question one space back in the array */
   reorderQuestionDown: (state, index) => {
      if (index < state.questions.length - 1) {
         const question = state.questions[index];
         state.questions.splice(index, 1);
         state.questions.splice(index + 1, 0, question);
         state.dirty = true;
      } else {
         console.log('Index must be less than the number of questions');
      }
   },

   /** Update a question's text */
   setQuestionText: (state, {index, value}) => {
      state.questions[index].question = value;
      state.dirty = true;
   },

   /** Set the answer type on a question */
   setAnswerType: (state, {index, value}) => {
      state.questions[index].ansType = value;
      state.dirty = true;
   },

   /** Set the help text on a question */
   setHelpText: (state, {index, value}) => {
      state.questions[index].helpText = value;
      state.dirty = true;
   },

   /** Update the validation on a question */
   updateValidation: (state, {index, value}) => {
      Object.assign(state.questions[index].validation, value);
      state.dirty = true;
   },

   /** Add an option to a question */
   addOption: (state, {questionIndex, optionIndex}) => {
      const newOption = {
         key: optionKey++,
         value: null,
      };
      state.questions[questionIndex].validation.options.splice(optionIndex + 1, 0, newOption);
      state.dirty = true;
   },

   /** Delete an option to a question */
   deleteOption: (state, {questionIndex, optionIndex}) => {
      state.questions[questionIndex].validation.options.splice(optionIndex, 1);
      state.dirty = true;
   },

   /** Update an option */
   setOption: (state, {questionIndex, optionIndex, value}) => {
      state.questions[questionIndex].validation.options[optionIndex].value = value;
      state.dirty = true;
   },

   /** Update an answer's value */
   setAnswerValue: (state, {questionIndex, value}) => {
      if (null === state.questions[questionIndex].answer) {
         state.questions[questionIndex].answer = new Answer();
      }

      state.questions[questionIndex].answer.answer = value;
      state.questions[questionIndex].answer.dirty = true;
   },

   /** Clear the dirty flag on an answer */
   setAnswerClean: (state, {questionIndex}) => {
      if (state.questions[questionIndex].answer) {
         state.questions[questionIndex].answer.dirty = false;
      }
   },

   /** Set an answer from an answer object */
   setAnswer: (state, {answer, questionIndex}) => {
      const newAnswer = new Answer();
      Object.assign(newAnswer, answer);
      Vue.set(state.questions[questionIndex], 'answer', newAnswer);
   },

   /** Clear the current answer of a question */
   clearAnswer: (state, {questionIndex}) => {
      Vue.set(state.questions[questionIndex], 'answer', null);
   },

   /** Clear the questionnaires state object */
   clearQuestionnaires: (state) => {
      state.questionnaires = {};
   },

   /** Store an array of questionnaires as an object, keyed on the questionnaire id */
   setQuestionnaires: (state, {questionnaires}) => {
      questionnaires.forEach((questionnaire) => {
         Vue.set(state.questionnaires, questionnaire.id, questionnaire);
      });
   },

   /** Remove a questionnaire from state */
   deleteQuestionnaire: (state, {questionnaireId}) => {
      if (questionnaireId in state.questionnaires) {
         Vue.delete(state.questionnaires, questionnaireId);
      }
   },

   //
   // TEMPLATES
   //
   /** Store an array of templates */
   setTemplates: (state, {templates}) => {
      templates.forEach((template) => {
         Vue.set(state.templates, template.id, template);
      });
   },

   /** Delete a stored template */
   deleteTemplate: (state, {templateId}) => {
      Vue.delete(state.templates, templateId);
   },
};

// Destructure a questionnaire object, leaving only the fields
// required to create or edit a quesionnaire.
const destructureQuestionaire = ({title, years, projectId}) => {
   const questionnaire = {title, years};
   if (projectId !== null) {
      questionnaire.projectId = projectId;
   }
   return questionnaire;
};

// Destructure a question object, leaving only the fields required to
// create or edit a quesionnaire
const destructureQuestion = ({
   id = null,
   questionnaireId,
   qorder,
   question,
   ansType,
   helpText,
   validation,
}) => {
   const result = {
      questionnaireId,
      qorder,
      question,
      ansType,
      helpText,
      validation: {...validation}, // deep(er) clone
   };
   if (id !== null) {
      result.id = id;
   }
   return result;
};

// Destructure an answer object
const destructureAnswer = ({answer}) => ({answer});

// Format a question before sending it to the server
const formatQuestionForServer = (question, order = null, questionnaireId = null) => {
   const formatted = destructureQuestion(question);

   if (formatted.ansType !== AnswerTypes.CHOICE) {
      formatted.validation.options = [];
   }

   if (formatted.ansType === AnswerTypes.CHOICE) {
      formatted.ansType = AnswerTypes.TEXT;
      formatted.validation.options = formatted.validation.options.map((option) => option.value);
   } else if (formatted.ansType === AnswerTypes.DATE) {
      if (formatted.validation.minimum instanceof Date) {
         formatted.validation.minimum = formatDate(formatted.validation.minimum);
      }
      if (formatted.validation.maximum instanceof Date) {
         formatted.validation.maximum = formatDate(formatted.validation.maximum);
      }
   }

   if ('' === formatted.validation.minimum) {
      formatted.validation.minimum = null;
   }
   if ('' === formatted.validation.maximum) {
      formatted.validation.maximum = null;
   }

   if (formatted.ansType === AnswerTypes.NUMBER) {
      if (formatted.validation.minimum !== null) {
         formatted.validation.minimum = +formatted.validation.minimum;
      }
      if (formatted.validation.maximum !== null) {
         formatted.validation.maximum = +formatted.validation.maximum;
      }
   }

   if (order !== null) {
      formatted.qorder = order;
   }

   if (questionnaireId !== null) {
      formatted.questionnaireId = questionnaireId;
   }

   return formatted;
};

// Format a question returned from the server
const formatQuestionFromServer = (question) => {
   if (question.validation.options && question.validation.options.length > 0) {
      question.ansType = AnswerTypes.CHOICE;
      question.validation.options = question.validation.options.map((value) => {
         return {key: optionKey++, value};
      });
   }

   if (question.ansType === AnswerTypes.DATE) {
      if (question.validation.minimum) {
         question.validation.minimum = formatAsDate(question.validation.minimum);
      }
      if (question.validation.maximum) {
         question.validation.maximum = formatAsDate(question.validation.maximum);
      }
   }

   return question;
};

// Format an answer before sending it to the server
const formatAnswerForServer = (answer, ansType = null) => {
   const formatted = destructureAnswer(answer);

   switch (ansType) {
      case AnswerTypes.TEXT:
      case AnswerTypes.EMAIL:
         if (formatted.answer !== null && formatted.answer.trim() === '') {
            formatted.answer = null;
         }
         break;
      case AnswerTypes.DATE:
         if (formatted.answer instanceof Date) {
            formatted.answer = formatDate(formatted.answer);
         }
         break;
      case AnswerTypes.NUMBER:
      case AnswerTypes.INTEGER:
         if (formatted.answer === '') {
            formatted.answer = null;
         }
         if (formatted.answer !== null) {
            formatted.answer = +formatted.answer;
         }
         break;
   }

   return formatted;
};

const formatAnswerFromServer = (answer, question) => {
   const newAnswer = new Answer();
   Object.assign(newAnswer, answer);

   if (question.ansType === AnswerTypes.DATE && newAnswer.answer) {
      newAnswer.answer = formatAsDate(answer.answer);
   }

   if (question.validation.multiple && newAnswer.answer === null) {
      newAnswer.answer = [];
   }

   return newAnswer;
};

//
// ACTIONS
//
const actions = {
   // Load a company quesionnaire to the current state
   async loadCompanyQuestionnaire({commit}, {questionnaireId}) {
      commit('clearQuestionnaire');
      const questionnaire = (await this._vm.$http.get(`/api/questionnaire/${questionnaireId}`))
         .data;

      commit('setQuestionnaireConfig', questionnaire);
      questionnaire.questions
         .map((question) => formatQuestionFromServer(question))
         .forEach((question) => {
            commit('addQuestion', {question});
         });
   },

   /**
    * Load a project quesionnaire to the current state
    * @param {String|Number} questionnaireId - The ID of the questionnaire to load
    * @param {boolean} joinProject - If true, loads the associated project
    * @param {boolean} addProjectYearsQuestion - If true and there are multiple possible project years, adds the project years question
    */
   async loadProjectQuestionnaire(
      {commit, dispatch, rootGetters},
      {questionnaireId, joinProject = false, addProjectYearsQuestion = true}
   ) {
      commit('clearQuestionnaire');
      const questionnaire = (await this._vm.$http.get(`/api/questionnaire/${questionnaireId}`))
         .data;

      // Fetch the project data
      if (joinProject) {
         await dispatch('projects/loadProject', {projectId: questionnaire.projectId}, {root: true});
      }

      // Update the state
      commit('setQuestionnaireConfig', questionnaire);

      // Add the project year question
      if (addProjectYearsQuestion) {
         const question = projectYearsQuestion(
            rootGetters['companies/study'],
            rootGetters['projects/projectMap'][questionnaire.projectId]
         );
         if (question)
            commit('addQuestion', {
               question,
            });
      }

      questionnaire.questions
         .map((question) => formatQuestionFromServer(question))
         .forEach((question) => {
            commit('addQuestion', {question});
         });
   },

   /** Load the profile questionnaire for the given user. */
   async loadProfileQuestionnaire({commit}, {userId}) {
      commit('clearQuestionnaire');
      const questionnaire = (await this._vm.$http.get(`/api/user/${userId}/profile/questionnaire`))
         .data;
      commit('setQuestionnaireConfig', questionnaire);
      questionnaire.questions
         .map((question) => formatQuestionFromServer(question))
         .forEach((question) => {
            commit('addQuestion', {question});
         });
   },

   /** Load all questionnaires for a company */
   async loadQuestionnaires({commit}, {companyId, allStudies = false}) {
      let params = {
         include_projects: true,
         all_studies: allStudies,
      };
      const questionnaires = (
         await this._vm.$http.get(`/api/company/${companyId}/questionnaire`, {params})
      ).data.results;
      commit('clearQuestionnaires');
      commit('setQuestionnaires', {questionnaires});
   },

   /** Delete a questionnaire */
   async deleteQuestionnaire({commit}, {questionnaireId, force = false}) {
      const params = {force};
      try {
         await this._vm.$http.delete(`/api/questionnaire/${questionnaireId}`, {params});
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.CONFIRMATION_REQUIRED) {
            return err.response.data.errors[0].detail;
         }
         throw err;
      }
      commit('deleteQuestionnaire', {questionnaireId});
   },

   /** Fetch all questionnaires for a given company */
   async fetchQuestionnaires({}, {companyId, questionnaireType = null}) {
      let params = {};
      if (questionnaireType === QuestionnaireTypes.PROJECT) {
         params.include_projects = true;
      }

      const questionnaires = (
         await this._vm.$http.get(`/api/company/${companyId}/questionnaire`, {params})
      ).data.results;

      return questionnaires.filter((questionnaire) => {
         switch (questionnaireType) {
            case QuestionnaireTypes.PROJECT:
               return questionnaire.projectId !== null;
            case QuestionnaireTypes.PROFILE:
               return questionnaire.userId !== null;
            case QuestionnaireTypes.COMPANY:
               return questionnaire.projectId === null && questionnaire.userId === null;
            default:
               return true;
         }
      });
   },

   // Fetch all company questionnaires for a given company
   async fetchCompanyQuestionnaires({dispatch}, {companyId}) {
      return dispatch('fetchQuestionnaires', {
         companyId,
         questionnaireType: QuestionnaireTypes.COMPANY,
      });
   },

   // Fetch all project questionnaires for a given company
   async fetchProjectQuestionnaires({dispatch}, {companyId}) {
      return dispatch('fetchQuestionnaires', {
         companyId,
         questionnaireType: QuestionnaireTypes.PROJECT,
      });
   },

   /** Create a new questionnaire */
   async createCompanyQuestionnaire({state, dispatch}, {companyId}) {
      const questionnaire = destructureQuestionaire(state.questionnaireConfig);
      questionnaire.companyId = companyId;

      let savedQuestionnaire;
      // Save questionnaire data and get its ID
      try {
         savedQuestionnaire = (
            await this._vm.$http.post(`/api/company/${companyId}/questionnaire`, questionnaire)
         ).data;
      } catch (err) {
         const code = err.response.data.errors[0].code;
         if (code === 121) {
            await this._vm.$bvModal.msgBoxOk(
               'The selected year range is outside the current study.',
               {
                  title: 'Invalid Year Range',
                  centered: true,
               }
            );
            throw err;
         } else {
            throw err;
         }
      }
      const questionnaireId = savedQuestionnaire.id;

      await dispatch('saveQuestions', {questionnaireId});
   },

   /** Update a company questionnaire */
   async editQuestionnaire({state, dispatch, rootGetters}, {questionnaireId}) {
      const questionnaire = destructureQuestionaire(state.questionnaireConfig);

      // Save questionnaire data and get its ID
      try {
         await this._vm.$http.put(`/api/questionnaire/${questionnaireId}`, questionnaire);
      } catch (err) {
         const code = err.response.data.errors[0].code;
         if (code === 121) {
            await this._vm.$bvModal.msgBoxOk(
               'The selected year range is outside the current study.',
               {
                  title: 'Invalid Year Range',
                  centered: true,
               }
            );
            throw err;
         } else {
            throw err;
         }
      }

      if (rootGetters.isRndig) {
         await dispatch('saveQuestions', {questionnaireId});
      }
   },

   /** Save the current project questionnaire and its questions */
   async createProjectQuestionnaire({state, commit, dispatch}, {titles = null}) {
      const config = destructureQuestionaire(state.questionnaireConfig);
      const questionnaires = [];

      if (titles) {
         titles.forEach((title) => {
            questionnaires.push({
               ...config,
               title,
            });
         });
      } else {
         questionnaires.push(config);
      }

      const promises = questionnaires.map((questionnaire) => {
         return this._vm.$http
            .post(`/api/project/${questionnaire.projectId}/questionnaire`, questionnaire)
            .then((response) => {
               const questionnaireId = response.data.id;
               return dispatch('saveQuestions', {questionnaireId});
            })
            .catch((err) => {
               const errCode = err.response.data.errors[0].code;
               if (errCode === ErrorCodes.DUPLICATE) {
                  const data = JSON.parse(err.config.data);
                  const title = data.title;
                  commit(
                     'showAlert',
                     {
                        msg: `Failed to save questionnaire "${title}". Another questionnaire already exists with that name.`,
                        seconds: 5,
                     },
                     {root: true}
                  );
               }
               throw err;
            });
      });

      await Promise.all(promises);
   },

   /** Save the questions of a questionnaire */
   async saveQuestions({state}, {questionnaireId}) {
      // If question.id is not null, this is an existing question, so make a PUT request to update it.
      // Otherwise, make a POST request to create a new question.
      const questionRequests = state.questions
         .map((question, index) => formatQuestionForServer(question, index, questionnaireId))
         .map((question) => {
            if (question.id) {
               return this._vm.$http.put(`/api/question/${question.id}`, question);
            } else {
               return this._vm.$http.post(
                  `/api/questionnaire/${questionnaireId}/question`,
                  question
               );
            }
         });

      // Send a DELETE request for each deleted question.
      const deleteRequests = state.deletedQuestions
         .filter((question) => question.id)
         .map((question) => {
            return this._vm.$http.delete(`/api/question/${question.id}`);
         });

      // Wait for all requests to complete
      return Promise.allSettled([...questionRequests, ...deleteRequests]);
   },

   // Mark a questionnaire complete
   async completeQuestionnaire({state, commit, dispatch, rootGetters}, {companyId}) {
      let params = {};
      if (rootGetters.isRndig) {
         await dispatch('users/loadCompanyUsers', {companyId}, {root: true});
         const assignee = Object.values(rootGetters['users/companyUsers']).find((user) =>
            user.groups.includes('CUSTOMER')
         );
         if (assignee) {
            params.assignee_id = assignee.id;
         } else {
            await this._vm.$bvModal.msgBoxOk(
               'This company has no customer users. A questionnaire can only be marked complete on behalf of a customer user.',
               {
                  title: 'Cannot Complete Questionnaire',
                  centered: true,
               }
            );
            return;
         }
      }

      const id = state.questionnaireConfig.id;
      const response = await this._vm.$http.put(`/api/questionnaire/${id}/completed`, {}, {params});
      const completed = response.data.completed;
      const newAssignments = response.data.assignments;
      commit('setCompleted', completed);
      commit('setAssignments', newAssignments);
   },

   // Save the current answer to a question
   async saveAnswer({state, dispatch}, {questionIndex}) {
      const question = state.questions[questionIndex];
      const questionId = question.id;
      const answer = formatAnswerForServer(question.answer, question.ansType);

      if (question.answers.length > 0) {
         answer.lastSubmitted = question.answers[0].submitted;
      } else {
         answer.lastSubmitted = null;
      }
      const answers = (await this._vm.$http.post(`/api/question/${questionId}/answer`, answer)).data
         .answers;
      dispatch('setAnswers', {
         questionIndex,
         answers: answers.map((answer) => formatAnswerFromServer(answer, question)),
      });
      state.questions[questionIndex].answer.dirty = false;
   },

   // Replace the answers to a given question with a new list of answers
   setAnswers: ({state, commit}, {questionIndex, answers}) => {
      state.questions[questionIndex].answers = [];
      answers.forEach((answer) => {
         state.questions[questionIndex].answers.push(answer);
      });
      if (answers.length > 0) {
         commit('setAnswer', {questionIndex, answer: answers[0]});
      } else {
         state.questions[questionIndex].answer = null;
      }
   },

   async assignQuestionnaire({state, commit}, {assignerId, assigneeId}) {
      const questionnaireId = state.questionnaireConfig.id;
      const data = (
         await this._vm.$http.put(`/api/questionnaire/${questionnaireId}/assignment`, {
            assignerId,
            assigneeId,
         })
      ).data;
      const newAssignments = data.assignments;
      commit('setAssignments', newAssignments);
   },

   async unassignQuestionnaire({state, commit}) {
      const questionnaireId = state.questionnaireConfig.id;
      const data = (await this._vm.$http.delete(`/api/questionnaire/${questionnaireId}/assignment`))
         .data;
      const newAssignments = data.assignments;
      commit('setAssignments', newAssignments);
   },

   async loadTemplates({commit}) {
      const response = await this._vm.$http.get('/api/qtemplate');
      commit('setTemplates', {templates: response.data.results});
   },

   async loadTemplate({state, commit}, {templateId, forceRefresh = true}) {
      let template;
      if (forceRefresh || !(templateId in state.templates)) {
         template = (await this._vm.$http.get(`/api/qtemplate/${templateId}`)).data;
      } else {
         template = state.templates[templateId];
      }
      commit('setTemplateConfig', template);
      template.questions
         .map((question) => formatQuestionFromServer(question))
         .forEach((question) => {
            commit('addQuestion', {question});
         });
      commit('setDirty', {value: false});
   },

   async createTemplate({state, commit}) {
      const questions = state.questions.map((question, index) =>
         formatQuestionForServer(question, index, 0)
      );

      const {name, industries, qtype} = state.templateConfig;
      try {
         await this._vm.$http.post(`/api/qtemplate`, {
            name,
            industries,
            qtype,
            questions,
         });
         commit('setDirty', {value: false});
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.DUPLICATE) {
            commit(
               'showAlert',
               {
                  msg: 'Another template exists with the same name within the same industry.',
                  seconds: 5,
               },
               {root: true}
            );
         }
         throw err;
      }
   },

   async editTemplate({state, commit}) {
      const questions = state.questions.map((question, index) =>
         formatQuestionForServer(question, index, 0)
      );

      const {id, name, industries, qtype} = state.templateConfig;
      try {
         await this._vm.$http.put(`/api/qtemplate/${state.templateConfig.id}`, {
            id,
            name,
            industries,
            qtype,
            questions,
         });
         commit('setDirty', {value: false});
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.DUPLICATE) {
            commit(
               'showAlert',
               {
                  msg: 'Another template exists with the same name within the same industry.',
                  seconds: 5,
               },
               {root: true}
            );
         }
         throw err;
      }
   },

   async deleteTemplate({commit}, {templateId, force = false}) {
      const params = {force};
      await this._vm.$http.delete(`/api/qtemplate/${templateId}`, {params});
      commit('deleteTemplate', {templateId});
   },
};

function projectYearsQuestion(study, project) {
   // Check the number of possible years for this question's answer
   // If there's only one possible year, return null so we don't ask the user this question
   const minYear = project.years.lower;
   const maxYear = project.years.upper
      ? Math.min(project.years.upper, study.years.upper)
      : study.years.upper;
   if (minYear === maxYear) {
      return null;
   }

   const question = new Question();
   question.ansType = AnswerTypes.PROJECT_YEARS;
   question.question = "Please tell us this project's start and end dates.";
   return question;
}

//
// DEFAULT EXPORT
//
export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
