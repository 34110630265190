/* eslint-disable no-empty-pattern */
import {SpecialCategories} from './uploads';

import Vue from 'vue';
import ErrorCodes from '@/helpers/errorCodes';

const state = () => ({
   employees: {},
   uploadCategoryId: null,
});

const getters = {
   employees: (state) => Object.values(state.employees),
   employeeMap: (state) => state.employees,

   uploadCategory: (state, getters, rootState, rootGetters) => {
      if (state.uploadCategoryId === null) {
         return null;
      }
      return rootGetters['uploads/uploadCategoryMap'][state.uploadCategoryId];
   },
};

const mutations = {
   clear(state) {
      state.employees = {};
   },

   setEmployees(state, {employees}) {
      employees.forEach((employee) => {
         Vue.set(state.employees, employee.id, employee);
      });
   },

   updateEmployee(state, {employee}) {
      Vue.set(state.employees, employee.id, employee);
   },

   /** Remove an employee from state */
   deleteEmployee(state, {employeeId}) {
      if (employeeId in state.employees) {
         Vue.delete(state.employees, employeeId);
      }
   },

   /** Store the UploadCategory ID for the Employees upload category */
   setUploadCategoryId(state, {uploadCategoryId}) {
      state.uploadCategoryId = uploadCategoryId;
   },
};

const actions = {
   /**
    * Fetch the employees of a company
    * @param {String} companyId - ID of the company
    * @param {string|number} [year] - Fetch employees from a given year
    * @param {Boolean} [allStudies] - Fetch employees from all studies
    */
   async fetchEmployees({}, {companyId, year = null, allStudies = false}) {
      const params = {all_studies: allStudies};
      if (year !== null) {
         params.year = year;
      }

      const employees = (await this._vm.$http.get(`/api/company/${companyId}/employee`, {params}))
         .data.results;
      return employees;
   },

   /**
    * Load the employees of a company
    * @param {String} companyId - ID of the company
    * @param {string|number} [year] - Fetch employees from a given year
    * @param {Boolean} [allStudies] - Fetch employees from all studies
    */
   async loadEmployees({commit, dispatch}, {companyId, year = null, allStudies = false}) {
      const employees = await dispatch('fetchEmployees', {companyId, year, allStudies});
      commit('clear');
      commit('setEmployees', {employees});
   },

   /**
    * Create a new employee
    * @param {string} companyId - Company to create the employee on
    * @param {Object} employee - The employee object
    * @param {string|number} year - The year to create the employee in
    */
   async createEmployee({commit}, {companyId, employee, year}) {
      let newEmployee;
      try {
         newEmployee = (await this._vm.$http.post('/api/employee', {...employee, companyId, year}))
            .data;
      } catch (err) {
         const code = err.response.data.errors[0].code;
         if (code === 120) {
            const force = await this._vm.$bvModal.msgBoxConfirm(
               'Adding this employee would invalidate a time survey that has already been submitted. Would you like to proceed anyways?',
               {
                  centered: true,
               }
            );
            if (force) {
               newEmployee = (
                  await this._vm.$http.post(
                     '/api/employee',
                     {...employee, companyId, year},
                     {params: {force}}
                  )
               ).data;
            } else {
               throw err;
            }
         } else if (code === 121) {
            await this._vm.$bvModal.msgBoxOk(
               'The selected year is part of a study which has been closed. No new employees may be added for that year.',
               {
                  title: 'Closed Study',
                  centered: true,
               }
            );
            throw err;
         } else {
            throw err;
         }
      }
      commit('updateEmployee', {employee: newEmployee});
   },

   /**
    * Edit an employee's info
    * @param {Object} employee - The updated employee object
    */
   async editEmployee({commit}, {employee}) {
      const newEmployee = (await this._vm.$http.put(`/api/employee/${employee.id}`, employee)).data;
      commit('updateEmployee', {employee: newEmployee});
   },

   /**
    * Delete an employee.
    *
    * The data will only be deleted if the force flag is set to `true`.
    * Otherwise, the server will return a related data object describing related data
    * that would be deleted if this employee were deleted.
    * @param {string} employeeId - The employee's ID
    * @param {boolean} force - Force the deletion to be carried out
    */
   async deleteEmployee({commit}, {employeeId, force = false}) {
      const params = {force};
      try {
         await this._vm.$http.delete(`/api/employee/${employeeId}`, {params});
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.CONFIRMATION_REQUIRED) {
            return err.response.data.errors[0].detail;
         }
         throw err;
      }
      commit('deleteEmployee', {employeeId});
   },

   /**
    * Import a list of employees from a CSV file
    * @param {string} companyId - The ID of the company to import employees to
    * @param {string|number} year - The year to import the employees to
    * @param {string} file - The file to import employees from
    */
   async importEmployeeCsv({state, dispatch}, {companyId, year, file, force = false}) {
      const validation = {
         type: SpecialCategories.EMPLOYEES,
         data: {force},
      };
      await dispatch(
         'uploads/uploadFiles',
         {
            uploadCategoryId: state.uploadCategoryId,
            year,
            files: [file],
            validation,
         },
         {root: true}
      );
      await dispatch('loadEmployees', {companyId, year});
   },

   /** Load the Employees upload category */
   async loadUploadCategory({commit, dispatch}, {companyId}) {
      const uploadCategory = await dispatch(
         'uploads/loadSpecialCategory',
         {
            companyId,
            category: SpecialCategories.EMPLOYEES,
         },
         {root: true}
      );

      commit('setUploadCategoryId', {
         uploadCategoryId: uploadCategory.id,
      });
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
