<template>
   <div
      class="manager-message-container d-flex"
      :class="{expanded: cssExpanded, 'has-short-text': hasShortText}"
   >
      <b-card class="manager-message d-relative" no-body>
         <div class="profile-image-container" @click="toggleExpanded">
            <img v-auth-src="imgSrc" class="profile-image" />
         </div>

         <button
            v-if="hasShortText"
            id="btn-mm-expand-toggle"
            class="icon-btn"
            @click="toggleExpanded"
         >
            <custom-icon
               :icon="collapseIcon"
               class="collapse-icon"
               :width="iconSize"
               :height="iconSize"
            />
         </button>

         <transition name="fade-expand" mode="out-in">
            <div v-if="expanded || !hasShortText" key="expanded">
               <slot />
            </div>

            <h2 v-else key="collapsed" class="text-primary">
               <slot name="short" />
            </h2>
         </transition>
      </b-card>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   props: {
      /** v-model support for the expanded state */
      value: {
         type: Boolean,
         default: false,
      },
   },

   data() {
      return {
         expanded: this.value,
         cssExpanded: this.value,
         iconSize: '30px',
      };
   },

   computed: {
      ...mapGetters({
         _manager: 'manager',
         isClient: 'isClient',
         company: 'companies/currentCompany',
      }),

      manager() {
         if (this.isClient) {
            return this._manager;
         } else {
            const companyId = this.$route.params.id;
            if (!companyId || this.company.id != companyId) {
               return null;
            }
            if (this.company.projectManagers.length > 0) {
               return {
                  id: this.company.projectManagers[0],
               };
            }
         }
         return null;
      },

      imgSrc() {
         return {
            src: `/api/user/${this.manager?.id}/profile/image`,
            fallbackUrl: require('@/assets/img/avatar.svg'),
         };
      },

      hasShortText() {
         //NB: Disabled for now per https://gitlab.artlogic.com/RAndDIncentivesGroup/TaxCreditPortal/-/issues/1046,
         //    but keeping the code around in case we want to turn this functionality back on in the future

         // return !!this.$slots.short;
         return false;
      },

      collapseIcon() {
         return this.expanded ? 'icon-chevron-up' : 'icon-chevron-down';
      },
   },

   methods: {
      async toggleExpanded() {
         if (this.expanded) {
            this.expanded = false;
            setTimeout(() => {
               this.cssExpanded = false;
            }, 500);
         } else {
            this.cssExpanded = true;
            setTimeout(() => {
               this.expanded = true;
            }, 300);
         }

         this.$emit('input', this.expanded);
      },
   },

   watch: {
      /** For v-model support, update component state when `value` prop changes */
      value(val) {
         if (this.expanded !== val) {
            this.toggleExpanded();
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.manager-message::v-deep h2 {
   color: $primary !important;
}

.manager-message-container {
   margin-bottom: 2rem;

   .collapse-icon {
      color: $primary;
      margin-top: 4px;
      transition: all 0.3s ease;

      &:hover {
         color: $secondary;
      }
   }

   .manager-message {
      padding: 26px 24px 26px 116px;
      color: $primary;
      position: relative;
      border-radius: 20px;
      border-top-left-radius: 25px;
      box-shadow: 0px 4px 20px 0px #005d8b26;

      a {
         color: $primary;
         text-decoration: underline;
         transition: 0.2s all;

         &:hover {
            color: $primary-200;
         }
      }

      .btn {
         text-decoration: none;
      }

      .profile-image-container {
         background-color: $primary-200;
         border: 3px solid $primary-200;
         border-radius: 50%;
         position: absolute;
         left: -12px;
         top: -12px;
         width: 106px;
         height: 106px;
         overflow: hidden;

         .profile-image {
            border-radius: 50%;
            width: 100%;
            transition: transform 0.3s ease;
         }
      }
   }

   &.has-short-text {
      #btn-mm-expand-toggle {
         position: absolute;
         right: 1.5rem;
         top: 1rem;
      }

      .manager-message {
         padding-right: 100px;
         flex-grow: 0;
         transition: flex-grow 1s;

         .profile-image-container {
            cursor: pointer;

            &:hover img {
               transform: scale(1.06);
            }
         }
      }

      &.expanded {
         .manager-message {
            flex-grow: 1;
         }
      }
   }
}
</style>
