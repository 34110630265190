import AnswerText from '@/views/questionnaire/widgets/AnswerText';
import AnswerEmail from '@/views/questionnaire/widgets/AnswerEmail';
import AnswerDate from '@/views/questionnaire/widgets/AnswerDate';
import AnswerNumber from '@/views/questionnaire/widgets/AnswerNumber';
import AnswerBoolean from '@/views/questionnaire/widgets/AnswerBoolean';
import AnswerMultipleChoice from '@/views/questionnaire/widgets/AnswerMultipleChoice';
import AnswerProjectYears from '@/views/questionnaire/widgets/AnswerProjectYears';
import {AnswerTypes} from '@/helpers/types';

/** Map an answer type to the Vue component that renders that answer type */
export const answerTypeComponent = (answerType) => {
   switch (answerType) {
      case AnswerTypes.TEXT:
         return AnswerText;
      case AnswerTypes.EMAIL:
         return AnswerEmail;
      case AnswerTypes.DATE:
         return AnswerDate;
      case AnswerTypes.INTEGER:
      case AnswerTypes.NUMBER:
         return AnswerNumber;
      case AnswerTypes.BOOLEAN:
         return AnswerBoolean;
      case AnswerTypes.CHOICE:
         return AnswerMultipleChoice;
      case AnswerTypes.PROJECT_YEARS:
         return AnswerProjectYears;
      default:
         throw `Unknown answer type: ${answerType}`;
   }
};
