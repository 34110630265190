/* eslint-disable no-empty-pattern */

import Vue from 'vue';
import {downloadFile} from '@/helpers/utils';

const state = () => ({
   statusMap: {},
});

const getters = {
   statusMap: (state) => state.statusMap,

   statusByCompanyId: (state) => (companyId) => state.statusMap[companyId],
};

const mutations = {
   setStatus(state, {status, companyId}) {
      // Use Vue.set here to make the new root level properties
      // on state.statusMap reactive.
      Vue.set(state.statusMap, companyId, status);
   },
};

const actions = {
   // Fetch an export status
   async fetchStatus({}, {companyId}) {
      const response = await this._vm.$http.get(`/api/company/${companyId}/export`);
      return response.data;
   },

   // Fetch an export status and store it
   async loadStatus({state, commit, dispatch}, {companyId, force = true}) {
      if (state.statusMap[companyId] && state.statusMap[companyId].id == companyId && !force) {
         return state.status;
      }
      const status = await dispatch('fetchStatus', {companyId});
      commit('setStatus', {status, companyId});
   },

   // Send a request to prepare an export then update the status
   async prepareExport({commit}, {companyId, fromYear = null, toYear = null}) {
      const response = await this._vm.$http.post(`/api/company/${companyId}/export`, null, {
         params: {
            // Case conversion doesn't apply to query params
            from_year: fromYear,
            to_year: toYear,
         },
      });
      // Uncomment to simulate an export error
      // const response = await this._vm.$http.post(
      //    `/api/company/${companyId}/export?simulate_error=true`
      // );
      const status = response.data;
      commit('setStatus', {status, companyId});
   },

   // Send a request to delete an export the update the status
   async deleteExport({commit}, {companyId}) {
      await this._vm.$http.delete(`/api/company/${companyId}/export`);
      // const status = response.data;
      commit('setStatus', {status: {state: 'NONE'}, companyId});
   },

   // Download an export
   async download({}, {companyId}) {
      const response = await this._vm.$http.get(`/api/company/${companyId}/export.zip`, {
         responseType: 'blob',
      });
      downloadFile(response);
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
