<template>
   <div class="d-flex align-items-start justify-content-between" :class="{'mb-3': !checkboxOnly}">
      <label :for="checkboxId" class="line-clamp-2 pr-3" v-if="!checkboxOnly">
         <ActivityInfo :activityName="name" :description="description" :ident="activityId" />
      </label>
      <b-form-checkbox
         :id="checkboxId"
         size="lg"
         :checked="value"
         @change="onChange"
         :value="1"
         :unchecked-value="0"
         :disabled="disabled"
         @keyup.native="$onKeydown"
      ></b-form-checkbox>
   </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import ActivityInfo from '@/views/activities/widgets/ActivityInfo.vue';
import EmployeeTimeInput from '../mixins/employeeTimeInput';

export default {
   components: {
      ActivityInfo,
   },

   mixins: [EmployeeTimeInput],

   props: {
      ident: {
         type: String,
         default: '',
      },
      checkboxOnly: Boolean,
      employeeId: {
         type: [String, Number],
         default: null,
      },
      activityId: [String, Number],
      disabled: {
         type: Boolean,
         default: false,
      },
      employeeIndex: {
         type: Number,
         default: null,
      },
      activityIndex: Number,
      employees: Array,
      projectId: [String, Number],
      tableView: {
         type: Boolean,
         default: false,
      },
   },

   data() {
      return {
         check: false,
      };
   },

   computed: {
      ...mapGetters({
         activities: 'timesurvey/activities',
         data: 'timesurvey/data',
      }),

      name() {
         return this.activities[this.activityId].name;
      },

      description() {
         return this.activities[this.activityId].description;
      },

      value() {
         return this.data[this.employeeId].activities[this.activityId];
      },

      checkboxId() {
         return this.$activityInputId(this.ident, this.activityIndex, this.employeeIndex);
      },
   },

   methods: {
      ...mapMutations({
         setActivity: 'timesurvey/setActivity',
      }),

      nextVerticalInputId(offset, wrap = false) {
         let nextId;
         if (this.tableView) {
            const employeeCount = this.employees.length;
            let nextIndex = this.employeeIndex + offset;

            // Modulo arithmatic to wrap around between first and last employee
            if (wrap) {
               nextIndex = ((nextIndex % employeeCount) + employeeCount) % employeeCount;
            }

            nextId = this.$activityInputId(this.ident, this.activityIndex, nextIndex);
         } else {
            let nextIndex = this.activityIndex + offset;

            if (wrap) {
               const activityCount = Object.keys(this.activities).length;
               nextIndex = ((nextIndex % activityCount) + activityCount) % activityCount;
            }

            nextId = this.$activityInputId(this.ident, nextIndex);
         }
         return nextId;
      },

      /** Handle the input event from the activity checkbox */
      onChange(value) {
         this.setActivity({
            employeeId: this.employeeId,
            activityId: this.activityId,
            value,
         });
         try {
            this.$store.dispatch('timesurvey/saveEmployee', {
               employeeId: this.employeeId,
            });
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Failed to save employee data',
               seconds: 5,
            });
         }
      },
   },
};
</script>
