<template>
   <b-container class="pt-1 pb-4">
      <div class="standard-width mx-auto">
         <h1 class="page-title">Create {{ userLabel }} Account</h1>
      </div>
      <b-card bg-variant="white" class="standard-width mx-auto">
         <b-form @submit.prevent="onSubmit">
            <!-- Name -->
            <b-form-row>
               <b-col md="6">
                  <b-form-group label="First Name" label-for="input-first-name">
                     <b-form-input
                        id="input-first-name"
                        v-model="profile.firstName"
                        trim
                        :state="profile.firstName ? !$v.profile.firstName.$invalid : null"
                        ref="firstName"
                     ></b-form-input>
                  </b-form-group>
               </b-col>
               <b-col md="6">
                  <b-form-group label="Last Name" label-for="input-last-name">
                     <b-form-input
                        id="input-last-name"
                        v-model="profile.lastName"
                        trim
                        :state="profile.lastName ? !$v.profile.lastName.$invalid : null"
                     ></b-form-input>
                  </b-form-group>
               </b-col>
            </b-form-row>

            <!-- Email -->
            <b-form-group
               label="Email"
               label-for="input-email"
               invalid-feedback="Please enter a valid email"
               description="This will be the user name for logging in."
            >
               <b-form-input
                  id="input-email"
                  v-model="profile.email"
                  trim
                  :state="profile.email ? !$v.profile.email.$invalid : null"
               ></b-form-input>
            </b-form-group>

            <!-- Company -->
            <b-form-group label="Company" label-for="select-company" v-if="isClient">
               <b-form-select
                  id="select-company"
                  :options="companyOptions"
                  v-model="companyId"
                  :disabled="null !== company"
                  @input="loadSuggestions"
               ></b-form-select>
            </b-form-group>

            <!-- Job Title -->
            <b-form-group label="Job Title" label-for="input-job-title" v-if="isClient">
               <b-form-input
                  id="input-job-title"
                  list="datalist-titles"
                  autocomplete="off"
                  v-model="profile.title"
                  trim
               ></b-form-input>

               <datalist id="datalist-titles" v-if="companyId">
                  <option v-for="title in companyTitles" :key="title">
                     {{ title }}
                  </option>
               </datalist>
            </b-form-group>

            <!-- Department -->
            <b-form-group label="Department" label-for="input-department" v-if="isClient">
               <b-form-input
                  id="input-department"
                  list="datalist-departments"
                  autocomplete="off"
                  v-model="profile.department"
                  trim
               ></b-form-input>

               <datalist id="datalist-departments" v-if="companyId">
                  <option v-for="department in companyDepartments" :key="department">
                     {{ department }}
                  </option>
               </datalist>
            </b-form-group>

            <!-- Subsidiary -->
            <b-form-group
               label="Subsidiary Company Name"
               label-for="input-subsidiary"
               v-if="isClient"
            >
               <b-form-input
                  id="input-subsidiary"
                  v-model="profile.subsidiary"
                  list="datalist-subsidiaries"
                  autocomplete="off"
               ></b-form-input>

               <datalist id="datalist-subsidiaries" v-if="companyId">
                  <option v-for="subsidiary in companySubsidiaries" :key="subsidiary">
                     {{ subsidiary }}
                  </option>
               </datalist>
            </b-form-group>

            <b-button type="submit" variant="primary" :disabled="$v.$invalid">Submit</b-button>
         </b-form>
      </b-card>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {required, requiredIf, email} from 'vuelidate/lib/validators';

import {clientUserSuggestions} from '@/mixins';
import {UserGroups} from '@/store/modules/session';
import {caseTransformer} from '@/helpers/utils';

export default {
   name: 'UserCreate',

   mixins: [
      // Adds support for department/job title suggestions
      clientUserSuggestions,
   ],

   props: {
      userType: {
         type: String,
         default: null,
      },
      company: {
         type: [String, Number],
         default: null,
      },
   },

   async created() {
      let requests = [];

      requests.push(
         this.$store.dispatch('companies/fetchCompanies').then((companies) => {
            this.companies = companies;
         })
      );

      if (this.company) {
         requests.push(this.loadSuggestions());
      }

      await this.blockUntilAllSettled(requests);
   },

   mounted() {
      this.$refs.firstName.focus();
   },

   data() {
      return {
         profile: {
            firstName: null,
            lastName: null,
            email: null,
            title: null,
            department: null,
            subsidiary: null,
         },
         companies: null,
         companyId: this.company,
      };
   },

   computed: {
      ...mapGetters({
         isAdmin: 'isAdmin',
         isStaff: 'isStaff',
         isCustomer: 'isCustomer',
      }),

      userLabel() {
         if (!this.userType) {
            return 'User';
         }
         if (UserGroups.SME === this.userType) {
            return this.userType;
         } else {
            return caseTransformer.UCFIRST(this.userType);
         }
      },

      isClient() {
         return [UserGroups.CUSTOMER, UserGroups.SME].includes(this.userType);
      },

      companyOptions() {
         if (!this.companies) {
            return [{text: 'Loading...', value: null}];
         } else {
            return [
               {text: 'Please select one', value: null},
               ...this.companies.map((company) => {
                  return {text: company.companyName, value: company.id};
               }),
            ];
         }
      },
   },
   methods: {
      /** Submit a create user request */
      async onSubmit() {
         let payload = {
            ...this.profile,
         };

         if (this.userType) {
            payload.groups = [this.userType];
         }

         if (this.companyId) {
            payload.companyId = this.companyId;
         }

         try {
            await this.blockingRequest('users/createUser', payload);
            if (this.companyId) {
               this.$router.push({name: 'company-detail', params: {id: this.companyId}});
            } else {
               this.$router.push({name: 'Home'});
            }
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Submission failed',
               seconds: 5,
            });
         }
      },
   },
   validations: {
      profile: {
         firstName: {
            required,
         },
         lastName: {
            required,
         },
         email: {
            required,
            email,
         },
      },
      companyId: {
         required: requiredIf(function () {
            return this.isClient;
         }),
      },
   },
};
</script>
