<template>
   <CollapseCard v-if="progress !== null" :ident="`${ident}-card`">
      <template #title>
         <div class="d-flex align-items-center section-title">
            <custom-icon
               class="mr-4 text-secondary"
               :class="{'text-success': isComplete}"
               :icon="isComplete ? 'icon-check' : iconName"
               :key="`${ident}-${isComplete}`"
            />
            {{ title }}
         </div>
      </template>
      <template #subtitle>
         <div class="d-flex align-items-center">
            <span :id="`${ident}-percentage`" class="mr-2"> {{ progress }}% Complete </span>
            <b-progress
               :value="progress"
               :max="100"
               class="section-progress"
               :variant="isComplete ? 'success' : 'primary'"
            >
            </b-progress>
         </div>
      </template>

      <slot name="header"></slot>

      <slot>
         <b-table
            :fields="actionFields"
            :items="actionItems"
            sort-icon-left
            :id="`table-${ident}-action`"
            class="mb-0 scrollbar"
            show-empty
            responsive
            sticky-header="500px"
         >
            <template #cell(actions)="data">
               <div class="cell-w-buttons d-flex justify-content-end">
                  <b-button
                     :id="`btn-${ident}-action`"
                     variant="primary"
                     class="no-wrap d-flex align-items-center"
                     size="sm"
                     :to="data.item.to"
                  >
                     {{ data.item.buttonText }}
                     <custom-icon
                        icon="icon-chevron-right"
                        height="16px"
                        width="16px"
                        class="ml-1"
                     />
                  </b-button>
               </div>
            </template>

            <template #empty>
               <div class="text-center py-4">You have no active items</div>
            </template>
         </b-table>

         <b-table
            v-if="completedActionItems.length > 0"
            :fields="completedActionFields"
            :items="completedActionItems"
            sort-icon-left
            :id="`table-${ident}-completed`"
            class="mb-0 mt-3 scrollbar"
            responsive
            sticky-header="500px"
         >
            <template #cell(actions)="data">
               <div class="cell-w-buttons d-flex justify-content-end">
                  <b-button variant="secondary" class="no-wrap" size="sm" :to="data.item.to">{{
                     data.item.buttonText
                  }}</b-button>
               </div>
            </template>
         </b-table>
      </slot>
   </CollapseCard>
</template>

<script>
export default {
   props: {
      ident: {
         type: String,
      },

      title: {
         type: String,
      },

      iconName: {
         type: String,
      },

      progress: {
         type: Number,
      },

      actionItems: {
         type: Array,
         default: () => [],
      },

      completedActionItems: {
         type: Array,
         default: () => [],
      },
   },

   data() {
      return {
         actionFields: [
            {key: 'text', label: 'Incomplete', sortable: true},
            {key: 'actions', label: ''},
         ],
         completedActionFields: [
            {key: 'text', label: 'Completed', sortable: true},
            {key: 'actions', label: ''},
         ],
      };
   },

   computed: {
      isComplete() {
         return this.progress === 100;
      },
   },
};
</script>

<style lang="scss" scoped>
.section-title {
   @include lg-max {
      font-size: 1.25rem;
   }
}

.section-progress {
   min-width: 7rem;
   @include lg {
      min-width: 14rem;
   }
}
</style>
