<template>
   <b-container class="client-dashboard">
      <PageTitle title="Welcome back!" />

      <b-row class="mb-4">
         <b-col md="6" cols="12">
            <b-card id="progress-card" class="info-card mb-4 mb-md-0">
               <h3 class="mb-3">Your Progress</h3>
               <b-progress
                  :value="progress.total"
                  :max="100"
                  variant="white"
                  class="progress-white mb-3"
               ></b-progress>
               <span id="progress-percentage">{{ progress.total }}% Complete</span>
            </b-card>
         </b-col>
         <b-col md="6" cols="12">
            <b-card id="continue-card" class="info-card">
               <h3 class="mb-3">
                  {{ continueCardTitle }}
               </h3>
               <p v-if="nextAction">
                  {{ nextAction.text }}
               </p>
               <p v-else>Thank you, all your assignments are now complete.</p>

               <div class="d-flex justify-content-end">
                  <b-button v-if="nextAction" variant="success" :to="nextAction.to">
                     Continue
                  </b-button>
               </div>
            </b-card>
         </b-col>
      </b-row>

      <!-- EMPLOYEE TIME CARD -->
      <b-card v-if="progress.timeSurvey !== null" class="mb-3">
         <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
               <custom-icon
                  :key="`ts-icon-${timeSurveyComplete ? 'icon-check' : 'icon-chronometer'}`"
                  class="mr-4 text-secondary"
                  :class="{'text-success': timeSurveyComplete}"
                  :icon="timeSurveyComplete ? 'icon-check' : 'icon-chronometer'"
               />
               <h2 class="mb-0">Employee Time</h2>
            </div>

            <div class="d-flex align-items-center">
               <span id="time-survey-percentage" class="mr-2">
                  {{ progress.timeSurvey }}% Complete
               </span>
               <b-progress
                  :value="progress.timeSurvey"
                  :max="100"
                  class="section-progress"
                  :variant="timeSurveyComplete ? 'success' : 'primary'"
               >
               </b-progress>
               <b-button
                  id="btn-time-survey"
                  variant="success"
                  size="sm"
                  class="ml-3"
                  :to="{name: 'time-survey-base'}"
               >
                  Go
               </b-button>
            </div>
         </div>
      </b-card>

      <!-- CONTRACTOR CARD -->
      <b-card v-if="progress.contractors !== null" class="mb-3">
         <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
               <custom-icon
                  :key="`contractor-icon-${contractorComplete ? 'icon-check' : 'icon-contractor'}`"
                  class="mr-4 text-secondary"
                  :class="{'text-success': contractorComplete}"
                  :icon="contractorComplete ? 'icon-check' : 'icon-contractor'"
               />
               <h2 class="mb-0">Contract Research</h2>
            </div>
            <div class="d-flex align-items-center">
               <span id="contractor-percentage" class="mr-2">
                  {{ progress.contractors }}% Complete
               </span>
               <b-progress
                  :value="progress.contractors"
                  :max="100"
                  class="section-progress"
                  :variant="contractorComplete ? 'success' : 'primary'"
               >
               </b-progress>
               <b-button
                  id="btn-contractors"
                  variant="success"
                  size="sm"
                  class="ml-3"
                  :class="{invisible: isSME && !hasContractorAssignment}"
                  :to="{name: 'contractors-home'}"
               >
                  Go
               </b-button>
            </div>
         </div>
      </b-card>

      <!-- SUPPLIES CARD -->
      <b-card v-if="progress.supplies !== null" class="mb-3">
         <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
               <custom-icon
                  :key="`supplies-icon-${suppliesComplete ? 'icon-check' : 'icon-supplies'}`"
                  class="mr-4 text-secondary"
                  :class="{'text-success': suppliesComplete}"
                  :icon="suppliesComplete ? 'icon-check' : 'icon-supplies'"
               />
               <h2 class="mb-0">Supplies</h2>
            </div>
            <div class="d-flex align-items-center">
               <span id="supplies-percentage" class="mr-2">
                  {{ progress.supplies }}% Complete
               </span>
               <b-progress
                  :value="progress.supplies"
                  :max="100"
                  class="section-progress"
                  :variant="suppliesComplete ? 'success' : 'primary'"
               >
               </b-progress>
               <b-button
                  id="btn-supplies"
                  variant="success"
                  size="sm"
                  class="ml-3"
                  :class="{invisible: suppliesComplete}"
                  :to="{name: 'supplies-projects'}"
               >
                  Go
               </b-button>
            </div>
         </div>
      </b-card>

      <!-- CLOUD COMPUTING CARD -->
      <b-card v-if="progress.cloud !== null" class="mb-3">
         <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
               <custom-icon
                  :key="`cloud-icon-${cloudComplete ? 'icon-check' : 'icon-cloud-computing'}`"
                  class="mr-4 text-secondary"
                  :class="{'text-success': cloudComplete}"
                  :icon="cloudComplete ? 'icon-check' : 'icon-cloud-computing'"
               />
               <h2 class="mb-0">Cloud Computing</h2>
            </div>
            <div class="d-flex align-items-center">
               <span id="cloud-percentage" class="mr-2"> {{ progress.cloud }}% Complete </span>
               <b-progress
                  :value="progress.cloud"
                  :max="100"
                  class="section-progress"
                  :variant="cloudComplete ? 'success' : 'primary'"
               >
               </b-progress>
               <b-button
                  id="btn-cloud"
                  variant="success"
                  size="sm"
                  class="ml-3"
                  :class="{invisible: cloudComplete || !hasCloudAssignment}"
                  :to="{name: 'cloud-declaration'}"
               >
                  Go
               </b-button>
            </div>
         </div>
      </b-card>

      <!-- COMPANY CARD -->
      <b-card v-if="progress.company !== null" class="mb-3">
         <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
               <custom-icon
                  :key="`company-icon-${companyComplete ? 'icon-check' : 'icon-office-building'}`"
                  class="mr-4 text-secondary"
                  :class="{'text-success': companyComplete}"
                  :icon="companyComplete ? 'icon-check' : 'icon-office-building'"
               />
               <h2 class="mb-0">Company Questionnaire</h2>
            </div>
            <div class="d-flex align-items-center">
               <span id="company-percentage" class="mr-2"> {{ progress.company }}% Complete </span>
               <b-progress
                  :value="progress.company"
                  :max="100"
                  class="section-progress"
                  :variant="companyComplete ? 'success' : 'primary'"
               >
               </b-progress>
               <b-button
                  id="btn-company"
                  variant="success"
                  size="sm"
                  class="ml-3"
                  :class="{invisible: companyComplete}"
                  :to="{name: 'questionnaires'}"
               >
                  Go
               </b-button>
            </div>
         </div>
      </b-card>

      <!-- PROJECT CARD -->
      <b-card v-if="progress.project !== null" class="mb-3">
         <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
               <custom-icon
                  :key="`project-icon-${projectComplete ? 'icon-check' : 'icon-workflow'}`"
                  class="mr-4 text-secondary"
                  :class="{'text-success': projectComplete}"
                  :icon="projectComplete ? 'icon-check' : 'icon-workflow'"
               />
               <h2 class="mb-0">Project Questionnaire</h2>
            </div>
            <div class="d-flex align-items-center">
               <span id="project-percentage" class="mr-2"> {{ progress.project }}% Complete </span>
               <b-progress
                  :value="progress.project"
                  :max="100"
                  class="section-progress"
                  :variant="projectComplete ? 'success' : 'primary'"
               >
               </b-progress>
               <b-button
                  id="btn-project"
                  variant="success"
                  size="sm"
                  class="ml-3"
                  :class="{invisible: projectComplete}"
                  :to="{name: 'questionnaires'}"
               >
                  Go
               </b-button>
            </div>
         </div>
      </b-card>

      <!-- PROFILE CARD -->
      <b-card class="mb-3">
         <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
               <custom-icon
                  :key="`profile-icon-${profileComplete ? 'icon-check' : 'icon-avatar'}`"
                  class="mr-4 text-secondary"
                  :class="{'text-success': profileComplete}"
                  :icon="profileComplete ? 'icon-check' : 'icon-avatar'"
               />
               <h2 class="mb-0">Profile</h2>
            </div>

            <div class="d-flex align-items-center">
               <span id="profile-percentage" class="mr-2"> {{ progress.profile }}% Complete </span>
               <b-progress
                  :value="progress.profile"
                  :max="100"
                  class="section-progress"
                  :variant="profileComplete ? 'success' : 'primary'"
               >
               </b-progress>
               <b-button
                  id="btn-profile"
                  variant="success"
                  size="sm"
                  class="ml-3"
                  :to="{name: 'profile', query: {section: 'about'}}"
               >
                  Go
               </b-button>
            </div>
         </div>
      </b-card>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   computed: {
      ...mapGetters({
         isSME: 'isSME',
         profile: 'profile',
         progress: 'progress/progress',
         priorityActionItems: 'progress/priorityActionItems',
         hasCloudAssignment: 'progress/hasCloudAssignment',
         hasContractorAssignment: 'progress/hasContractorAssignment',
      }),

      companyId() {
         return this.profile.companyId;
      },

      fullName() {
         let name = this.profile.firstName;
         if (this.profile.lastName) {
            name += ' ' + this.profile.lastName;
         }
         return name;
      },

      continueCardTitle() {
         if (this.progress.total === 0) {
            return "Let's get started!";
         } else if (this.nextAction) {
            return 'Continue where you left off';
         } else {
            return 'All done!';
         }
      },

      nextAction() {
         if (this.priorityActionItems.length === 0) {
            return null;
         } else {
            return this.priorityActionItems[0];
         }
      },

      totalComplete() {
         return this.progress.total === 100;
      },

      profileComplete() {
         return this.progress.profile === 100;
      },

      suppliesComplete() {
         return this.progress.supplies === 100;
      },

      timeSurveyComplete() {
         return this.progress.timeSurvey === 100;
      },

      projectComplete() {
         return this.progress.project === 100;
      },

      companyComplete() {
         return this.progress.company === 100;
      },

      contractorComplete() {
         return this.progress.contractors === 100;
      },

      cloudComplete() {
         return this.progress.cloud === 100;
      },
   },

   async mounted() {
      await this.blockingRequest('progress/loadProgress', {
         companyId: this.companyId,
      });
   },
};
</script>

<style lang="scss" scoped>
.info-card {
   background-color: $gray-800;
   color: $white;
   border: none;

   @include md {
      height: 100%;
   }
}

.section-progress {
   min-width: 7rem;
   @include lg {
      min-width: 14rem;
   }
}
</style>
