<template>
   <FieldInput
      ref="input"
      :ident="id"
      append="%"
      :field="percentageField"
      :disabled="disabled"
      :required="vendorId !== null"
      @input="setPercentage"
   />
</template>

<script>
import {mapGetters} from 'vuex';
import {integer} from 'vuelidate/lib/validators';
import FieldInput from '@/components/forms/FieldInput';

export default {
   components: {
      FieldInput,
   },

   props: {
      ident: {
         type: [String, Number],
      },
      year: {
         type: [String, Number],
      },
      account: {
         type: String,
      },
      vendorId: {
         type: [String, Number],
         default: null,
      },
      disabled: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      ...mapGetters({
         accountData: 'supplies/data',
         adHocData: 'supplies/adHocData',
      }),

      id() {
         const accountStr = this.account ? this.account : 'adhoc';
         return `input-percentage-${this.year}-${accountStr}-${this.ident}`.replace(/\s/g, '-');
      },

      percentageField() {
         if (this.vendorId === null) {
            return null;
         } else if (this.account) {
            return this.accountData[this.year][this.account][this.vendorId].percentage;
         } else {
            return this.adHocData[this.year][this.vendorId].percentage;
         }
      },
   },

   methods: {
      async setPercentage(value) {
         if (this.validate(value)) {
            if (value === '') {
               value = null;
            } else {
               // Parse as int so the server doesn't complain about numbers like 1.0
               value = parseInt(value, 10);
            }
            if (this.vendorId) {
               this.$store.dispatch('supplies/savePercentage', {
                  year: this.year,
                  account: this.account,
                  vendorId: this.vendorId,
                  percentage: value,
               });
            } else {
               if (value === null) {
                  const proceed = await this.$bvModal.msgBoxConfirm(
                     'Are you sure you want to clear the percentage for every vendor in this account?',
                     {
                        centered: true,
                        title: 'Clear all vendors?',
                     }
                  );
                  if (!proceed) {
                     return;
                  }
               }
               const vendors = this.account
                  ? this.accountData[this.year][this.account]
                  : this.adHocData[this.year];
               Object.values(vendors).forEach((vendor) => {
                  this.$store.dispatch('supplies/savePercentage', {
                     year: this.year,
                     account: this.account,
                     vendorId: vendor.id,
                     percentage: value,
                  });
               });
            }
         }
      },

      /** Validate a percentage */
      validate(value) {
         if (value === '' || value === null) {
            this.$refs.input.setState(null);
            return true;
         }
         if (!integer(value)) {
            this.$refs.input.setState(false, 'Must be an integer');
            return false;
         }
         value = parseInt(value, 10);
         if (value < 0 || value > 100) {
            this.$refs.input.setState(false, 'Must be between 0 and 100');
            return false;
         }
         this.$refs.input.setState(null);
         return true;
      },
   },
};
</script>
