<template>
   <g>
      <path
         d="M14.5071 26.2826C10.0266 26.2831 5.89502 23.7157 3.91622 19.6747C1.91386 15.5843 2.52014 10.4831 5.42558 6.98086C8.32785 3.48229 13.0462 1.95571 17.4452 3.09169C17.9296 3.21675 18.2209 3.71111 18.0963 4.19596C17.9713 4.6808 17.4764 4.97171 16.9921 4.8471C13.271 3.88602 9.27714 5.17743 6.82075 8.1386C4.363 11.1016 3.85007 15.4176 5.5443 18.8786C7.23219 22.3259 10.9555 24.5716 14.7772 24.4674C18.5984 24.3632 22.0661 22.0391 23.6118 18.5469C24.5194 16.496 24.7115 14.1738 24.1519 12.0074C24.0268 11.523 24.3182 11.0286 24.803 10.9031C25.2865 10.7776 25.7818 11.0694 25.9073 11.5543C26.5679 14.114 26.3418 16.8581 25.2697 19.2805C23.4427 23.4085 19.3433 26.1558 14.827 26.279C14.7201 26.2813 14.6131 26.2826 14.5071 26.2826Z"
      />
      <path
         d="M14.5001 17.3275C14.2681 17.3275 14.0361 17.2392 13.8594 17.062C13.5055 16.7081 13.5055 16.1344 13.8594 15.7806L24.7344 4.90555C25.0878 4.55166 25.6624 4.55166 26.0158 4.90555C26.3697 5.25945 26.3697 5.8331 26.0158 6.18699L15.1409 17.062C14.9641 17.2387 14.7321 17.3275 14.5001 17.3275Z"
      />
      <path
         d="M14.4999 17.3274C14.2679 17.3274 14.0359 17.2391 13.8592 17.0619L10.0144 13.2171C9.66055 12.8633 9.66055 12.2896 10.0144 11.9357C10.3679 11.5818 10.9424 11.5818 11.2959 11.9357L15.1407 15.7805C15.4945 16.1344 15.4945 16.708 15.1407 17.0619C14.9639 17.2386 14.7319 17.3274 14.4999 17.3274Z"
      />
   </g>
</template>

<script>
export default {
   data() {
      return {
         name: 'icon-check',
         viewBox: '0 0 32 32',
      };
   },
};
</script>
