import MessagesHome from '@/views/messaging/MessageOverview';

export default [
   {
      path: '/messages',
      name: 'messages',
      component: MessagesHome,
      meta: {
         authGroup: 'rndig',
         breadCrumb: [{text: 'Dashboard', to: {name: 'Home'}}, {text: 'Messages'}],
      },
   },
];
