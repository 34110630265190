import {clientStaffRoutes} from '../utils';

import ContractorsHome from '@/views/contractors/ContractorsHome';
import ContractorsPermissions from '@/views/contractors/ContractorsPermissions';
import ContractorTimeYears from '@/views/contractors/ContractorTimeYears';
import ContractorTimeData from '@/views/contractors/ContractorTimeData';

export default [
   ...clientStaffRoutes({
      name: 'contractors-home',
      path: '/contractors',
      component: ContractorsHome,
      stage: 'contractors',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Contractors'},
      ],
   }),
   ...clientStaffRoutes({
      name: 'contractors-permissions',
      path: '/contractors/collaborators',
      component: ContractorsPermissions,
      stage: 'contractors',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Contractors', to: {name: 'contractors-home-staff', params: {id: 'FILL'}}},
         {text: 'Permissions'},
      ],
   }),
   ...clientStaffRoutes({
      name: 'contractor-time-years',
      path: '/contractors/time',
      component: ContractorTimeYears,
      stage: 'contractors',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Contractors', to: {name: 'contractors-home-staff', params: {id: 'FILL'}}},
         {text: 'Years'},
      ],
   }),
   ...clientStaffRoutes({
      name: 'contractor-time-data',
      path: '/contractors/time/:year',
      component: ContractorTimeData,
      stage: 'contractors',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Contractors', to: {name: 'contractors-home-staff', params: {id: 'FILL'}}},
         {text: 'Years', to: {name: 'contractor-time-years-staff', params: {id: 'FILL'}}},
         {text: '__YEAR__'},
      ],
   }),
];
