<template>
   <b-modal
      id="modal-create-client"
      title="Invite Another User"
      size="xl"
      centered
      :ok-disabled="$v.$invalid"
      ok-variant="success"
      ok-title="Invite"
      ok-only
      @ok="submit"
      @show="onShow"
   >
      <p class="text-instruction mb-4">
         <slot>
            Invite someone else in your organization to help you enter information in this section.
            This user will only be able to see and enter info for the areas you assign to them.
         </slot>
      </p>

      <div class="form-section" v-for="(user, index) in users" :key="user.key">
         <b-form-row>
            <b-col cols="4">
               <b-form-group :label-for="`input-first-name-${index}`" invalid-feedback="Required">
                  <template #label> First Name </template>
                  <b-form-input
                     :id="`input-first-name-${index}`"
                     :value="user.firstName"
                     @input="
                        (value) =>
                           $store.commit('users/updateNewUser', {
                              index,
                              field: 'firstName',
                              value,
                           })
                     "
                     :state="$v.users.$each[index].firstName.$invalid ? false : null"
                  ></b-form-input>
               </b-form-group>
            </b-col>

            <b-col cols="4">
               <b-form-group :label-for="`input-last-name-${index}`" invalid-feedback="Required">
                  <template #label> Last Name </template>
                  <b-form-input
                     :id="`input-last-name-${index}`"
                     :value="user.lastName"
                     @input="
                        (value) =>
                           $store.commit('users/updateNewUser', {
                              index,
                              field: 'lastName',
                              value,
                           })
                     "
                     :state="$v.users.$each[index].lastName.$invalid ? false : null"
                  ></b-form-input>
               </b-form-group>
            </b-col>

            <b-col cols="4">
               <b-form-group
                  :label-for="`input-email-${index}`"
                  invalid-feedback="Enter a valid email"
               >
                  <template #label> Email </template>
                  <b-form-input
                     :id="`input-email-${index}`"
                     :value="user.email"
                     @input="
                        (value) =>
                           $store.commit('users/updateNewUser', {
                              index,
                              field: 'email',
                              value,
                           })
                     "
                     :state="$v.users.$each[index].email.$invalid ? false : null"
                  ></b-form-input>
               </b-form-group>
            </b-col>

            <b-col cols="4">
               <b-form-group label="Job Title" :label-for="`input-title-${index}`">
                  <b-form-input
                     :id="`input-title-${index}`"
                     list="datalist-titles"
                     autocomplete="off"
                     :value="user.title"
                     @input="
                        (value) =>
                           $store.commit('users/updateNewUser', {
                              index,
                              field: 'title',
                              value,
                           })
                     "
                  ></b-form-input>
               </b-form-group>
            </b-col>

            <b-col cols="4">
               <b-form-group label="Department" :label-for="`input-department-${index}`">
                  <b-form-input
                     :id="`input-department-${index}`"
                     list="datalist-departments"
                     autocomplete="off"
                     :value="user.department"
                     @input="
                        (value) =>
                           $store.commit('users/updateNewUser', {
                              index,
                              field: 'department',
                              value,
                           })
                     "
                  ></b-form-input>
               </b-form-group>
            </b-col>

            <b-col cols="4">
               <b-form-group label="Subsidiary Company" :label-for="`input-subsidiary-${index}`">
                  <b-form-input
                     :id="`input-subsidiary-${index}`"
                     :value="user.subsidiary"
                     list="datalist-subsidiaries"
                     autocomplete="off"
                     @input="
                        (value) =>
                           $store.commit('users/updateNewUser', {
                              index,
                              field: 'subsidiary',
                              value,
                           })
                     "
                  ></b-form-input>
               </b-form-group>
            </b-col>
         </b-form-row>

         <div class="form-section-delete" v-if="users.length > 1" @click="removeUser(index)">
            Remove user <b-icon-x class="ml-1" :font-scale="1.3" />
         </div>
      </div>

      <datalist id="datalist-titles">
         <option v-for="title in companyTitles" :key="title">
            {{ title }}
         </option>
      </datalist>

      <datalist id="datalist-departments">
         <option v-for="department in companyDepartments" :key="department">
            {{ department }}
         </option>
      </datalist>

      <datalist id="datalist-subsidiaries">
         <option v-for="subsidiary in companySubsidiaries" :key="subsidiary">
            {{ subsidiary }}
         </option>
      </datalist>
   </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
import {required, email} from 'vuelidate/lib/validators';

import {clientUserSuggestions} from '@/mixins';

export default {
   mixins: [
      // Adds support for department/job title suggestions
      clientUserSuggestions,
   ],

   computed: {
      ...mapGetters({
         users: 'users/newUsers',
         company: 'companies/currentCompany',
      }),

      /** The current company */
      companyId() {
         if (this.$route.params.id) {
            return this.$route.params.id;
         } else {
            return this.company.id;
         }
      },
   },

   methods: {
      /** Remove the user at index */
      removeUser(index) {
         this.$store.commit('users/removeNewUser', {index});
      },

      /** Ensure at least one user exists */
      onShow() {
         if (this.users.length === 0) {
            this.$store.commit('users/addNewUser');
         }
      },

      /** Submit the new users */
      async submit() {
         if (this.$v.invalid) {
            return;
         }

         const results = await this.blockingRequest('users/saveNewUsers', {
            companyId: this.companyId,
         });
         this.loadSuggestions(true);
         results
            .filter((result) => result.value.error)
            .forEach((result) => {
               this.$store.commit('showAlert', {
                  msg: `Failed to create user: ${result.value.user.email}`,
                  seconds: 5,
               });
            });
      },
   },

   validations() {
      return {
         users: {
            $each: {
               firstName: {required},
               lastName: {required},
               email: {
                  required,
                  email,
               },
            },
         },
      };
   },
};
</script>
