import Constants from '@/helpers/constants';
import ErrorCodes from '@/helpers/errorCodes';

// Make constants available throughout the app
export default {
   install(Vue) {
      Vue.prototype.$constants = () => Constants;
      Vue.prototype.$errorCodes = () => ErrorCodes;
   },
};
