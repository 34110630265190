const state = () => ({
   rawData: null,
   loaded: false,
});

/** Action types used by the server. */
const ServerActionTypes = Object.freeze({
   /**
    * ServerActionTypes.TIME_SURVEY_DATA
    * Indicates how much of a user's assigned employees have completed data
    * data:
    *    completed - Timestamp of when the questionnaire was marked complete or null
    *    uid       - User ID or null
    *    firstName - User's first name or null
    *    lastName  - User's last name or null
    *    units     - Total number of assigned employees
    *    completed - The number of assigned employees with complete data
    *    year      - The year of the time survey
    */
   TIME_SURVEY_DATA: 'TIME_SURVEY_DATA',

   /**
    * ServerActionTypes.TIME_SURVEY_SUBMIT
    * Indicates that a time survey is ready for submission
    * data:
    *    year - The year of the time survey
    */
   TIME_SURVEY_SUBMIT: 'TIME_SURVEY_SUBMIT',

   /**
    * ServerActionTypes.TIME_SURVEY_MARK_COMPLETED
    * Indicates that a user is ready to mark their assigned employees complete in a time survey
    * data:
    *    completed - The number of employees with complete, valid data assigned to the user
    *    firstName - User's first name
    *    lastName  - User's last name
    *    marked    - Number of employees marked complete
    *    uid       - User ID
    *    units     - Number of units of progress possible
    *    year      - Year of the time survey
    */
   TIME_SURVEY_MARK_COMPLETED: 'TIME_SURVEY_MARK_COMPLETED',

   /**
    * ServerActionTypes.PROJECT_QUESTIONNAIRE_ANSWERS
    * Indicates how many questions of a project questionnaire are unanswered
    * data:
    *    completed   - Timestamp of when the questionnaire was marked complete or null
    *    count       - Total number of questions in the questionnaire
    *    unanswered  - An array of question ids for questions that are unanswered
    *    id          - The id of the questionnaire
    *    title       - The title of the questionnaire
    *    year        - The year of the questionnaire
    *    projectId   - The id of the project
    *    projectName - The name of the project
    */
   PROJECT_QUESTIONNAIRE_ANSWERS: 'PROJECT_QUESTIONNAIRE_ANSWERS',

   /**
    * ServerActionTypes.COMPANY_QUESTION_ANSWERS
    * Indicates the number of questions of a company questionnaire are unanswered
    * data:
    *    completed  - Timestamp of when the questionnaire was marked complete or null
    *    count      - Total number of questions in the questionnaire
    *    unanswered - An array of question ids for questions that are unanswered
    *    id         - The id of the questionnaire
    *    title      - The title of the questionnaire
    *    year       - The year of the questionnaire
    */
   COMPANY_QUESTIONNAIRE_ANSWERS: 'COMPANY_QUESTIONNAIRE_ANSWERS',

   /**
    * ServerActionTypes.PROFILE_QUESTION_ANSWERS
    * Indicates the number of questions of a profile questionnaire are unanswered
    * data:
    *    completed  - Timestamp of when the questionnaire was marked complete or null
    *    count      - Total number of questions in the questionnaire
    *    unanswered - An array of question ids for questions that are unanswered
    *    id         - The id of the questionnaire
    *    title      - The title of the questionnaire
    *    year       - The year of the questionnaire
    */
   PROFILE_QUESTIONNAIRE_ANSWERS: 'PROFILE_QUESTIONNAIRE_ANSWERS',

   /**
    * ServerActionTypes.CONTRACTOR
    * Indicates that some contractors have incomplete basic info
    * data:
    *    invalid - Incomplete or invalid data per contractor
    */
   CONTRACTOR: 'CONTRACTOR',

   /**
    * ServerActionTypes.CONTRACTOR_SUBMIT
    * Indicates that the contractor section is ready to submit
    * no data
    */
   CONTRACTOR_SUBMIT: 'CONTRACTOR_SUBMIT',
});

/** Action types used by this app. */
const ActionItemTypes = Object.freeze({
   /**
    * ActionItemTypes.TIME_SURVEY_DATA
    * Indicates that a user's assigned employees have incomplete data
    * parent:
    *    ServerActionTypes.TIME_SURVEY_DATA
    */
   TIME_SURVEY_DATA: 'TIME_SURVEY_DATA',

   /**
    * ActionItemTypes.TIME_SURVEY_ASSIGNMENTS
    * Indicates that a user is responsible for assigning employees
    * parent:
    *    ServerActionTypes.TIME_SURVEY_DATA
    */
   TIME_SURVEY_ASSIGNMENTS: 'TIME_SURVEY_ASSIGNMENTS',

   /**
    * ActionItemTypes.TIME_SURVEY_SUBMIT
    * Indicates that a time survey is ready for submission
    * parent:
    *    ServerActionTypes.TIME_SURVEY_SUBMIT
    */
   TIME_SURVEY_SUBMIT: 'TIME_SURVEY_SUBMIT',

   /**
    * ActionItemTypes.TIME_SURVEY_DATA_FINISHED
    * Indicates that a user has completed entering data for their assigned employees
    * parent:
    *    ServerActionTypes.TIME_SURVEY_DATA
    */
   TIME_SURVEY_DATA_FINISHED: 'TIME_SURVEY_DATA_FINISHED',

   /**
    * ActionItemTypes.TIME_SURVEY_MARK_COMPLETED
    * Indicates that user is ready to mark their assigned employees complete
    * parent:
    *    ServerActionTypes.TIME_SURVEY_MARK_COMPLETED
    */
   TIME_SURVEY_MARK_COMPLETED: 'TIME_SURVEY_MARK_COMPLETED',

   /**
    * ActionItemTypes.PROJECT_QUESTIONNAIRE_ANSWERS
    * Indicates that a questionnaire has unanswered questions
    * parent:
    *    ServerActionTypes.PROJECT_QUESTIONNAIRE_ANSWERS
    */
   PROJECT_QUESTIONNAIRE_ANSWERS: 'PROJECT_QUESTIONNAIRE_ANSWERS',

   /**
    * ActionItemTypes.PROJECT_QUESTIONNAIRE_SUBMIT
    * Indicates that a questionnaire has no unanswered questions, but has not been submitted
    * parent:
    *    ServerActionTypes.PROJECT_QUESTIONNAIRE_ANSWERS
    */
   PROJECT_QUESTIONNAIRE_SUBMIT: 'PROJECT_QUESTIONNAIRE_SUBMIT',

   /**
    * ActionItemTypes.PROJECT_QUESTIONNAIRE_FINISHED
    * Indicates that a questionnaire has been submitted
    * parent:
    *    ServerActionTypes.PROJECT_QUESTIONNAIRE_ANSWERS
    */
   PROJECT_QUESTIONNAIRE_FINISHED: 'PROJECT_QUESTIONNAIRE_FINISHED',

   /**
    * ActionItemTypes.COMPANY_QUESTION_ANSWERS
    * Indicates that a questionnaire has unanswered questions
    * parent:
    *    ServerActionTypes.COMPANY_QUESTIONNAIRE_ANSWERS
    */
   COMPANY_QUESTIONNAIRE_ANSWERS: 'COMPANY_QUESTIONNAIRE_ANSWERS',

   /**
    * ActionItemTypes.COMPANY_QUESTIONNAIRE_SUBMIT
    * Indicates that a questionnaire has no unanswered questions, but has not been submitted
    * parent:
    *    ServerActionTypes.COMPANY_QUESTIONNAIRE_ANSWERS
    */
   COMPANY_QUESTIONNAIRE_SUBMIT: 'COMPANY_QUESTIONNAIRE_SUBMIT',

   /**
    * ActionItemTypes.COMPANY_QUESTIONNAIRE_FINISHED
    * Indicates that a questionnaire has been submitted
    * parent:
    *    ServerActionTypes.COMPANY_QUESTIONNAIRE_ANSWERS
    */
   COMPANY_QUESTIONNAIRE_FINISHED: 'COMPANY_QUESTIONNAIRE_FINISHED',

   /**
    * ActionItemTypes.PROFILE_QUESTION_ANSWERS
    * Indicates that a questionnaire has unanswered questions
    * parent:
    *    ServerActionTypes.PROFILE_QUESTIONNAIRE_ANSWERS
    */
   PROFILE_QUESTIONNAIRE_ANSWERS: 'PROFILE_QUESTIONNAIRE_ANSWERS',

   /**
    * ActionItemTypes.PROFILE_QUESTIONNAIRE_FINISHED
    * Indicates that all profile questionnaire questions have been answered
    * parent:
    *    ServerActionTypes.PROFILE_QUESTIONNAIRE_ANSWERS
    */
   PROFILE_QUESTIONNAIRE_FINISHED: 'PROFILE_QUESTIONNAIRE_FINISHED',

   /**
    * ActionItemTypes.SUPPLIES_DECLARATION
    * Indicates that the user needs to make a supplies declaration
    */
   SUPPLIES_DECLARATION: 'SUPPLIES_DECLARATION',

   /**
    * ActionItemTypes.SUPPLIES_UPLOAD
    * Indicates that the user needs to upload supplies data
    */
   SUPPLIES_UPLOAD: 'SUPPLIES_UPLOAD',

   /**
    * ActionItemTypes.SUPPLIES_DATA
    * Indicates that the user needs to enter supplies data
    */
   SUPPLIES_DATA: 'SUPPLIES_DATA',

   /**
    * ActionItemTypes.SUPPLIES_SUBMIT
    * Indicates that the user needs to submit supplies data
    */
   SUPPLIES_SUBMIT: 'SUPPLIES_SUBMIT',

   /**
    * ActionItemTypes.SUPPLIES_COMPLETE
    * Indicates that the supplies section is complete
    */
   SUPPLIES_COMPLETE: 'SUPPLIES_COMPLETE',

   /**
    * ActionItemTypes.CONTRACTOR_EMPTY
    * Indicates that no contractors have been created
    */
   CONTRACTOR_EMPTY: 'CONTRACTOR_EMPTY',

   /**
    * ActionItemTypes.CONTRACTOR_INCOMPLETE
    * Indicates that some contractors have incomplete data
    */
   CONTRACTOR_INCOMPLETE: 'CONTRACTOR_INCOMPLETE',

   /**
    * ActionItemTypes.CONTRACTOR_SUBMIT
    * Indicates that the contractor section is ready to submit
    */
   CONTRACTOR_SUBMIT: 'CONTRACTOR_SUBMIT',

   /**
    * ActionItemTypes.CONTRACTOR_COMPLETE
    * Indicates that the contractor section is complete
    */
   CONTRACTOR_COMPLETE: 'CONTRACTOR_COMPLETE',

   /**
    * ActionItemTypes.CLOUD_EMPTY
    * Indicates that no cloud vendors have been created
    */
   CLOUD_EMPTY: 'CLOUD_EMPTY',

   /**
    * ActionItemTypes.CLOUD_UPLOAD
    * Indicates that the user needs to upload cloud computing data
    */
   CLOUD_UPLOAD: 'CLOUD_UPLOAD',

   /**
    * ActionItemTypes.CLOUD_DATA
    * Indicates that the user needs to fill-in missing cloud computing data
    */
   CLOUD_DATA: 'CLOUD_DATA',

   /**
    * ActionItemTypes.CLOUD_SUBMIT
    * Indicates that the cloud computing section is ready to submit
    */
   CLOUD_SUBMIT: 'CLOUD_SUBMIT',

   /**
    * ActionItemTypes.CLOUD_COMPLETE
    * Indicates that the cloud computing section is complete
    */
   CLOUD_COMPLETE: 'CLOUD_COMPLETE',
});

/**
 * A mapping of action item types to that action item's
 * priority as an integer. Smaller number => more important.
 */
const ACTION_PRIORITY = [
   // SUBMISSIONS
   ActionItemTypes.TIME_SURVEY_SUBMIT,
   ActionItemTypes.TIME_SURVEY_MARK_COMPLETED,
   ActionItemTypes.CONTRACTOR_SUBMIT,
   ActionItemTypes.SUPPLIES_SUBMIT,
   ActionItemTypes.CLOUD_SUBMIT,
   ActionItemTypes.COMPANY_QUESTIONNAIRE_SUBMIT,
   ActionItemTypes.PROJECT_QUESTIONNAIRE_SUBMIT,

   // INCOMPLETE WORK
   ActionItemTypes.TIME_SURVEY_DATA,
   ActionItemTypes.TIME_SURVEY_ASSIGNMENTS,
   ActionItemTypes.CONTRACTOR_EMPTY,
   ActionItemTypes.CONTRACTOR_INCOMPLETE,
   ActionItemTypes.SUPPLIES_DECLARATION,
   ActionItemTypes.SUPPLIES_UPLOAD,
   ActionItemTypes.SUPPLIES_DATA,
   ActionItemTypes.CLOUD_EMPTY,
   ActionItemTypes.CLOUD_UPLOAD,
   ActionItemTypes.CLOUD_DATA,
   ActionItemTypes.COMPANY_QUESTIONNAIRE_ANSWERS,
   ActionItemTypes.PROJECT_QUESTIONNAIRE_ANSWERS,

   // COMPLETED WORK
   ActionItemTypes.PROFILE_QUESTIONNAIRE_FINISHED,
   ActionItemTypes.TIME_SURVEY_DATA_FINISHED,
   ActionItemTypes.CONTRACTOR_COMPLETE,
   ActionItemTypes.SUPPLIES_COMPLETE,
   ActionItemTypes.CLOUD_COMPLETE,
   ActionItemTypes.COMPANY_QUESTIONNAIRE_FINISHED,
   ActionItemTypes.PROJECT_QUESTIONNAIRE_FINISHED,

   // PROFILE QUESTIONS LAST
   ActionItemTypes.PROFILE_QUESTIONNAIRE_ANSWERS,
].reduce((obj, action, idx) => ({...obj, [action]: idx}), {});

/**
 * Formatters for incoming action items
 * {
 *    action     - an ActionItemType
 *    text       - A text description of the action
 *    to         - A route to direct the user to
 *    buttonText - Text for the button attached to the action
 *    data       - The incoming data associated with the action
 * }
 */
const ActionItemFormatters = {
   /* eslint-disable-next-line no-unused-vars */
   [ServerActionTypes.TIME_SURVEY_DATA]: (item, profile) => {
      const incomplete = item.units - item.completed;
      const userName = item.uid ? `${item.firstName} ${item.lastName}` : 'Unassigned';

      let action, text, to, buttonText;
      if (incomplete === 0) {
         action = ActionItemTypes.TIME_SURVEY_DATA_FINISHED;
         text = `${userName} - All${item.uid ? ' assigned' : ''} ${
            item.year
         } employees have valid data`;
         to = {name: 'time-survey-review', params: {year: item.year}};
         buttonText = 'Review';
      } else if (item.uid === null) {
         action = ActionItemTypes.TIME_SURVEY_ASSIGNMENTS;
         to = {name: 'time-survey-assignments', params: {year: item.year}};
         buttonText = 'Review Assignments';
         text = `Some employees need assignments for employee time entry.`;
      } else {
         action = ActionItemTypes.TIME_SURVEY_DATA;

         if (item.uid === profile.id) {
            // Time survey employees assigned to the current user with incomplete data
            to = {name: 'time-survey-review', params: {year: item.year}};
            buttonText = incomplete < item.units ? 'Go' : 'Start';
            text = `${incomplete}/${item.units} employee${
               incomplete === 1 ? '' : 's'
            } assigned to you need${incomplete === 1 ? 's' : ''} time entered for ${item.year}`;
         } else {
            // Time survey employees assigned to another user with incomplete data
            to = {name: 'time-survey-assignments', params: {year: item.year}};
            buttonText = 'Review Assignments';
            text = `${userName} - ${incomplete}/${item.units} employees remaining in ${item.year} Employee Time`;
         }
      }

      return {action, data: item, text, to, buttonText};
   },

   [ServerActionTypes.TIME_SURVEY_MARK_COMPLETED]: (item, profile) => {
      let to, buttonText;
      const userName = `${item.firstName} ${item.lastName}`;
      const text = `${userName} - Awaiting completion of ${item.year} Employee Time`;

      if (item.uid === profile.id) {
         to = {name: 'time-survey-review', params: {year: item.year}};
         buttonText = 'Go';
      } else {
         to = {name: 'time-survey-assignments', params: {year: item.year}};
         buttonText = 'Review Assignments';
      }
      return {action: ActionItemTypes.TIME_SURVEY_MARK_COMPLETED, data: item, text, to, buttonText};
   },

   /* eslint-disable-next-line no-unused-vars */
   [ServerActionTypes.TIME_SURVEY_SUBMIT]: (item, profile) => {
      const text = `${item.year} employee time is ready to submit`;
      const to = {name: 'time-survey-review', params: {year: item.year}};
      const buttonText = 'Go';
      return {action: ActionItemTypes.TIME_SURVEY_SUBMIT, data: item, text, to, buttonText};
   },

   [ServerActionTypes.PROJECT_QUESTIONNAIRE_ANSWERS]: (item, profile) => {
      let text, routeName, action, buttonText;

      if (item.completed !== null) {
         text = item.title;
         routeName = 'customer-project-questionnaire-review';
         action = ActionItemTypes.PROJECT_QUESTIONNAIRE_FINISHED;
         buttonText = 'Review';
      } else if (item.unanswered.length === 0 && item.completed === null) {
         text = `"${item.title}" is ready to submit`;
         routeName = 'customer-project-questionnaire-review';
         action = ActionItemTypes.PROJECT_QUESTIONNAIRE_SUBMIT;
         buttonText = 'Go';
      } else {
         text = `${item.unanswered.length}/${item.count} questions on "${item.title}" remaining`;
         routeName = 'customer-project-questionnaire-complete';
         action = ActionItemTypes.PROJECT_QUESTIONNAIRE_ANSWERS;
         buttonText = item.count === item.unanswered.length ? 'Start' : 'Go';
      }

      const to = {
         name: routeName,
         params: {id: profile.companyId, questionnaireId: item.id},
      };

      return {action, data: item, text, to, buttonText};
   },

   [ServerActionTypes.COMPANY_QUESTIONNAIRE_ANSWERS]: (item, profile) => {
      let text, routeName, action, buttonText;

      if (item.completed !== null) {
         text = item.title;
         routeName = 'customer-company-questionnaire-review';
         action = ActionItemTypes.COMPANY_QUESTIONNAIRE_FINISHED;
         buttonText = 'Review';
      } else if (item.unanswered.length === 0 && item.completed === null) {
         text = `"${item.title}" is ready to submit`;
         routeName = 'customer-company-questionnaire-review';
         action = ActionItemTypes.COMPANY_QUESTIONNAIRE_SUBMIT;
         buttonText = 'Go';
      } else {
         text = `${item.unanswered.length}/${item.count} questions on "${item.title}" remaining`;
         routeName = 'customer-company-questionnaire-complete';
         action = ActionItemTypes.COMPANY_QUESTIONNAIRE_ANSWERS;
         buttonText = item.count === item.unanswered.length ? 'Start' : 'Go';
      }

      const to = {
         name: routeName,
         params: {id: profile.companyId, questionnaireId: item.id},
      };

      return {action, data: item, text, to, buttonText};
   },

   /* eslint-disable-next-line no-unused-vars */
   [ServerActionTypes.PROFILE_QUESTIONNAIRE_ANSWERS]: (item, profile) => {
      let text, action, buttonText;

      if (item.unanswered.length === 0) {
         text = `Your Onboard profile is complete`;
         action = ActionItemTypes.PROFILE_QUESTIONNAIRE_FINISHED;
         buttonText = 'Go';
      } else {
         text = `${item.unanswered.length} questions in your Onboard profile need your response`;
         action = ActionItemTypes.PROFILE_QUESTIONNAIRE_ANSWERS;
         buttonText = item.completed === 0 ? 'Start' : 'Go';
      }

      const to = {name: 'profile', query: {section: 'about'}};

      return {action, data: item, text, to, buttonText};
   },
};

/** Computes the supplies section status, returning an ActionItem */
const suppliesState = (data) => {
   if (data.units === 1) {
      // With only 1 unit, we are at the declaration stage
      if (data.completed === 0) {
         // No declaration has been made
         return {
            action: ActionItemTypes.SUPPLIES_DECLARATION,
            buttonText: 'Start',
            text: 'Determine whether or not your company made qualified R&D supplies purchases',
            to: {name: 'supplies-projects'},
         };
      } else {
         // Declaration was "no"
         return {
            action: ActionItemTypes.SUPPLIES_COMPLETE,
            buttonText: 'Review',
            text: 'Your company did not make qualified R&D supplies purchases',
            to: {name: 'supplies-projects'},
         };
      }
   } else if (data.units === 2) {
      // With two units, we're at the uploads stage
      return {
         action: ActionItemTypes.SUPPLIES_UPLOAD,
         buttonText: 'Go',
         text: 'Upload data from ledger accounts that were used to make supplies purchases',
         to: {name: 'supplies-uploads'},
      };
   } else {
      if (data.completed === data.units) {
         // With all units completed, we're done
         return {
            action: ActionItemTypes.SUPPLIES_COMPLETE,
            buttonText: 'Review',
            text: 'The Supplies section is complete',
            to: {name: 'supplies-review'},
         };
      } else if (data.completed === data.units - 1) {
         // With only 1 unit uncompleted, we're at the completion step
         return {
            action: ActionItemTypes.SUPPLIES_SUBMIT,
            buttonText: 'Go',
            text: 'Supplies section is ready to review and submit',
            to: {name: 'supplies-review'},
         };
      } else {
         // With any other number of units remaining, we've got data to enter
         return {
            action: ActionItemTypes.SUPPLIES_DATA,
            buttonText: 'Go',
            text: 'Some Supplies vendors need qualifying percents entered',
            to: {name: 'supplies-percentages'},
         };
      }
   }
};

/** Computes the contractor section status, returning an action item */
const contractorStatus = (data, isSME) => {
   let action, text;
   let to = {name: 'contractors-home'};
   let buttonText = 'Go';

   if (isSME) {
      if (data.percentage === null || data.percentage < 100) {
         action = ActionItemTypes.CONTRACTOR_INCOMPLETE;
         text = 'The Contract Research section needs your attention.';
      } else {
         action = ActionItemTypes.CONTRACTOR_COMPLETE;
         text = 'The Contract Research section is complete.';
      }
   } else {
      if (data.units === 1 && data.completed === 0) {
         action = ActionItemTypes.CONTRACTOR_EMPTY;
         text = 'The Contract Research section is ready for your attention';
         buttonText = 'Start';
      } else if (data.actions.length > 0) {
         if (data.actions[0].action === ServerActionTypes.CONTRACTOR_SUBMIT) {
            action = ActionItemTypes.CONTRACTOR_SUBMIT;
            text = 'The Contract Research section is ready to submit';
         } else {
            action = ActionItemTypes.CONTRACTOR_INCOMPLETE;
            text = 'The Contract Research section is still in progress';
         }
      } else {
         action = ActionItemTypes.CONTRACTOR_COMPLETE;
         text = 'The Contract Research section is complete';
         buttonText = 'Review';
      }
   }

   return {action, text, to, buttonText};
};

/** Computes the cloud computing section status, returning an action item */
const cloudStatus = (data) => {
   if (data.units === 1) {
      // With only 1 unit, we are at the declaration stage
      if (data.completed === 0) {
         // No declaration has been made
         return {
            action: ActionItemTypes.CLOUD_EMPTY,
            buttonText: 'Start',
            text: 'Determine whether or not your company used cloud computing',
            to: {name: 'cloud-declaration'},
         };
      } else {
         // Declaration was "no"
         return {
            action: ActionItemTypes.CLOUD_COMPLETE,
            buttonText: 'Review',
            text: 'Your company did not use qualified cloud computing resources',
            to: {name: 'cloud-declaration'},
         };
      }
   } else if (data.units === 2) {
      // With two units, we're at the uploads stage
      return {
         action: ActionItemTypes.CLOUD_UPLOAD,
         buttonText: 'Go',
         text: 'Upload data from ledger accounts that were used to pay for cloud computing resources',
         to: {name: 'cloud-uploads'},
      };
   } else {
      if (data.completed === data.units) {
         // With all units completed, we're done
         return {
            action: ActionItemTypes.CLOUD_COMPLETE,
            buttonText: 'Review',
            text: 'The Cloud Computing section is complete',
            to: {name: 'cloud-review'},
         };
      } else if (data.completed === data.units - 1) {
         // With only 1 unit uncompleted, we're at the completion step
         return {
            action: ActionItemTypes.CLOUD_SUBMIT,
            buttonText: 'Go',
            text: 'Cloud Computing section is ready to review and submit',
            to: {name: 'cloud-review'},
         };
      } else {
         // With any other number of units remaining, we've got data to enter
         return {
            action: ActionItemTypes.CLOUD_DATA,
            buttonText: 'Go',
            text: 'Some Cloud Computing vendors need qualifying percents entered',
            to: {name: 'cloud-percentages'},
         };
      }
   }
};

/** Sort actions by action type, then year ascending */
const sortActions = (a, b) => {
   const priorityA = ACTION_PRIORITY[a.action];
   const priorityB = ACTION_PRIORITY[b.action];

   if (priorityA < priorityB) {
      return -1;
   } else if (priorityB < priorityA) {
      return 1;
   }

   // For items of the same type, sort by year
   if (a.year && b.year) {
      if (a.year < b.year) {
         return -1;
      } else if (a.year > b.year) {
         return 1;
      }
   }

   return 0;
};

const getters = {
   /** Has progress data been loaded this session? */
   loaded: (state) => state.loaded,

   /** Returns an object containing the progress percentage for each progress category */
   progress: (state, getters, rootState, rootGetters) => {
      const progress = {
         total: null,
         profile: null,
         company: null,
         project: null,
         timeSurvey: null,
         supplies: null,
         contractors: null,
      };

      if (state.rawData !== null) {
         progress.total = state.rawData.percentage;
         progress.profile = state.rawData.components.profileQuestionnaire
            ? state.rawData.components.profileQuestionnaire.percentage
            : null;
         progress.company = state.rawData.components.companyQuestionnaire
            ? state.rawData.components.companyQuestionnaire.percentage
            : null;
         progress.project = state.rawData.components.projectQuestionnaire
            ? state.rawData.components.projectQuestionnaire.percentage
            : null;
         progress.timeSurvey = state.rawData.components.timeSurvey
            ? state.rawData.components.timeSurvey.percentage
            : null;
         progress.supplies = state.rawData.components.supplies
            ? state.rawData.components.supplies.percentage
            : null;
         progress.contractors = state.rawData.components.contractor
            ? state.rawData.components.contractor.percentage || 0
            : null;
         progress.cloud = state.rawData.components.cloudComputing
            ? state.rawData.components.cloudComputing.percentage
            : null;

         if (rootGetters.isSME) {
            const userId = rootGetters.profile.id;

            if (!getters.hasCloudAssignment) {
               // If an SME is currently unassigned the cloud section, but has previously completed
               // an assignment, set their progress to 100.
               const hasPastAssignment = rootGetters[
                  'companies/study'
               ].cloudcomputingAssignments.find((assignment) => assignment.assigneeId === userId);
               if (hasPastAssignment) {
                  progress.cloud = 100;
               }
            }

            if (!getters.hasContractorAssignment) {
               // Same as above, but for the Contract Research section
               const pastAssignments = rootGetters['contractors/pastAssignments'];
               const hasPastAssignment = pastAssignments.some(
                  (assignment) => assignment.assigneeId === userId
               );

               if (hasPastAssignment) {
                  progress.contractors = 100;
               }
            }
         }
      }

      return progress;
   },

   /**  Formatted actions for each category */
   actionItems: (state, getters, rootState, rootGetters) => {
      let companyActionItems = [];
      let projectActionItems = [];
      let timeSurveyActionItems = [];
      let profileActionItems = [];
      let suppliesActionItems = [];
      let contractorActionItems = [];
      let cloudActionItems = [];

      if (state.rawData !== null) {
         const profile = state.rawData.user;

         const _mapActionItems = (componentName, fallback) => {
            const component = state.rawData.components[componentName];
            if (!component && typeof fallback !== 'undefined') {
               return fallback;
            }

            return component.actions
               .map((item) => ActionItemFormatters[item.action](item, profile))
               .sort(sortActions);
         };

         companyActionItems = _mapActionItems('companyQuestionnaire', []);
         projectActionItems = _mapActionItems('projectQuestionnaire', []);
         timeSurveyActionItems = _mapActionItems('timeSurvey');
         profileActionItems = _mapActionItems('profileQuestionnaire');

         if (state.rawData.components.supplies) {
            suppliesActionItems.push(suppliesState(state.rawData.components.supplies));
         }

         if (
            state.rawData.components.contractor &&
            !(rootGetters.isSME && !getters.hasContractorAssignment) // exclude the case where an SME has no assignment
         ) {
            contractorActionItems.push(
               contractorStatus(state.rawData.components.contractor, rootGetters.isSME)
            );
         }

         if (state.rawData.components.cloudComputing) {
            cloudActionItems.push(
               cloudStatus(
                  state.rawData.components.cloudComputing,
                  rootGetters.isSME,
                  getters.hasCloudAssignment
               )
            );
         }
      }

      return {
         company: companyActionItems,
         project: projectActionItems,
         timeSurvey: timeSurveyActionItems,
         profile: profileActionItems,
         supplies: suppliesActionItems,
         contractors: contractorActionItems,
         cloud: cloudActionItems,
      };
   },

   /** Priority action items for the current user */
   priorityActionItems: (state, getters, rootState, rootGetters) => {
      let assignmentsActionItemExists = false;

      if (state.rawData === null) {
         return [];
      }

      const userId = state.rawData.user.id;
      const actionItems = getters['actionItems'];
      const cloudAssigneeId = rootGetters['cloud/assignee'];
      const suppliesAssigneeId = rootGetters['supplies/assignee'];

      return [
         ...actionItems.company,
         ...actionItems.project,
         ...actionItems.timeSurvey,
         ...actionItems.supplies,
         ...actionItems.contractors,
         ...actionItems.cloud,
         ...actionItems.profile,
      ]
         .filter((item) => {
            switch (item.action) {
               case ActionItemTypes.TIME_SURVEY_ASSIGNMENTS:
                  // Include the first instance of TIME_SURVEY_ASSIGNMENTS
                  if (assignmentsActionItemExists) return false;
                  assignmentsActionItemExists = true;
                  return true;

               case ActionItemTypes.TIME_SURVEY_DATA:
                  // Include incomplete TIME_SURVEY_DATA assigned to the current user or unassigned
                  return (
                     (item.data.uid === null || item.data.uid === userId) &&
                     item.data.completed !== item.data.units
                  );

               case ActionItemTypes.TIME_SURVEY_MARK_COMPLETED:
                  return item.data.uid === userId;

               case ActionItemTypes.PROJECT_QUESTIONNAIRE_ANSWERS:
               case ActionItemTypes.COMPANY_QUESTIONNAIRE_ANSWERS:
                  return (
                     item.data.unanswered.length > 0 &&
                     (item.data.assigneeId === null || item.data.assigneeId === userId)
                  );
               case ActionItemTypes.PROFILE_QUESTIONNAIRE_ANSWERS:
                  return item.data.userId === userId;

               case ActionItemTypes.CLOUD_EMPTY:
               case ActionItemTypes.CLOUD_UPLOAD:
               case ActionItemTypes.CLOUD_DATA:
               case ActionItemTypes.CLOUD_SUBMIT:
                  // Deprioritize when cloud computing is assigned to another user
                  return cloudAssigneeId === null || cloudAssigneeId === userId;

               case ActionItemTypes.SUPPLIES_DECLARATION:
               case ActionItemTypes.SUPPLIES_UPLOAD:
               case ActionItemTypes.SUPPLIES_DATA:
               case ActionItemTypes.SUPPLIES_SUBMIT:
                  // Deprioritize when supplies is assigned to another user
                  return suppliesAssigneeId === null || suppliesAssigneeId === userId;

               case ActionItemTypes.TIME_SURVEY_SUBMIT:
               case ActionItemTypes.PROJECT_QUESTIONNAIRE_SUBMIT:
               case ActionItemTypes.COMPANY_QUESTIONNAIRE_SUBMIT:
               case ActionItemTypes.CONTRACTOR_EMPTY:
               case ActionItemTypes.CONTRACTOR_INCOMPLETE:
               case ActionItemTypes.CONTRACTOR_SUBMIT:
                  return true;

               default:
                  return false;
            }
         })
         .sort(sortActions);
   },

   /** All profile action items */
   profileActionItems: (state, getters) => {
      if (state.rawData === null) {
         return [];
      }

      return getters['actionItems'].profile;
   },

   /** Is the current user's profile questionnaire complete? */
   isProfileQuestionnaireComplete: (state, getters, rootState, rootGetters) => {
      if (rootGetters.isRndig || state.rawData === null) {
         return true;
      }

      const userId = state.rawData.user.id;
      return getters.profileActionItems.some(
         (item) =>
            item.action === ActionItemTypes.PROFILE_QUESTIONNAIRE_FINISHED &&
            item.data.userId === userId
      );
   },

   /** Incomplete action items related to time surveys */
   timeSurveyActionItems: (state, getters) => {
      let assignmentsActionItemExists = false;

      if (state.rawData === null) {
         return [];
      }

      return getters['actionItems'].timeSurvey.filter((item) => {
         switch (item.action) {
            case ActionItemTypes.TIME_SURVEY_ASSIGNMENTS:
               if (assignmentsActionItemExists) return false;
               assignmentsActionItemExists = true;
               return true;
            case ActionItemTypes.TIME_SURVEY_DATA:
            case ActionItemTypes.TIME_SURVEY_SUBMIT:
            case ActionItemTypes.TIME_SURVEY_MARK_COMPLETED:
               return true;
            default:
               return false;
         }
      });
   },

   /** Completed action items related to time surveys */
   timeSurveyCompletedItems: (state, getters) => {
      if (state.rawData === null) {
         return [];
      }

      return getters['actionItems'].timeSurvey.filter((item) => {
         return item.action === ActionItemTypes.TIME_SURVEY_DATA_FINISHED;
      });
   },

   /** Incomplete action items related to project questionnaires */
   projectActionItems: (state, getters) => {
      return getters['actionItems'].project.filter((item) => {
         switch (item.action) {
            case ActionItemTypes.PROJECT_QUESTIONNAIRE_ANSWERS:
            case ActionItemTypes.PROJECT_QUESTIONNAIRE_SUBMIT:
               return true;
            default:
               return false;
         }
      });
   },

   /** Completed action items related to project questionnaires */
   projectCompletedItems: (state, getters) => {
      return getters['actionItems'].project.filter((item) => {
         return item.action === ActionItemTypes.PROJECT_QUESTIONNAIRE_FINISHED;
      });
   },

   /** Incomplete action items related to company questionnaires */
   companyActionItems: (state, getters) => {
      return getters['actionItems'].company.filter((item) => {
         switch (item.action) {
            case ActionItemTypes.COMPANY_QUESTIONNAIRE_ANSWERS:
            case ActionItemTypes.COMPANY_QUESTIONNAIRE_SUBMIT:
               return true;
            default:
               return false;
         }
      });
   },

   /** Completed action items related to company questionnaires */
   companyCompletedItems: (state, getters) => {
      return getters['actionItems'].company.filter((item) => {
         return item.action === ActionItemTypes.COMPANY_QUESTIONNAIRE_FINISHED;
      });
   },

   /** Incomplete action items related to supplies */
   suppliesActionItems: (state, getters) => {
      return getters['actionItems'].supplies.filter((item) => {
         switch (item.action) {
            case ActionItemTypes.SUPPLIES_DECLARATION:
            case ActionItemTypes.SUPPLIES_UPLOAD:
            case ActionItemTypes.SUPPLIES_DATA:
            case ActionItemTypes.SUPPLIES_SUBMIT:
               return true;
            default:
               return false;
         }
      });
   },

   /** Completed action items related to supplies */
   suppliesCompletedItems: (state, getters) => {
      return getters['actionItems'].supplies.filter((item) => {
         return item.action === ActionItemTypes.SUPPLIES_COMPLETE;
      });
   },

   /** Incomplete actions for the contractor section */
   contractorActionItems: (state, getters) => {
      return getters.actionItems.contractors.filter((item) => {
         switch (item.action) {
            case ActionItemTypes.CONTRACTOR_EMPTY:
            case ActionItemTypes.CONTRACTOR_INCOMPLETE:
            case ActionItemTypes.CONTRACTOR_SUBMIT:
               return true;
            default:
               return false;
         }
      });
   },

   /** Completed actions for the contractor section */
   contractorCompletedItems: (state, getters) => {
      return getters.actionItems.contractors.filter((item) => {
         return item.action === ActionItemTypes.CONTRACTOR_COMPLETE;
      });
   },

   /** Incomplete actions for the cloud computing section */
   cloudActionItems: (state, getters) => {
      return getters.actionItems.cloud.filter((item) => {
         switch (item.action) {
            case ActionItemTypes.CLOUD_EMPTY:
            case ActionItemTypes.CLOUD_UPLOAD:
            case ActionItemTypes.CLOUD_DATA:
            case ActionItemTypes.CLOUD_SUBMIT:
               return true;
            default:
               return false;
         }
      });
   },

   /** Completed actions for the cloud computing section */
   cloudCompletedItems: (state, getters) => {
      return getters.actionItems.cloud.filter((item) => {
         return item.action === ActionItemTypes.CLOUD_COMPLETE;
      });
   },

   /** Does the current user have any questionnaire assignments? */
   hasQuestionnaireAssignment: (state, getters, rootState, rootGetters) => {
      if (rootGetters.isRndig) {
         return false;
      }

      const anyCompanyQs = getters.actionItems.company.length > 0;
      const anyProjectQs = getters.actionItems.project.length > 0;

      return anyCompanyQs || anyProjectQs;
   },

   /** Does the current user have any contractor permissions? */
   hasContractorAssignment: (state, getters, rootState, rootGetters) => {
      if (rootGetters.isRndig) {
         return false;
      }

      const currentAssignments = rootGetters['contractors/currentAssignments'];
      const userId = rootGetters.profile.id;
      return currentAssignments.some((assignment) => assignment.assigneeId === userId);
   },

   hasSuppliesAssignment: (state, getters, rootState, rootGetters) => {
      if (rootGetters.isRndig) {
         return false;
      }

      return getters.priorityActionItems.some((item) => {
         return [
            ActionItemTypes.SUPPLIES_SUBMIT,
            ActionItemTypes.SUPPLIES_DECLARATION,
            ActionItemTypes.SUPPLIES_UPLOAD,
            ActionItemTypes.SUPPLIES_DATA,
         ].includes(item.action);
      });
   },

   /** Does the current user have any time survey assignments? */
   hasTimeSurveyAssignment: (state, getters, rootState, rootGetters) => {
      if (rootGetters.isRndig) {
         return false;
      }

      return getters.actionItems.timeSurvey.some((item) => {
         return [
            ActionItemTypes.TIME_SURVEY_DATA,
            ActionItemTypes.TIME_SURVEY_DATA_FINISHED,
         ].includes(item.action);
      });
   },

   hasCloudAssignment: (state, getters, rootState, rootGetters) => {
      if (rootGetters.isRndig || state.rawData === null) {
         return false;
      }

      const assigneeId = rootGetters['cloud/assignee'];
      const userId = state.rawData.user.id;
      return assigneeId === userId;
   },
};

const mutations = {
   /** Store the incoming data */
   setData(state, {data}) {
      state.rawData = data;
      state.loaded = true;
   },
};

const actions = {
   /** Load progress data for a company */
   async loadProgress({commit}, {companyId}) {
      const data = (await this._vm.$http.get(`/api/company/${companyId}/progress`)).data;
      commit('setData', {
         data,
      });
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
