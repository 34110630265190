<template>
   <b-container>
      <h1 class="mb-3">Configure Qualified Activities</h1>
      <b-card>
         <div class="d-flex align-items-center justify-content-end cell-w-buttons">
            <b-button
               id="btn-import-industry-defaults"
               variant="primary"
               size="sm"
               class="action-btn mt-3 mr-3"
               @click="openImportActivitiesModal"
            >
               Import Industry Defaults
            </b-button>

            <b-button
               id="btn-add-activity"
               variant="primary"
               size="sm"
               class="action-btn mt-3"
               @click="createActivityModal"
            >
               New Qualified Activity
            </b-button>
         </div>

         <b-table
            id="table-edit-activities"
            :fields="[
               {key: 'name', sortable: true},
               {key: 'description'},
               {key: 'actions', label: ''},
            ]"
            class="mb-0"
            sort-by="name"
            :items="activities"
         >
            <template #cell(name)="data">
               <b>{{ data.value }}</b>
            </template>

            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button
                     v-if="isStaff"
                     :id="`btn-delete-${data.index + 1}`"
                     class="mr-2"
                     variant="danger"
                     size="sm"
                     @click="deleteActivityModal(data.item)"
                  >
                     Delete
                  </b-button>
                  <b-button
                     :id="`btn-edit-${data.index + 1}`"
                     variant="primary"
                     size="sm"
                     @click="editActivityModal(data.item)"
                  >
                     Edit
                  </b-button>
               </div>
            </template>
         </b-table>
      </b-card>

      <b-modal
         id="modal-new-activity"
         title="New Qualified Activity"
         centered
         @ok="submitNewActivity"
         ok-title="Submit"
         :ok-disabled="$v.newActivity.$invalid"
         @shown="focusName"
      >
         <b-form @submit.prevent="submitNewActivity">
            <b-form-group
               label="Qualified Activity Name"
               label-for="input-activity-name"
               invalid-feedback="An activity with this name already exists"
            >
               <template #label>
                  Qualified Activity Name <span class="text-danger">*</span>
               </template>
               <b-form-input
                  id="input-activity-name"
                  v-model="newActivity.name"
                  :state="newActivity.name && $v.newActivity.$invalid ? false : null"
                  ref="newName"
               ></b-form-input>
            </b-form-group>

            <b-form-group
               label="Qualified Activity Description"
               label-for="input-activity-description"
            >
               <b-form-textarea
                  id="input-activity-description"
                  v-model="newActivity.description"
                  ref="newDescription"
                  rows="4"
               ></b-form-textarea>
            </b-form-group>
         </b-form>
      </b-modal>

      <b-modal
         id="modal-edit-activity"
         title="Edit Qualified Activity"
         centered
         @ok="submitEditActivity"
         ok-title="Submit"
         :ok-disabled="$v.editActivity.$invalid"
      >
         <b-form @submit.prevent="submitEditActivity">
            <b-form-group
               label="Qualified Activity Name"
               label-for="input-activity-name"
               invalid-feedback="An activity with this name already exists"
            >
               <template #label>
                  Qualified Activity Name <span class="text-danger">*</span>
               </template>
               <b-form-input
                  id="input-activity-name"
                  v-model="editActivity.name"
                  :state="editActivity.name && $v.editActivity.$invalid ? false : null"
               ></b-form-input>
            </b-form-group>

            <b-form-group
               label="Qualified Activity Description"
               label-for="input-activity-description"
            >
               <b-form-textarea
                  id="input-activity-description"
                  v-model="editActivity.description"
                  rows="4"
               ></b-form-textarea>
            </b-form-group>
         </b-form>
      </b-modal>

      <b-modal
         id="modal-delete-activity"
         title="Delete Qualified Activity"
         centered
         @ok="submitDeleteActivity"
         ok-title="Delete"
         ok-variant="danger"
      >
         Are you sure you want to delete qualified activity <b>{{ deleteActivity.name }}</b
         >?
      </b-modal>

      <b-modal
         id="modal-import-activities"
         title="Import Industry Default Qualified Activities"
         centered
         @ok="submitImportActivities"
         :ok-disabled="importIndustry.activitiesForImport.length === 0"
         @shown="toggleBoxes()"
         ok-title="Import"
         size="xl"
      >
         <b-form @submit.prevent="submitImportActivities">
            <b-form-group
               label="Select an industry to import its qualified activities:"
               label-for="input-industry"
               class="mb-4"
            >
               <b-form-select
                  v-model="importIndustry.id"
                  :options="industryOptions"
                  @change="toggleBoxes()"
               >
                  <template #first>
                     <b-form-select-option :value="null">Please select one</b-form-select-option>
                  </template>
               </b-form-select>
            </b-form-group>

            <div v-if="nonConflictingActivitiesToImport.length > 0" class="mb-4">
               <h3>New Qualified Activities</h3>
               <p>
                  The following new qualified activities will be added to this company. Use the
                  checkboxes to omit any qualified activity you don't want to add.
               </p>

               <b-table
                  id="table-import-new-activities"
                  :fields="[
                     {
                        key: 'name',
                        formatter: (value, key, item) => item[0],
                        sortByFormatted: true,
                     },
                     {key: 'description', formatter: (value, key, item) => item[1]},
                  ]"
                  sort-by="name"
                  :items="nonConflictingActivitiesToImport"
               >
                  <template #cell(name)="data">
                     <b-form-checkbox
                        :id="`non-conflict-activity-checkbox-${data.index}`"
                        :name="`non-conflict-activity-checkbox-${data.index}`"
                        :value="data.value"
                        v-model="importIndustry.activitiesForImport"
                     >
                        <b>{{ data.value }}</b>
                     </b-form-checkbox>
                  </template>
               </b-table>
            </div>

            <b-form-group v-if="conflictingActivitiesToImport.length > 0">
               <h3>Conflicting Qualified Activities</h3>
               <p>
                  The following qualified activities conflict with existing qualified activities for
                  this company. Select any qualified activities for which you'd like to update the
                  description to match the industry default.
               </p>

               <template>
                  <b-table
                     id="table-import-conflicting-activities"
                     :fields="[
                        {
                           key: 'name',
                           formatter: (value, key, item) => item[0],
                           sortByFormatted: true,
                        },
                        {key: 'description', formatter: (value, key, item) => item[1]},
                     ]"
                     sort-by="name"
                     :items="conflictingActivitiesToImport"
                  >
                     <template #cell(name)="data">
                        <b-form-checkbox
                           :id="`conflict-activity-checkbox-${data.index}`"
                           :name="`conflict-activity-checkbox-${data.index}`"
                           :value="data.value"
                           v-model="importIndustry.activitiesForImport"
                        >
                           <b>{{ data.value }}</b>
                        </b-form-checkbox>
                     </template>
                  </b-table>
               </template>
            </b-form-group>

            <b-alert
               variant="primary"
               :show="
                  conflictingActivitiesToImport.length === 0 &&
                  nonConflictingActivitiesToImport.length === 0
               "
            >
               No qualified activities found
            </b-alert>
         </b-form>
      </b-modal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';

import ErrorCodes from '@/helpers/errorCodes';

export default {
   data() {
      return {
         newActivity: {
            name: '',
            description: '',
         },
         editActivity: {
            id: null,
            name: null,
            description: null,
         },
         deleteActivity: {
            id: null,
            name: null,
         },
         importIndustry: {
            id: null,
            activitiesForImport: [],
         },
      };
   },

   computed: {
      ...mapGetters({
         company: 'companies/currentCompany',
         isStaff: 'isStaff',
         activities: 'activities/activities',
         industries: 'industries/industries',
         industryMap: 'industries/industryMap',
      }),

      activitiesToImport() {
         const industryId = this.importIndustry.id;
         if (industryId in this.industryMap) {
            return Object.entries(this.industryMap[industryId].activities).toSorted();
         }
         return [];
      },

      nonConflictingActivitiesToImport() {
         const myActivities = new Set(this.activities.map((a) => a.name));
         return this.activitiesToImport.filter(([name]) => !myActivities.has(name));
      },

      companyId() {
         return this.$route.params.id;
      },

      conflictingActivitiesToImport() {
         const myActivities = new Set(this.activities.map((a) => a.name));
         return this.activitiesToImport.filter(([name]) => myActivities.has(name));
      },

      industryOptions() {
         return this.industries.map(({name, id}) => ({
            text: name,
            value: id,
         }));
      },
   },

   methods: {
      resetNewActivity() {
         this.newActivity = {name: '', description: ''};
      },

      createActivityModal() {
         this.resetNewActivity();
         this.$bvModal.show('modal-new-activity');
      },

      /** Focus the name input in the create activity modal */
      focusName() {
         this.$refs.newName.focus();
      },

      toggleBoxes() {
         this.importIndustry.activitiesForImport = this.nonConflictingActivitiesToImport.map(
            ([name]) => name
         );
      },

      /** Open the Import Activities modal */
      openImportActivitiesModal() {
         const company = this.company;
         this.importIndustry.id = company.industryId;
         this.$bvModal.show('modal-import-activities');
      },

      async submitImportActivities() {
         await this.blockingRequest('activities/importFromIndustry', {
            companyId: this.companyId,
            industryId: this.importIndustry.id,
            activities: this.importIndustry.activitiesForImport,
         });
      },

      async submitNewActivity() {
         try {
            await this.blockingRequest('activities/saveNewActivity', {
               companyId: this.companyId,
               activity: this.newActivity,
            });
            this.resetNewActivity();
         } catch (err) {
            const msg = err.response.data.errors[0].detail;
            if (msg.includes('UniqueViolationError')) {
               this.$store.commit('showAlert', {
                  msg: 'A qualified activity with that name already exists',
                  seconds: 5,
               });
            } else {
               this.$store.commit('showAlert', {
                  response: err.response,
                  fallbackMsg: 'Submission failed',
                  seconds: 5,
               });
            }
         }
      },

      editActivityModal(activity) {
         this.editActivity = {...activity};
         this.$bvModal.show('modal-edit-activity');
      },

      async submitEditActivity() {
         try {
            await this.blockingRequest('activities/editActivity', {
               activity: this.editActivity,
            });
         } catch (err) {
            const msg = err.response.data.errors[0].detail;
            if (msg.includes('UniqueViolationError')) {
               this.$store.commit('showAlert', {
                  msg: 'A qualified activity with that name already exists',
                  seconds: 5,
               });
            } else {
               this.$store.commit('showAlert', {
                  response: err.response,
                  fallbackMsg: 'Submission failed',
                  seconds: 5,
               });
            }
         }
      },

      async deleteActivityModal(activity) {
         try {
            await this.blockingRequest('activities/deleteActivity', {
               activityId: activity.id,
            });
            this.deleteActivity = activity;
            this.$bvModal.show('modal-delete-activity');
         } catch (err) {
            const errCode = err.response.data.errors[0].code;
            if (errCode === ErrorCodes.CANNOT_DELETE) {
               this.$bvModal.msgBoxOk(
                  'The selected qualified activity has related data, and cannot be deleted by a staff user. Contact an admin user to delete this qualified activity.',
                  {
                     title: 'Qualified Activity Has Related Data',
                     centered: true,
                  }
               );
            }
         }
      },

      async submitDeleteActivity() {
         await this.blockingRequest('activities/deleteActivity', {
            activityId: this.deleteActivity.id,
            force: true,
         });
      },
   },

   validations() {
      return {
         newActivity: {
            name: {
               required,
               isUnique(name) {
                  return !this.activities.some((activity) => activity.name === name);
               },
            },
         },
         editActivity: {
            name: {
               required,
               isUnique(name, thisActivity) {
                  return !this.activities
                     .filter((activity) => activity.id !== thisActivity.id)
                     .some((activity) => activity.name === name);
               },
            },
         },
         importIndustry: {
            id: {required},
         },
      };
   },

   async created() {
      const requests = [
         this.$store.dispatch('activities/loadActivities', {companyId: this.companyId}),
         this.$store.dispatch('industries/loadIndustries'),
      ];
      await this.blockUntilAllSettled(requests);
   },
};
</script>
<style lang="scss" scoped>
::v-deep legend {
   border-bottom: 1px solid #ccc;
   padding: 0;
   margin-bottom: 1rem;
}
</style>
