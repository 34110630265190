<template>
   <div class="tcp-input-group" :class="{'has-prepend': hasPrepend, 'has-append': hasAppend}">
      <div class="prepend">
         <slot name="prepend">
            {{ prepend }}
         </slot>
      </div>

      <slot></slot>

      <div class="append">
         <slot name="append">
            {{ append }}
         </slot>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      prepend: {
         type: String,
         default: null,
      },

      append: {
         type: String,
         default: null,
      },
   },

   computed: {
      hasPrepend() {
         return !!this.$slots.prepend || this.prepend;
      },

      hasAppend() {
         return !!this.$slots.append || this.append;
      },
   },
};
</script>

<style lang="scss" scoped>
.tcp-input-group {
   position: relative;
   color: $gray-700;

   &.has-prepend {
      ::v-deep input {
         padding-left: 1.375rem;
      }
   }

   &.has-append {
      ::v-deep input {
         padding-right: 1.375rem;
      }
   }

   .prepend {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      padding-left: 0.5rem;
      pointer-events: none;
   }

   .append {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      padding-right: 0.5rem;
      pointer-events: none;
   }
}
</style>
