var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-create-client","title":"Invite Another User","size":"xl","centered":"","ok-disabled":_vm.$v.$invalid,"ok-variant":"success","ok-title":"Invite","ok-only":""},on:{"ok":_vm.submit,"show":_vm.onShow}},[_c('p',{staticClass:"text-instruction mb-4"},[_vm._t("default",[_vm._v(" Invite someone else in your organization to help you enter information in this section. This user will only be able to see and enter info for the areas you assign to them. ")])],2),_vm._l((_vm.users),function(user,index){return _c('div',{key:user.key,staticClass:"form-section"},[_c('b-form-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":("input-first-name-" + index),"invalid-feedback":"Required"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" First Name ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":("input-first-name-" + index),"value":user.firstName,"state":_vm.$v.users.$each[index].firstName.$invalid ? false : null},on:{"input":function (value) { return _vm.$store.commit('users/updateNewUser', {
                           index: index,
                           field: 'firstName',
                           value: value,
                        }); }}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":("input-last-name-" + index),"invalid-feedback":"Required"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Last Name ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":("input-last-name-" + index),"value":user.lastName,"state":_vm.$v.users.$each[index].lastName.$invalid ? false : null},on:{"input":function (value) { return _vm.$store.commit('users/updateNewUser', {
                           index: index,
                           field: 'lastName',
                           value: value,
                        }); }}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":("input-email-" + index),"invalid-feedback":"Enter a valid email"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":("input-email-" + index),"value":user.email,"state":_vm.$v.users.$each[index].email.$invalid ? false : null},on:{"input":function (value) { return _vm.$store.commit('users/updateNewUser', {
                           index: index,
                           field: 'email',
                           value: value,
                        }); }}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Job Title","label-for":("input-title-" + index)}},[_c('b-form-input',{attrs:{"id":("input-title-" + index),"list":"datalist-titles","autocomplete":"off","value":user.title},on:{"input":function (value) { return _vm.$store.commit('users/updateNewUser', {
                           index: index,
                           field: 'title',
                           value: value,
                        }); }}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Department","label-for":("input-department-" + index)}},[_c('b-form-input',{attrs:{"id":("input-department-" + index),"list":"datalist-departments","autocomplete":"off","value":user.department},on:{"input":function (value) { return _vm.$store.commit('users/updateNewUser', {
                           index: index,
                           field: 'department',
                           value: value,
                        }); }}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Subsidiary Company","label-for":("input-subsidiary-" + index)}},[_c('b-form-input',{attrs:{"id":("input-subsidiary-" + index),"value":user.subsidiary,"list":"datalist-subsidiaries","autocomplete":"off"},on:{"input":function (value) { return _vm.$store.commit('users/updateNewUser', {
                           index: index,
                           field: 'subsidiary',
                           value: value,
                        }); }}})],1)],1)],1),(_vm.users.length > 1)?_c('div',{staticClass:"form-section-delete",on:{"click":function($event){return _vm.removeUser(index)}}},[_vm._v(" Remove user "),_c('b-icon-x',{staticClass:"ml-1",attrs:{"font-scale":1.3}})],1):_vm._e()],1)}),_c('datalist',{attrs:{"id":"datalist-titles"}},_vm._l((_vm.companyTitles),function(title){return _c('option',{key:title},[_vm._v(" "+_vm._s(title)+" ")])}),0),_c('datalist',{attrs:{"id":"datalist-departments"}},_vm._l((_vm.companyDepartments),function(department){return _c('option',{key:department},[_vm._v(" "+_vm._s(department)+" ")])}),0),_c('datalist',{attrs:{"id":"datalist-subsidiaries"}},_vm._l((_vm.companySubsidiaries),function(subsidiary){return _c('option',{key:subsidiary},[_vm._v(" "+_vm._s(subsidiary)+" ")])}),0)],2)}
var staticRenderFns = []

export { render, staticRenderFns }