const state = () => ({
   stages: [
      {
         id: 'dashboard',
         name: 'Dashboard',
         icon: 'icon-dashboard',
         active: false,
         route: 'Home',
         requirements: {
            appState: ['isAppConfigured'],
            customer: [],
            sme: [],
         },
      },
      {
         id: 'get-started',
         name: 'What Qualifies?',
         icon: 'icon-target',
         active: false,
         route: 'get-started-stage',
         requirements: {
            appState: [],
            customer: [],
            sme: [],
         },
      },
      {
         id: 'time',
         name: 'Employee Time',
         icon: 'icon-chronometer',
         active: false,
         route: 'time-survey-base',
         requirements: {
            appState: ['isAppConfigured'],
            customer: [],
            sme: ['progress/hasTimeSurveyAssignment'],
         },
      },
      {
         id: 'contractors',
         name: 'Contract Research',
         icon: 'icon-contractor',
         active: false,
         route: 'contractors-home',
         requirements: {
            appState: ['isAppConfigured'],
            customer: [],
            sme: ['progress/hasContractorAssignment'],
         },
      },
      {
         id: 'supplies',
         name: 'Supplies',
         icon: 'icon-supplies',
         active: false,
         route: 'supplies-projects',
         requirements: {
            appState: ['isAppConfigured'],
            customer: [],
            sme: ['progress/hasSuppliesAssignment'],
         },
      },
      {
         id: 'cloud',
         name: 'Cloud Computing',
         icon: 'icon-cloud-computing',
         active: false,
         route: 'cloud-declaration',
         requirements: {
            appState: ['isAppConfigured'],
            customer: [],
            sme: ['progress/hasCloudAssignment'],
         },
      },
      {
         id: 'questionnaire',
         name: 'Questionnaires',
         icon: 'b-clipboard-check',
         active: false,
         route: 'questionnaires',
         requirements: {
            appState: ['isAppConfigured', 'progress/hasQuestionnaireAssignment'],
            customer: [],
            sme: [],
         },
      },
      {
         id: 'uploads',
         name: 'Documents', // Uploads were renamed to Documents in #838
         icon: 'icon-uploads',
         active: false,
         route: 'uploads-stage',
         requirements: {
            appState: [],
            customer: [],
            sme: [],
         },
      },
   ],
});

const getters = {
   /** Stages currently accessible to the current user */
   stages: (state, getters, rootState, rootGetters) => {
      return state.stages.filter((stage) => {
         let include = true;

         const requirements = [
            ...stage.requirements.appState,
            ...(getters.isCustomer ? stage.requirements.customer : []),
            ...(getters.isSME ? stage.requirements.sme : []),
         ];

         requirements.forEach((item) => {
            include = include && rootGetters[item];
         });

         return include;
      });
   },

   /** The currently active stage */
   activeStage: (state, getters) => {
      const active = getters.stages.find((stage) => stage.active);
      return active ? active : null;
   },

   /** The stage immediately after the current active stage */
   nextStage: (state, getters) => {
      if (getters.activeStage === null || getters.activeStage.id === 'dashboard') {
         return null;
      }

      const idx = getters.stages.indexOf(getters.activeStage);
      if (idx < getters.stages.length - 1) {
         return getters.stages[idx + 1];
      }
      return null;
   },

   /** The stage immediately preceding the current active stage */
   previousStage: (state, getters) => {
      if (getters.activeStage === null || getters.activeStage.id === 'dashboard') {
         return null;
      }

      const idx = getters.stages.indexOf(getters.activeStage);
      if (idx > 1) {
         // The first stage (dashboard) has no previous, and is itself
         // not considered previous to the second stage. Start considering
         // stages as a sequence from getters.stages[1]
         return getters.stages[idx - 1];
      }
      return null;
   },
};

const mutations = {
   setActiveStage(state, id) {
      state.stages.forEach((stage) => {
         stage.active = stage.id === id;
      });
   },
};

export default {
   state,
   getters,
   mutations,
};
