<template>
   <div>
      <b-row class="test-card-row">
         <b-col cols="6" xl="3" class="mb-3" v-for="(part, index) in testParts" :key="part.name">
            <b-card
               class="test-part-card"
               :class="{inactive: active !== null && active !== index}"
               @click="cardSelected(index)"
            >
               <h4 class="my-auto">{{ part.name }}</h4>
               <icon-base class="icon" :width="120" :height="120" :viewBox="viewBox(part.icon)">
                  <component :is="part.icon" />
               </icon-base>
            </b-card>
         </b-col>
      </b-row>
   </div>
</template>

<script>
export default {
   props: {
      active: {
         type: Number,
         default: null,
      },
   },

   data() {
      return {
         testParts: [
            {
               name: 'Permitted Purpose',
               icon: 'icon-target',
            },
            {
               name: 'Technological in Nature',
               icon: 'icon-science',
            },
            {
               name: 'Elimination of Uncertainty',
               icon: 'icon-uncertain',
            },
            {
               name: 'Process of Experimentation',
               icon: 'icon-experiment',
            },
         ],
      };
   },

   methods: {
      /** Returns an appropriate viewBox for the svg icon */
      viewBox(icon) {
         if (icon === 'icon-target') {
            return '0 0 24 25';
         }
         return '0 0 120 120';
      },

      cardSelected(index) {
         this.$emit('selection', index);
      },
   },
};
</script>
