import store from '@/store';

/**
 * Generate both a client and staff version of a route. For the staff version of the route,
 * the path gets `/company/:id` prepended, and the route name gets `-staff` appended.
 * @param name - Route name
 * @param path - Path for the client route
 * @param component - Route component
 * @param stage - Stage for the client route
 * @param breadCrumb - Breadcrumb for the staff route
 */
export const clientStaffRoutes = ({
   name,
   path,
   component,
   stage,
   breadCrumb,
   loadCompany = false,
   clientBeforeEnter = null,
}) => {
   const clientRoute = {
      name,
      path,
      component,
      meta: {
         stage,
         authGroup: 'client',
      },
   };
   const staffRoute = {
      name: `${name}-staff`,
      path: `/company/:id${path}`,
      component,
      meta: {
         authGroup: 'rndig',
         breadCrumb,
      },
   };

   if (loadCompany) {
      staffRoute.beforeEnter = async (to, from, next) => {
         const {params} = to;
         let requests = [];

         requests.push(
            store.dispatch('companies/loadCompany', {
               companyId: params.id,
            })
         );
         await Promise.all(requests);

         next();
      };
   }

   if (clientBeforeEnter) {
      clientRoute.beforeEnter = clientBeforeEnter;
   }

   return [clientRoute, staffRoute];
};
