<template>
   <g>
      <ellipse cx="9" cy="9.5" rx="6" ry="6.5" fill="currentColor" />
      <path
         fill-rule="evenodd"
         clip-rule="evenodd"
         d="M11.0397 9.12183C11.1423 9.22042 11.2 9.35413 11.2 9.49354C11.2 9.63295 11.1423 9.76666 11.0397 9.86525L7.94158 12.8395C7.89106 12.8897 7.83063 12.9298 7.76381 12.9573C7.69699 12.9849 7.62513 12.9994 7.55241 13C7.4797 13.0006 7.40758 12.9873 7.34028 12.9608C7.27297 12.9344 7.21182 12.8954 7.1604 12.846C7.10898 12.7966 7.06832 12.7379 7.04078 12.6733C7.01325 12.6087 6.99939 12.5395 7.00002 12.4697C7.00065 12.3999 7.01576 12.3309 7.04446 12.2667C7.07316 12.2026 7.11489 12.1446 7.16719 12.0961L9.87808 9.49354L7.16719 6.89102C7.06743 6.79186 7.01223 6.65905 7.01348 6.52119C7.01473 6.38334 7.07232 6.25147 7.17386 6.15399C7.2754 6.05651 7.41276 6.00122 7.55635 6.00002C7.69995 5.99882 7.83829 6.05182 7.94158 6.14759L11.0397 9.12183Z"
         fill="white"
      />
      <path
         fill-rule="evenodd"
         clip-rule="evenodd"
         d="M2 9.4375C2 5.57159 5.13409 2.4375 9 2.4375C12.8659 2.4375 16 5.57159 16 9.4375C16 13.3034 12.8659 16.4375 9 16.4375C5.13409 16.4375 2 13.3034 2 9.4375ZM9 15.1648C8.24788 15.1648 7.50313 15.0166 6.80827 14.7288C6.1134 14.441 5.48203 14.0191 4.95021 13.4873C4.41838 12.9555 3.99651 12.3241 3.70869 11.6292C3.42087 10.9344 3.27273 10.1896 3.27273 9.4375C3.27273 8.68538 3.42087 7.94063 3.70869 7.24577C3.99651 6.5509 4.41838 5.91953 4.95021 5.38771C5.48203 4.85588 6.1134 4.43401 6.80827 4.14619C7.50313 3.85837 8.24788 3.71023 9 3.71023C10.519 3.71023 11.9757 4.31363 13.0498 5.38771C14.1239 6.46178 14.7273 7.91853 14.7273 9.4375C14.7273 10.9565 14.1239 12.4132 13.0498 13.4873C11.9757 14.5614 10.519 15.1648 9 15.1648Z"
         fill="currentColor"
      />
   </g>
</template>

<script>
export default {
   data() {
      return {
         name: 'icon-circle-chevron',
         viewBox: '0 0 18 19',
         fill: 'none',
      };
   },
};
</script>
