/* eslint-disable no-empty-pattern */
import Vue from 'vue';

class ThreadSummary {
   constructor({thread, latestMsgTs = null, count = 0, seen = 0}) {
      this.thread = thread;
      this.latestMsgTs = latestMsgTs;
      this.count = count;
      this.seen = seen;
   }

   get unread() {
      return this.count - this.seen;
   }
}

const state = () => ({
   threads: {},
   threadId: null,
   messages: [],
   clientMessagingCollapsed: true,
   clientMessagingDetailCollapsed: false,
});

const getters = {
   threadMap: (state) => state.threads,
   threads: (state) =>
      Object.values(state.threads).sort((a, b) => {
         const aUnread = a.unread > 0;
         const bUnread = b.unread > 0;
         const aTime = new Date(a.latestMsgTs);
         const bTime = new Date(b.latestMsgTs);

         // Sort unread messages to the top
         if (aUnread !== bUnread) {
            return aUnread ? -1 : 1;
         }

         // Secondly, sort newest messages to the top
         if (aTime < bTime) {
            return 1;
         } else if (aTime > bTime) {
            return -1;
         }

         return 0;
      }),

   threadId: (state) => state.threadId,

   currentThread: (state) => {
      if (state.threadId in state.threads) {
         return state.threads[state.threadId];
      }
      return null;
   },

   messages: (state) => state.messages,

   clientMessagingCollapsed: (state, getters, rootState, rootGetters) => {
      if (rootGetters['isRndig']) {
         return false;
      }
      return state.clientMessagingCollapsed;
   },

   clientMessagingDetailCollapsed: (state) => state.clientMessagingDetailCollapsed,

   /** Do any threads (that this user knows about) have any unread messaages? */
   anyUnreadMessages: (state) => {
      return Object.values(state.threads).some((tSummary) => tSummary.unread > 0);
   },
};

const mutations = {
   setThreads(state, {threads}) {
      threads.forEach((tSummary) => {
         Vue.set(state.threads, tSummary.thread.id, new ThreadSummary(tSummary));
      });
   },

   setCurrentThread(state, {threadId}) {
      state.threadId = threadId;
   },

   clearMessages(state) {
      state.messages.splice(0);
   },

   setMessages(state, {messages}) {
      messages.forEach((message) => {
         state.messages.push(message);
      });
   },

   toggleClientMessagingCollapsed(state) {
      state.clientMessagingCollapsed = !state.clientMessagingCollapsed;

      // Toggle class on <body> for dynamic styling of modals
      document.body.classList.toggle('msg-expanded', !state.clientMessagingCollapsed);
   },

   toggleClientMessagingDetailCollapsed(state) {
      state.clientMessagingDetailCollapsed = !state.clientMessagingDetailCollapsed;
   },
};

const actions = {
   /** Initialize the messaging service */
   async launch({rootGetters}) {
      if (rootGetters['isClient']) {
         this._vm.$websocket.threadMsgs();
      }
   },

   /** Load all messages in a thread */
   loadMessages({}, {threadId, callback = null}) {
      this._vm.$websocket.threadMsgs({threadId, callback});
   },

   /** Post a new message to a thread */
   postMessage({}, {content, threadId}) {
      this._vm.$websocket.postMessage(threadId, content);
   },

   /** Update the `seen` state on a message */
   updateMessage({state}, {message, seen}) {
      this._vm.$websocket.updateMessage({messageId: message.id, seen});

      // Update thread count
      if (message.seen !== seen) {
         const delta = seen ? 1 : -1;
         if (message.threadId in state.threads) {
            state.threads[message.threadId].seen += delta;
         }
      }
      message.seen = seen;
   },

   /**
    * Handle an incoming message
    * @param {Object} message - A message object from the server
    * @param {*} thread - The thread object this message belongs to
    */
   newMessage({state, rootGetters, dispatch}, {message, thread}) {
      const isClient = rootGetters.isClient;
      let threadSummary;

      if (!(thread.id in state.threads)) {
         threadSummary = new ThreadSummary({thread});
         Vue.set(state.threads, thread.id, threadSummary);
      } else {
         threadSummary = state.threads[thread.id];
      }

      // The first time a client user sends a message, store the thread ID
      if (isClient && !state.threadId) {
         state.threadId = thread.id;
      }

      // Update the message count and timestamp
      threadSummary.count++;
      threadSummary.latestMsgTs = message.timestamp;

      // Determine if the user will immediately see this message
      let seeingMessages;
      if (isClient) {
         seeingMessages = !state.clientMessagingCollapsed;
      } else {
         seeingMessages = thread.id === state.threadId;
      }

      // Update the message `seen` state and the seen message count
      if (seeingMessages && !message.seen) {
         dispatch('updateMessage', {
            message: message,
            seen: true,
         });
      } else if (message.seen) {
         threadSummary.seen++;
      }

      if (state.threadId === message.threadId) {
         state.messages.push(message);
      }
   },

   /**
    * Set the `seen` state to true on every message in the current thread
    */
   seeAllMessages({state, dispatch}) {
      state.messages.forEach((message) => {
         if (!message.seen) {
            dispatch('updateMessage', {
               message: message,
               seen: true,
            });
         }
      });
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
