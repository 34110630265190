import Vue from 'vue';

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';

Vue.use(BootstrapVue, {
   BModal: {
      noEnforceFocus: true,
   },
});
Vue.use(IconsPlugin);
