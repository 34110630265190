<template>
   <div>
      <b-table
         :id="categoryTableId"
         :fields="fields"
         sort-by="name"
         sort-icon-left
         :items="filteredCategories"
         class="mb-0"
      >
         <template #head(actions)>
            <div class="d-flex justify-content-end">
               <b-button
                  v-if="!internal"
                  variant="primary"
                  size="sm"
                  class="action-btn"
                  @click="onNewCategory"
               >
                  New Category
               </b-button>
            </div>
         </template>

         <template #head()="data">
            <div class="d-flex justify-content-between align-items-center" style="height: 31px">
               {{ data.label }}
            </div>
         </template>

         <template #cell(name)="data">
            <b-icon-circle-fill
               class="mr-2"
               :variant="uploadStatus(data.item).variant"
            ></b-icon-circle-fill>
            {{ data.value }}
         </template>

         <template #cell(actions)="data">
            <div class="d-flex justify-content-end">
               <button
                  v-if="isStaff && uploadStatus(data.item).text === 'Not Started' && !internal"
                  class="icon-btn icon-btn-danger mr-3"
                  v-b-tooltip="'Delete Category'"
                  @click="onDelete(data.item)"
               >
                  <b-icon-trash :id="`btn-delete-category-${data.index}`" font-scale="0.9" />
               </button>
               <button
                  v-if="data.item.completedAt === null && !internal"
                  class="icon-btn icon-btn-secondary mr-3"
                  @click="onUpload(data.item)"
               >
                  <b-icon-upload></b-icon-upload>
               </button>
               <button
                  v-if="!internal"
                  class="icon-btn icon-btn-secondary mr-3"
                  @click="onLock(data.item)"
               >
                  <b-icon :icon="data.item.completedAt ? 'lock' : 'unlock'"></b-icon>
               </button>
               <button
                  v-if="!internal"
                  class="icon-btn icon-btn-secondary mr-3 pt-1"
                  @click="onEdit(data.item)"
               >
                  <b-icon-pencil-square></b-icon-pencil-square>
               </button>
               <button class="icon-btn icon-btn-secondary ml-3" @click="data.toggleDetails">
                  <b-icon
                     :icon="data.detailsShowing ? 'caret-up-fill' : 'caret-down-fill'"
                  ></b-icon>
               </button>
            </div>
         </template>

         <template #row-details="row">
            <b-row v-if="row.item.summary && row.item.summary.length > 0" class="mb-2">
               <b-col cols="2">
                  <h5 class="text-center">Year</h5>
               </b-col>
               <b-col cols="10">
                  <h5>Files</h5>
               </b-col>
            </b-row>
            <transition-group name="list">
               <b-row v-for="(item, index) in summaryItems(row.item.summary)" :key="`${item.id}`">
                  <b-col cols="2" class="d-flex align-items-center justify-content-center">
                     <YearSelect
                        :value="item.year"
                        @input="(value) => onUpdateYear(item, value)"
                        tax-years
                        allow-empty
                        empty-text="None"
                        :disabled="internal"
                     />
                  </b-col>
                  <b-col cols="10">
                     <b-list-group-item>
                        <div class="d-flex align-items-center justify-content-between mb-1">
                           <div class="d-flex align-items-center">
                              <h6 class="d-inline-block mb-0">
                                 {{ item.name }}
                                 <span v-if="item.year" class="text-muted" style="font-size: 14px">
                                    ({{ item.year }})
                                 </span>
                              </h6>
                              <b-badge
                                 class="ml-2 mt-1"
                                 :variant="item.isLatest ? 'primary' : 'gray'"
                                 pill
                              >
                                 v{{ item.version }}
                              </b-badge>
                           </div>
                           <small class="text-muted">{{ formatBytes(item.size) }}</small>
                        </div>
                        <div>
                           <small v-if="!!item.description">{{ item.description }}</small>
                           <small v-else class="text-muted">No description</small>
                           <b-button
                              v-if="!internal"
                              :id="`btn-edit-description-${index}`"
                              class="ml-2 pt-1"
                              size="xs"
                              @click="showEditDescriptionModal(item)"
                           >
                              <b-icon-pencil-square></b-icon-pencil-square>
                           </b-button>
                        </div>
                        <div class="d-flex justify-content-between align-items-end">
                           <small class="text-muted">
                              Uploaded {{ formatDatetime(item.uploadedAt) }}
                           </small>

                           <div class="d-flex align-items-center">
                              <button
                                 v-if="!internal"
                                 :id="`btn-delete-file-${index}`"
                                 class="icon-btn icon-btn-danger mr-2"
                                 @click="deleteFile(row.item.id, item.id)"
                              >
                                 <b-icon-trash />
                              </button>
                              <button
                                 class="icon-btn icon-btn-secondary"
                                 @click="downloadFile(item.id)"
                              >
                                 <b-icon-cloud-download />
                              </button>
                           </div>
                        </div>
                     </b-list-group-item>
                  </b-col>
               </b-row>
            </transition-group>

            <b-alert
               variant="primary"
               :show="(row.item.summary && row.item.summary.length < 1) || !row.item.summary"
            >
               No files have been uploaded to this category.
            </b-alert>
         </template>
      </b-table>

      <b-modal
         v-if="!internal"
         id="modal-edit-description"
         :title="`Edit Description for ${editDescriptionTarget && editDescriptionTarget.name}`"
         centered
         ok-title="Save"
         @ok="() => onUpdateDescription(editDescriptionTarget, this.updatedDescription)"
      >
         <b-form-input
            type="text"
            id="input-description"
            v-model="updatedDescription"
         ></b-form-input>
      </b-modal>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

import {formatBytes} from '@/helpers/utils';
import YearSelect from '@/components/forms/YearSelect';

export default {
   name: 'ConfigureUploads',

   components: {
      YearSelect,
   },

   data() {
      return {
         editDescriptionTarget: null,
         updatedDescription: null,
         fields: [
            {key: 'name', sortable: true},
            {
               key: 'status',
               class: 'no-wrap',
               formatter: (value, key, item) => this.uploadStatus(item).text,
               sortable: true,
               sortByFormatted: true,
            },
            {key: 'actions', label: ''},
         ],
      };
   },

   props: {
      internal: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      ...mapGetters({
         isStaff: 'isStaff',
         categories: 'uploads/uploadCategories',
      }),

      categoryTableId() {
         return this.internal
            ? 'table-configure-internal-categories'
            : 'table-configure-categories';
      },

      // The company ID for the current company
      companyId() {
         return this.$route.params.id;
      },

      filteredCategories() {
         return this.categories.filter((category) => {
            return this.internal ? category.internal : !category.internal;
         });
      },
   },

   methods: {
      // Format an integer representing a number of bytes
      formatBytes,

      // Determines the status of an upload category
      uploadStatus(item) {
         if (item.completedAt) {
            return {
               text: 'Completed',
               variant: 'success',
            };
         } else if (item.summary && item.summary.length > 0) {
            return {
               text: 'In Progress',
               variant: 'warning',
            };
         } else {
            return {
               text: 'Not Started',
               variant: 'danger',
            };
         }
      },

      /** Emit a 'newCategory' event to the parent component */
      onNewCategory() {
         this.$emit('newCategory');
      },

      /** Emit an 'upload' event to the parent component */
      onUpload(category) {
         this.$emit('upload', category);
      },

      /** Emit an 'edit' event to the parent component */
      onEdit(category) {
         this.$emit('edit', category);
      },

      /** Emit a 'lock' event to the parent component */
      onLock(category) {
         this.$emit('lock', category);
      },

      /** Emit a 'delete' event to the parent component */
      onDelete(category) {
         this.$emit('delete', category);
      },

      // Display the modal for editing descriptions on uploads
      showEditDescriptionModal(item) {
         this.editDescriptionTarget = item;
         this.updatedDescription = item.description;
         this.$bvModal.show('modal-edit-description');
      },

      /**
       * An array of files uploaded to an upload category. Files are sorted by year
       * and upload date. Each file has a flag indicating whether it's the latest version
       * of the file.
       */
      summaryItems(summary) {
         if (null === summary) {
            return [];
         }

         const seen = {};
         return [...summary]
            .sort((a, b) => {
               // Convert null years to infinity so they're always listed first
               const aYear = parseInt(a.year, 10) || Infinity;
               const bYear = parseInt(b.year, 10) || Infinity;
               const aUploaded = a.uploadedAt;
               const bUploaded = b.uploadedAt;

               // Sort by year, descending
               if (aYear < bYear) {
                  return 1;
               } else if (aYear > bYear) {
                  return -1;
               }

               // Sort by upload date, descending
               if (aUploaded < bUploaded) {
                  return 1;
               } else if (aUploaded > bUploaded) {
                  return -1;
               }

               return 0;
            })
            .map((item) => {
               const ident = `${item.name}::${item.year}`;
               const wasSeen = seen[ident] || false;
               seen[ident] = true;
               return Object.assign({isLatest: !wasSeen}, item);
            });
      },

      // Format a timestamp
      formatDatetime(str) {
         return new Date(str).toLocaleString();
      },

      async downloadFile(fileId) {
         await this.blockingRequest('uploads/downloadFile', {fileId});
      },

      /** Delete a file */
      async deleteFile(uploadCategoryId, fileId) {
         const proceed = await this.$bvModal.msgBoxConfirm(
            'Are you sure you want to delete this file?',
            {
               title: 'Delete this file?',
               centered: true,
            }
         );
         if (proceed) {
            await this.blockingRequest('uploads/deleteFile', {
               uploadCategoryId,
               fileId,
               force: true,
            });
         }
      },

      // Send an edit category request
      async saveCategory() {
         try {
            await this.blockingRequest('uploads/editCategory', {
               ...this.editCategory,
            });
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Submission failed',
               seconds: 5,
            });
         }
         this.$bvModal.hide('modal-edit-category');
      },

      /** Update the description on a file */
      async onUpdateDescription(summaryItem, newDescription) {
         const {uploadCategoryId, year, id} = summaryItem;

         await this.$store.dispatch('uploads/updateFileDetails', {
            uploadCategoryId,
            fileId: id,
            year,
            description: newDescription,
         });
      },

      /** Update the year on a file */
      async onUpdateYear(summaryItem, newYear) {
         const {uploadCategoryId, description, id} = summaryItem;

         await this.$store.dispatch('uploads/updateFileDetails', {
            uploadCategoryId,
            fileId: id,
            year: newYear,
            description,
         });
      },
   },
};
</script>
