<template>
   <b-container>
      <h1 class="mb-3">Configure Company-Specific Text</h1>

      <p class="mb-4">
         Here, you can configure the copy that's shown to users from
         <b>{{ company.companyName }}</b
         >. This allows us to provide instructions and examples tailored to their business or
         industry.
      </p>

      <b-alert
         variant="success"
         :show="alertCountdown"
         fade
         dismissible
         @dismissed="alertCountdown = 0"
         @dismiss-count-down="(val) => (alertCountdown = val)"
      >
         Saved
      </b-alert>

      <template v-if="prompts !== null">
         <b-card v-for="(prompt, key) in PromptTypes" :key="key" class="mb-3">
            <div class="d-flex align-items-center justify-content-between mb-2">
               <h2 class="mb-0">{{ prompt.title }}</h2>

               <div class="d-flex align-items-center">
                  <b-button
                     :id="`btn-import-default-${key}`"
                     variant="primary"
                     @click="startImportDefault(key)"
                  >
                     Import Industry Default
                  </b-button>
                  <b-button
                     :id="`btn-preview-${key}`"
                     variant="primary"
                     class="ml-2"
                     @click="preview[key] = !preview[key]"
                  >
                     {{ preview[key] ? 'Edit' : 'Preview' }}
                  </b-button>
               </div>
            </div>
            <p>
               {{ prompt.description }}
            </p>

            <b-form-textarea
               :id="`textarea-${key}`"
               v-if="!preview[key]"
               rows="4"
               v-model="prompts[key]"
               @input="dirty = true"
            ></b-form-textarea>

            <template v-else>
               <ManagerMessage v-if="key === 'supplies'" class="mt-3">
                  <h2>
                     Supply costs that qualify for credit are any supplies that were used
                     exclusively for your R&D experimentation.
                  </h2>

                  <p
                     id="manager-message-prompt"
                     v-for="(paragraph, idx) in prompts[key].split('\n')"
                     :key="idx"
                  >
                     {{ paragraph }}
                  </p>
               </ManagerMessage>

               <b-card v-else-if="key === 'uploads'" class="mb-3">
                  <h3>Documents</h3>
                  <p id="manager-message-prompt">
                     Do you have any supporting documents to add?
                     {{ prompts[key] }}
                  </p>
               </b-card>
            </template>
         </b-card>
      </template>

      <div class="d-flex align-items-center justify-content-between">
         <LinkBtn id="btn-back" left :to="{name: 'company-detail', params: {id: companyId}}">
            Back
         </LinkBtn>
         <b-button v-if="prompts !== null" variant="primary" :disabled="!dirty" @click="submit">
            Save
         </b-button>
      </div>

      <b-modal
         id="modal-import-default-text"
         title="Import Industry Default"
         centered
         ok-title="Import"
         @ok="importDefault"
      >
         <p>Select an industry to import default copy from.</p>
         <b-form-select
            v-model="importIndustryId"
            id="select-industry"
            :options="industries"
            value-field="id"
            text-field="name"
            class="mb-4"
         ></b-form-select>

         <h3>{{ importPromptKey ? PromptTypes[importPromptKey].title : '' }}</h3>
         <p id="prompt-industry-default">{{ copyToImport }}</p>
      </b-modal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {PromptTypes} from '@/store/modules/companies';

export default {
   data() {
      return {
         PromptTypes,
         prompts: null,
         preview: null,
         dirty: false,
         importPromptKey: null,
         importIndustryId: null,
         alertCountdown: 0,
      };
   },

   computed: {
      ...mapGetters({
         company: 'companies/currentCompany',
         _prompts: 'companies/prompts',
         industries: 'industries/industries',
         industryMap: 'industries/industryMap',
      }),

      companyId() {
         return this.$route.params.id;
      },

      /** The default industry copy which is being prepared for import */
      copyToImport() {
         if (this.importPromptKey === null || this.importIndustryId === null) {
            return null;
         }
         return this.industryMap[this.importIndustryId].prompts[this.importPromptKey];
      },
   },

   methods: {
      /** Display the import industry default modal */
      startImportDefault(promptKey) {
         this.importPromptKey = promptKey;
         this.importIndustryId = this.company.industryId;
         this.$bvModal.show('modal-import-default-text');
      },

      /** Copy the industry default into the current state */
      importDefault() {
         this.prompts[this.importPromptKey] = this.copyToImport;
         this.dirty = true;
      },

      /** Save the prompt data */
      async submit() {
         await this.$store.dispatch('companies/updateCompany', {
            id: this.companyId,
            company: {
               ...this.company,
               prompts: this.prompts,
            },
         });
         this.dirty = false;
         this.alertCountdown = 5;
      },
   },

   async created() {
      const requests = [
         this.$store.dispatch('companies/loadCompany', {companyId: this.companyId, force: true}),
         this.$store.dispatch('industries/loadIndustries'),
      ];
      await this.blockUntilAllSettled(requests);

      // Set up the state behind the prompt inputs
      this.$set(this, 'prompts', {...this._prompts});

      // Set up the preview state for each prompt
      const preview = Object.keys(PromptTypes).reduce((obj, key) => {
         obj[key] = false;
         return obj;
      }, {});
      this.$set(this, 'preview', preview);
   },

   /** Before leaving the route, check for any unsaved changes */
   async beforeRouteLeave(to, from, next) {
      if (this.dirty) {
         const proceed = await this.$bvModal.msgBoxConfirm(
            'You have unsaved changes. Would you like to stay on this page to save your work, or proceed and discard any unsaved changes?',
            {
               id: 'modal-proceed',
               centered: true,
               title: 'Proceed without saving?',
               okTitle: 'Proceed without saving',
               cancelTitle: 'Stay',
            }
         );
         if (!proceed) {
            // Cancel the navigation
            return next(false);
         }
      }
      next();
   },
};
</script>
