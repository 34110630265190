<template>
   <svg
      :width="width"
      :height="height"
      :viewBox="viewBox"
      :aria-labelledby="iconName"
      xmlns="http://www.w3.org/2000/svg"
   >
      <title :id="iconName">{{ iconName }}</title>
      <g :fill="iconColor">
         <slot />
      </g>
   </svg>
</template>

<script>
export default {
   props: {
      width: {type: [Number, String], default: 32},
      height: {type: [Number, String], default: 32},
      viewBox: {type: String, default: '0 0 32 32'},
      iconColor: {type: String, default: 'currentColor'},
      iconName: {type: String, default: ''},
   },
};
</script>
