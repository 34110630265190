<template>
   <b-container>
      <PageTitle title="Employee Time" />

      <p class="mb-5">
         Wages paid for time spent on R&D are where most companies get the most tax credit. In this
         section, you will assign employees to Onboard users at your company who can tell us how
         much time those employees spent on R&D in years past. This will be quick and painless, so
         let's dive in!
      </p>

      <div class="d-flex justify-content-end">
         <b-button id="btn-begin" variant="success" @click="leaveIntro">
            Begin
            <b-icon-arrow-right-short />
         </b-button>
      </div>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   computed: {
      ...mapGetters({
         clientState: 'clientState',
         company: 'companies/currentCompany',
      }),

      /** The time survey's company */
      companyId() {
         if (this.$route.params.id) {
            return parseInt(this.$route.params.id);
         } else {
            return this.company.id;
         }
      },

      /** Has the user seen the intro for this section? */
      seenIntro() {
         return this.clientState.sectionIntros.timeSurvey;
      },

      nextRoute() {
         const redirect = this.$route.query.redirect;
         return redirect ? redirect : {name: 'time-survey-years', params: {id: this.companyId}};
      },
   },

   methods: {
      leaveIntro() {
         if (!this.seenIntro) {
            this.$store.dispatch('setSectionIntro', {
               section: 'timeSurvey',
               value: true,
            });
         }
         this.$router.push(this.nextRoute);
      },
   },
};
</script>
