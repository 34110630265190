import Vue from 'vue';
import {SpecialCategories} from './uploads';
import {VendorData} from '../utils';

export const SuppliesDecl = Object.freeze({
   NONE: 'NONE',
   NOT_USA: 'NOT_USA',
   ONE: 'ONE',
   MANY: 'MANY',
});

const state = () => ({
   assignments: [],
   data: {},
   adHocData: {},
   vendorList: [],
   documentsUploadCategoryId: null,
});

const getters = {
   assignee: (state) => {
      const assignee = state.assignments.find((assignment) => assignment.completed === null);
      return assignee ? assignee.assigneeId : null;
   },

   data: (state) => state.data,

   adHocData: (state) => state.adHocData,

   adHocVendorList: (state) => state.vendorList.filter((vendor) => vendor.manuallyGenerated),

   importedVendorList: (state) => state.vendorList.filter((vendor) => !vendor.manuallyGenerated),

   /** Has the supplies section been marked complete? */
   isCompleted: (state, getters, rootState, rootGetters) => {
      return rootGetters['companies/study']?.supplies.completedAt !== null;
   },

   /**
    * The supplies declaration
    * @returns {SuppliesDecl}
    */
   declaration: (state, getters, rootState, rootGetters) => {
      return rootGetters['companies/study']?.supplies.declared;
   },

   hasSectionAssignment: (state, getters, rootState, rootGetters) => {
      const userId = rootGetters.profile.id;
      return getters.assignee == userId;
   },

   /** The location declaration when the supplies declaration is `ONE` */
   location: (state, getters, rootState, rootGetters) => {
      return rootGetters['companies/study']?.supplies.location;
   },

   uploadCategory: (state, getters, rootState, rootGetters) => {
      if (state.documentsUploadCategoryId === null) {
         return null;
      }
      return rootGetters['uploads/uploadCategoryMap'][state.documentsUploadCategoryId];
   },
};

const mutations = {
   clearSuppliesData(state) {
      state.data = {};
   },

   /** Process incoming supplies data, organizing it by year then account */
   importSuppliesData(state, {data}) {
      let importedData = {};
      let adHocData = {};
      data.forEach((datum) => {
         const isAdHoc = datum.account === null;
         let data = isAdHoc ? adHocData : importedData;

         if (!Object.prototype.hasOwnProperty.call(data, datum.year)) {
            data[datum.year] = {};
         }

         if (isAdHoc) {
            data[datum.year][datum.id] = new VendorData(datum);
         } else {
            if (!Object.prototype.hasOwnProperty.call(data[datum.year], datum.account)) {
               data[datum.year][datum.account] = {};
            }
            data[datum.year][datum.account][datum.id] = new VendorData(datum);
         }
      });

      state.data = importedData;
      state.adHocData = adHocData;
   },

   importVendorList(state, {vendors}) {
      state.vendorList = vendors;
   },

   /** Set the assignee of the supplies section */
   setAssignments: (state, assignments) => {
      Vue.set(state, 'assignments', assignments);
   },

   /** Store the supplies supporting documents file upload category id */
   setDocumentsUploadCategoryId(state, {uploadCategoryId}) {
      state.documentsUploadCategoryId = uploadCategoryId;
   },
};

const actions = {
   /** Assign the supplies section to another user */
   async assignSection({commit}, {companyId, assignerId, assigneeId}) {
      const newAssignments = (
         await this._vm.$http.put(`/api/company/${companyId}/supplies/assignment`, {
            assignerId,
            assigneeId,
         })
      ).data;
      commit('setAssignments', newAssignments);
   },

   /** Load supplies data */
   async loadSupplies({commit}, {companyId}) {
      const response = await this._vm.$http.get(`/api/company/${companyId}/supplies`);
      commit('importSuppliesData', {data: response.data.results});
   },

   /** Save the declared attribute for supplies data */
   async declareSuppliesUsed({commit}, {companyId, value, location = null}) {
      let declaration = {
         declared: value,
      };
      if (location !== null) {
         declaration.location = location;
      }
      const supplies = (await this._vm.$http.put(`/api/company/${companyId}/supplies`, declaration))
         .data;
      commit('companies/setSupplies', {supplies}, {root: true});
   },

   /** Complete section assignment */
   async completeSectionAssignment({commit}, {companyId}) {
      const data = (
         await this._vm.$http.put(`/api/company/${companyId}/supplies/assignment/complete`)
      ).data;
      commit('setAssignments', data);
   },

   /** Complete or uncomplete the supplies section */
   async completeSupplies({commit}, {companyId, completed = true}) {
      const supplies = (await this._vm.$http.put(`/api/company/${companyId}/supplies`, {completed}))
         .data;
      commit('companies/setSupplies', {supplies}, {root: true});
   },

   /** Load section assignment */
   async loadSectionAssignment({commit}, {companyId}) {
      const data = (await this._vm.$http.get(`/api/company/${companyId}/supplies/assignment`)).data;
      commit('setAssignments', data);
   },

   /** Save a qualifying percentage for a vendor */
   async savePercentage({state}, {year, account, vendorId, percentage}) {
      const vendorData =
         account === null ? state.adHocData[year][vendorId] : state.data[year][account][vendorId];
      vendorData.percentage.saveStarted();
      try {
         await this._vm.$http.put(`/api/supplies/${vendorId}/summary`, {
            percentage,
         });
         vendorData.percentage.value = percentage;
         vendorData.percentage.saveSuccessful();
      } catch (err) {
         vendorData.percentage.saveFailed();
      }
   },

   /** Save the net amount for an ad hoc for a vendor */
   async saveNetAmount({state}, {year, vendorId, netAmount}) {
      const vendorData = state.adHocData[year][vendorId];
      vendorData.netAmount.saveStarted();
      try {
         await this._vm.$http.put(`/api/supplies/${vendorId}/summary`, {
            netAmount,
         });
         vendorData.netAmount.value = netAmount;
         vendorData.netAmount.saveSuccessful();
      } catch (err) {
         vendorData.netAmount.saveFailed();
      }
   },

   async loadVendors({commit}, {companyId}) {
      const response = await this._vm.$http.get(`/api/company/${companyId}/supplies/vendors`);
      commit('importVendorList', {vendors: response.data.results});
   },

   async createVendor({state, getters, commit}, {companyId, vendor, location}) {
      const payload = {vendor, location};
      try {
         await this._vm.$httpSimple.post(`/api/company/${companyId}/supplies/vendors`, payload);
         const newLocation = location ? location : getters.location;
         state.vendorList.push({vendor, location: newLocation, manuallyGenerated: true});
      } catch (err) {
         commit(
            'showAlert',
            {
               msg: 'Failed to create vendor',
               seconds: 5,
            },
            {root: true}
         );
      }
   },

   async deleteVendor({state, commit}, {companyId, vendor, location}) {
      const payload = {vendor, location};
      try {
         await this._vm.$http.delete(`/api/company/${companyId}/supplies/vendors`, {
            data: payload,
         });
         state.vendorList = state.vendorList.filter((v) => {
            return !(v.vendor === vendor && v.location === location && v.manuallyGenerated);
         });
      } catch (err) {
         console.log(err);
         commit(
            'showAlert',
            {
               msg: 'Failed to delete vendor',
               seconds: 5,
            },
            {root: true}
         );
      }
   },

   /** Unssign the supplies section */
   async unassignSection({commit}, {companyId}) {
      const newAssignments = (
         await this._vm.$http.delete(`/api/company/${companyId}/supplies/assignment`)
      ).data;
      commit('setAssignments', newAssignments);
   },

   /** Load the UploadCategory for cloud computing documentation */
   async loadSuppliesDocuments({dispatch, commit}, {companyId}) {
      const uploadCategory = await dispatch(
         'uploads/loadSpecialCategory',
         {
            companyId,
            category: SpecialCategories.SUPPLIES_DOCS,
         },
         {root: true}
      );
      commit('setDocumentsUploadCategoryId', {
         uploadCategoryId: uploadCategory.id,
      });
   },

   /** Upload to the cloud computing documents upload category */
   async uploadSuppliesDocuments({state, dispatch}, {files}) {
      if (files.length === 0) {
         return;
      }
      const validation = {
         type: SpecialCategories.SUPPLIES_DOCS,
      };

      await dispatch(
         'uploads/uploadFiles',
         {
            uploadCategoryId: state.documentsUploadCategoryId,
            year: null,
            files,
            validation,
         },
         {root: true}
      );
   },

   /** Delete a cloud computing document */
   async deleteSuppliesDocument({state, dispatch}, {fileId, force = false}) {
      await dispatch(
         'uploads/deleteFile',
         {
            fileId,
            uploadCategoryId: state.documentsUploadCategoryId,
            force,
         },
         {root: true}
      );
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
