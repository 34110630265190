<template>
   <b-container>
      <PageTitle title="Questionnaires" />
      <QuestionnaireReviewForm v-if="loaded" is-project />
   </b-container>
</template>

<script>
import QuestionnaireReviewForm from './widgets/QuestionnaireReviewForm.vue';

export default {
   components: {
      QuestionnaireReviewForm,
   },

   data() {
      return {
         loaded: false,
      };
   },

   async created() {
      await this.fetchData();
   },

   computed: {
      companyId() {
         return this.$route.params.id;
      },

      questionnaireId() {
         return this.$route.params.questionnaireId;
      },
   },

   methods: {
      /** Load the project questionnaire data */
      async fetchData() {
         let requests = [];
         requests.push(
            this.$store.dispatch('questionnaire/loadProjectQuestionnaire', {
               questionnaireId: this.questionnaireId,
               joinProject: true,
            })
         );
         requests.push(
            this.$store.dispatch('uploads/loadCompanyUploadCategories', {
               companyId: this.companyId,
               summary: true,
               force: false,
            })
         );
         await this.blockUntilAllSettled(requests);
         this.loaded = true;
      },
   },
};
</script>
