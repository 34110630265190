<template>
   <b-breadcrumb v-if="breadCrumb" :items="breadCrumb"></b-breadcrumb>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
   computed: {
      ...mapGetters(['breadCrumb']),
   },
};
</script>
