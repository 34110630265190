<template>
   <b-container>
      <b-row class="mt-5 mb-4">
         <b-col>
            <h2 class="d-inline-block">My Clients</h2>
         </b-col>
         <b-col class="d-flex justify-content-end align-items-center">
            <b-button id="btn-new-company" variant="primary" :to="{name: 'company-create'}">
               <b-icon-plus></b-icon-plus>
               Add New
            </b-button>
         </b-col>
      </b-row>

      <b-row v-if="loading">
         <b-col class="loading-container">
            <b-spinner variant="primary" class="loading-spinner"></b-spinner>
         </b-col>
      </b-row>
      <b-row class="mb-3">
         <b-col>
            <b-form-checkbox name="cb-show-all-companies" v-model="showAllCompanies" switch>
               Show All
            </b-form-checkbox>
         </b-col>
      </b-row>
      <b-row v-if="companies.length > 0">
         <b-col v-if="filteredCompanies.length === 0">
            <b-alert variant="primary" :show="filteredCompanies.length === 0" class="mx-auto">
               <h6 class="font-weight-normal mb-3">You Don't Have Any Client Companies</h6>

               <div class="text-dark">
                  <p>
                     Click <b>Show All</b> to see all of R&D Incentives Group's client companies. If
                     you have a role with one of these companies, click <b>View this Client</b>,
                     then add the appropriate role for yourself under <b>Staff Assignments</b>.
                  </p>
                  <p>
                     If your client company is not in the system yet, click <b>Add New</b> to add a
                     new company.
                  </p>
               </div>
               <div>
                  <b-button variant="white" size="sm" class="mr-4" @click="showAllCompanies = true">
                     Show All Companies
                  </b-button>
                  <b-button variant="white" size="sm" :to="{name: 'company-create'}">
                     Add New Company
                  </b-button>
               </div>
            </b-alert>
         </b-col>
         <b-col v-for="company in filteredCompanies" :key="company.id" cols="6" lg="4" class="mb-4">
            <b-card>
               <h2 :id="`company-name-${company.id}`" class="mb-3">{{ company.companyName }}</h2>
               <b>Your Role</b>
               <p>{{ company.role }}</p>
               <b-button
                  variant="primary"
                  block
                  class="mt-4"
                  :to="{name: 'company-detail', params: {id: company.id}}"
               >
                  View this Client
               </b-button>
            </b-card>
         </b-col>
      </b-row>
      <b-row v-if="companies.length === 0">
         <b-card class="no-company-card">
            <p>No client companies to display.</p>
            <b-button
               id="btn-new-company-empty"
               variant="primary"
               class="action-btn"
               :to="{name: 'company-create'}"
               block
            >
               Add New
            </b-button>
         </b-card>
      </b-row>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {sortBy} from '../../helpers/utils';

export default {
   async created() {
      this.loading = true;
      try {
         this.companyData = await this.$store.dispatch('companies/fetchCompanies');
         this.companyData.sort(sortBy('companyName', (name) => name.toUpperCase()));
      } catch (err) {
         this.$store.commit('showAlert', {
            response: err.response,
            fallbackMsg: 'Failed to fetch companies',
            seconds: 5,
         });
      } finally {
         this.loading = false;
      }
   },

   data() {
      return {
         companyData: [],
         loading: false,
         showAllCompanies: false,
      };
   },

   computed: {
      ...mapGetters({profile: 'profile'}),

      // The company list, including the current user's role
      companies() {
         return this.companyData.map((company) => {
            let role = 'None';
            if (company.businessManagers.includes(this.profile.id)) {
               role = 'Business Manager';
            } else if (company.projectManagers.includes(this.profile.id)) {
               role = 'Project Manager';
            } else if (company.projectAssociates.includes(this.profile.id)) {
               role = 'Project Associate';
            }
            return {
               ...company,
               role,
            };
         });
      },

      filteredCompanies() {
         if (this.showAllCompanies) {
            return this.companies;
         } else {
            return this.companies.filter((company) => company.role !== 'None');
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.loading-container {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 10rem;
}

.no-company-card {
   margin: 0 auto;

   p {
      text-align: center;
      font-size: 1.25rem;
   }
}
</style>
