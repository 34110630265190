<template>
   <b-container fluid="md" class="pt-1">
      <div class="standard-width mx-auto">
         <h1 class="page-title">{{ title }}</h1>
      </div>
      <div v-if="profile">
         <b-nav tabs class="card-tabs mx-auto standard-width">
            <b-nav-item
               v-for="section in sections"
               :key="section.key"
               :active="activeSection === section.key"
               @click="selectSection(section.key)"
               class="position-relative"
            >
               <b-icon-circle-fill
                  v-if="section.alert"
                  variant="danger"
                  font-scale="0.8"
                  class="tab-badge"
               />
               {{ section.label }}
            </b-nav-item>
         </b-nav>

         <b-card class="tabbed-card mx-auto standard-width mb-5">
            <!-- PROFILE -->
            <b-form @submit.prevent="onSubmitProfile" v-if="'profile' === activeSection">
               <!-- Name -->
               <b-form-row>
                  <b-col md="6">
                     <b-form-group
                        label="First Name"
                        label-for="input-first-name"
                        invalid-feedback="Required"
                     >
                        <b-form-input
                           id="input-first-name"
                           v-model="profile.firstName"
                           :state="profile.firstName ? !$v.profile.firstName.$invalid : null"
                           trim
                        ></b-form-input>
                     </b-form-group>
                  </b-col>
                  <b-col md="6">
                     <b-form-group
                        label="Last Name"
                        label-for="input-last-name"
                        invalid-feedback="Required"
                     >
                        <b-form-input
                           id="input-last-name"
                           v-model="profile.lastName"
                           :state="profile.lastName ? !$v.profile.lastName.$invalid : null"
                           trim
                        ></b-form-input>
                     </b-form-group>
                  </b-col>
               </b-form-row>
               <!-- Email -->
               <b-form-group
                  label="Email"
                  label-for="input-email"
                  invalid-feedback="Please enter a valid email"
                  description="This will be the user name for logging in."
               >
                  <b-form-input
                     id="input-email"
                     v-model="profile.email"
                     :state="profile.email ? !$v.profile.email.$invalid : null"
                     trim
                  ></b-form-input>
               </b-form-group>

               <!-- Job Title -->
               <b-form-group label="Job Title" label-for="input-job-title" v-if="isClient">
                  <b-form-input
                     id="input-job-title"
                     v-model="profile.title"
                     trim
                     list="datalist-titles"
                     autocomplete="off"
                  ></b-form-input>

                  <datalist id="datalist-titles" v-if="isClient">
                     <option v-for="title in companyTitles" :key="title">
                        {{ title }}
                     </option>
                  </datalist>
               </b-form-group>

               <!-- Department -->
               <b-form-group label="Department" label-for="input-department" v-if="isClient">
                  <b-form-input
                     id="input-department"
                     v-model="profile.department"
                     list="datalist-departments"
                     autocomplete="off"
                     trim
                  ></b-form-input>

                  <datalist id="datalist-departments" v-if="companyId">
                     <option v-for="department in companyDepartments" :key="department">
                        {{ department }}
                     </option>
                  </datalist>
               </b-form-group>

               <!-- Subsidiary -->
               <b-form-group label="Subsidiary" label-for="input-subsidiary" v-if="isClient">
                  <b-form-input
                     id="input-subsidiary"
                     v-model="profile.subsidiary"
                     list="datalist-subsidiaries"
                     autocomplete="off"
                     trim
                  ></b-form-input>

                  <datalist id="datalist-subsidiaries" v-if="companyId">
                     <option v-for="subsidiary in companySubsidiaries" :key="subsidiary">
                        {{ subsidiary }}
                     </option>
                  </datalist>
               </b-form-group>

               <div class="d-flex justify-content-end">
                  <b-button type="submit" variant="primary" :disabled="$v.profile.$invalid">
                     Save
                  </b-button>
               </div>
            </b-form>

            <!-- PROFILE QUESTIONNAIRE -->
            <div v-else-if="'about' === activeSection">
               <ProfileQuestionnaire
                  ref="profileQuestionnaire"
                  :user="profile"
                  @revert="reloadQuestionnaire"
                  :redirectOnSave="!!$route.query.first"
               />
            </div>

            <div v-else-if="'picture' === activeSection">
               <ProfilePictureUpload :userId="userId" />
            </div>

            <!-- CREDENCIALS -->
            <b-form @submit.prevent="onPasswordReset" v-else-if="'password' === activeSection">
               <div class="d-flex justify-content-end">
                  <b-button id="btn-request-password-reset" type="submit" variant="primary">
                     Request Password Reset
                  </b-button>
               </div>
            </b-form>

            <!-- COMPANY ROLES -->
            <b-form v-else-if="showCompanies && 'roles' === activeSection">
               <b-table
                  borderless
                  thead-class="table-head"
                  :fields="roleFields"
                  :items="roles"
                  fixed
               >
                  <template #head(actions)>
                     <div class="d-flex align-items-center justify-content-end">
                        <b-button
                           variant="primary"
                           size="sm"
                           class="action-btn"
                           v-b-modal.modal-add-assignment
                        >
                           Add Role
                        </b-button>
                     </div>
                  </template>
                  <template #head()="data">
                     <div class="d-flex align-items-center">
                        {{ data.label }}
                     </div>
                  </template>
                  <template #cell(companyId)="data">
                     {{ companyNameMap[data.value] }}
                  </template>
                  <template #cell(role)="data">
                     {{ roleMap[data.value] }}
                  </template>
                  <template #cell(actions)="row">
                     <div class="d-flex align-items-center justify-content-end">
                        <div
                           :id="`btn-delete-role-${row.item.companyId}`"
                           class="delete-btn"
                           @click="removeAssignment(row.item.companyId)"
                        >
                           <b-icon
                              icon="x-square"
                              v-b-tooltip.left="'Remove this Assignment'"
                           ></b-icon>
                        </div>
                     </div>
                  </template>
               </b-table>

               <b-modal id="modal-add-assignment" title="Add Role" hide-footer centered>
                  <b-form @submit.prevent="onAssignmentSubmit">
                     <b-form-group label="Company" label-for="select-company">
                        <b-form-select
                           id="select-company"
                           :options="companySelectOptions"
                           v-model="newAssignment.companyId"
                        ></b-form-select>
                     </b-form-group>
                     <b-form-group label="Role" label-for="select-role">
                        <b-form-select
                           id="select-role"
                           :options="roleSelectOptions"
                           v-model="newAssignment.role"
                        ></b-form-select>
                     </b-form-group>
                     <b-row>
                        <b-col>
                           <b-button
                              type="submit"
                              variant="primary"
                              :disabled="$v.newAssignment.$invalid"
                           >
                              Submit
                           </b-button>
                        </b-col>
                     </b-row>
                  </b-form>
               </b-modal>
            </b-form>
         </b-card>
      </div>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {required, sameAs, email} from 'vuelidate/lib/validators';
import {passwordValidation} from '@/helpers/validation';
import {clientUserSuggestions} from '@/mixins';

import {UserGroups} from '@/store/modules/session';

import ProfilePictureUpload from './widgets/ProfilePictureUpload';
import ProfileQuestionnaire from '@/views/questionnaire/ProfileQuestionnaire';

export default {
   name: 'UserEdit',

   components: {
      ProfilePictureUpload,
      ProfileQuestionnaire,
   },

   mixins: [
      // Support for department and job title suggestions
      clientUserSuggestions,
   ],

   async created() {
      await this.fetchData();
      if (this.$route.query.section) {
         if (this.sections.some((section) => section.key === this.$route.query.section)) {
            this.activeSection = this.$route.query.section;
         }
      }
   },

   watch: {
      async $route() {
         await this.fetchData();
      },
   },

   data() {
      return {
         activeSection: 'profile',
         profile: null,
         credentials: {
            password: null,
            passwordConf: null,
         },
         companies: [],
         roles: [],
         roleFields: [
            {
               key: 'companyId',
               label: 'Company',
               sortable: true,
            },
            {key: 'role', sortable: true},
            'actions',
         ],
         roleMap: {
            BM: 'Business Manager',
            PM: 'Project Manager',
            PA: 'Project Associate',
         },
         newAssignment: {
            companyId: null,
            role: null,
         },
         displayProfileQuestionnaire: false,
      };
   },

   computed: {
      ...mapGetters({
         userInfo: 'userInfo',
         isProfileQuestionnaireComplete: 'progress/isProfileQuestionnaireComplete',
      }),

      companyId() {
         return this.profile.companyId;
      },

      sections() {
         let sections = [
            {key: 'profile', label: 'Profile'},
            {key: 'picture', label: 'Picture'},
            {key: 'password', label: 'Password'},
         ];

         if (this.displayProfileQuestionnaire) {
            sections.splice(1, 0, {
               key: 'about',
               label: 'About You',
               alert: !this.isProfileQuestionnaireComplete,
            });
         }

         if (this.showCompanies) {
            sections.push({key: 'roles', label: 'Roles'});
         }

         return sections;
      },

      title() {
         if (this.$route.name == 'profile') {
            return 'Update Your Profile';
         } else {
            return 'Update User Profile';
         }
      },

      showCompanies() {
         return this.profile.groups.includes(UserGroups.STAFF);
      },

      isClient() {
         return (
            this.profile.groups.includes(UserGroups.CUSTOMER) ||
            this.profile.groups.includes(UserGroups.SME)
         );
      },

      userId() {
         if (this.$route.params.id) {
            return this.$route.params.id;
         } else {
            return this.$keycloak.subject;
         }
      },

      companyNameMap() {
         let map = {};
         this.companies.forEach((company) => {
            map[company.id] = company.companyName;
         });
         return map;
      },

      companySelectOptions() {
         return [
            {text: 'Please Select One', value: null},
            ...this.companies
               .map((company) => ({text: company.companyName, value: company.id}))
               .filter((option) => {
                  // filter out any companies where the user already has a role
                  return !this.roles.some((role) => role.companyId === option.value);
               }),
         ];
      },

      roleSelectOptions() {
         return [
            {text: 'Please Select One', value: null},
            ...Object.entries(this.roleMap).map((values) => {
               const [abbrev, name] = values;
               return {text: name, value: abbrev};
            }),
         ];
      },
   },

   methods: {
      /** Fetch profile data, and any other relevant data for this user */
      async fetchData() {
         const id = this.userId;

         let profile;
         try {
            profile = await this.blockingRequest('users/fetchProfile', {id});
            this.profile = profile;
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Failed to fetch user profile',
               seconds: 5,
            });
            return;
         }

         if (profile.groups.includes(UserGroups.STAFF)) {
            this.$store.dispatch('users/fetchUserRoles', {id}).then((data) => {
               this.roles = data.results;
            });
            this.$store.dispatch('companies/fetchCompanies').then((companies) => {
               this.companies = companies;
            });
         }

         if (this.isClient) {
            this.loadSuggestions();

            try {
               await this.blockingRequest('questionnaire/loadProfileQuestionnaire', {
                  userId: this.profile.id,
               });
               this.displayProfileQuestionnaire = true;
            } catch (err) {
               console.log('Failed to fetch profile questionnaire');
            }
         }
      },

      /** Change the active section */
      async selectSection(section) {
         if (this.activeSection === 'about') {
            const proceed = await this.$refs.profileQuestionnaire.checkSave(false);
            if (!proceed) {
               return;
            }
         }
         this.activeSection = section;
      },

      /** Submit an edit user request */
      async onSubmitProfile() {
         let id = this.$route.params.id;
         if (!id) {
            id = this.$keycloak.subject;
         }

         const payload = {
            id,
            profile: this.profile,
         };

         try {
            await this.blockingRequest('users/updateProfile', payload);
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Submission failed',
               seconds: 5,
            });
         }
      },

      /** Reload the profile questionnaire */
      async reloadQuestionnaire() {
         await this.$store.dispatch('questionnaire/loadProfileQuestionnaire', {
            userId: this.profile.id,
         });
      },

      /** Submit a password reset request */
      async onPasswordReset() {
         let id = null;
         if (this.$route.name == 'profile') {
            id = this.$keycloak.subject;
         } else if (this.$route.name == 'user-edit') {
            id = this.$route.params.id;
         }

         try {
            await this.blockingRequest('users/resetPassword', {id});
            this.$router.push({name: 'Home'});
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Submission failed',
               seconds: 5,
            });
         }
      },

      /** Remove a staff user's role with a company */
      async removeAssignment(companyId) {
         const payload = {
            companyId,
            userId: this.userId,
         };
         await this.$store.dispatch('companies/removeStaffAssignment', payload);

         const index = this.roles.map((role) => role.companyId).indexOf(companyId);
         if (index >= 0) {
            this.roles.splice(index, 1);
         }
      },

      /** Submit a create company role request */
      async onAssignmentSubmit() {
         const payload = {
            companyId: this.newAssignment.companyId,
            userId: this.userId,
            role: this.newAssignment.role,
         };
         await this.$store.dispatch('companies/addStaffAssignment', payload);

         this.roles.push(payload);
         this.$bvModal.hide('modal-add-assignment');
         this.newAssignment = {
            companyId: null,
            role: null,
         };
      },
   },

   async beforeRouteLeave(to, from, next) {
      if (this.activeSection === 'about') {
         const proceed = await this.$refs.profileQuestionnaire.checkSave(false);
         return next(proceed);
      }
      next();
   },

   validations: {
      profile: {
         firstName: {
            required,
         },
         lastName: {
            required,
         },
         email: {
            required,
            email,
         },
      },
      credentials: {
         password: passwordValidation,
         passwordConf: {
            required,
            sameAsPassword: sameAs('password'),
         },
      },
      newAssignment: {
         companyId: {
            required,
         },
         role: {
            required,
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.tab-badge {
   position: absolute;
   top: -4px;
   right: -4px;
}
</style>
