<template>
   <b-container v-if="loaded">
      <PageTitle title="Questionnaires" />

      <transition name="fade" mode="out-in">
         <div v-if="displayIntro" key="intro-content">
            <p class="mb-3">
               This section contains questionnaires we need you to answer, justifying how your
               efforts pass the
               <b-link id="four-part-test-link" :to="{name: 'get-started-stage'}"
                  >4-part test</b-link
               >.
               <template v-if="!isSME">
                  You can assign these questionnaires to other users if someone else could answer
                  them better.
               </template>
               These questionnaires are the final bit of info we need to get you an audit-proof R&D
               tax credit. So let's get to it!
            </p>

            <div class="d-flex justify-content-end align-items-center mt-4">
               <b-button id="btn-begin" variant="success" @click="hideIntro">
                  Begin
                  <b-icon-arrow-right-short />
               </b-button>
            </div>
         </div>

         <div v-else key="main-content">
            <ManagerMessage>
               <template #short>
                  <p v-if="!allQuestionnairesComplete" class="mb-0">
                     Some of these questionnaires need answers.
                  </p>
                  <p v-else-if="questionnaireList.length > 0" class="mb-0">
                     This section is complete.
                  </p>
                  <p v-else class="mb-0">Nothing to do here. Let's move on.</p>
               </template>

               <template v-if="!allQuestionnairesComplete">
                  <h2>Some of these questionnaires need answers.</h2>
                  <p>
                     These questionnaires provide us with the information we will use to defend your
                     tax credit against audit. Please answer them to the best of your knowledge.
                     <span v-if="!isSME">
                        Remember, you can assign a questionnaire to another user if someone else is
                        more qualified to answer one or more of its questions.
                     </span>
                  </p>

                  <p class="mb-0">
                     <span>
                        If you need any help answering these, you can always reach me via the
                        Onboard secure messaging feature in the upper right corner.
                     </span>
                  </p>
               </template>
               <template v-else-if="questionnaires.length > 0">
                  <h2>This section is complete.</h2>
                  <p>
                     All your project questionnaires are complete. Visit the dashboard to see if we
                     still need more info from you.
                  </p>

                  <div class="d-flex justify-content-end">
                     <b-button variant="success" :to="{name: 'Home'}">Dashboard</b-button>
                  </div>
               </template>
               <template v-else>
                  <h2>Nothing to do here. Let's move on.</h2>
                  <p>
                     You don't have any questionnaires to complete. Visit the dashboard to see if we
                     still need more info from you.
                  </p>

                  <div class="d-flex justify-content-end">
                     <b-button variant="success" :to="{name: 'Home'}">Dashboard</b-button>
                  </div>
               </template>
            </ManagerMessage>

            <b-table
               v-if="questionnaireList.length > 0"
               :fields="fields"
               sort-by="title"
               :items="questionnaireList"
               id="table-questionnaires"
               class="mb-3"
            >
               <template #cell(title)="data">
                  <b>{{ data.value }}</b>
               </template>

               <template #cell(status)="data">
                  <RdigStatusPill :status="questionnaireStatus(data.item).status" />
               </template>

               <template #cell(actions)="data">
                  <div class="cell-w-buttons d-flex justify-content-end">
                     <b-button
                        :id="`btn-questionnaire-${data.index}`"
                        :variant="questionnaireStatus(data.item).btnVariant"
                        size="sm"
                        class="btn-w-icon no-wrap"
                        @click="toQuestionnaire(data.item)"
                        :disabled="hasOnlyCompletedPastAssignments(data.item)"
                     >
                        {{ questionnaireStatus(data.item).btnText }}
                        <custom-icon
                           icon="icon-chevron-right"
                           height="16px"
                           width="16px"
                           class="ml-1"
                        />
                     </b-button>
                  </div>
               </template>
            </b-table>

            <b-alert variant="primary" class="mb-0 my-4" :show="questionnaires.length === 0">
               You don't have any project questionnaires assigned to you.
            </b-alert>

            <b-button
               v-if="!displayIntro"
               variant="secondary"
               class="d-flex align-items-center ml-2"
               @click="displayIntro = true"
            >
               <b-icon-arrow-left-short class="mr-1" />
               View Introduction
            </b-button>
         </div>
      </transition>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   data() {
      return {
         loaded: false,
         displayIntro: true,
         fields: [
            {key: 'title', sortable: true},
            {
               key: 'projectId',
               label: 'Project',
               formatter: (projId) => this.projectName(projId),
               sortable: true,
               sortByFormatted: true,
            },
            {
               key: 'status',
               class: 'no-wrap',
               formatter: (value, key, item) => this.questionnaireStatus(item).status,
               sortable: true,
               sortByFormatted: true,
            },
            {key: 'actions', label: ''},
         ],
         StatusType: this.$constants().StatusType,
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         isSME: 'isSME',
         manager: 'manager',
         clientState: 'clientState',
         uploadCategories: 'uploads/uploadCategories',
         company: 'companies/currentCompany',
         projectMap: 'projects/projectMap',
         previousStage: 'previousStage',
         nextStage: 'nextStage',
         questionnaires: 'questionnaire/questionnaires',
      }),

      /** The company ID for the logged in user */
      companyId() {
         return this.profile.companyId;
      },

      /** Has the user seen the intro for this section */
      seenIntro() {
         return this.clientState.sectionIntros.project;
      },

      questionnaireList() {
         return Object.values(this.questionnaires);
      },

      allQuestionnairesComplete() {
         return !this.questionnaireList.some((item) => item.completed === null);
      },
   },

   methods: {
      /** Hide the intro section */
      hideIntro() {
         if (!this.seenIntro) {
            this.$store.dispatch('setSectionIntro', {
               section: 'project',
               value: true,
            });
         }
         this.displayIntro = false;
      },

      /** Redirect to the selected project questionnaire */
      toQuestionnaire(item) {
         if (item.projectId === null) {
            this.$router.push({
               name: 'customer-company-questionnaire-complete',
               params: {id: this.companyId, questionnaireId: item.id},
            });
         } else {
            this.$router.push({
               name: 'customer-project-questionnaire-complete',
               params: {id: this.companyId, questionnaireId: item.id},
            });
         }
      },

      /** Has the user completed all of their assigned questionnaires? */
      hasOnlyCompletedPastAssignments(questionnaire) {
         let assignments = questionnaire.assignments.filter((q) => q.assignee === this.profile.id);
         return assignments.length > 0 && assignments.every((q) => q.completed);
      },

      projectName(projectId) {
         if (projectId === null) {
            return '(Company-wide)';
         }
         return this.projectMap[projectId].name;
      },

      /** Determine the status of a questionnaire */
      questionnaireStatus(questionnaire) {
         if (questionnaire.completed !== null) {
            return {
               status: this.StatusType.COMPLETE,
               btnText: 'Review',
               btnVariant: 'primary',
            };
         }

         let hasAnswer = false;
         questionnaire.questions.forEach((question) => {
            hasAnswer = hasAnswer || question.answers.length > 0;
         });

         if (hasAnswer) {
            return {
               status: this.StatusType.IN_PROGRESS,
               btnText: 'Continue',
               btnVariant: 'primary',
            };
         } else {
            return {
               status: this.StatusType.INCOMPLETE,
               btnText: 'Start',
               btnVariant: 'success',
            };
         }
      },
   },

   async created() {
      let requests = [
         this.$store.dispatch('questionnaire/loadQuestionnaires', {companyId: this.companyId}),
         this.$store.dispatch('projects/loadProjects', {companyId: this.companyId}),
      ];

      try {
         await this.blockUntilAllSettled(requests);
      } finally {
         this.displayIntro = !this.seenIntro;
         this.loaded = true;
      }
   },
};
</script>
