<template>
   <div class="state-form mb-4">
      <b-form-row class="mb-2">
         <b-col class="d-flex align-items-center">
            <label :for="'input-state-' + index" class="mb-0">State</label>
         </b-col>
         <b-col class="d-flex align-items-center justify-content-end">
            <div :id="`btn-delete-state-${index}`" class="delete-btn" @click="removeState">
               <b-icon icon="x-square" v-b-tooltip.left="'Remove this state'"></b-icon>
            </div>
         </b-col>
      </b-form-row>
      <b-form-select
         :id="`input-state-${index}`"
         class="mb-3"
         :options="stateOptions"
         @input="onInput"
         v-model="state.state"
      >
         <template #first>
            <b-form-select-option :value="null" disabled> Please select one </b-form-select-option>
         </template>
      </b-form-select>

      <b-form-row class="mb-2">
         <b-col class="d-flex align-items-center" cols="9">
            <label class="d-inline-block mb-0">Credits Years</label>
         </b-col>
         <b-col class="d-flex align-items-center justify-content-end" cols="2">
            <div :id="`btn-add-state-year-${index}`" class="add-btn" @click="addYear">
               <b-icon icon="plus-circle" v-b-tooltip.left="'Add a year'"></b-icon>
            </div>
         </b-col>
      </b-form-row>
      <b-form-row>
         <b-col cols="11">
            <CreditYearsSelect
               :yearsCount="yearsCount"
               v-model="state.years"
               :index="`scy-${index}-`"
            />
         </b-col>
      </b-form-row>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

import CreditYearsSelect from '@/views/company/widgets/CreditYearsSelect';

export default {
   props: ['index', 'value', 'yearsCount'],
   components: {
      CreditYearsSelect,
   },
   data() {
      return {
         state: this.value,
      };
   },
   computed: {
      ...mapGetters({
         usStates: 'usStates',
      }),

      stateOptions() {
         const stateOptions = Object.entries(this.usStates).map((entry) => {
            const [key, value] = entry;
            return {text: value, value: key};
         });
         return stateOptions;
      },
   },
   methods: {
      onInput() {
         this.$emit('input', this.state);
      },
      addYear() {
         this.state.years.push(null);
         this.onInput();
      },
      removeState() {
         this.$emit('remove');
      },
   },
   watch: {
      value(value) {
         this.state = value;
         this.onInput();
      },
   },
};
</script>

<style lang="scss" scoped>
.state-form {
   padding: 15px;
   background-color: rgba(#000000, 0.075);
}
</style>
