<template>
   <g>
      <path
         d="M4.50012 9.8559L12.5001 17.8559L20.5001 9.8559"
         stroke="currentColor"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
   </g>
</template>

<script>
export default {
   data() {
      return {
         name: 'icon-chevron-down',
         viewBox: '0 0 25 25',
         fill: 'none',
      };
   },
};
</script>
