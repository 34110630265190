import 'mutationobserver-shim';
import Vue from 'vue';
import _ from 'lodash';

// vue-router, vuex config (don't change the order)
import router from './router';
import store from './store';

const storeConfig = {
   store,
};

// plugin configuration
import './plugins/bootstrap-vue';
import {Plugin as AxiosPlugin} from './plugins/axios';
Vue.use(AxiosPlugin, storeConfig);

import Constants from './plugins/constants';
Vue.use(Constants);
import KeycloakPlugin from './plugins/keycloak';

// Websocket plugin
import {Plugin as WebsocketPlugin} from './plugins/websocket';
Vue.use(WebsocketPlugin, storeConfig);

// App entry point
import App from './App.vue';

// register global components
import './views';
import './components';

// register global mixins and directives
import './mixins';
import './directives';

// vue-advenced-cropper styles
// import 'vue-advanced-cropper/dist/style.css';

// site-wide styles
import './assets/css/main.scss';

// other packages
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

// Define the behavior when merging validations from a mixin
// with validations in a component
function mergeValidations(a, b) {
   if (typeof a === 'function' || typeof b === 'function') {
      return function () {
         const aObj = typeof a === 'function' ? a.call(this) : a;
         const bObj = typeof b === 'function' ? b.call(this) : b;
         return _.merge(aObj, bObj);
      };
   }
   return _.merge(a, b);
}
Vue.config.optionMergeStrategies.validations =
   Vue.config.optionMergeStrategies.validations || mergeValidations;

import VCalendar from 'v-calendar';
Vue.use(VCalendar);

Vue.config.productionTip = false;

const kcConfig = {
   url: '/auth',
   realm: 'TaxCreditPortal',
   clientId: 'app',
};

const kcInit = {
   onLoad: 'login-required',
   // redirectUri: location.origin,
};

// Create keycloak instance and make it available on our Vue instance
Vue.use(KeycloakPlugin, kcConfig);

Vue.$keycloak.init(kcInit).then((auth) => {
   if (!auth) {
      // Not authenticated?  Theoretically, we shouldn't reach here
      // given `login-required`.
      console.log('Not authorized');
   } else {
      // User is authenticated!

      // We also need to fetch the user session before we can do anything else.
      store
         .dispatch('fetchUserSession')
         .then(() => {
            // Start the app
            window.$app = new Vue({
               router,
               store,
               render: (h) => h(App),
            }).$mount('#app');
         })
         .catch((err) => {
            console.log('Failed to retrieve session:', err);
         });

      // load app version
      store.dispatch('fetchVersion');

      // Update token when window gains focus
      window.onfocus = () => {
         Vue.updateToken();
      };
   }
});
