<template>
   <g>
      <path
         d="M16.842 9.47368C16.842 15.9258 7.15778 15.9258 7.15778 9.47368H9.57883C9.57883 12.7058 14.4209 12.7058 14.4209 9.47368M21.6841 20.3684V24H2.31567V20.3684C2.31567 17.1363 8.76778 15.5263 11.9999 15.5263C15.232 15.5263 21.6841 17.1363 21.6841 20.3684ZM19.3841 20.3684C19.3841 19.5937 15.5951 17.8263 11.9999 17.8263C8.40462 17.8263 4.61567 19.5937 4.61567 20.3684V21.7H19.3841M12.6051 1C12.9441 1 13.2104 1.26632 13.2104 1.60526V5.23684H14.4209V2.21053C15.2776 2.607 15.9954 3.25181 16.4811 4.06128C16.9668 4.87075 17.1979 5.80751 17.1446 6.75C17.1446 6.75 17.992 6.91947 18.0525 8.26316H5.94725C5.94725 6.91947 6.85515 6.75 6.85515 6.75C6.80183 5.80751 7.03301 4.87075 7.51869 4.06128C8.00437 3.25181 8.72213 2.607 9.57883 2.21053V5.23684H10.7894V1.60526C10.7894 1.26632 11.0557 1 11.3946 1"
      />
   </g>
</template>

<script>
export default {
   data() {
      return {
         name: 'icon-contractor',
         viewBox: '0 0 24 24',
      };
   },
};
</script>
