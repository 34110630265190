<template>
   <b-container>
      <h1 class="mb-3">Configure Business Components</h1>
      <b-card>
         <b-table
            id="table-edit-projects"
            class="mb-0"
            :fields="[
               {key: 'name', sortable: true},
               {key: 'years.lower', label: 'Start Year', sortable: true},
               {
                  key: 'years.upper',
                  label: 'End Year',
                  sortable: true,
                  formatter: (value) => value || 'Ongoing',
               },
               'actions',
            ]"
            sort-by="name"
            :items="projects"
         >
            <template #head(actions)>
               <div class="cell-w-buttons d-flex justify-content-end">
                  <b-button variant="primary" size="sm" @click="newProjectModal">
                     New Business Component
                  </b-button>
               </div>
            </template>
            <template #cell(actions)="data">
               <div class="cell-w-buttons d-flex justify-content-end">
                  <b-button
                     v-if="isStaff"
                     id="btn-delete"
                     variant="danger"
                     size="sm"
                     class="mr-2"
                     @click="deleteProjectModal(data.item)"
                  >
                     Delete
                  </b-button>
                  <b-button
                     id="btn-edit"
                     variant="primary"
                     size="sm"
                     @click="editProjectModal(data.item)"
                  >
                     Edit
                  </b-button>
               </div>
            </template>
         </b-table>
      </b-card>

      <b-modal
         id="modal-new-project"
         title="New Business Component"
         centered
         ok-title="Submit"
         @ok="saveNewProject"
         :ok-disabled="!newProjectValid"
         @shown="focusName"
      >
         <ProjectForm
            ref="newProjectForm"
            :value="newProject"
            @input="updateNewProject"
            @submit="saveNewProject"
            @validate="validateNewProject"
         />
      </b-modal>

      <b-modal
         id="modal-edit-project"
         title="Edit Business Component"
         centered
         ok-title="Submit"
         @ok="saveEditProject"
         :ok-disabled="!editProjectValid"
      >
         <ProjectForm
            :value="editProject"
            @input="updateEditProject"
            @submit="saveEditProject"
            @validate="validateEditProject"
         />
      </b-modal>

      <b-modal
         id="modal-delete-project"
         title="Delete Business Component"
         centered
         @ok="submitDeleteProject"
         ok-title="Delete"
         ok-variant="danger"
      >
         Are you sure you want to delete business component <b>{{ deleteProject.name }}</b
         >?
      </b-modal>
   </b-container>
</template>

<script>
import _ from 'lodash';
import {mapGetters} from 'vuex';

import ErrorCodes from '@/helpers/errorCodes';
import ProjectForm from './widgets/ProjectForm';

export default {
   components: {
      ProjectForm,
   },

   data() {
      return {
         newProject: {
            name: null,
            years: {
               lower: null,
               upper: null,
            },
         },
         newProjectValid: false,
         editProject: {
            id: null,
            name: null,
            years: {
               lower: null,
               upper: null,
            },
         },
         editProjectValid: false,
         deleteProject: {
            id: null,
            name: null,
            years: {
               lower: null,
               upper: null,
            },
         },
      };
   },

   computed: {
      ...mapGetters({
         isStaff: 'isStaff',
         projects: 'projects/projects',
      }),

      /** The ID of the current company */
      companyId() {
         return this.$route.params.id;
      },
   },

   methods: {
      /** Send a POST request to create a new project */
      async saveNewProject() {
         if (!this.newProjectValid) {
            return;
         }

         try {
            await this.$store.dispatch('projects/createProject', {
               project: this.newProject,
               companyId: this.companyId,
            });
         } catch (err) {
            const msg = err.response.data.errors[0].detail;
            if (msg.includes('UniqueViolationError')) {
               this.$store.commit('showAlert', {
                  msg: 'A project with that name already exists',
                  seconds: 5,
               });
            } else {
               this.$store.commit('showAlert', {
                  response: err.response,
                  fallbackMsg: 'Submission failed',
                  seconds: 5,
               });
            }
         }
      },

      /** Clear the new project object and display the new project modal */
      newProjectModal() {
         this.newProject = {
            name: null,
            years: {
               lower: null,
               upper: null,
            },
         };
         this.newProjectValid = false;
         this.$bvModal.show('modal-new-project');
      },

      /** Focus the name field in the new project modal */
      focusName() {
         this.$refs.newProjectForm.focusName();
      },

      /** Input handler for the new project modal */
      updateNewProject(project) {
         this.newProject = project;
      },

      /** Update the validation status of the new project modal */
      validateNewProject(value) {
         this.newProjectValid = value;
      },

      /** Send a PUT request to update a project */
      async saveEditProject() {
         if (!this.editProjectValid) {
            return;
         }

         try {
            await this.blockingRequest('projects/editProject', {
               project: this.editProject,
               companyId: this.companyId,
            });
         } catch (err) {
            const msg = err.response.data.errors[0].detail;
            if (msg.includes('UniqueViolationError')) {
               this.$store.commit('showAlert', {
                  msg: 'A project with that name already exists',
                  seconds: 5,
               });
            } else if (msg.includes('year_within_project_years')) {
               this.$store.commit('showAlert', {
                  msg: "Invalid date range: Time survey data already exists for at least one of the years you're attempting to remove from this project's date range",
                  seconds: 10,
               });
            } else {
               this.$store.commit('showAlert', {
                  response: err.response,
                  fallbackMsg: 'Submission failed',
                  seconds: 5,
               });
            }
         }
      },

      /** Create a copy of the selected project's data and display the edit project modal */
      editProjectModal(project) {
         this.editProject = _.cloneDeep(project);
         this.$bvModal.show('modal-edit-project');
      },

      /** Input handler for the edit project modal */
      updateEditProject(project) {
         this.editProject = project;
      },

      /** Update the validation status of the edit project modal */
      validateEditProject(value) {
         this.editProjectValid = value;
      },

      async deleteProjectModal(project) {
         try {
            await this.blockingRequest('projects/deleteProject', {
               projectId: project.id,
            });
            this.deleteProject = project;
            this.$bvModal.show('modal-delete-project');
         } catch (err) {
            const errCode = err.response.data.errors[0].code;
            if (errCode === ErrorCodes.CANNOT_DELETE) {
               this.$bvModal.msgBoxOk(
                  'The selected business component has related data, and cannot be deleted by a staff user. Contact an admin user to delete this business component.',
                  {
                     title: 'Business Component Has Related Data',
                     centered: true,
                  }
               );
            }
         }
      },

      async submitDeleteProject() {
         await this.blockingRequest('projects/deleteProject', {
            projectId: this.deleteProject.id,
            force: true,
         });
      },
   },

   async created() {
      await this.blockingRequest('projects/loadProjects', {companyId: this.companyId});
   },
};
</script>
