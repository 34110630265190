<template>
   <b-navbar variant="white" sticky>
      <b-navbar-brand :to="{name: 'Home'}">
         <img src="@/assets/img/logo/logo-dark.svg" alt="Onboard.tax" />
      </b-navbar-brand>

      <b-navbar-nav class="ml-auto d-flex align-items-center text-secondary text-small">
         <span id="nav-name" class="nav-item text-dark-gray">
            Welcome, {{ profile.firstName }}!
         </span>
         <div v-if="isStaff" class="separator"></div>
         <b-link v-if="isStaff" id="nav-messages" :to="{name: 'messages'}">
            Messages
            <b-icon-circle-fill
               v-if="anyUnreadMessages"
               variant="danger"
               font-scale="0.5"
               shift-v="15"
               class="tab-badge"
            />
         </b-link>
         <div class="separator"></div>
         <b-link id="nav-qtemplates" :to="{name: 'qtemplates'}">Questionnaire Templates</b-link>
         <div class="separator"></div>
         <b-link id="nav-logout" @click="logout">Logout</b-link>
         <div class="separator"></div>
         <b-avatar id="nav-profile" :to="{name: 'profile'}" variant="white" size="2rem">
            <img v-auth-src="profileImageUrl" style="width: 100%" />
         </b-avatar>
      </b-navbar-nav>
   </b-navbar>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   computed: {
      ...mapGetters({
         profile: 'profile',
         isStaff: 'isStaff',
         profileImageUrl: 'profileImageUrl',
         anyUnreadMessages: 'messaging/anyUnreadMessages',
      }),
   },
};
</script>

<style lang="scss" scoped>
.separator {
   background-color: $gray-600;
   border-radius: 2px;
   height: 22px;
   width: 2px;
   margin-left: 1rem;
   margin-right: 1rem;
}
</style>
