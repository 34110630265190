import CompanyCreate from '@/views/company/CompanyCreate';
import CompanyCopyConfig from '@/views/company/CompanyCopyConfig';
import CompanyDetail from '@/views/company/CompanyDetail';
import CompanyEdit from '@/views/company/CompanyEdit';
import CompanyExport from '@/views/company/CompanyExport';
import UserCreate from '@/views/user/UserCreate';

import {UserGroups} from '@/store/modules/session';

export default [
   {
      path: '/company/create',
      name: 'company-create',
      component: CompanyCreate,
      meta: {
         breadCrumb: [{text: 'Dashboard', to: {name: 'Home'}}, {text: 'Create Company'}],
         authGroup: 'rndig',
      },
   },
   {
      path: '/company/:id',
      name: 'company-detail',
      component: CompanyDetail,
      meta: {
         breadCrumb: [{text: 'Dashboard', to: {name: 'Home'}}, {text: '__COMPANYNAME__'}],
         authGroup: 'rndig',
      },
   },
   {
      path: '/company/:id/copy',
      name: 'company-copy-config',
      component: CompanyCopyConfig,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Configure Copy'},
         ],
         authGroup: 'rndig',
      },
   },
   {
      path: '/company/:id/edit',
      name: 'company-edit',
      component: CompanyEdit,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Edit Company'},
         ],
         authGroup: 'rndig',
      },
   },
   {
      path: '/company/:id/create-customer',
      name: 'company-create-customer',
      component: UserCreate,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Create Customer'},
         ],
         authGroup: 'rndig',
      },
      props: (route) => ({company: route.params.id, userType: UserGroups.CUSTOMER}),
   },
   {
      path: '/company/:id/create-sme',
      name: 'company-create-sme',
      component: UserCreate,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Create SME'},
         ],
         authGroup: 'rndig',
      },
      props: (route) => ({company: route.params.id, userType: UserGroups.SME}),
   },
   {
      path: '/company/:id/export',
      name: 'company-export',
      component: CompanyExport,
      meta: {
         authGroup: 'rndig',
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Export Data'},
         ],
      },
      props: (route) => ({companyId: route.params.id}),
   },
];
