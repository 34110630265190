<template>
   <div>
      <b-row>
         <b-col class="d-flex align-items-center">
            <b-avatar variant="white" size="150px" icon="plus" class="mr-4">
               <img v-auth-src="imageUrl" style="width: 100%" />
            </b-avatar>

            <input
               type="file"
               ref="fileInput"
               @input="onFileSelected"
               accept="image/gif, image/jpeg, image/png, image/webp"
               style="display: none"
            />
            <b-button
               id="btn-update-profile-picture"
               variant="primary"
               @click="$refs.fileInput.click()"
            >
               Upload Profile Picture
            </b-button>
         </b-col>
      </b-row>

      <b-modal
         id="modal-crop-image"
         @ok="uploadImage"
         title="Update Profile Picture"
         ok-title="Save"
         no-close-on-backdrop
      >
         <cropper
            class="cropper"
            :src="image.src"
            stencil-component="circle-stencil"
            @change="onCropChange"
            :debounce="false"
            ref="cropper"
         ></cropper>
      </b-modal>
   </div>
</template>

<script>
import {Cropper} from 'vue-advanced-cropper';

export default {
   components: {
      Cropper,
   },

   props: {
      userId: {
         type: [String, Number],
      },
   },

   data() {
      return {
         image: {
            src: null,
            type: null,
         },

         imgCrop: {
            coordinates: null,
            image: null,
         },

         cacheKey: 0,
      };
   },

   computed: {
      imageUrl() {
         return {
            src: `/api/user/${this.userId}/profile/image?cache=${this.cacheKey}`,
            fallbackUrl: require('@/assets/img/avatar.svg'),
         };
      },
   },

   methods: {
      /** Handle a file selection */
      onFileSelected(event) {
         const {files} = event.target;
         if (files && files[0]) {
            if (this.image.src) {
               URL.revokeObjectURL(this.image.src);
            }

            const blob = URL.createObjectURL(files[0]);

            // NOTE: this method of determining the file type is based on
            //       the file extension, and is thus not fool-proof.
            this.image = {
               src: blob,
               type: files[0].type,
            };
         }

         this.$refs.fileInput.value = null;
         this.$bvModal.show('modal-crop-image');
      },

      /** Handle a change in the image crop */
      onCropChange({coordinates, image}) {
         this.imgCrop = {
            coordinates,
            image,
         };
      },

      /** Submit a request to upload a profile image */
      async uploadImage() {
         const {canvas} = this.$refs.cropper.getResult();
         if (canvas) {
            this.$emit('uploadImage', canvas);
            await this.blockingRequest('users/uploadProfileImage', {
               canvas,
               userId: this.userId,
            });
            this.cacheKey++;
         }
      },
   },

   destroyed() {
      if (this.image.src) {
         URL.revokeObjectURL(this.image.src);
      }
   },
};
</script>

<style lang="scss" scoped>
.cropper {
   max-height: 450px;
}
</style>
