<template>
   <b-form @submit.prevent="submit">
      <b-form-group
         label="Business Component Name"
         label-for="input-project-name"
         invalid-feedback="Required"
      >
         <b-form-input
            id="input-project-name"
            :value="value.name"
            @input="(value) => onInput('name', value)"
            :state="!$v.value.name.$invalid"
            ref="name"
         ></b-form-input>
      </b-form-group>

      <b-form-group
         label="Start Year"
         invalid-feedback="Required"
         :state="!$v.value.years.lower.$invalid"
      >
         <YearSelect
            index="start"
            :value="value.years.lower"
            @input="(value) => onInput('startYear', value)"
            tax-years
            :maxYear="maxStartYear"
            :state="!$v.value.years.lower.$invalid"
         />
      </b-form-group>

      <b-form-checkbox id="cb-years-ongoing" v-model="ongoing" @input="ongoingToggled" class="mb-3">
         Ongoing
      </b-form-checkbox>

      <b-form-group
         label="End Year"
         v-if="!ongoing"
         invalid-feedback="Required unless project is ongoing"
         :state="!$v.value.years.upper.$invalid"
      >
         <YearSelect
            index="end"
            :value="value.years.upper"
            @input="(value) => onInput('endYear', value)"
            tax-years
            :minYear="minEndYear"
            :maxYear="maxEndYear"
            :state="!$v.value.years.upper.$invalid"
         />
      </b-form-group>
   </b-form>
</template>

<script>
import {required, requiredUnless} from 'vuelidate/lib/validators';
import {MIN_TAX_YEAR} from '@/helpers/constants';
import YearSelect from '@/components/forms/YearSelect';

export default {
   props: ['value'],

   components: {
      YearSelect,
   },

   created() {
      if (this.value.years.lower && this.value.years.upper === null) {
         this.ongoing = true;
      }
   },

   data() {
      return {
         ongoing: false,
         MIN_TAX_YEAR,
      };
   },

   computed: {
      currentYear() {
         return new Date().getFullYear();
      },

      // Max year for the startYear input
      maxStartYear() {
         return this.value.years.upper ? this.value.years.upper : this.currentYear + 1;
      },

      // Min year for the endYear input
      minEndYear() {
         return this.value.years.lower ? this.value.years.lower : MIN_TAX_YEAR;
      },

      // Max year for the endYear input
      maxEndYear() {
         return this.currentYear + 1;
      },
   },

   methods: {
      // Emit a 'submit' event
      submit() {
         this.$emit('submit');
      },

      // Handle inputs, emit events to update the parent component
      onInput(field, value) {
         let project = {
            ...this.value,
         };
         if ('startYear' === field) {
            project.years.lower = value;
         } else if ('endYear' === field) {
            project.years.upper = value;
         } else {
            project[field] = value;
         }
         this.$emit('input', project);
         this.$emit('validate', !this.$v.$invalid);
      },

      // Handle the 'ongoing' checkbox toggle event
      ongoingToggled(value) {
         if (value) {
            this.onInput('endYear', null);
         } else {
            this.$emit('validate', !this.$v.$invalid);
         }
      },

      /** Focus the name input (used externally) */
      focusName() {
         this.$refs.name.focus();
      },
   },

   validations() {
      return {
         value: {
            name: {required},
            years: {
               lower: {
                  required,
                  beforeEnd(val) {
                     if (this.value.years.upper) {
                        return val <= this.value.years.upper;
                     }
                     return true;
                  },
               },
               upper: {
                  requiredUnlessOngoing: requiredUnless(() => this.ongoing),
                  afterStart(val) {
                     if (this.value.years.lower && !this.ongoing) {
                        return val >= this.value.years.lower;
                     }
                     return true;
                  },
               },
            },
         },
      };
   },
};
</script>
