import Vue from 'vue';

import {ContractorPermissionTypes} from './contractor-permissions';
import {SpecialCategories} from './uploads';
import {formatCurrency, sortBy} from '@/helpers/utils';
import ErrorCodes from '@/helpers/errorCodes';

export const ContractorStatus = Object.freeze({
   NOT_STARTED: 'NOT_STARTED',
   INCOMPLETE: 'INCOMPLETE',
   COMPLETE: 'COMPLETE',
   NONE: 'NONE',
});

class Contractor {
   static #key = 0;

   constructor({
      id = null,
      companyId = null,
      fullname = null,
      contractType = null,
      location = null,
      years = null,
      contractOptOut = false,
      description = null,
      companyIpRights = null,
      economicRisk = null,
   } = {}) {
      if (id !== null) {
         this.id = id;
      } else {
         this.id = `local-${Contractor.#key++}`;
      }

      this.companyId = companyId;
      this.fullname = fullname;
      this.contractType = contractType;
      this.location = location;
      this.contractOptOut = contractOptOut;
      this.description = description;
      this.companyIpRights = companyIpRights;
      this.economicRisk = economicRisk;

      if (years !== null) {
         this.yearsLower = years.lower;
         this.yearsUpper = years.upper;
      } else {
         this.yearsLower = null;
         this.yearsUpper = null;
      }

      this.uploadFiles = [];
   }

   get years() {
      return {
         lower: this.yearsLower,
         upper: this.yearsUpper,
      };
   }

   /** Contractor data formatted for exporting to the server */
   get forExport() {
      const contractor = {
         companyId: this.companyId,
         fullname: this.fullname,
         years: null,
         location: this.location,
         contractOptOut: this.contractOptOut,
         contractType: this.contractType,
         companyIpRights: this.companyIpRights,
         economicRisk: this.economicRisk,
         description: this.description || '',
      };
      if (this.yearsUpper && this.yearsLower) {
         contractor.years = this.years;
      }
      return contractor;
   }
}

export const ContractTypes = Object.freeze({
   FIXED_FEE: 'Fixed fee',
   TIME_MAT: 'Time & materials',
   FEE_CAP: 'Fee-cap',
});

const state = () => ({
   contractors: {},
   refyear: null,
   newContractors: {},
   editContractor: new Contractor(),
   contractUploadCategoryId: null,
   financialData: {},
   editFinancialData: {},
   editFinancialDataDirty: false,
   financialDocumentCategoryId: null,
});

const getters = {
   isComplete: (state, getters, rootState, rootGetters) => {
      return rootGetters['companies/study']
         ? rootGetters['companies/study'].contractors.completedAt !== null
         : false;
   },

   /** All-. current-, and past- contract research assignments on the current study */
   assignments: (state, getters, rootState, rootGetters) => {
      return rootGetters['companies/study'].contractorAssignments;
   },

   currentAssignments: (state, getters) => {
      return getters.assignments.filter((assignment) => assignment.completed === null);
   },

   pastAssignments: (state, getters) => {
      return getters.assignments.filter((assignment) => assignment.completed !== null);
   },

   /**
    * Contractor data as it's been saved on the server
    *
    *    {
    *       CONTRACTOR_ID: Contractor
    *    }
    */
   contractorMap: (state) => state.contractors,
   contractors: (state) => {
      return Object.values(state.contractors).sort(sortBy('fullname', (x) => x.toUpperCase()));
   },

   /** Reference year for the first year of the study */
   refyear: (state) => state.refyear,

   /**
    * Contractor data which is being prepared for creating new contractors on the server
    *
    *    {
    *       CONTRACTOR_ID: Contractor
    *    }
    */
   newContractorMap: (state) => state.newContractors,
   newContractors: (state) => Object.values(state.newContractors),

   /** Contract data which is being edited */
   editContractor: (state) => state.editContractor,

   /** The contractor contract UploadCategory */
   contractUploadCategory: (state, getters, rootState, rootGetters) => {
      if (state.contractUploadCategoryId === null) {
         return null;
      }
      return rootGetters['uploads/uploadCategoryMap'][state.contractUploadCategoryId];
   },

   /**
    * Contract files that have been uploaded for each contractor
    *
    *    {
    *       CONTRACTOR_ID: [UploadFile]
    *    }
    */
   contractorContracts: (state, getters) => {
      if (getters.contractUploadCategory === null) {
         return null;
      }
      const map = {};
      getters.contractors.forEach((contractor) => {
         map[contractor.id] = [];
      });

      getters.contractUploadCategory.summary.forEach((file) => {
         const contractorId = file.validation.data.contractorId;
         if (contractorId in map) {
            map[contractorId].push(file);
         }
      });

      return map;
   },

   /**
    * Contractor financial data
    *
    *    {
    *       CONTRACTOR_ID: {
    *          YEAR: {
    *             invoiced: Number
    *          }
    *       }
    *    }
    */
   financialData: (state) => state.financialData,

   /** Financial data which is being edited */
   editFinancialData: (state) => state.editFinancialData,

   /** Returns `true` when there are unsaved changes to financial data */
   editFinancialDataDirty: (state) => state.editFinancialDataDirty,

   /** Uploaded financial documents */
   financialDocuments: (state, getters, rootState, rootGetters) => {
      if (state.financialDocumentCategoryId === null) {
         return [];
      }
      return rootGetters['uploads/uploadCategoryMap'][state.financialDocumentCategoryId].summary;
   },

   infoStatusMap: (state, getters, rootState, rootGetters) => {
      return Object.entries(state.contractors).reduce((obj, [contractorId, contractor]) => {
         let status;
         if (rootGetters.isSME && !rootGetters['contractorPermissions/canEditInfo'](contractorId)) {
            status = ContractorStatus.NONE;
         } else {
            const requiredFields = ['fullname', 'contractType', 'location', 'description'];

            const fieldComplete = (field) => {
               return !['', null].includes(contractor[field]);
            };

            let complete = requiredFields.every(fieldComplete);

            const contractorRightsAndRiskComplete =
               !contractor.contractOptOut ||
               (fieldComplete('companyIpRights') && fieldComplete('economicRisk'));
            complete = complete && contractorRightsAndRiskComplete;

            const contractFileComplete =
               contractor.contractOptOut ||
               (getters.contractorContracts !== null &&
                  contractorId in getters.contractorContracts &&
                  getters.contractorContracts[contractorId].length > 0);
            complete = complete && contractFileComplete;

            status = complete ? ContractorStatus.COMPLETE : ContractorStatus.INCOMPLETE;
         }
         obj[contractorId] = status;
         return obj;
      }, {});
   },

   financialDataStatusMap: (state, getters, rootState, rootGetters) => {
      return Object.keys(state.contractors).reduce((obj, contractorId) => {
         let status;
         if (
            rootGetters.isSME &&
            !rootGetters['contractorPermissions/canEditFinancialData'](contractorId)
         ) {
            status = ContractorStatus.NONE;
         } else {
            let allComplete = true;
            let someComplete = false;

            Object.values(state.financialData[contractorId]).forEach((data) => {
               const isNull = data.invoiced === null;
               allComplete = allComplete && !isNull;
               someComplete = someComplete || !isNull;
            });

            if (allComplete) {
               status = ContractorStatus.COMPLETE;
            } else if (someComplete) {
               status = ContractorStatus.INCOMPLETE;
            } else {
               status = ContractorStatus.NOT_STARTED;
            }
         }

         obj[contractorId] = status;
         return obj;
      }, {});
   },

   canValidate: (state, getters, rootState, rootGetters) => {
      const allInfoValid = Object.values(getters.infoStatusMap).every(
         (status) => status === ContractorStatus.COMPLETE
      );
      const allFinancialDataValid = Object.values(getters.financialDataStatusMap).every(
         (status) => status === ContractorStatus.COMPLETE
      );
      const allTimeDataValid = Object.values(rootGetters['contractorTime/contractorStatus']).every(
         (status) => status === ContractorStatus.COMPLETE
      );
      return allInfoValid && allFinancialDataValid && allTimeDataValid;
   },
};

const mutations = {
   /** Clear contractor data */
   clearContractors(state) {
      Object.keys(state.contractors).forEach((contractorId) => {
         Vue.delete(state.contractors, contractorId);
      });
   },

   /** Update a contractor */
   setContractor(state, {contractor}) {
      const newContractor = new Contractor(contractor);
      Vue.set(state.contractors, contractor.id, newContractor);
   },

   /** Set the reference year for the first year of the study */
   setRefYear(state, {refyear}) {
      state.refyear = refyear;
   },

   /** Process a yearly data array, storing the financial data */
   setFinancialData(state, {yearly}) {
      if (yearly.length === 0) {
         return;
      }

      yearly.forEach((data) => {
         if (data.invoiced >= 0) {
            if (!(data.contractorId in state.financialData)) {
               Vue.set(state.financialData, data.contractorId, {});
            }
            let invoiced = data.invoiced;
            if (invoiced !== null) {
               // Convert cents => dollars
               invoiced = invoiced / 100;
            }
            Vue.set(state.financialData[data.contractorId], data.year, {invoiced});
         }
      });
   },

   /** Clear a contractor's financial data */
   clearContractorFinancialData(state, {contractorId}) {
      Vue.delete(state.financialData, contractorId);
   },

   /** Delete a contractor */
   deleteContractor(state, {contractorId}) {
      Vue.delete(state.contractors, contractorId);
   },

   // NEW CONTRACTORS

   /** Clear new contractors. Use the clearNewContractor action to call this. */
   _clearNewContractors(state) {
      state.newContractors = {};
   },

   /** Add a new contractor. Use the addNewContractor action to call this. */
   _addNewContractor(state, {contractor}) {
      Vue.set(state.newContractors, contractor.id, contractor);
   },

   /** Update a field on a new contractor */
   updateNewContractor(state, {contractorId, field, value}) {
      state.newContractors[contractorId][field] = value;
   },

   /** Delete a new contractor */
   deleteNewContractor(state, {contractorId}) {
      Vue.delete(state.newContractors, contractorId);
   },

   // EDIT CONTRACTORS

   /** Store a copy of the specified contractor for editing */
   loadContractorForEdit(state, {contractorId}) {
      const contractor = state.contractors[contractorId];
      const contractorCopy = new Contractor(contractor);
      Vue.set(state, 'editContractor', contractorCopy);
   },

   /** Update a field on the edit contractor */
   updateEditContractor(state, {field, value}) {
      state.editContractor[field] = value;
   },

   // CONTRACT FILES

   /** Store the contract file upload category id */
   setContractUploadCategoryId(state, {uploadCategoryId}) {
      state.contractUploadCategoryId = uploadCategoryId;
   },

   // FINANCIAL DATA

   /** Create a copy of financialData to edit */
   loadEditFinancialData(state) {
      state.editFinancialData = {};
      for (const contractorId in state.financialData) {
         Vue.set(state.editFinancialData, contractorId, {});
         for (const year in state.financialData[contractorId]) {
            Vue.set(state.editFinancialData[contractorId], year, {
               invoiced: formatCurrency(state.financialData[contractorId][year].invoiced),
            });
         }
      }
      state.editFinancialDataDirty = false;
   },

   setEditFinancialData(state, {contractorId, year, value}) {
      state.editFinancialData[contractorId][year].invoiced = value;
      state.editFinancialDataDirty = true;
   },

   setFinancialDataDirty(state, {value}) {
      state.editFinancialDataDirty = value;
   },

   setFinancialDocumentCategoryId(state, {uploadCategoryId}) {
      state.financialDocumentCategoryId = uploadCategoryId;
   },
};

const actions = {
   /** Process and store incoming contractor data */
   deserializeContractorData({commit}, {contractorData}) {
      const contractorId = contractorData.contractor.id;
      commit('setContractor', {contractor: contractorData.contractor});

      // Store financial data
      commit('clearContractorFinancialData', {contractorId});
      commit('setFinancialData', {yearly: contractorData.yearly});

      // Store time data
      commit('contractorTime/clearContractorData', {contractorId}, {root: true});
      commit(
         'contractorTime/deserializeData',
         {
            yearly: contractorData.yearly,
            contractorId,
         },
         {root: true}
      );
   },

   /** Clear the new contractor data, and add a blank Contractor object */
   clearNewContractors({commit, dispatch}) {
      commit('_clearNewContractors');
      dispatch('addNewContractor');
   },

   /** Add a blank contractor object with the years set to the study year range */
   addNewContractor({commit, rootGetters}) {
      const study = rootGetters['companies/study'];
      commit('_addNewContractor', {contractor: new Contractor({years: {...study.years}})});
   },

   /**
    * Load contractors for a given company
    * @param {String} companyId - ID of the company
    * @param {boolean} ref - Request reference data for contractor time
    */
   async loadContractors({commit, dispatch}, {companyId = null, ref = false}) {
      const params = {};
      if (companyId) {
         params.company_id = companyId;
      }
      if (ref) {
         params.ref = ref;
      }
      const data = (await this._vm.$http.get(`/api/contractor`, {params})).data;
      const contractors = data.results;
      const refyear = data.refyear;
      commit('clearContractors');
      commit('setRefYear', {refyear});
      contractors.forEach((contractorData) => {
         dispatch('deserializeContractorData', {contractorData});
      });
   },

   /**
    * Create contractors from the stored `newContractors` object
    * @param {String} companyId - ID of the company to add the contractors to
    */
   async createContractors({state, rootGetters, commit, dispatch}, {companyId}) {
      const saveContractor = async (contractor) => {
         contractor.companyId = companyId;
         try {
            const contractorData = (
               await this._vm.$http.post(`/api/contractor`, contractor.forExport)
            ).data;
            await dispatch('uploadContractFiles', {
               contractorId: contractorData.contractor.id,
               files: contractor.uploadFiles,
            });
            dispatch('deserializeContractorData', {contractorData});
            commit(
               'contractorPermissions/setContractorPermission',
               {
                  contractorId: contractorData.contractor.id,
                  type: ContractorPermissionTypes.INFO,
                  value: true,
               },
               {root: true}
            );
            commit(
               'contractorPermissions/setContractorPermission',
               {
                  contractorId: contractorData.contractor.id,
                  type: ContractorPermissionTypes.TIME,
                  value: true,
               },
               {root: true}
            );
         } catch (err) {
            const errCode = err.response.data.errors[0].code;
            if (errCode === ErrorCodes.PERMISSION_DENIED) {
               commit(
                  'showAlert',
                  {
                     msg: "You don't have permission to add contractors",
                     seconds: 5,
                  },
                  {root: true}
               );
               dispatch(
                  'contractorPermissions/loadUserPermissions',
                  {
                     userId: rootGetters.profile.id,
                  },
                  {root: true}
               );
            } else if (errCode === ErrorCodes.LOCKED) {
               commit(
                  'showAlert',
                  {
                     msg: 'This section has been completed. Contractor could not be created.',
                     seconds: 10,
                  },
                  {root: true}
               );
               dispatch(
                  'companies/loadCompany',
                  {
                     companyId: rootGetters['companies/currentCompany'].id,
                     force: true,
                  },
                  {root: true}
               );
            }
         }
      };

      const requests = Object.values(state.newContractors).map((contractor) =>
         saveContractor(contractor)
      );

      await Promise.allSettled(requests);
   },

   /** Edit a contractor */
   async updateContractor({state, rootGetters, commit, dispatch}) {
      const contractor = state.editContractor;

      const requests = [
         this._vm.$http
            .put(`/api/contractor/${contractor.id}`, contractor.forExport)
            .then((response) => {
               dispatch('deserializeContractorData', {contractorData: response.data});
            })
            .catch((err) => {
               const errCode = err.response.data.errors[0].code;
               console.log('ERROR CODE', errCode);
               if (errCode === ErrorCodes.PERMISSION_DENIED) {
                  commit(
                     'showAlert',
                     {
                        msg: "You don't have permission to edit this contractor",
                        seconds: 5,
                     },
                     {root: true}
                  );
                  dispatch(
                     'contractorPermissions/loadUserPermissions',
                     {
                        userId: rootGetters.profile.id,
                     },
                     {root: true}
                  );
               } else if (errCode === ErrorCodes.LOCKED) {
                  commit(
                     'showAlert',
                     {
                        msg: 'This section has been completed. Changes could not be saved.',
                        seconds: 10,
                     },
                     {root: true}
                  );
                  dispatch(
                     'companies/loadCompany',
                     {
                        companyId: rootGetters['companies/currentCompany'].id,
                        force: true,
                     },
                     {root: true}
                  );
               }
            }),
         dispatch('uploadContractFiles', {
            contractorId: contractor.id,
            files: contractor.uploadFiles,
         }),
      ];

      await Promise.allSettled(requests);
   },

   /**
    * Delete a contractor
    * @param {String} contractorId - ID of the contractor to delete
    * @param {Boolean} force - Force the operation. If not true, the server will reject the operation
    */
   async deleteContractor({rootGetters, commit, dispatch}, {contractorId, force = false}) {
      const params = {force};
      try {
         await this._vm.$http.delete(`/api/contractor/${contractorId}`, {params});
         commit('deleteContractor', {contractorId});
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.CANNOT_DELETE) {
            commit(
               'showAlert',
               {
                  msg: "You don't have permission to delete contractors",
                  seconds: 5,
               },
               {root: true}
            );
            await dispatch(
               'contractorPermissions/loadUserPermissions',
               {
                  userId: rootGetters.profile.id,
               },
               {root: true}
            );
         } else if (errCode === ErrorCodes.LOCKED) {
            commit(
               'showAlert',
               {
                  msg: 'This section has been completed. Contractor could not be deleted.',
                  seconds: 10,
               },
               {root: true}
            );
            dispatch(
               'companies/loadCompany',
               {
                  companyId: rootGetters['companies/currentCompany'].id,
                  force: true,
               },
               {root: true}
            );
         }
      }
   },

   /** Load the contract files upload category */
   async loadContractFiles({dispatch, commit}, {companyId}) {
      const uploadCategory = await dispatch(
         'uploads/loadSpecialCategory',
         {
            companyId,
            category: SpecialCategories.CONTRACTOR_DOCS,
         },
         {root: true}
      );
      commit('setContractUploadCategoryId', {
         uploadCategoryId: uploadCategory.id,
      });
   },

   /** Upload contract files for a contractor */
   async uploadContractFiles({state, dispatch}, {contractorId, files}) {
      if (files.length === 0) {
         return;
      }

      const validation = {
         type: SpecialCategories.CONTRACTOR_DOCS,
         data: {
            contractor_id: contractorId,
         },
      };

      await dispatch(
         'uploads/uploadFiles',
         {
            uploadCategoryId: state.contractUploadCategoryId,
            year: null,
            files,
            validation,
         },
         {root: true}
      );
   },

   /** Delete a contract file */
   async deleteContractFile({state, dispatch}, {fileId, force = false}) {
      await dispatch(
         'uploads/deleteFile',
         {
            fileId,
            uploadCategoryId: state.contractUploadCategoryId,
            force,
         },
         {root: true}
      );
   },

   /** Update the financial data for all contractors */
   async saveFinancialData({state, rootGetters, commit, dispatch}, {companyId}) {
      const yearly = [];
      for (const contractorId in state.editFinancialData) {
         for (const year in state.editFinancialData[contractorId]) {
            let invoiced = state.editFinancialData[contractorId][year].invoiced;
            if (invoiced === '') {
               invoiced = null;
            }
            if (invoiced !== null) {
               invoiced = parseFloat(invoiced.replace(/[^\d.]/g, ''));
               invoiced = invoiced * 100;
            }
            yearly.push({
               contractorId,
               year,
               invoiced,
            });
         }
      }

      try {
         const updatedData = (
            await this._vm.$http.post(`/api/contractor/invoiced`, {
               companyId,
               yearly,
            })
         ).data;
         commit('setFinancialData', {yearly: updatedData.yearly});
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.PERMISSION_DENIED) {
            commit(
               'showAlert',
               {
                  msg: "You don't have permission to edit financial data",
                  seconds: 5,
               },
               {root: true}
            );
            dispatch('loadContractors', {companyId});
            await dispatch(
               'contractorPermissions/loadUserPermissions',
               {
                  userId: rootGetters.profile.id,
               },
               {root: true}
            );
         } else if (errCode === ErrorCodes.LOCKED) {
            commit(
               'showAlert',
               {
                  msg: 'This section has been completed. Changes could not be saved.',
                  seconds: 10,
               },
               {root: true}
            );
            dispatch(
               'companies/loadCompany',
               {
                  companyId: rootGetters['companies/currentCompany'].id,
                  force: true,
               },
               {root: true}
            );
         }
      }
   },

   /** Load the financial document upload category */
   async loadFinancialDocuments({commit, dispatch}, {companyId}) {
      const uploadCategory = await dispatch(
         'uploads/loadSpecialCategory',
         {
            companyId,
            category: SpecialCategories.CONTRACTOR_FINANCIAL_DOCS,
         },
         {root: true}
      );
      commit('setFinancialDocumentCategoryId', {
         uploadCategoryId: uploadCategory.id,
      });
   },

   /** Upload to the financial documents upload category */
   async uploadFinancialDocuments({state, dispatch}, {files}) {
      if (files.length === 0) {
         return;
      }
      const validation = {
         type: SpecialCategories.CONTRACTOR_FINANCIAL_DOCS,
      };

      await dispatch(
         'uploads/uploadFiles',
         {
            uploadCategoryId: state.financialDocumentCategoryId,
            year: null,
            files,
            validation,
         },
         {root: true}
      );
   },

   /** Delete a financial document */
   async deleteFinancialDocument({state, dispatch}, {fileId, force = false}) {
      await dispatch(
         'uploads/deleteFile',
         {
            fileId,
            uploadCategoryId: state.financialDocumentCategoryId,
            force,
         },
         {root: true}
      );
   },

   /** Set the completed state of the contractor section */
   async saveCompleted({commit}, {companyId, completed}) {
      try {
         const companies = (
            await this._vm.$http.put(`/api/company/${companyId}/contractors`, {completed})
         ).data;
         commit('companies/setContractors', {companies}, {root: true});
      } catch (err) {
         if (completed) {
            commit(
               'showAlert',
               {
                  msg: 'The section could not be validated',
                  seconds: 5,
               },
               {root: true}
            );
            throw err;
         } else {
            commit(
               'showAlert',
               {
                  msg: 'The section could not be unlocked',
                  seconds: 5,
               },
               {root: true}
            );
            throw err;
         }
      }
   },

   /** Mark the Contractor Section assignment as complete */
   async completeAssignment({commit}, {companyId}) {
      const assignments = (
         await this._vm.$http.put(`/api/company/${companyId}/contractor/assignment/complete`)
      ).data;
      await commit('companies/setContractorAssignments', {assignments}, {root: true});
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
