<template>
   <g>
      <path
         fill-rule="evenodd"
         clip-rule="evenodd"
         d="M5.23977 3.62711L19.8379 10.9243C20.2456 11.128 20.5032 11.5447 20.5032 12.0005C20.5032 12.4562 20.2456 12.8729 19.8379 13.0766L5.23977 20.3738C4.81675 20.5854 4.30903 20.5295 3.94212 20.2311C3.57521 19.9326 3.41715 19.4469 3.53814 18.9897L5.38939 12.0005L3.53814 5.01125C3.41715 4.55402 3.57521 4.06831 3.94212 3.76985C4.30903 3.4714 4.81675 3.41555 5.23977 3.62711Z"
         fill="white"
      />
      <path
         d="M10.8828 12.0002H5.38965"
         stroke="url(#paint0_linear_12390_45015)"
         stroke-width="1.5"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
      <defs>
         <linearGradient
            id="paint0_linear_12390_45015"
            x1="3.65696"
            y1="12.5004"
            x2="5.73268"
            y2="15.5076"
            gradientUnits="userSpaceOnUse"
         >
            <stop offset="0.265625" stop-color="#1C72B9" />
            <stop offset="1" stop-color="#01498C" />
         </linearGradient>
      </defs>
   </g>
</template>

<script>
export default {
   data() {
      return {
         name: 'icon-check',
         viewBox: '0 0 24 24',
      };
   },
};
</script>
