<template>
   <g>
      <path
         d="M12 0C5.39264 0 0 5.39288 0 12C0 18.6074 5.39292 24 12 24C18.6075 24 24 18.6071 24 12C24 5.39255 18.6071 0 12 0ZM12 22.5938C6.15858 22.5938 1.40625 17.8414 1.40625 12C1.40625 6.15858 6.15858 1.40625 12 1.40625C17.8414 1.40625 22.5938 6.15858 22.5938 12C22.5938 17.8414 17.8414 22.5938 12 22.5938ZM15.5156 7.78125C15.5156 5.84273 13.9385 4.26562 12 4.26562C10.0615 4.26562 8.48438 5.84273 8.48438 7.78125C8.48438 9.71977 10.0615 11.2969 12 11.2969C13.9385 11.2969 15.5156 9.71977 15.5156 7.78125ZM12 9.89062C10.8369 9.89062 9.89062 8.94436 9.89062 7.78125C9.89062 6.61814 10.8369 5.67188 12 5.67188C13.1631 5.67188 14.1094 6.61814 14.1094 7.78125C14.1094 8.94436 13.1631 9.89062 12 9.89062ZM12 11.2969C8.51067 11.2969 5.67188 14.1357 5.67188 17.625V18.284C5.67188 18.4838 5.75691 18.6742 5.90578 18.8076C7.6185 20.3423 9.78281 21.1875 12 21.1875C14.2172 21.1875 16.3815 20.3423 18.0942 18.8076C18.2431 18.6742 18.3281 18.4838 18.3281 18.284V17.625C18.3281 14.1357 15.4893 11.2969 12 11.2969ZM16.9219 17.9622C15.5076 19.1374 13.7714 19.7812 12 19.7812C10.2286 19.7812 8.49244 19.1374 7.07812 17.9622V17.625C7.07812 14.9111 9.28608 12.7031 12 12.7031C14.7139 12.7031 16.9219 14.9111 16.9219 17.625V17.9622Z"
      />
   </g>
</template>

<script>
export default {
   data() {
      return {
         name: 'icon-avatar',
         viewBox: '0 0 24 24',
      };
   },
};
</script>
