var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('h1',{staticClass:"mb-3"},[_vm._v("Configure Business Components")]),_c('b-card',[_c('b-table',{staticClass:"mb-0",attrs:{"id":"table-edit-projects","fields":[
            {key: 'name', sortable: true},
            {key: 'years.lower', label: 'Start Year', sortable: true},
            {
               key: 'years.upper',
               label: 'End Year',
               sortable: true,
               formatter: function (value) { return value || 'Ongoing'; },
            },
            'actions' ],"sort-by":"name","items":_vm.projects},scopedSlots:_vm._u([{key:"head(actions)",fn:function(){return [_c('div',{staticClass:"cell-w-buttons d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.newProjectModal}},[_vm._v(" New Business Component ")])],1)]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"cell-w-buttons d-flex justify-content-end"},[(_vm.isStaff)?_c('b-button',{staticClass:"mr-2",attrs:{"id":"btn-delete","variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deleteProjectModal(data.item)}}},[_vm._v(" Delete ")]):_vm._e(),_c('b-button',{attrs:{"id":"btn-edit","variant":"primary","size":"sm"},on:{"click":function($event){return _vm.editProjectModal(data.item)}}},[_vm._v(" Edit ")])],1)]}}])})],1),_c('b-modal',{attrs:{"id":"modal-new-project","title":"New Business Component","centered":"","ok-title":"Submit","ok-disabled":!_vm.newProjectValid},on:{"ok":_vm.saveNewProject,"shown":_vm.focusName}},[_c('ProjectForm',{ref:"newProjectForm",attrs:{"value":_vm.newProject},on:{"input":_vm.updateNewProject,"submit":_vm.saveNewProject,"validate":_vm.validateNewProject}})],1),_c('b-modal',{attrs:{"id":"modal-edit-project","title":"Edit Business Component","centered":"","ok-title":"Submit","ok-disabled":!_vm.editProjectValid},on:{"ok":_vm.saveEditProject}},[_c('ProjectForm',{attrs:{"value":_vm.editProject},on:{"input":_vm.updateEditProject,"submit":_vm.saveEditProject,"validate":_vm.validateEditProject}})],1),_c('b-modal',{attrs:{"id":"modal-delete-project","title":"Delete Business Component","centered":"","ok-title":"Delete","ok-variant":"danger"},on:{"ok":_vm.submitDeleteProject}},[_vm._v(" Are you sure you want to delete business component "),_c('b',[_vm._v(_vm._s(_vm.deleteProject.name))]),_vm._v("? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }