function StateCreditYear() {
   this.state = null;
   this.years = [null];
}

export function Company() {
   this.companyName = null;
   this.filingDeadline = null;
   this.fein = null;
   this.entityType = null;
   this.taxYearEnd = null;
   this.industryId = null;
   this.federalCreditYears = {years: [null]};
   this.stateCreditYears = [new StateCreditYear()];
   this.payrollTaxElection = false;
   this.incorporated = null;
   this.firstRndEffort = null;
   this.latestCredit = null;
   this.businessManagers = [];
   this.projectManagers = [];
   this.projectAssociates = [];
   this.cpaInfo = {name: null, firm: null, email: null};
   this.contactInfo = {name: null, email: null};
   this.prompts = {supplies: null, uploads: null};
}

export const QuestionnaireTypes = Object.freeze({
   COMPANY: 'COMPANY',
   PROJECT: 'PROJECT',
   PROFILE: 'PROFILE',
});

export const AnswerTypes = Object.freeze({
   TEXT: 'TEXT',
   EMAIL: 'EMAIL',
   DATE: 'DATE',
   INTEGER: 'INTEGER',
   NUMBER: 'NUMBER',
   BOOLEAN: 'BOOLEAN',
   CHOICE: 'CHOICE',
   PROJECT_YEARS: 'PROJECT_YEARS',
});

export function Answer() {
   this.answer = null;
   this.submitted = null;
   this.submittedBy = null;
   this.dirty = false;
}

let questionKey = 0;

export function Question(key = null) {
   this.qorder = 0;
   this.question = null;
   this.ansType = null;
   this.validation = {
      minimum: null,
      maximum: null,
      options: [],
      multiple: false,
   };
   this.answer = null;
   this.answers = [];

   if (key) {
      this.key = key;
   } else {
      this.key = questionKey++;
   }
}

export class UploadCategory {
   static #key = 0;

   constructor({
      id = null,
      companyId = null,
      name = null,
      projects = null,
      completedAt = null,
      completedBy = null,
      internal = false,
      summary = [],
   }) {
      this.id = id;
      this.companyId = companyId;
      this.name = name;
      this.projects = projects;
      this.completedAt = completedAt;
      this.completedBy = completedBy;
      this.internal = internal;
      this.summary = summary;

      this.key = UploadCategory.#key++;
   }
}
