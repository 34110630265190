<template>
   <div class="d-flex align-items-start">
      <span class="line-clamp-2" v-b-tooltip="activityName">{{ activityName }}</span>
      <b-icon
         v-if="description"
         :id="`icon-activity-q-${ident}`"
         :icon="icon"
         class="ml-2 mt-1"
         variant="secondary"
         tabindex="-1"
         @click.prevent
      />

      <b-popover
         :target="`icon-activity-q-${ident}`"
         triggers="hover focus"
         placement="top"
         @show="popoverActive = true"
         @hidden="popoverActive = false"
      >
         <template #title>{{ activityName }}</template>
         {{ description }}
      </b-popover>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   props: {
      ident: {
         type: [String, Number],
         default: '',
      },

      activityName: {
         type: String,
      },

      description: {
         type: String,
         default: null,
      },
   },

   data() {
      return {
         popoverActive: false,
      };
   },

   computed: {
      ...mapGetters({
         company: 'companies/currentCompany',
         industryMap: 'industries/industryMap',
      }),

      industryId() {
         return this.company.industryId;
      },

      icon() {
         return this.popoverActive ? 'question-circle-fill' : 'question-circle';
      },
   },
};
</script>
