<template>
   <b-container>
      <PageTitle title="Cloud Computing" />

      <SectionAssignment section="cloud" />

      <ManagerMessage v-if="manager">
         <div v-if="completed">
            <h2>You're all done with this section!</h2>
            <p>Head over to the dashboard to find out what you need to do next.</p>
            <div class="d-flex justify-content-end">
               <b-button variant="success" :to="{name: 'Home'}">Dashboard</b-button>
            </div>
         </div>
         <div v-else-if="dataComplete">
            <h2 class="mb-0">Does everything look correct?</h2>
            <p>
               If all the information shown here looks correct, click
               <b>{{ hasSectionAssignment ? 'Done' : 'Complete' }}</b> below to finish up.
            </p>
         </div>
         <div v-else-if="hasSectionAssignment">
            <h2>This section still needs some data.</h2>
            <p>
               This section is not yet complete, but if you've entered all the information you have
               you can click Done below to be done with your assignment.
            </p>
         </div>
         <div v-else>
            <h2>This section still needs some data.</h2>
            <p>
               Finish entering required data in the previous screen, then come back here to complete
               this section.
            </p>
         </div>
      </ManagerMessage>

      <b-alert variant="success" class="mb-5" :show="!manager && completed">
         This section has been submitted.
      </b-alert>

      <div v-for="year in years" :key="year">
         <h2 :id="`header-${year}`" class="mb-4">Cloud Computing purchases for {{ year }}</h2>

         <b-card class="mb-4">
            <template v-if="year in accountData">
               <div
                  v-for="(account, accountIndex) in sortedAccounts(year)"
                  :key="`${year}:${account}`"
                  class="mb-4"
               >
                  <h2 :id="`account-name-${year}-${accountIndex + 1}`" class="mb-4">
                     {{ account }}
                  </h2>

                  <b-row class="mb-3">
                     <b-col cols="4">
                        <b>Vendor</b>
                     </b-col>
                     <b-col cols="2">
                        <b>Total Debits</b>
                     </b-col>
                     <b-col cols="2">
                        <b>Total Credits</b>
                     </b-col>
                     <b-col cols="2">
                        <b>Net Amount</b>
                     </b-col>
                     <b-col cols="2">
                        <b>Percentage</b>
                     </b-col>
                  </b-row>
                  <b-row
                     v-for="(
                        {vendor, totalDebits, totalCredits, netAmount, percentage}, index
                     ) in sortedVendors(year, account)"
                     :key="`${year}:${account}:${vendor}`"
                     class="mb-3"
                  >
                     <b-col :id="`vendor-${year}-${index}`" cols="4">
                        <b>{{ vendor }}</b>
                     </b-col>
                     <b-col :id="`total-debit-${year}-${index}`" cols="2">{{
                        formatMoney(totalDebits)
                     }}</b-col>
                     <b-col :id="`total-credit-${year}-${index}`" cols="2">{{
                        formatMoney(totalCredits)
                     }}</b-col>
                     <b-col :id="`net-amount-${year}-${index}`" cols="2">
                        {{ formatMoney(netAmount.value) }}
                     </b-col>
                     <b-col :id="`percentage-${year}-${index}`" cols="2">
                        {{ formatPercent(percentage.value) }}
                        <b-icon
                           icon="exclamation-circle-fill"
                           v-if="percentage.value === null"
                           class="text-warning ml-2"
                           v-b-tooltip="
                              'This vendor\'s qualifying percentage has not been entered.'
                           "
                        ></b-icon>
                     </b-col>
                  </b-row>
               </div>
            </template>

            <div v-if="Object.keys(adHocData).length > 0">
               <h2 class="mb-4">Manually-Added Vendors</h2>
               <div>
                  <b-row class="mb-3">
                     <b-col cols="4">
                        <b>Vendor</b>
                     </b-col>
                     <b-col cols="2">
                        <b>Net Amount</b>
                     </b-col>
                     <b-col cols="2">
                        <b>Percentage</b>
                     </b-col>
                  </b-row>
                  <b-row
                     v-for="({vendor, netAmount, percentage}, index) in sortedVendors(year, null)"
                     :key="`${year}:${vendor}`"
                     class="mb-3"
                  >
                     <b-col :id="`vendor-adhoc-${year}-${index}`" cols="4">
                        <b>{{ vendor }}</b>
                     </b-col>
                     <b-col :id="`net-amount-adhoc-${year}-${index}`" cols="2">
                        {{ formatMoney(netAmount.value) }}
                        <b-icon
                           icon="exclamation-circle-fill"
                           v-if="netAmount.value === null"
                           class="text-warning ml-2"
                           v-b-tooltip="'This vendor\'s net amount has not been entered.'"
                        ></b-icon>
                     </b-col>
                     <b-col :id="`percentage-adhoc-${year}-${index}`" cols="2">
                        {{ formatPercent(percentage.value) }}
                        <b-icon
                           icon="exclamation-circle-fill"
                           v-if="percentage.value === null"
                           class="text-warning ml-2"
                           v-b-tooltip="
                              'This vendor\'s qualifying percentage has not been entered.'
                           "
                        ></b-icon>
                     </b-col>
                  </b-row>
               </div>
            </div>
         </b-card>
      </div>

      <div class="d-flex align-items-center justify-content-between">
         <b-button
            class="d-flex align-items-center"
            :to="$clientStaffRoute({name: 'cloud-percentages'})"
         >
            <b-icon-arrow-left-short class="mr-1" />
            Back
         </b-button>

         <b-button
            id="btn-submit"
            variant="success"
            v-if="!completed && !hasSectionAssignment"
            :disabled="!dataComplete"
            @click="submit"
         >
            Complete <b-icon-check />
         </b-button>
         <b-button
            v-if="hasSectionAssignment"
            id="btn-complete-assignment"
            variant="success"
            @click="completeAssignment"
         >
            Done <b-icon-check />
         </b-button>
      </div>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {sortBy} from '@/helpers/utils';
import SectionAssignment from '../components/SectionAssignment';

export default {
   components: {
      SectionAssignment,
   },

   computed: {
      ...mapGetters({
         hasSectionAssignment: 'cloud/hasSectionAssignment',
         isSME: 'isSME',
         manager: 'manager',
         accountData: 'cloud/accountData',
         adHocData: 'cloud/adHocData',
         completed: 'cloud/isCompleted',
      }),

      years() {
         return Object.keys({
            ...this.accountData,
            ...this.adHocData,
         });
      },

      dataComplete() {
         const accountDataComplete = Object.values(this.accountData).reduce((yearAgg, yearData) => {
            const yearComplete = Object.values(yearData).reduce((accountAgg, vendors) => {
               const accountComplete = vendors.every((v) => v.percentage.value !== null);
               return accountAgg && accountComplete;
            }, true);
            return yearAgg && yearComplete;
         }, true);

         if (!accountDataComplete) {
            return false;
         }

         return Object.values(this.adHocData).reduce((yearAgg, vendors) => {
            const yearComplete = vendors.every(
               (v) => v.percentage.value !== null && v.netAmount.value !== null
            );
            return yearAgg && yearComplete;
         }, true);
      },
   },

   methods: {
      /** Complete a section assignment */
      async completeAssignment() {
         await this.blockingRequest('cloud/completeSectionAssignment', {
            companyId: this.$companyId,
         });

         if (this.isSME) {
            this.$router.push({name: 'Home'});
         }
      },

      /** Account names of a given year, sorted alphabetically */
      sortedAccounts(year) {
         return Object.keys(this.accountData[year]).sort();
      },

      /** Vendors of a given year and account, sorted by vendor name */
      sortedVendors(year, account) {
         const vendors = account === null ? this.adHocData[year] : this.accountData[year][account];
         return [...vendors].sort(sortBy('vendor'));
      },

      /** Format cents as USD */
      formatMoney(cents) {
         if (cents === null) {
            return null;
         }
         const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
         });
         return formatter.format(cents / 100);
      },

      /** Format a percentage */
      formatPercent(percentage) {
         if (percentage === null) {
            return '';
         }
         return `${percentage}%`;
      },

      async submit() {
         // sanity check
         if (!this.dataComplete) {
            return;
         }
         await this.blockingRequest('cloud/submit', {companyId: this.$companyId});
      },
   },

   async created() {
      const requests = [
         this.$store.dispatch('cloud/loadData', {
            companyId: this.$companyId,
         }),

         // Refresh company data to update study
         this.$store.dispatch('companies/loadCompany', {companyId: this.$companyId, force: true}),
      ];
      await this.blockUntilAllSettled(requests);
   },
};
</script>
