<template>
   <div>
      <v-date-picker
         v-model="answerValue"
         :min-date="validationMinimum"
         :max-date="validationMaximum"
         :input-debounce="500"
         v-if="!readonly"
      >
         <template v-slot="{inputValue, inputEvents}">
            <input
               id="input-answer"
               class="form-control"
               :value="inputValue"
               v-on="inputEvents"
               placeholder="mm/dd/yyyy"
            />
         </template>
      </v-date-picker>
      <div v-else :id="`answer-${index + 1}`">
         <div v-if="answerValue !== null">
            {{ answerValue.toLocaleDateString() }}
         </div>
         <div v-else>
            <b>Not Answered</b>
         </div>
      </div>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   props: {
      index: Number,
      readonly: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      ...mapGetters({
         questions: 'questionnaire/questions',
      }),

      question() {
         return this.questions[this.index];
      },

      answer() {
         return this.question.answer;
      },

      validationMinimum() {
         return this.question.validation.minimum;
      },

      validationMaximum() {
         return this.question.validation.maximum;
      },

      answerValue: {
         get() {
            if (this.answer) {
               return this.answer.answer;
            } else {
               return null;
            }
         },
         set(value) {
            this.$store.commit('questionnaire/setAnswerValue', {
               questionIndex: this.index,
               value,
            });
         },
      },
   },

   watch: {
      answerValue: {
         immediate: true,
         handler() {
            this.$emit('validate', true);
         },
      },
   },
};
</script>
