<template>
   <b-container>
      <PageTitle title="Contract Research" />

      <transition name="fade" mode="out-in">
         <div v-if="displayIntro" key="intro-content">
            <p class="mb-3">
               Contractor wages spent on research and development are a key source of R&D tax
               credits. In this section, you will tell us about the contract-based workers who
               contributed to R&D for your company and share their wage information. This is often
               information that no single person at your company will know completely, so in this
               section it is easy for you to invite others at your company to collaborate with you
               on the data entry. Let's get to it!
            </p>

            <div class="d-flex align-items-center justify-content-end">
               <b-button
                  id="btn-begin"
                  variant="success"
                  class="d-flex align-items-center"
                  @click="hideIntro"
               >
                  Begin
                  <b-icon-arrow-right-short class="ml-1" />
               </b-button>
            </div>
         </div>

         <div v-else-if="loaded" key="main-content">
            <div class="d-flex align-items-center justify-content-end mb-4">
               <b-button
                  v-if="!isSME"
                  id="btn-assign-section"
                  variant="secondary"
                  class="mr-2"
                  size="sm"
                  :to="$clientStaffRoute({name: 'contractors-permissions'})"
               >
                  Assign Section
               </b-button>
            </div>

            <ManagerMessage v-if="manager" :key="managerMessage.noCollapse">
               <template #short v-if="!managerMessage.noCollapse">
                  {{ managerMessage.short }}
               </template>

               <h2>{{ managerMessage.short }}</h2>

               <p class="mb-0">
                  {{ managerMessage.long }}
               </p>

               <div class="d-flex align-items-center justify-content-end mt-3">
                  <b-button
                     variant="primary"
                     v-if="!isSME && isComplete"
                     class="ml-2"
                     @click="toggleCompletion"
                  >
                     Unlock
                  </b-button>
                  <b-button v-if="isComplete" variant="success" :to="{name: 'Home'}" class="ml-2">
                     Dashboard
                  </b-button>
               </div>
            </ManagerMessage>
            <div v-else>
               <b-alert
                  :show="isComplete"
                  variant="success"
                  class="d-flex align-items-center justify-content-between mb-4"
               >
                  This section has been marked complete.
                  <b-button v-if="!isSME" variant="primary" @click="toggleCompletion">
                     Unlock
                  </b-button>
               </b-alert>
            </div>

            <div v-if="loaded" class="d-flex align-items-center justify-content-end mb-4">
               <b-button
                  v-if="canEditAnyFinancialData"
                  id="btn-financial-data"
                  variant="primary"
                  class="mr-2"
                  @click="displayFinancialData()"
               >
                  Enter Payments Information
               </b-button>
               <b-button
                  id="btn-add-contractor"
                  v-if="canCreateContractors"
                  variant="primary"
                  @click="displayAddContractor"
                  :disabled="isComplete"
               >
                  Add a Contract Researcher <b-icon-plus />
               </b-button>
            </div>

            <b-table
               v-if="loaded"
               id="table-contractors"
               class="scrollbar"
               :items="contractors"
               :fields="fields"
               sort-by="fullname"
               sticky-header="420px"
               small
               show-empty
            >
               <template #cell(fullname)="data">
                  <b-link
                     v-if="canEditInfo(data.item.id)"
                     :id="`btn-edit-${data.index}`"
                     @click="displayEditContractor(data.item.id)"
                  >
                     <b>{{ data.value }}</b>
                  </b-link>

                  <b v-else>{{ data.value }}</b>
               </template>
               <template #cell(contractType)="data">
                  {{ data.value ? ContractTypes[data.value] : '' }}
               </template>
               <template #cell(location)="data">
                  {{ data.value ? usStates[data.value] : '' }}
               </template>
               <template #cell(info)="data">
                  <RdigStatusPill
                     :ident="`info-${data.index}`"
                     :status="contractorStatusType(infoStatusMap[data.item.id])"
                     btn
                     :disabled="!canEditInfo(data.item.id)"
                     @click.native="displayEditContractor(data.item.id)"
                  />
               </template>
               <template #cell(financial)="data">
                  <RdigStatusPill
                     :ident="`payment-${data.index}`"
                     :status="contractorStatusType(financialDataStatusMap[data.item.id])"
                     btn
                     :disabled="!canEditFinancialData(data.item.id)"
                     @click.native="
                        canEditFinancialData(data.item.id)
                           ? displayFinancialData(data.item.id)
                           : null
                     "
                  />
               </template>
               <template #cell(time)="data">
                  <RdigStatusPill
                     :ident="`time-${data.index}`"
                     :status="
                        contractorStatusType(
                           contractorTimeStatus[data.item.id],
                           canEditTimeData(data.item.id)
                        )
                     "
                     btn
                     :disabled="!canEditTimeData(data.item.id)"
                     @click.native="toContractorTime(data.item.id)"
                  />
               </template>
               <template #cell(actions)="data">
                  <div class="cell-w-buttons justify-content-end">
                     <button
                        v-if="canDeleteContractors && !isComplete"
                        :id="`btn-delete-${data.index}`"
                        class="icon-btn icon-btn-danger"
                        type="button"
                        @click="deleteContractor(data.item.id)"
                     >
                        <b-icon-trash></b-icon-trash>
                     </button>
                  </div>
               </template>

               <template #empty>
                  <div class="empty-message">
                     <span>No contract researchers have been added yet.</span>
                  </div>
               </template>
            </b-table>

            <div v-if="loaded" class="d-flex align-items-center justify-content-between mb-3">
               <b-button
                  id="btn-contractor-introduction"
                  variant="secondary"
                  class="d-flex align-items-center ml-2"
                  @click="displayIntro = true"
               >
                  <b-icon-arrow-left-short class="mr-1" />
                  View Introduction
               </b-button>

               <b-button
                  id="btn-time-data"
                  variant="primary"
                  :to="$clientStaffRoute({name: 'contractor-time-years'})"
                  :disabled="contractors.length === 0"
               >
                  Enter R&D Time Information
               </b-button>
            </div>

            <div class="d-flex align-items-center justify-content-end" v-if="!isComplete">
               <b-button
                  v-if="isSME"
                  id="btn-done"
                  class="d-flex align-items-center"
                  variant="success"
                  @click="toggleCompletion"
               >
                  Done
                  <b-icon-check class="ml-1" />
               </b-button>
               <b-button
                  v-else
                  id="btn-complete"
                  class="d-flex align-items-center"
                  variant="success"
                  :disabled="!canValidate"
                  @click="toggleCompletion"
               >
                  Complete
                  <b-icon-check class="ml-1" />
               </b-button>
            </div>
         </div>
         <div
            v-else
            key="loading-content"
            class="d-flex align-items-center justify-content-center py-4"
         >
            <b-spinner id="spinner-content" variant="primary"></b-spinner>
         </div>
      </transition>

      <AddContractorModal ref="addContractor" />
      <EditContractorModal ref="editContractor" />
      <FinancialDataModal ref="financialData" />
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {ContractTypes, ContractorStatus} from '@/store/modules/contractors';

import AddContractorModal from './widgets/AddContractorModal';
import EditContractorModal from './widgets/EditContractorModal';
import FinancialDataModal from './widgets/FinancialDataModal';

export default {
   components: {
      AddContractorModal,
      EditContractorModal,
      FinancialDataModal,
   },

   data() {
      return {
         loaded: false,
         displayIntro: true,
         ContractTypes,
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         isSME: 'isSME',
         isCustomer: 'isCustomer',
         isRndig: 'isRndig',
         manager: 'manager',
         clientState: 'clientState',
         usStates: 'usStatesOutsideUS',
         studyYears: 'companies/studyYears',
         isComplete: 'contractors/isComplete',
         canValidate: 'contractors/canValidate',
         contractors: 'contractors/contractors',
         contractorMap: 'contractors/contractorMap',
         contractorContracts: 'contractors/contractorContracts',
         financialData: 'contractors/financialData',
         infoStatusMap: 'contractors/infoStatusMap',
         financialDataStatusMap: 'contractors/financialDataStatusMap',
         permissions: 'contractorPermissions/userPermissions',
         canCreateContractors: 'contractorPermissions/canCreateContractors',
         canDeleteContractors: 'contractorPermissions/canDeleteContractors',
         canEditInfo: 'contractorPermissions/canEditInfo',
         canEditFinancialData: 'contractorPermissions/canEditFinancialData',
         canEditTimeData: 'contractorPermissions/canEditTimeData',
         hasAnyPermission: 'progress/hasContractorAssignment',
         contractorTimeStatus: 'contractorTime/contractorStatus',
         firstIncompleteTimeYear: 'contractorTime/firstIncompleteTimeYear',
      }),

      companyId() {
         return this.$route.params.id ? this.$route.params.id : this.profile.companyId;
      },

      /** Has the current user seen the section intro? */
      seenIntro() {
         return this.clientState?.sectionIntros.contractors;
      },

      canEditAnyFinancialData() {
         return this.contractors.some((contractor) => this.canEditFinancialData(contractor.id));
      },

      fields() {
         let fields = [
            {
               key: 'fullname',
               label: 'Contract Researcher Name',
               stickyColumn: true,
               sortable: true,
            },
            {key: 'contractType', label: 'Fee Type', sortable: true},
            {key: 'location', sortable: true},
            {key: 'info', label: 'Basic Info'},
            {key: 'financial', label: 'Payments'},
            {key: 'time', label: 'R&D Time'},
            {key: 'actions', label: ''},
         ];
         if (!this.canEditAnyFinancialData) {
            fields = fields.filter((field) => field.key !== 'financial');
         }

         return fields;
      },

      managerMessage() {
         let short = null,
            long = null,
            noCollapse = false;

         if (this.isComplete) {
            short = 'This section is complete!';
            long =
               "Visit your dashboard to see what's next. If you need to modify the info in this section you must unlock it first. Please note that this may cause delays in your credit calculation.";
            noCollapse = true;
         } else if (this.contractors.length === 0) {
            // No contractors
            short = 'Tell us about your contract researchers & developers.';
            long = 'Add all the contract researchers who worked on R&D for your company.';
            if (this.isSME) {
               short = 'Add the contract researchers & developers you know about.';
            } else {
               long +=
                  ' Not sure? Invite collaborators who might know and you can work together on this section.';
            }
         } else {
            if (this.canValidate) {
               // Ready to submit
               short = 'This section may be ready to mark complete.';
               long =
                  "We have all the info we need for the contract researchers you've added so far. ";
               if (this.isSME) {
                  short = 'Add another contract researcher if you know of one.';
                  long +=
                     "If there are no more contract researchers to add, there's nothing left for you to do here at this time.";
               } else {
                  long +=
                     "Add more contract researchers, or mark this section complete if you're all done.";
               }
            } else {
               // More work to do
               short = 'Tell us about your contract researchers & developers.';
               long =
                  'Finish telling us about the contract researchers that worked on R&D for your company here.';
               if (!this.isSME) {
                  long +=
                     ' Remember that you can invite other users to collaborate on this section.';
               }
            }
         }

         return {short, long, noCollapse};
      },
   },

   methods: {
      async loadData() {
         const requests = [
            this.$store.dispatch('contractorPermissions/loadUserPermissions', {
               userId: this.profile.id,
            }),
            this.$store.dispatch('contractors/loadContractors', {
               companyId: this.companyId,
            }),
            this.$store.dispatch('contractors/loadContractFiles', {
               companyId: this.companyId,
            }),
            this.$store.dispatch('contractors/loadFinancialDocuments', {
               companyId: this.companyId,
            }),
            this.$store.dispatch('users/loadCompanyUsers', {
               companyId: this.companyId,
            }),
            this.$store.dispatch('activities/loadActivities', {
               companyId: this.companyId,
            }),
            this.$store.dispatch('projects/loadProjects', {
               companyId: this.companyId,
            }),
         ];
         await Promise.allSettled(requests);
      },

      /** Hide the intro and update the client state indicating this user has seent the intro */
      hideIntro() {
         if (!this.seenIntro) {
            this.$store.dispatch('setSectionIntro', {
               section: 'contractors',
               value: true,
            });
         }
         this.displayIntro = false;
      },

      /** Display the new contractor modal */
      displayAddContractor() {
         this.$refs.addContractor.show();
      },

      /** Display the edit contractor modal */
      displayEditContractor(contractorId) {
         this.$refs.editContractor.show(contractorId);
      },

      /** Display the financial data modal */
      displayFinancialData(contractorIdToExpand) {
         this.$refs.financialData.show(contractorIdToExpand);
      },

      /**
       * Navigate to the first year in which the contractor has incomplete time,
       * or the first year if all years are complete.
       */
      toContractorTime(contractorId) {
         let year = this.firstIncompleteTimeYear(contractorId);
         year = year ? year : this.studyYears[0];
         this.$router.push(this.$clientStaffRoute({name: 'contractor-time-data', params: {year}}));
      },

      /** Maps a ContractorStatus to a bootstrap variant */
      statusToVariant(status) {
         switch (status) {
            case ContractorStatus.NOT_STARTED:
               return 'danger';
            case ContractorStatus.INCOMPLETE:
               return 'warning';
            case ContractorStatus.COMPLETE:
               return 'success';
            default:
               return 'gray';
         }
      },

      /** Map ContractorStatus to StatusType */
      contractorStatusType(status, hasPermission = true) {
         if (!hasPermission) {
            return this.$constants().StatusType.NA;
         }
         switch (status) {
            case ContractorStatus.NOT_STARTED:
               return this.$constants().StatusType.INCOMPLETE;
            case ContractorStatus.INCOMPLETE:
               return this.$constants().StatusType.IN_PROGRESS;
            case ContractorStatus.COMPLETE:
               return this.$constants().StatusType.COMPLETE;
            default:
               return this.$constants().StatusType.NA;
         }
      },

      /** Delete a contractor */
      async deleteContractor(contractorId) {
         const contractor = this.contractorMap[contractorId];
         const proceed = await this.$bvModal.msgBoxConfirm(
            `Are you sure you want to delete "${contractor.fullname}" and any associated data?`,
            {
               id: 'modal-delete-contractor',
               title: 'Delete this contract researcher?',
               centered: true,
            }
         );
         if (proceed) {
            await this.blockingRequest('contractors/deleteContractor', {
               contractorId,
               force: proceed,
            });
            await this.$store.commit('contractorTime/clearContractorData', {contractorId});
         }
      },

      async toggleCompletion() {
         let msg, title;
         if (this.isSME) {
            msg =
               "Be sure you've entered all the information you can for this section. You will be unable to enter more data without being reassigned.";
            title = 'All done?';
         } else {
            if (this.isComplete) {
               msg =
                  "Unlocking this section may delay your credit calculation. You will have to assign users again and will have to mark the section complete again once you're done.";
               title = 'Unlock this section?';
            } else {
               msg =
                  'Marking this section complete will unassign invited users from this section and lock the section to data entry. To modify this section afterward will require you to unlock this section, which may delay your credit calculation.';
               title = 'Is everything complete and correct?';
            }
         }

         const proceed = await this.$bvModal.msgBoxConfirm(msg, {
            title,
            centered: true,
         });

         if (proceed) {
            try {
               if (this.isSME) {
                  await this.blockingRequest('contractors/completeAssignment', {
                     companyId: this.companyId,
                  });
                  await this.$router.push({name: 'Home'});
               } else {
                  await this.blockingRequest('contractors/saveCompleted', {
                     companyId: this.companyId,
                     completed: !this.isComplete,
                  });
               }
            } catch {
               await this.loadData();
            }
         }
      },
   },

   async created() {
      this.displayIntro = !this.seenIntro && !this.isRndig;
      await this.loadData();

      if (this.isSME && !this.hasAnyPermission) {
         this.$router.push({name: 'Home'});
      }
      this.loaded = true;
   },
};
</script>

<style lang="scss" scoped>
.time-breakdown {
   min-width: 8rem;
}
.empty-message {
   text-align: center;
}

.edit-btn {
   border-radius: 50rem;
   padding: 4px;
   font-size: 0.8rem;
   display: flex;
   align-items: center;
   justify-content: center;
}

.icon-btn-dot {
   font-size: 1rem;
}
</style>
