<template>
   <div>
      <b-card v-if="!templateSelected">
         <div class="d-flex align-items-center justify-content-between mb-2">
            <h2 class="mb-0">Import Template</h2>
            <b-button id="btn-blank-questionnaire" variant="primary" @click="openBlankTemplate">
               Blank Questionnaire
            </b-button>
         </div>
         <p class="mb-4">
            Would you like to import a questionnaire template, or start from a blank questionnaire?
         </p>

         <QTemplateSelect :default-filters="defaultTemplateFilters">
            <template #actions="{templateId}">
               <div class="cell-w-buttons justify-content-end">
                  <b-button variant="primary" size="sm" @click="loadTemplate(templateId)">
                     Select
                  </b-button>
               </div>
            </template>
         </QTemplateSelect>
      </b-card>
      <QuestionnaireForm
         v-else
         :duplicateTitles="duplicateTitles"
         :is-project="isProject"
         @submit="submit"
      />
   </div>
</template>

<script>
import {mapGetters} from 'vuex';
import QuestionnaireForm from './QuestionnaireForm';
import QTemplateSelect from './QTemplateSelect';

export default {
   components: {
      QuestionnaireForm,
      QTemplateSelect,
   },

   props: {
      duplicateTitles: {
         type: Array,
         default: () => [],
      },
      isProject: {
         type: Boolean,
         default: false,
      },
   },

   data() {
      return {
         templateSelected: false,
      };
   },

   computed: {
      ...mapGetters({
         questions: 'questionnaire/questions',
         title: 'questionnaire/title',
         study: 'companies/study',
         company: 'companies/currentCompany',
      }),

      companyId() {
         return this.$route.params.id;
      },

      defaultTemplateFilters() {
         const filters = {
            industry: this.company.industryId,
            qtype: this.isProject ? 'PROJECT' : 'COMPANY',
         };
         return filters;
      },
   },

   methods: {
      addQuestion(index = null) {
         this.$store.commit('questionnaire/addQuestion', {index});
      },

      /** Start editing from a blank template */
      openBlankTemplate() {
         this.addQuestion();
         this.templateSelected = true;
      },

      /** Load a template to start editing from */
      async loadTemplate(templateId) {
         await this.blockingRequest('questionnaire/loadTemplate', {
            templateId,
            forceRefresh: false,
         });
         this.templateSelected = true;
      },

      /** Pass a submit event to the parent component */
      submit(data = null) {
         this.$emit('submit', data);
      },
   },
};
</script>
