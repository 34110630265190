<template>
   <b-card no-body class="collapse-card" :class="{'pb-0': visible && noPadding}">
      <div
         v-b-toggle="'collapse-' + formattedIdent"
         :id="`toggle-${formattedIdent}`"
         class="collapse-card-toggle"
      ></div>

      <div class="d-flex align-items-center justify-content-between">
         <h2 :id="`title-${formattedIdent}`" class="text-dark-gray mb-0">
            <slot name="title">{{ title }}</slot>
         </h2>
         <div class="d-flex align-items-center">
            <div :id="`subtitle-${formattedIdent}`" class="mr-3 text-dark-gray flex-grow">
               <slot name="subtitle">{{ subtitle }}</slot>
            </div>
            <custom-icon :icon="collapseIcon" class="text-dark-gray" />
         </div>
      </div>

      <b-collapse
         :id="`collapse-${formattedIdent}`"
         class="mt-3"
         :class="{'no-outer-padding': noPadding}"
         v-model="visible"
         @input="toggleVisible"
      >
         <slot />
      </b-collapse>
   </b-card>
</template>

<script>
export default {
   props: {
      /**
       * Unique identifier for this card. Required if there are multiple
       * collapse cards on thhe same page.
       */
      ident: {
         type: String,
         default: 'collapse-card',
      },
      /** Text for the card title. Also available as a slot */
      title: {
         type: String,
         default: '',
      },
      /** Text for the card subtitle. Also available as a slot */
      subtitle: {
         type: String,
         default: '',
      },
      /** v-model support for the collapse */
      value: {
         type: Boolean,
         default: false,
      },
      /** Remove the padding around the collapse so content is flush with the edge of the card */
      noPadding: {
         type: Boolean,
         default: false,
      },
   },

   data() {
      return {
         visible: this.value,
      };
   },

   computed: {
      collapseIcon() {
         return this.visible ? 'icon-chevron-up' : 'icon-chevron-down';
      },

      /**
       * There are some places where the unique identifier might be a string with spaces. Convert
       * those spaces to '-' so we can use that identifier in an `id` attribute.
       */
      formattedIdent() {
         if (this.ident === null) {
            return null;
         }
         return this.ident.replace(/\s/g, '-');
      },
   },

   methods: {
      open() {
         this.visible = true;
         this.toggleVisible(true);
      },

      toggleVisible(value) {
         this.$emit('input', value);
      },
   },

   watch: {
      value(val) {
         this.visible = val;
      },
   },
};
</script>

<style lang="scss" scoped>
.collapse-card {
   padding: 22px 24px;
   margin-bottom: 2rem;
   position: relative;

   .collapse-card-toggle {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 68px; // content(24px) + 2*padding(22px)
      z-index: 50;
   }

   .no-outer-padding {
      margin-left: -24px;
      margin-right: -24px;
   }
}
</style>
