<template>
   <g>
      <path
         d="M9 20.856L17 12.856L9 4.85596"
         stroke="currentColor"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
   </g>
</template>

<script>
export default {
   data() {
      return {
         name: 'icon-chevron-right',
         viewBox: '0 0 24 25',
         fill: 'none',
      };
   },
};
</script>
