import Vue from 'vue';
import VueRouter from 'vue-router';

import _ from 'lodash';

import store from '@/store';
import {UserGroups, SidebarStates} from '@/store/modules/session';

import UserCreate from '@/views/user/UserCreate';
import UserEdit from '@/views/user/UserEdit';

import Dashboard from '@/views/dashboards/Dashboard';
import Unconfigured from '@/views/stages/Unconfigured';

import adminRoutes from './modules/admin';
import cloudRoutes from './modules/cloud';
import companyRoutes from './modules/company';
import contractorsRoutes from './modules/contractors';
import messageRoutes from './modules/messages';
import projectRoutes from './modules/project';
import questionnaireRoutes from './modules/questionnaire';
import timeSurveyRoutes from './modules/time-survey';
import uploadRoutes from './modules/uploads';
import stagesRoutes from './modules/stages';
import suppliesRoutes from './modules/supplies';

Vue.use(VueRouter);

/*
The following routes as regexes are used by other parts of the application and should not be used in the Vue app:

`^/health-check$` - AWS load balancer health check path
`^/api/` - reverse proxy to aiohttp
`^/auth` - reverse proxy to keycloak
`^/mail` - reverse proxy to the development mail server/reader

Layouts
All routes use views/layouts/DefaultLayout by default. To provide a custom layout,
register the layout component in views/index.js, then add the component name
to the the route's meta attribute. 
{
   path: '/my-path',
   name: 'MyPath',
   component: Foo,
   meta: {
      layout: 'MyLayout',
   },
},

Route Gaurds
To protect a route, add `authGroup` as a meta attribute on the route. See
`authGroupMap` for the accepted values.
*/

const routes = [
   ...adminRoutes,
   ...cloudRoutes,
   ...companyRoutes,
   ...contractorsRoutes,
   ...messageRoutes,
   ...questionnaireRoutes,
   ...projectRoutes,
   ...timeSurveyRoutes,
   ...uploadRoutes,
   ...stagesRoutes,
   ...suppliesRoutes,
   {
      path: '/',
      name: 'Home',
      component: Dashboard,
      meta: {
         stage: 'dashboard',
      },
   },
   {
      path: '/profile',
      name: 'profile',
      component: UserEdit,
      meta: {
         breadCrumb: [{text: 'Dashboard', to: {name: 'Home'}}, {text: 'My Profile'}],
         allowUnconfigured: true,
         allowUngreeted: true,
      },
   },
   {
      path: '/user/:id',
      name: 'user-edit',
      component: UserEdit,
      meta: {
         breadCrumb: [{text: 'Dashboard', to: {name: 'Home'}}, {text: 'Edit User'}],
         authGroup: 'admin',
      },
   },
   {
      path: '/user/create/admin',
      name: 'user-create-admin',
      component: UserCreate,
      meta: {
         breadCrumb: [{text: 'Dashboard', to: {name: 'Home'}}, {text: 'Create Admin'}],
         authGroup: 'admin',
      },
      props: {
         userType: UserGroups.ADMIN,
      },
   },
   {
      path: '/user/create/staff',
      name: 'user-create-staff',
      component: UserCreate,
      meta: {
         breadCrumb: [{text: 'Dashboard', to: {name: 'Home'}}, {text: 'Create Staff'}],
         authGroup: 'rndig',
      },
      props: {
         userType: UserGroups.STAFF,
      },
   },
   {
      path: '/user/create/customer',
      name: 'user-create-customer',
      component: UserCreate,
      meta: {
         breadCrumb: [{text: 'Dashboard', to: {name: 'Home'}}, {text: 'Create Customer'}],
         authGroup: 'rndig',
      },
      props: {
         userType: UserGroups.CUSTOMER,
      },
   },
   {
      path: '/user/create/sme',
      name: 'user-create-sme',
      component: UserCreate,
      meta: {
         breadCrumb: [{text: 'Dashboard', to: {name: 'Home'}}, {text: 'Create SME'}],
         authGroup: 'notSme',
      },
      props: {
         userType: UserGroups.SME,
      },
   },
   {
      path: '/setup',
      name: 'company-unconfigured',
      component: Unconfigured,
      meta: {
         authGroup: 'client',
         allowUnconfigured: true,
      },
   },

   // catch-all redirect
   {path: '*', redirect: '/'},
];

// Scroll to top when route changes
function scrollBehavior(to, from, savedPosition) {
   if (savedPosition) {
      return savedPosition;
   } else {
      return {x: 0, y: 0};
   }
}

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes,
   scrollBehavior,
});

//
// Route Gaurds
//
const authGroupMap = {
   // All users
   all: () => true,

   // Only admins
   admin: () => store.getters.isAdmin,

   // Only staff
   staff: () => store.getters.isStaff,

   // Staff and Admin
   rndig: () => store.getters.isRndig,

   // Customers only
   customer: () => store.getters.isCustomer,

   // SMEs only
   sme: () => store.getters.isSME,

   // Customers and SMEs
   client: () => store.getters.isClient,

   // Admin, staff, and Customers (not SMEs)
   notSme: () => store.getters.isRndig || store.getters.isCustomer,
};

// Protect routes based on user's auth group
function requireAuth(to, from, next) {
   if (to.matched.some((record) => record.meta.authGroup)) {
      // This route requires an auth group, check the user's auth groups.
      // If user doesn't have permissions, redirect to Home route.
      const requiredGroup = to.meta.authGroup;
      if (!authGroupMap[requiredGroup]()) {
         next({name: 'Home'});
      } else {
         next();
      }
   } else {
      next();
   }
}

// Set up breadcrumbs
async function generateBreadcrumb(to, from, next) {
   // Breadcrumbs are disabled for Customer and SME users
   if (!store.getters.isClient) {
      store.commit('setBreadcrumb', null);
      if (to.matched.some((record) => record.meta.breadCrumb)) {
         // create a copy so we don't modify the original
         const crumbs = to.meta.breadCrumb.map((crumb) => _.cloneDeep(crumb));

         for (const crumb of crumbs) {
            if (crumb.to && crumb.to.params) {
               if (crumb.to.params.id === 'FILL') {
                  crumb.to.params.id = to.params.id;
               }
               if (crumb.to.params.year === '__YEAR__') {
                  crumb.to.params.year = to.params.year;
               }
               if (crumb.to.params.projectId === '__PROJECTID__') {
                  crumb.to.params.projectId = to.params.projectId;
               }
               if (crumb.to.params.questionnaireId === '__QUESTIONNAIREID__') {
                  crumb.to.params.questionnaireId = to.params.questionnaireId;
               }
            }
            if (crumb.text === '__COMPANYNAME__') {
               const companyId = to.params.id;
               const company = await store.dispatch('companies/loadCompany', {
                  companyId,
                  force: false,
               });
               crumb.text = company.companyName;
            }
         }

         store.commit('setBreadcrumb', crumbs);
      }
   }
   next();
}

// Update the active stage for the stages sidebar
function setActiveStage(to, from, next) {
   if (to.matched.some((record) => record.meta.stage)) {
      store.commit('setActiveStage', to.meta.stage);
   } else {
      store.commit('setActiveStage', null);
   }
   next();
}

/**
 * Apply restrictions to client user accounts
 *   * Redirect first time users to the get-started page and hide the sidebar
 *   * If the user's company isn't configured, restrict them to routes with the
 *     `allowUnconfigured` meta attribute.
 *   * If the company is configured, restrict access to routes associated with
 *     stages the user doesn't have access to. (See `store/modules/stages.js`)
 */
async function clientUserRestrictions(to, from, next) {
   if (store.getters.isClient) {
      const {params, meta} = to;

      if (!store.getters.greeted) {
         store.commit('setSidebar', {sidebar: SidebarStates.HIDDEN});
         if (!meta.allowUngreeted) {
            return next({name: 'get-started-stage', params});
         }
      }

      if (!store.getters.isAppConfigured && !meta.allowUnconfigured) {
         return next({name: 'uploads-stage', params});
      }

      // Check that progress has been loaded
      if (store.getters.isAppConfigured && !store.getters['progress/loaded']) {
         await store.dispatch('progress/loadProgress', {
            companyId: store.getters.profile.companyId,
         });
      }

      if (meta.stage) {
         const stageIdx = store.getters.stages.findIndex((item) => item.id === meta.stage);
         if (stageIdx < 0) {
            return next({name: 'Home', params});
         }
      }
   }

   next();
}

router.beforeEach(requireAuth);
router.beforeEach(setActiveStage);
router.beforeEach(generateBreadcrumb);
router.beforeEach(clientUserRestrictions);

export default router;
