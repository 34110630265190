<template>
   <div class="d-flex align-items-center justify-content-center text-instruction py-3">
      <span>©{{ currentYear }} Arden Software Group LLC. All rights reserved.</span>
   </div>
</template>

<script>
export default {
   computed: {
      currentYear() {
         return new Date().getFullYear();
      },
   },
};
</script>
