<template>
   <div class="link-btn" @click="nav">
      <custom-icon v-if="left" icon="icon-chevron-left" class="mr-2" />
      <span> <slot /> </span>
      <custom-icon v-if="right" icon="icon-chevron-right" class="ml-2" />
   </div>
</template>

<script>
export default {
   props: {
      left: {
         type: Boolean,
         default: false,
      },
      right: {
         type: Boolean,
         default: false,
      },
      to: {
         type: Object,
      },
   },

   methods: {
      nav() {
         this.$router.push(this.to);
      },
   },
};
</script>

<style lang="scss" scoped>
.link-btn {
   display: flex;
   align-items: center;
   cursor: pointer;
   color: $secondary;
   transition: all 0.2s ease;

   &:hover {
      color: $primary;
      text-decoration: underline;
   }
}
</style>
