// Application Version

const state = () => ({
   version: null,
   versionStr: null,
});

const getters = {
   /** version object with details: major, minor, etc **/
   version: (state) => state.version,

   /** human readable string **/
   versionStr: (state) => state.versionStr,
};

const mutations = {
   /** set version **/
   setVersion(state, {version, versionStr}) {
      state.version = version;
      state.versionStr = versionStr;
   },
};

const actions = {
   /** Fetch version info prior to app startup **/
   async fetchVersion({commit}) {
      let version_info;
      version_info = (await this._vm.$http.get('/api/version')).data;
      commit('setVersion', version_info);
   },
};

export default {
   state,
   getters,
   mutations,
   actions,
};
