<template>
   <g>
      <path
         fill-rule="evenodd"
         clip-rule="evenodd"
         d="M12 4.60938C14.5416 4.60938 16.6847 6.25549 17.478 8.53055C17.5945 8.52279 17.7071 8.49173 17.8235 8.49173C20.6771 8.49173 23 10.8134 23 13.6682C23 16.523 20.6771 18.8447 17.8235 18.8447V17.5506C19.964 17.5506 21.7059 15.8087 21.7059 13.6682C21.7059 11.5277 19.964 9.78585 17.8235 9.78585C17.8015 9.78585 17.7808 9.79231 17.7588 9.79361C17.7834 10.0058 17.8235 10.2142 17.8235 10.4329C17.8235 10.9415 17.7575 11.4449 17.6294 11.9315L16.378 11.5976C16.4789 11.221 16.5294 10.8289 16.5294 10.4329C16.5294 7.93526 14.4976 5.90349 12 5.90349C9.56577 5.90349 7.58705 7.83949 7.48871 10.2491C7.92741 10.4614 8.34153 10.7292 8.69871 11.0787L7.79412 12.004C7.18459 11.4074 6.38094 11.08 5.52941 11.08C3.74612 11.08 2.29412 12.532 2.29412 14.3153C2.29412 16.0986 3.74612 17.5506 5.52941 17.5506H7.47059V18.8447H5.52941C3.03177 18.8447 1 16.8129 1 14.3153C1 11.8176 3.03177 9.78586 5.52941 9.78586C5.76882 9.78586 6.00306 9.81691 6.2347 9.85445C6.52976 6.91808 8.98729 4.60938 12 4.60938Z"
         fill="currentColor"
      />
      <path
         d="M12.5112 18.6953C11.8209 18.6953 11.2612 18.1357 11.2612 17.4453C11.2612 16.7549 11.8209 16.1953 12.5112 16.1953C13.2016 16.1953 13.7612 16.7549 13.7612 17.4453C13.7612 18.1357 13.2016 18.6953 12.5112 18.6953Z"
         stroke="currentColor"
         stroke-width="0.833333"
         stroke-linecap="round"
         stroke-linejoin="round"
         fill="none"
      />
      <path
         fill-rule="evenodd"
         clip-rule="evenodd"
         d="M11.5653 14.3374C11.5653 13.9828 11.8527 13.6953 12.2074 13.6953H12.8151C13.1697 13.6953 13.4572 13.9828 13.4572 14.3374C13.4572 14.6102 13.6334 14.847 13.8732 14.9772C13.9106 14.9976 13.9476 15.0187 13.984 15.0407C14.2194 15.1826 14.5144 15.2167 14.7529 15.0802C15.0642 14.9021 15.4609 15.0083 15.6414 15.3182L15.9349 15.822C16.1147 16.1307 16.0086 16.5268 15.6986 16.7042C15.461 16.8401 15.3428 17.1116 15.3486 17.3852C15.349 17.4052 15.3492 17.4252 15.3492 17.4453C15.3492 17.4654 15.349 17.4854 15.3486 17.5054C15.3428 17.779 15.461 18.0506 15.6985 18.1864C16.0086 18.3639 16.1147 18.7599 15.9349 19.0686L15.6414 19.5724C15.4609 19.8823 15.0642 19.9886 14.7529 19.8104C14.5144 19.674 14.2194 19.708 13.984 19.8499C13.9476 19.8719 13.9106 19.8931 13.8732 19.9134C13.6334 20.0436 13.4572 20.2804 13.4572 20.5532C13.4572 20.9079 13.1697 21.1953 12.8151 21.1953H12.2074C11.8527 21.1953 11.5653 20.9079 11.5653 20.5532C11.5653 20.2804 11.3891 20.0436 11.1493 19.9134C11.1119 19.8931 11.0749 19.8719 11.0385 19.8499C10.8031 19.708 10.5081 19.674 10.2696 19.8104C9.95833 19.9886 9.56167 19.8823 9.38113 19.5724L9.08758 19.0686C8.90779 18.7599 9.01392 18.3639 9.32396 18.1865C9.56146 18.0506 9.67971 17.779 9.67392 17.5054C9.6735 17.4854 9.67329 17.4654 9.67329 17.4453C9.67329 17.4252 9.6735 17.4052 9.67392 17.3852C9.67971 17.1116 9.56146 16.8401 9.32396 16.7042C9.01387 16.5268 8.90779 16.1307 9.08758 15.822L9.38108 15.3182C9.56162 15.0083 9.95829 14.9021 10.2696 15.0802C10.5081 15.2167 10.8031 15.1826 11.0385 15.0407C11.0749 15.0187 11.1119 14.9976 11.1493 14.9772C11.3891 14.847 11.5653 14.6102 11.5653 14.3374Z"
         stroke="currentColor"
         stroke-width="0.833333"
         fill="none"
      />
   </g>
</template>

<script>
export default {
   data() {
      return {
         name: 'icon-cloud-computing',
         viewBox: '0 0 24 25',
      };
   },
};
</script>
