<template>
   <div>
      <b-form-group label="Name *" label-for="input-name">
         <template #label> Name<span class="text-danger">*</span> </template>
         <b-form-input
            id="input-name"
            v-model="templateConfig.name"
            @input="$store.commit('questionnaire/setDirty', {value: true})"
         ></b-form-input>
      </b-form-group>

      <b-form-row>
         <b-col cols="6">
            <b-form-group label="Industries" label-for="select-industry">
               <b-form-select
                  id="select-industry"
                  ref="industrySelect"
                  class="mb-2"
                  :value="null"
                  @change="onIndustrySelect"
                  :disabled="industryOptions.length === 0"
                  :options="industryOptions"
               >
                  <template #first>
                     <option :value="null" disabled>Add an industry</option>
                  </template>
               </b-form-select>
               <ul
                  v-if="templateConfig.industries.length > 0"
                  class="list-inline d-inline-block mb-2"
               >
                  <li
                     v-for="(industryId, index) in templateConfig.industries"
                     :key="industryId"
                     class="list-inline-item"
                  >
                     <b-form-tag
                        :id="`tag-${index + 1}`"
                        :title="industryMap[industryId].name"
                        variant="primary"
                        pill
                        @remove="removeIndustry(industryId)"
                     >
                        {{ industryMap[industryId].name }}
                     </b-form-tag>
                  </li>
               </ul>
            </b-form-group>
         </b-col>

         <b-col cols="6">
            <b-form-group label="Questionnaire Type" label-for="select-qtype">
               <template #label> Questionnaire Type<span class="text-danger">*</span> </template>
               <b-form-select
                  id="select-qtype"
                  :options="templateTypeOptions"
                  v-model="templateConfig.qtype"
                  @change="$store.commit('questionnaire/setDirty', {value: true})"
               >
                  <template #first>
                     <option :value="null" disabled>Select a questionnaire type</option>
                  </template>
               </b-form-select>
            </b-form-group>
         </b-col>
      </b-form-row>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   data() {
      return {
         templateTypeOptions: [
            {value: 'COMPANY', text: 'Company'},
            {value: 'PROJECT', text: 'Project'},
         ],
      };
   },

   computed: {
      ...mapGetters({
         industries: 'industries/industries',
         industryMap: 'industries/industryMap',
         templateConfig: 'questionnaire/templateConfig',
      }),

      industryOptions() {
         return this.industries
            .filter((industry) => this.templateConfig.industries.indexOf(industry.id) === -1)
            .map((industry) => {
               return {text: industry.name, value: industry.id};
            });
      },
   },

   methods: {
      onIndustrySelect(industryId) {
         this.templateConfig.industries.push(this.industryMap[industryId].id);
         this.$nextTick(() => {
            this.$refs.industrySelect.localValue = null;
         });
         this.$store.commit('questionnaire/setDirty', {value: true});
      },

      removeIndustry(industryId) {
         const idx = this.templateConfig.industries.indexOf(industryId);
         if (idx > -1) {
            this.templateConfig.industries.splice(idx, 1);
            this.$store.commit('questionnaire/setDirty', {value: true});
         }
      },
   },
};
</script>
