import ProjectsConfigure from '@/views/projects/ProjectsConfigure';

export default [
   {
      path: '/company/:id/projects',
      name: 'projects-configure',
      component: ProjectsConfigure,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Configure Projects'},
         ],
         authGroup: 'rndig',
      },
   },
];
