<template>
   <div>
      <div v-if="!readonly" id="group-answer">
         <b-form-checkbox-group
            :checked="answerValue"
            @change="onChange"
            :options="options"
            stacked
            v-if="multiple"
         ></b-form-checkbox-group>
         <b-form-radio-group
            :checked="answerValue"
            @change="onChange"
            :options="options"
            stacked
            v-else
         ></b-form-radio-group>
      </div>
      <div v-else :id="`answer-${index + 1}`">
         <div v-if="answerValue !== null && answerValue.length > 0">
            <div v-if="multiple">
               <div v-for="option in question.validation.options" :key="option.key">
                  <b-icon
                     :icon="answerValue.includes(option.value) ? 'check-square' : 'square'"
                     class="mr-2"
                  />
                  <span :class="{'checked-answer': answerValue.includes(option.value)}">
                     {{ option.value }}
                  </span>
               </div>
            </div>
            <div v-else>
               <div v-for="option in question.validation.options" :key="option.key">
                  <b-icon
                     :icon="answerValue === option.value ? 'check-square' : 'square'"
                     class="mr-2"
                  />
                  <span :class="{'checked-answer': answerValue === option.value}">
                     {{ option.value }}
                  </span>
               </div>
            </div>
         </div>
         <div v-else>
            <b>Not Answered</b>
         </div>
      </div>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   name: 'AnswerMultipleChoice',

   props: {
      index: Number,
      readonly: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      ...mapGetters({
         questions: 'questionnaire/questions',
      }),

      question() {
         return this.questions[this.index];
      },

      answer() {
         return this.question.answer;
      },

      options() {
         return this.question.validation.options.map((option) => option.value);
      },

      multiple() {
         return this.question.validation.multiple;
      },

      answerValue() {
         if (this.answer) {
            return this.answer.answer;
         } else {
            if (this.multiple) {
               return [];
            } else {
               return null;
            }
         }
      },
   },

   methods: {
      onChange(value) {
         this.$store.commit('questionnaire/setAnswerValue', {
            questionIndex: this.index,
            value,
         });
      },
   },

   watch: {
      answerValue: {
         immediate: true,
         handler() {
            this.$emit('validate', true);
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.checked-answer {
   font-weight: 700;
}
</style>
