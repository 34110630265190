var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.contractor)?_c('div',{staticClass:"form-section"},[_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label-for":("input-name-" + (_vm.edit ? 1 : _vm.contractorId)),"invalid-feedback":_vm.nameInvalidFeedback},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Contract Researcher Name ")]},proxy:true}],null,false,1382334176)},[_c('b-form-input',{ref:"input-name",attrs:{"id":("input-name-" + (_vm.edit ? 1 : _vm.contractorId)),"state":_vm.validation.fullname.$invalid ? false : null,"value":_vm.contractor.fullname,"disabled":_vm.disableInputs},on:{"input":function (value) { return _vm.updateContractor({
                        contractorId: _vm.contractorId,
                        field: 'fullname',
                        value: value,
                     }); }}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":("select-type-" + (_vm.edit ? 1 : _vm.contractorId))}},[_vm._v(" Fee Type "),_c('b-icon',{attrs:{"id":"icon-tooltip-contract-type","icon":_vm.icon.contractType}}),_c('b-popover',{attrs:{"target":"icon-tooltip-contract-type","triggers":"hover focus","placement":"right"},on:{"show":function($event){_vm.popoverActive.contractType = true},"hidden":function($event){_vm.popoverActive.contractType = false}}},[_vm._v(" Was the contractor paid for their time on an hourly or monthly basis (time and material), or did the contractor receive lump sum payments regardless of their hours worked (fixed fee)? ")])],1),_c('b-form-select',{class:{'input-warning': _vm.edit ? !_vm.contractor.contractType : false},attrs:{"id":("select-type-" + (_vm.edit ? 1 : _vm.contractorId)),"value":_vm.contractor.contractType,"options":_vm.typeOptions,"disabled":_vm.disableInputs},on:{"change":function (value) { return _vm.updateContractor({
                        contractorId: _vm.contractorId,
                        field: 'contractType',
                        value: value,
                     }); }},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}})]},proxy:true}],null,false,2339885724)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":("select-location-" + (_vm.edit ? 1 : _vm.contractorId))}},[_vm._v(" Location "),_c('b-icon',{attrs:{"id":"icon-tooltip-location","icon":_vm.icon.location}}),_c('b-popover',{attrs:{"target":"icon-tooltip-location","triggers":"hover focus","placement":"right"},on:{"show":function($event){_vm.popoverActive.location = true},"hidden":function($event){_vm.popoverActive.location = false}}},[_vm._v(" In which state did the contractor perform the qualified R&D activity? ")])],1),_c('b-form-select',{class:{'input-warning': _vm.edit ? !_vm.contractor.location : false},attrs:{"id":("select-location-" + (_vm.edit ? 1 : _vm.contractorId)),"options":_vm.stateOptions,"value":_vm.contractor.location,"disabled":_vm.disableInputs},on:{"input":function (value) { return _vm.updateContractor({
                        contractorId: _vm.contractorId,
                        field: 'location',
                        value: value,
                     }); }},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}})]},proxy:true}],null,false,2339885724)})],1)],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Contract Documents","label-for":("file-contracts-" + (_vm.edit ? 1 : _vm.contractorId))}},[_c('b-form-file',{class:{
                  'input-warning': _vm.edit
                     ? _vm.contracts.length === 0 && !_vm.contractor.contractOptOut
                     : false,
               },attrs:{"id":("file-contracts-" + (_vm.edit ? 1 : _vm.contractorId)),"placeholder":"","multiple":"","disabled":_vm.contractor.contractOptOut || _vm.disableInputs},model:{value:(_vm.contractor.uploadFiles),callback:function ($$v) {_vm.$set(_vm.contractor, "uploadFiles", $$v)},expression:"contractor.uploadFiles"}})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-2"},[_c('small',{class:[_vm.fileSizeExceedsLimit ? 'text-danger' : 'text-muted']},[_vm._v(" "+_vm._s(_vm.formatBytes(_vm.uploadSize))+" Selected / Limit "+_vm._s(_vm.formatBytes(_vm.MAX_INTERNAL_FILE_SIZE))+" ")])])],1),_c('b-col',{staticClass:"contract-right-side",attrs:{"cols":"12","lg":"6"}},[(_vm.contracts.length === 0)?_c('div',[_c('b-form-checkbox',{attrs:{"id":("checkbox-no-document-" + (_vm.edit ? 1 : _vm.contractorId)),"checked":_vm.contractor.contractOptOut,"disabled":_vm.contractor.uploadFiles.length > 0 || _vm.disableInputs},on:{"change":function (value) { return _vm.updateContractor({
                        contractorId: _vm.contractorId,
                        field: 'contractOptOut',
                        value: value,
                     }); }}},[_vm._v(" There is no contract document available for this contract researcher. ")])],1):_vm._e()])],1),_c('b-collapse',{attrs:{"id":("collapse-contracts-" + (_vm.edit ? 1 : _vm.contractorId)),"visible":_vm.contracts.length > 0}},[_c('b-card',{staticClass:"mb-3"},[_c('b-table',{staticClass:"scrollbar mb-0",attrs:{"fields":_vm.contractFields,"sort-by":"uploadedAt","items":_vm.contracts,"small":"","sticky-header":"150px"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('b',[_vm._v(_vm._s(data.value))])]}},{key:"cell(uploadedAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDatetime(data.value))+" ")]}},{key:"cell(uploadedBy)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.userName(data.value))+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"cell-w-buttons justify-content-end"},[(!_vm.isSME && !_vm.isComplete)?_c('b-button',{attrs:{"variant":"danger","size":"sm","disabled":_vm.disableInputs},on:{"click":function($event){return _vm.deleteFile(data.item.id)}}},[_vm._v(" Delete ")]):_vm._e()],1)]}}],null,false,2227121227)})],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"12","lg":"6","order":"2","order-lg":"1"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Describe the service provided by this contract researcher.","label-for":("text-description-" + (_vm.edit ? 1 : _vm.contractorId))}},[_c('b-form-textarea',{class:{'input-warning': _vm.edit ? !_vm.contractor.description : false},attrs:{"id":("text-description-" + (_vm.edit ? 1 : _vm.contractorId)),"rows":"4","value":_vm.contractor.description,"disabled":_vm.disableInputs},on:{"input":function (value) { return _vm.updateContractor({
                        contractorId: _vm.contractorId,
                        field: 'description',
                        value: value,
                     }); }}})],1)],1),(_vm.contractor.contractOptOut)?_c('b-col',{attrs:{"cols":"12","lg":"6","order":"1","order-lg":"2"}},[_c('label',{staticClass:"d-block",attrs:{"for":("radio-ip-rights-" + (_vm.edit ? 1 : _vm.contractorId))}},[_vm._v(" IP rights belong to "+_vm._s(_vm.company.companyName)+" "),_c('b-icon',{attrs:{"id":"icon-tooltip-ip-rights","icon":_vm.icon.companyIpRights}}),_c('b-popover',{attrs:{"target":"icon-tooltip-ip-rights","triggers":"hover focus","placement":"right"},on:{"show":function($event){_vm.popoverActive.companyIpRights = true},"hidden":function($event){_vm.popoverActive.companyIpRights = false}}},[_vm._v(" Did your company retain the rights of the contractor’s R&D results, such as the ability to reuse any technology, process, and/or other notable breakthroughs for future R&D efforts? ")])],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-2"},[_c('b-form-radio-group',{class:{'input-warning': _vm.edit ? _vm.contractor.companyIpRights === null : false},attrs:{"id":("radio-ip-rights-" + (_vm.edit ? 1 : _vm.contractorId)),"checked":_vm.contractor.companyIpRights,"options":[
                  {text: 'Yes', value: true},
                  {text: 'No', value: false} ],"size":"sm","buttons":"","button-variant":"outline-primary","disabled":_vm.disableInputs},on:{"change":function (value) { return _vm.updateContractor({
                        contractorId: _vm.contractorId,
                        field: 'companyIpRights',
                        value: value,
                     }); }}}),_c('b-button',{attrs:{"id":("btn-clear-ip-rights-" + (_vm.edit ? 1 : _vm.contractorId)),"variant":"secondary","size":"sm","disabled":_vm.disableInputs},on:{"click":function($event){return _vm.updateContractor({
                     contractorId: _vm.contractorId,
                     field: 'companyIpRights',
                     value: null,
                  })}}},[_vm._v(" Clear ")])],1),_c('label',{staticClass:"d-block",attrs:{"for":("radio-eco-risk-" + (_vm.edit ? 1 : _vm.contractorId))}},[_vm._v(" Economic risk taken by "+_vm._s(_vm.company.companyName)+" "),_c('b-icon',{attrs:{"id":"icon-tooltip-economic-risk","icon":_vm.icon.economicRisk}}),_c('b-popover',{attrs:{"target":"icon-tooltip-economic-risk","triggers":"hover focus","placement":"right"},on:{"show":function($event){_vm.popoverActive.economicRisk = true},"hidden":function($event){_vm.popoverActive.economicRisk = false}}},[_vm._v(" Did your company bear the economic risk of the work performed by the contractor, regardless of whether it's successful or not? ")])],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-2"},[_c('b-form-radio-group',{class:{'input-warning': _vm.edit ? _vm.contractor.economicRisk === null : false},attrs:{"id":("radio-eco-risk-" + (_vm.edit ? 1 : _vm.contractorId)),"checked":_vm.contractor.economicRisk,"options":[
                  {text: 'Yes', value: true},
                  {text: 'No', value: false} ],"size":"sm","buttons":"","button-variant":"outline-primary","disabled":_vm.disableInputs},on:{"change":function (value) { return _vm.updateContractor({
                        contractorId: _vm.contractorId,
                        field: 'economicRisk',
                        value: value,
                     }); }}}),_c('b-button',{attrs:{"id":("btn-clear-eco-risk-" + (_vm.edit ? 1 : _vm.contractorId)),"variant":"secondary","size":"sm","disabled":_vm.disableInputs},on:{"click":function($event){return _vm.updateContractor({
                     contractorId: _vm.contractorId,
                     field: 'economicRisk',
                     value: null,
                  })}}},[_vm._v(" Clear ")])],1)]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }