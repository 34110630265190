<template>
   <component :is="view" />
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   computed: {
      ...mapGetters(['isAdmin', 'isStaff', 'isCustomer', 'isSME']),
      view() {
         if (this.isStaff) {
            return 'StaffDashboard';
         } else if (this.isCustomer) {
            return 'CustomerDashboard';
         } else if (this.isSME) {
            return 'SmeDashboard';
         } else if (this.isAdmin) {
            return 'AdminDashboard';
         } else {
            return 'Users';
         }
      },
   },
};
</script>
