const AdminCompany = () =>
   import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminCompany');
const AdminEmployees = () =>
   import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminEmployees');
const AdminActivities = () =>
   import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminActivities');
const AdminProjects = () =>
   import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminProjects');
const AdminQuestionnaire = () =>
   import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminQuestionnaire');
const AdminTimeSurvey = () =>
   import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminTimeSurvey');
const AdminDocumentCategories = () =>
   import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminDocumentCategories');
const AdminIndustries = () =>
   import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminIndustries');

export default [
   {
      path: '/admin/company/:id',
      name: 'admin-company',
      component: AdminCompany,
      meta: {
         authGroup: 'admin',
         breadCrumb: [{text: 'Admin', to: {name: 'Home'}}, {text: '__COMPANYNAME__'}],
      },
   },

   {
      path: '/admin/company/:id/employees',
      name: 'admin-employees',
      component: AdminEmployees,
      meta: {
         authGroup: 'admin',
         breadCrumb: [
            {text: 'Admin', to: {name: 'Home'}},
            {text: '__COMPANYNAME__', to: {name: 'admin-company', params: {id: 'FILL'}}},
            {text: 'Employees'},
         ],
      },
   },

   {
      path: '/admin/company/:id/activities',
      name: 'admin-activities',
      component: AdminActivities,
      meta: {
         authGroup: 'admin',
         breadCrumb: [
            {text: 'Admin', to: {name: 'Home'}},
            {text: '__COMPANYNAME__', to: {name: 'admin-company', params: {id: 'FILL'}}},
            {text: 'Activities'},
         ],
      },
   },

   {
      path: '/admin/company/:id/projects',
      name: 'admin-projects',
      component: AdminProjects,
      meta: {
         authGroup: 'admin',
         breadCrumb: [
            {text: 'Admin', to: {name: 'Home'}},
            {text: '__COMPANYNAME__', to: {name: 'admin-company', params: {id: 'FILL'}}},
            {text: 'Projects'},
         ],
      },
   },

   {
      path: '/admin/company/:id/questionnaires',
      name: 'admin-questionnaires',
      component: AdminQuestionnaire,
      meta: {
         authGroup: 'admin',
         breadCrumb: [
            {text: 'Admin', to: {name: 'Home'}},
            {text: '__COMPANYNAME__', to: {name: 'admin-company', params: {id: 'FILL'}}},
            {text: 'Questionnaires'},
         ],
      },
   },

   {
      path: '/admin/company/:id/time-survey',
      name: 'admin-timesurvey',
      component: AdminTimeSurvey,
      meta: {
         authGroup: 'admin',
         breadCrumb: [
            {text: 'Admin', to: {name: 'Home'}},
            {text: '__COMPANYNAME__', to: {name: 'admin-company', params: {id: 'FILL'}}},
            {text: 'Time Surveys'},
         ],
      },
   },

   {
      path: '/admin/company/:id/document-categories',
      name: 'admin-document-categories',
      component: AdminDocumentCategories,
      meta: {
         authGroup: 'admin',
         breadCrumb: [
            {text: 'Admin', to: {name: 'Home'}},
            {text: '__COMPANYNAME__', to: {name: 'admin-company', params: {id: 'FILL'}}},
            {text: 'Document Categories'},
         ],
      },
   },

   {
      path: '/admin/industries',
      name: 'admin-industries',
      component: AdminIndustries,
      meta: {
         authGroup: 'admin',
         breadCrumb: [{text: 'Admin', to: {name: 'Home'}}, {text: 'Industries'}],
      },
   },
];
