export const MAX_UPLOAD_SIZE = 104857600; // 100 MiB as bytes
export const MAX_INTERNAL_FILE_SIZE = 10485760; // 10 MiB as bytes
export const MIN_TAX_YEAR = 1984;

/**
 * Enum for employee data state values within a time survey
 * @readonly
 * @enum {String}
 */
export const TsEmployeeStates = Object.freeze({
   // Employee has invalid data
   INVALID: 'INVALID',

   // Employee has incomplete data
   INCOMPLETE: 'INCOMPLETE',

   // Employee's data is complete and valid
   VALID: 'VALID',
});

/**
 * Enum for user states within a time survey
 * @readonly
 * @enum {String}
 */
export const TsUserStates = Object.freeze({
   // An assigned employee has invalid data
   INVALID: 'INVALID',

   // An assigned employee has incomplete data
   INCOMPLETE: 'INCOMPLETE',

   // All employees have valid data, but needs to mark assignments complete
   VALID: 'VALID',

   // Assignments have been marked complete
   COMPLETE: 'COMPLETE',
});

/**
 * Enum for time survey state values
 * @readonly
 * @enum {String}
 */
export const TsStates = Object.freeze({
   // The time survey has been validated by the server
   VALIDATED: 'VALIDATED',

   // User's assigned employees have invalid data
   ASSIGNMENTS_INVALID: 'ASSIGNMENTS_INVALID',

   // User's assigned employees still need R&D time entered
   ASSIGNMENTS_INCOMPLETE: 'ASSIGNMENTS_INCOMPLETE',

   // User's assigned employees have valid data, but have not been marked complete
   ASSIGNMENTS_COMPLETE: 'ASSIGNMENTS_COMPLETE',

   // Time survey has invalid data
   TS_INVALID: 'TS_INVALID',

   // Time survey has incomplete data
   TS_INCOMPLETE: 'TS_INCOMPLETE',

   // Time survey is complete and valid
   VALID: 'VALID',
});

export const StatusType = Object.freeze({
   COMPLETE: 'Complete',
   IN_PROGRESS: 'In Progress',
   INCOMPLETE: 'Incomplete',
   NA: 'N/A',
});

export const TsEmployeeStateToStatusType = Object.freeze({
   // Employee has invalid data
   [TsEmployeeStates.INVALID]: StatusType.IN_PROGRESS,

   // Employee has incomplete data
   [TsEmployeeStates.INCOMPLETE]: StatusType.INCOMPLETE,

   // Employee's data is complete and valid
   [TsEmployeeStates.VALID]: StatusType.COMPLETE,
});

/**
 * Enum for staff roles
 * @readonly
 * @enum {String}
 */
export const StaffRoleMap = Object.freeze({
   PM: 'Project Manager',
   PA: 'Project Associate',
   BM: 'Business Manager',
});

export default {
   MAX_UPLOAD_SIZE,
   MAX_INTERNAL_FILE_SIZE,
   MIN_TAX_YEAR,
   StaffRoleMap,
   TsEmployeeStates,
   TsUserStates,
   TsStates,
   StatusType,
};
