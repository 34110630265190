<template>
   <div>
      <b-form-row v-for="(item, cyIndex) in creditYears" :key="'cy' + cyIndex">
         <b-col cols="10" md="11">
            <YearSelect tax-years v-model="creditYears[cyIndex]" :index="index + cyIndex" />
         </b-col>
         <b-col cols="2" md="1" class="d-flex justify-content-end">
            <div
               :id="`btn-delete-credit-year-${index}${cyIndex}`"
               class="delete-btn"
               @click="removeYear(cyIndex)"
            >
               <b-icon icon="x-square" v-b-tooltip.left="'Remove this credits year'"></b-icon>
            </div>
         </b-col>
      </b-form-row>
   </div>
</template>

<script>
import YearSelect from '@/components/forms/YearSelect';

export default {
   props: {
      // for v-model support
      value: Array,

      // Unique Identifier
      index: {
         type: [String, Number],
         default: '',
      },
   },

   components: {
      YearSelect,
   },

   data() {
      return {
         creditYears: this.value,
      };
   },

   methods: {
      onInput() {
         this.$emit('input', this.creditYears);
      },
      removeYear(index) {
         this.creditYears.splice(index, 1);
         this.onInput();
      },
   },

   watch: {
      value(value) {
         this.creditYears = value;
      },
   },
};
</script>
