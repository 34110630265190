<template>
   <div>
      <b-alert
         v-model="alertVisible"
         :variant="alertVariant"
         class="mt-3 mx-auto standard-width"
         fade
         dismissible
      >
         {{ alertMessage }}
      </b-alert>
      <b-alert
         :show="alertVisibleSeconds"
         :variant="alertVariant"
         class="mt-3 mx-auto standard-width"
         fade
         dismissible
         @dismiss-count-down="countdownChanged"
      >
         {{ alertMessage }}
      </b-alert>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   computed: {
      ...mapGetters({
         alertMessage: 'alertMessage',
         alertVariant: 'alertVariant',
      }),

      // Two way binding with alertVisible from vuex store
      alertVisible: {
         get() {
            return this.$store.state.alerts.alertVisible;
         },
         set(value) {
            this.$store.commit('setAlertVisible', value);
         },
      },

      // Two way binding with alertVisibleSeconds from vuex store
      alertVisibleSeconds: {
         get() {
            return this.$store.state.alerts.alertVisibleSeconds;
         },
         set(value) {
            this.$store.commit('setAlertVisibleSeconds', value);
         },
      },
   },

   methods: {
      // Update alertVisibleSeconds each time the alert counts down
      countdownChanged(seconds) {
         this.alertVisibleSeconds = seconds;
      },
   },
};
</script>
