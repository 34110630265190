<template>
   <div class="messaging-container" :class="{collapsed: collapsed}">
      <div class="messaging-tab opener" @click="toggleCollapse">
         <span v-if="anyUnread" class="badge badge-danger">New</span>

         <b-icon-question class="fg"></b-icon-question>
         <b-icon-chat-right-fill class="bg"></b-icon-chat-right-fill>
      </div>
      <div class="messaging-tab closer" @click="toggleCollapse">
         <b-icon-x-circle class="fg"></b-icon-x-circle>
         <b-icon-chat-right class="bg"></b-icon-chat-right>
      </div>

      <div class="header-box" @click="toggleDetailCollapse">
         <div id="messaging-title" class="title-bar">
            <div class="left-col">
               <img
                  v-if="manager != null"
                  v-auth-src="managerImgUrl"
                  id="img-profile"
                  class="profile-image"
                  v-b-tooltip="`${manager.firstName} ${manager.lastName}`"
               />
            </div>
            <div class="right-col">
               <div class="greeting">Hello, {{ profile.firstName }}!</div>
               <div class="ml-2">
                  <b-icon
                     :icon="`chevron-${detailCollapsed ? 'down' : 'up'}`"
                     aria-hidden="true"
                  ></b-icon>
               </div>
            </div>
         </div>
         <transition name="fade-expand">
            <div v-if="!detailCollapsed">
               <div class="mx-3">
                  I am {{ manager.firstName }}, your R&D study's {{ StaffRoleMap[manager.role] }}.
                  If you have any questions or comments, you can leave them here as a secure message
                  for your credit study team and we will respond to you personally as soon as
                  possible.
               </div>
               <div class="p-3 participants">
                  <img
                     v-for="(s, idx) in sortedStaff"
                     :key="`participant-img-${idx}`"
                     :id="`participant-${idx}`"
                     v-auth-src="imgSrcFor(s.id)"
                     class="profile-image"
                     v-b-tooltip="`${s.firstName} ${s.lastName}, ${StaffRoleMap[s.role]}`"
                  />
               </div>
            </div>
         </transition>
      </div>
      <div class="thread-container">
         <MessageThread ref="thread" />
      </div>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

import MessageThread from '@/views/messaging/MessageThread';
import {StaffRoleMap} from '@/helpers/constants';

export default {
   components: {
      MessageThread,
   },

   computed: {
      ...mapGetters({
         _manager: 'manager',
         collapsed: 'messaging/clientMessagingCollapsed',
         company: 'companies/currentCompany',
         detailCollapsed: 'messaging/clientMessagingDetailCollapsed',
         isClient: 'isClient',
         profile: 'profile',
         thread: 'messaging/currentThread',
         userInfo: 'userInfo',
      }),

      anyUnread() {
         return !!this.thread?.unread;
      },

      sortedStaff() {
         return [
            ...this.company.projectManagers,
            ...this.company.projectAssociates,
            ...this.company.businessManagers,
         ]
            .filter((id) => this.manager?.id !== id)
            .map((id) => this.userInfo.mystaff.find((e) => e.id === id));
      },

      manager() {
         if (this.isClient) {
            return this._manager;
         } else {
            const companyId = this.$route.params.id;
            if (!companyId || this.company.id != companyId) {
               return null;
            }
            if (this.company.projectManagers.length > 0) {
               const person = this.company.projectManagers[0];
               return {
                  id: person.id,
                  firstName: person.firstName,
                  lastName: person.lastName,
               };
            }
         }
         return null;
      },

      managerImgUrl() {
         return this.imgSrcFor(this.manager?.id);
      },
   },

   data() {
      return {
         StaffRoleMap,
      };
   },

   methods: {
      imgSrcFor: (id) => {
         return {
            src: `/api/user/${id}/profile/image`,
            fallbackUrl: require('@/assets/img/avatar.svg'),
         };
      },

      toggleCollapse() {
         this.$store.commit('messaging/toggleClientMessagingCollapsed');

         if (this.collapsed) {
            this.$refs.thread.seeAllMessagesIfScrolledToBottom();
         } else {
            this.$refs.thread.setInitialScroll();
            this.$refs.thread.focusMessageInput();
         }
      },

      toggleDetailCollapse() {
         this.$store.commit('messaging/toggleClientMessagingDetailCollapsed');
      },
   },
};
</script>

<style lang="scss" scoped>
//$container-width: 375px;
$container-width: 33%;
$icon-fg-color: $secondary;
$icon-fg-collapsed-color: $gray-100;
$icon-bg-color: $secondary;
$icon-bg-collapsed-color: $secondary;

.messaging-container {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   position: fixed;
   top: 0;
   bottom: 0;
   right: 0;
   z-index: 2000;
   width: $container-width;
   border-left: 1.5px solid $gray-600;
   background-color: $gray-100;
   transition: all 0.5s ease;

   &.collapsed {
      right: -$container-width;

      .messaging-tab {
         cursor: pointer;
         transition: inherit;
         &.closer {
            left: 101%;
         }
         &.opener {
            left: -4rem;
         }
         .fg {
            color: $icon-fg-collapsed-color;
         }
         .bg {
            color: $icon-bg-collapsed-color;
         }
      }
   }
   .messaging-tab {
      transition: inherit;
      width: 4rem;
      height: 4rem;
      position: absolute;
      top: 2rem;
      &.closer {
         left: -4rem;
      }
      &.opener {
         left: 101%;
      }

      .fg {
         height: 1.1rem;
         width: 1.1rem;
         position: absolute;
         top: 0.9rem;
         left: 1.45rem;
         color: $icon-fg-color;
         z-index: 1;
      }
      .bg {
         height: 2rem;
         width: 2rem;
         position: absolute;
         top: 0.7rem;
         left: 1rem;
         color: $icon-bg-color;
         z-index: 0;
      }

      .badge {
         z-index: 2;
         position: absolute;
         bottom: 1.5rem;
         right: 0.25rem;
      }
   }

   .profile-image {
      flex-shrink: 0;
      width: 40px;
      border-radius: 50%;
      transition: inherit;
   }

   .header-box {
      background-color: #015d8b;
      transition: inherit;

      cursor: pointer;
      color: $white;

      .title-bar {
         display: flex;
         align-items: center;

         transition: inherit;

         .underlined {
            border-bottom: 1px solid $white;
            transition: inherit;
         }

         .left-col {
            width: 4.5rem;
            padding: 0 1em;
            text-align: center;

            transition: inherit;

            .close-btn {
               font-size: 2.5rem;
               font-weight: 600;
            }
         }

         .right-col {
            flex-grow: 1;
            padding: 1em 0;

            display: flex;
            align-items: center;

            transition: inherit;

            .title {
               font-size: 1.125rem;
            }

            .greeting {
               font-size: 1.5rem;
            }

            .title,
            .greeting {
               font-weight: 600;
               transition: inherit;
               flex-grow: 1;

               & + div {
                  width: 2em;
               }
            }
         }
      }

      .participants img + img {
         margin-left: 0.5rem;
      }
   }

   .thread-container {
      display: flex;
      position: relative;
      flex-shrink: 2;
      min-height: 0;
   }
}
</style>
