import Vue from 'vue';
import ErrorCodes from '@/helpers/errorCodes';

import {ContractorStatus} from './contractors';

export const CellType = Object.freeze({
   PERC: 'PERC',
   PROJ: 'PROJ',
   ACT: 'ACT',
});

/** Checks that the input is a valid percentage. Returns the value if so, and `null` otherwise. */
const validatePercentage = (val) => {
   if (val === null) {
      return val;
   }

   val = parseInt(val, 10);
   if (val < 0 || val > 100) {
      return null;
   }
   return val;
};

const state = () => ({
   data: {},
   edit: {
      contractorId: null,
      year: null,
      data: {},
      dirty: false,
   },
});

const getters = {
   data: (state) => state.data,

   /** Computes the status of a contractor in a year */
   contractorYearStatus: (state, getters) => (contractorId, year) => {
      const yearData = state.data[contractorId][year];
      const percentage = yearData.percentage;
      if (percentage !== null) {
         if (getters.projectSumEqualsTotal(contractorId, year)) {
            return ContractorStatus.COMPLETE;
         } else {
            return ContractorStatus.INCOMPLETE;
         }
      }
      return ContractorStatus.NOT_STARTED;
   },

   /** Computes a contractor's project sum in a year */
   projectSum: (state, getters, rootState, rootGetters) => (contractorId, year) => {
      const projData = state.data[contractorId][year].projects || {};
      const projects = rootGetters['projects/projectsInYear'](year);
      const projectSum = projects
         .map((proj) => parseInt(projData[proj.id] || 0, 10))
         .reduce((a, b) => a + b, 0);
      return projectSum;
   },

   projectSumEqualsTotal: (state, getters) => (contractorId, year) => {
      const percentage = getters.data[contractorId][year].percentage;
      if (percentage !== null) {
         const projectSum = getters.projectSum(contractorId, year);
         return parseInt(percentage, 10) === parseInt(projectSum, 10);
      }
      return true;
   },

   /** A mapping of contractorIds to `ContractorStatus`, aggrigated across study years */
   contractorStatus: (state, getters) => {
      // Loop over contractors in `state.data`
      return Object.entries(state.data).reduce((obj, [contractorId, contractorData]) => {
         // For each contractor, compute their status in each year
         const statuses = Object.keys(contractorData).map((year) =>
            getters.contractorYearStatus(contractorId, year)
         );

         // Aggrigate the contractor's yearly statuses into an overall status
         let contractorStatus = ContractorStatus.INCOMPLETE;
         if (statuses.every((status) => status === ContractorStatus.NOT_STARTED)) {
            contractorStatus = ContractorStatus.NOT_STARTED;
         } else if (statuses.every((status) => status === ContractorStatus.COMPLETE)) {
            contractorStatus = ContractorStatus.COMPLETE;
         } else if (statuses.some((status) => status === ContractorStatus.NONE)) {
            contractorStatus = ContractorStatus.NONE;
         }

         obj[contractorId] = contractorStatus;
         return obj;
      }, {});
   },

   /** A mapping of study years to `ContractorStatus` */
   yearlyStatus: (state, getters, rootState, rootGetters) => {
      const studyYears = rootGetters['companies/studyYears'];

      // Loop over study years
      return studyYears.reduce((obj, year) => {
         // In each year, compute the status of each contractor
         const contractorStatuses = Object.keys(state.data).map((contractorId) => {
            return getters.contractorYearStatus(contractorId, year);
         });

         // Aggrigate each contractor's status into an overall status for the year
         let status = ContractorStatus.INCOMPLETE;
         if (contractorStatuses.every((status) => status === ContractorStatus.NOT_STARTED)) {
            status = ContractorStatus.NOT_STARTED;
         } else if (contractorStatuses.every((status) => status === ContractorStatus.COMPLETE)) {
            status = ContractorStatus.COMPLETE;
         } else if (contractorStatuses.some((status) => status === ContractorStatus.NONE)) {
            status = ContractorStatus.NONE;
         }

         obj[year] = status;
         return obj;
      }, {});
   },

   /** Returns the first year in the current study in which the contractor has incomplete time data */
   firstIncompleteTimeYear: (state, getters, rootState, rootGetters) => (contractorId) => {
      const studyYears = rootGetters['companies/studyYears'];

      const year = studyYears.find((year) => {
         const status = getters.contractorYearStatus(contractorId, year);
         return status !== ContractorStatus.COMPLETE;
      });

      return year || null;
   },

   /** Returns the ref year for the given contractor and year */
   refYear: (state, getters, rootState, rootGetters) => (contractorId, year) => {
      const contractor = rootGetters['contractors/contractorMap'][contractorId];
      if (!contractor) {
         return null;
      }

      const firstYear = contractor.yearsLower;
      year = parseInt(year, 10);

      if (firstYear === year) {
         return rootGetters['contractors/refyear'];
      } else {
         return year - 1;
      }
   },

   /** Reference data for the given contractor and year */
   refData: (state, getters) => (contractorId, year, kind, id) => {
      const refYear = getters.refYear(contractorId, year);
      if (refYear === null) {
         return null;
      }

      const yearData = state.data[contractorId][refYear];
      if (yearData == null) {
         return null;
      }

      switch (kind) {
         case CellType.PERC:
            return yearData.percentage;
         case CellType.PROJ:
            return id in yearData.projects ? yearData.projects[id] : null;
      }
   },

   /** The data currently being edited */
   editData: (state) => state.edit.data[state.edit.year],

   /** The year currently being edited */
   editYear: (state) => state.edit.year,

   /** The ID of the contractor currently being edited */
   editContractorId: (state) => state.edit.contractorId,

   /** Is the edit data dirty? */
   editDirty: (state) => state.edit.dirty,
};

const mutations = {
   /** Clear all time data */
   clearData(state) {
      state.data = {};
   },

   /** Clear a contractor's time data */
   clearContractorData(state, {contractorId}) {
      Vue.delete(state.data, contractorId);
   },

   /**
    * Deserialize an array of ContractorYearly objects
    * @param {Object[]]} yearly - an array of contractor yearly data
    * @param {string} [contractorId] - Indicates every yearly data should be stored to this contractor
    *                                  e.g. if the yearly data includes reference data
    */
   deserializeData(state, {yearly, contractorId = null}) {
      if (yearly.length === 0) {
         return;
      }
      yearly.forEach((yearData) => {
         const cId = contractorId === null ? yearData.contractorId : contractorId;
         if (!(cId in state.data)) {
            Vue.set(state.data, cId, {});
         }

         const projects = yearData.projects === null ? null : {...yearData.projects};
         const activities = yearData.activities === null ? null : {...yearData.activities};
         if (activities !== null) {
            for (let key in activities) {
               // Ensure values are booleans, converting null to false
               activities[key] = !!activities[key];
            }
         }

         Vue.set(state.data[cId], yearData.year, {
            percentage: yearData.percentage,
            activities,
            projects,
         });
      });
   },

   updateCell(state, {contractorId, year, kind, id = null, value}) {
      switch (kind) {
         case CellType.PERC:
            state.data[contractorId][year].percentage = value;
            break;
         case CellType.PROJ:
            state.data[contractorId][year].projects[id] = value;
            break;
         case CellType.ACT:
            state.data[contractorId][year].activities[id] = value;
            break;
      }
   },

   /** Set the year being edited */
   setEditYear(state, {year}) {
      state.edit.year = year;
      state.edit.dirty = false;
   },

   /** Update an activity value */
   setActivity(state, {activityId, value}) {
      state.edit.data[state.edit.year].activities[activityId] = value;
      state.edit.dirty = true;
   },

   /** Update a project value */
   setProject(state, {projectId, value}) {
      if (value === '') {
         value = null;
      }
      Vue.set(state.edit.data[state.edit.year].projects, projectId, value);
      state.edit.dirty = true;
   },
};

const actions = {
   /**
    * Update a single cell of contractor time
    * @param {String|Number} companyId
    * @param {String|Number} contractorId
    * @param {String|Number} year
    * @param {CellType} kind - Type of cell being updated. One of 'PROJ', 'ACT', 'PERC'
    * @param {String|Number} id - Project or activity. Must be `null` for total percentage
    * @param {Number} oldValue - The previous cell value
    * @param {Number} newValue - The new cell value
    */
   async updateCell(
      {commit, dispatch, rootGetters},
      {companyId, contractorId, year, kind, id = null, oldValue, newValue}
   ) {
      // In the event that a user has entered an invalid value, the value in memory
      // will be out of sync with the server, so we need to make sure to use an
      // oldValue of `null`, rather than the invalid value in memory.
      oldValue = validatePercentage(oldValue);
      const payload = {
         companyId,
         contractorId,
         year,
         kind,
         id,
         val: newValue,
         old: oldValue,
      };

      try {
         await this._vm.$http.put('/api/contractor/time', payload);
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.PERMISSION_DENIED) {
            commit(
               'showAlert',
               {
                  msg: "You don't have permission to edit R&D time data",
                  seconds: 5,
               },
               {root: true}
            );
            dispatch('loadContractors', {companyId});
            await dispatch(
               'contractorPermissions/loadUserPermissions',
               {
                  userId: rootGetters.profile.id,
               },
               {root: true}
            );
         } else if (errCode === ErrorCodes.LOCKED) {
            commit(
               'showAlert',
               {
                  msg: 'This section has been completed. Changes could not be saved.',
                  seconds: 10,
               },
               {root: true}
            );
            dispatch(
               'companies/loadCompany',
               {
                  companyId: rootGetters['companies/currentCompany'].id,
                  force: true,
               },
               {root: true}
            );
         } else if (errCode === ErrorCodes.STALE_VALUE) {
            commit(
               'showAlert',
               {
                  msg: 'Failed to save percentage. Another user updated this cell while you were entering data.',
                  seconds: 5,
               },
               {root: true}
            );
            const updatedValue = err.response.data.errors[0].detail.stale;
            commit('updateCell', {
               contractorId,
               year,
               kind,
               id,
               value: updatedValue,
            });
         }
      }
   },

   /**
    * Update contractor yearly time data
    * @param {String|Number} companyId
    * @param {[Object]} yearly - An array of contractor yearly data
    * @param {String|Number} yearly.contractorId
    * @param {String|Number} yearly.year
    * @param {Number} yearly.percentage - Total percentage for the year
    * @param {Object} yearly.projects - A mapping of project IDs to percentages
    */
   async updateContractors({commit, dispatch, rootGetters}, {companyId, yearly}) {
      // Filter out null projects and ensure year is an int
      yearly = yearly.map((yearData) => {
         const projects = Object.entries(yearData.projects)
            .filter((entry) => entry[1] !== null)
            .reduce((obj, entry) => {
               obj[entry[0]] = entry[1];
               return obj;
            }, {});
         return {
            ...yearData,
            projects,
            year: parseInt(yearData.year, 10),
         };
      });

      const data = {
         companyId,
         yearly,
      };

      try {
         const response = await this._vm.$http.post('/api/contractor/time', data);

         response.data.results.forEach((result) => {
            commit('deserializeData', {yearly: result.yearly});
         });
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.PERMISSION_DENIED) {
            commit(
               'showAlert',
               {
                  msg: "You don't have permission to edit R&D time data",
                  seconds: 5,
               },
               {root: true}
            );
            dispatch('loadContractors', {companyId});
            await dispatch(
               'contractorPermissions/loadUserPermissions',
               {
                  userId: rootGetters.profile.id,
               },
               {root: true}
            );
         } else if (errCode === ErrorCodes.LOCKED) {
            commit(
               'showAlert',
               {
                  msg: 'This section has been completed. Changes could not be saved.',
                  seconds: 10,
               },
               {root: true}
            );
            dispatch(
               'companies/loadCompany',
               {
                  companyId: rootGetters['companies/currentCompany'].id,
                  force: true,
               },
               {root: true}
            );
         }
      }
   },

   /** DEPRECATED: Load a contractor's time data for editing */
   loadContractorForEdit({state, rootGetters}, {contractorId, year = null}) {
      state.edit.data = {};
      Object.entries(state.data[contractorId]).forEach(([year, data]) => {
         const editData = {
            percentage: data.percentage,
            activities: {},
            projects: {},
         };

         rootGetters['activities/activities'].forEach((activity) => {
            editData.activities[activity.id] =
               activity.id in data.activities ? data.activities[activity.id] : false;
         });

         rootGetters['projects/projectsInStudy'].forEach((project) => {
            editData.projects[project.id] =
               project.id in data.projects ? data.projects[project.id] : null;
         });

         Vue.set(state.edit.data, year, editData);
      });

      state.edit.year =
         year !== null
            ? year
            : Math.min(...Object.keys(state.edit.data).map((year) => parseInt(year)));
      state.edit.contractorId = contractorId;
      state.edit.dirty = false;
   },

   /** DEPRECATED: Save the contractor time data currently being edited */
   async saveContractorTime({state, getters, rootGetters, commit, dispatch}, {companyId}) {
      // Strip any projects with `null` percentages
      const projects = Object.entries(getters.editData.projects).reduce(
         (obj, [projectId, value]) => {
            if (value !== null) {
               obj[projectId] = parseInt(value);
            }
            return obj;
         },
         {}
      );

      const data = {
         companyId,
         yearly: [
            {
               contractorId: state.edit.contractorId,
               year: parseInt(state.edit.year),
               percentage: getters.projectSum,
               activities: getters.editData.activities,
               projects,
            },
         ],
      };

      try {
         const response = await this._vm.$http.post('/api/contractor/time', data);
         commit('deserializeData', {yearly: response.data.results[0].yearly});
         state.edit.dirty = false;
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.PERMISSION_DENIED) {
            commit(
               'showAlert',
               {
                  msg: "You don't have permission to edit R&D time data",
                  seconds: 5,
               },
               {root: true}
            );
            dispatch('loadContractors', {companyId});
            await dispatch(
               'contractorPermissions/loadUserPermissions',
               {
                  userId: rootGetters.profile.id,
               },
               {root: true}
            );
         } else if (errCode === ErrorCodes.LOCKED) {
            commit(
               'showAlert',
               {
                  msg: 'This section has been completed. Changes could not be saved.',
                  seconds: 10,
               },
               {root: true}
            );
            dispatch(
               'companies/loadCompany',
               {
                  companyId: rootGetters['companies/currentCompany'].id,
                  force: true,
               },
               {root: true}
            );
         }
      }
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
