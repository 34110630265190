<template>
   <b-container>
      <PageTitle title="Questionnaires" />
      <QuestionnaireReviewForm />
   </b-container>
</template>

<script>
import QuestionnaireReviewForm from './widgets/QuestionnaireReviewForm.vue';

export default {
   components: {
      QuestionnaireReviewForm,
   },

   async created() {
      await this.fetchData();
   },

   computed: {
      companyId() {
         return this.$route.params.id;
      },

      questionnaireId() {
         return this.$route.params.questionnaireId;
      },
   },

   methods: {
      /** Load the company questionnaire data */
      async fetchData() {
         await this.blockingRequest('questionnaire/loadCompanyQuestionnaire', {
            questionnaireId: this.questionnaireId,
         });
      },
   },
};
</script>
