<template>
   <g>
      <path
         d="M0 2.25769C0 1.66095 0.237053 1.08866 0.65901 0.6667C1.08097 0.244743 1.65326 0.00769043 2.25 0.00769043H21.75C22.3467 0.00769043 22.919 0.244743 23.341 0.6667C23.7629 1.08866 24 1.66095 24 2.25769V5.25769C24 5.85443 23.7629 6.42672 23.341 6.84868C22.919 7.27064 22.3467 7.50769 21.75 7.50769H2.25C1.65326 7.50769 1.08097 7.27064 0.65901 6.84868C0.237053 6.42672 0 5.85443 0 5.25769V2.25769ZM2.25 1.50769C2.05109 1.50769 1.86032 1.58671 1.71967 1.72736C1.57902 1.86801 1.5 2.05878 1.5 2.25769V5.25769C1.5 5.4566 1.57902 5.64737 1.71967 5.78802C1.86032 5.92867 2.05109 6.00769 2.25 6.00769H21.75C21.9489 6.00769 22.1397 5.92867 22.2803 5.78802C22.421 5.64737 22.5 5.4566 22.5 5.25769V2.25769C22.5 2.05878 22.421 1.86801 22.2803 1.72736C22.1397 1.58671 21.9489 1.50769 21.75 1.50769H2.25Z"
      />
      <path
         d="M3 3.75769C3 3.55878 3.07902 3.36801 3.21967 3.22736C3.36032 3.08671 3.55109 3.00769 3.75 3.00769H8.25C8.44891 3.00769 8.63968 3.08671 8.78033 3.22736C8.92098 3.36801 9 3.55878 9 3.75769C9 3.9566 8.92098 4.14737 8.78033 4.28802C8.63968 4.42867 8.44891 4.50769 8.25 4.50769H3.75C3.55109 4.50769 3.36032 4.42867 3.21967 4.28802C3.07902 4.14737 3 3.9566 3 3.75769ZM19.2345 4.24219L18.6405 3.64819C18.5879 3.59574 18.5521 3.52887 18.5376 3.45603C18.523 3.3832 18.5305 3.3077 18.5589 3.23909C18.5874 3.17049 18.6355 3.11187 18.6973 3.07068C18.7591 3.02948 18.8317 3.00756 18.906 3.00769H20.094C20.1683 3.00756 20.2409 3.02948 20.3027 3.07068C20.3645 3.11187 20.4126 3.17049 20.4411 3.23909C20.4695 3.3077 20.477 3.3832 20.4624 3.45603C20.4479 3.52887 20.4121 3.59574 20.3595 3.64819L19.7655 4.24219C19.7307 4.27711 19.6893 4.30482 19.6437 4.32372C19.5982 4.34263 19.5493 4.35236 19.5 4.35236C19.4507 4.35236 19.4018 4.34263 19.3563 4.32372C19.3107 4.30482 19.2693 4.27711 19.2345 4.24219Z"
      />
      <path
         d="M0 10.2577C0 9.66095 0.237053 9.08866 0.65901 8.6667C1.08097 8.24474 1.65326 8.00769 2.25 8.00769H21.75C22.3467 8.00769 22.919 8.24474 23.341 8.6667C23.7629 9.08866 24 9.66095 24 10.2577V13.2577C24 13.8544 23.7629 14.4267 23.341 14.8487C22.919 15.2706 22.3467 15.5077 21.75 15.5077H2.25C1.65326 15.5077 1.08097 15.2706 0.65901 14.8487C0.237053 14.4267 0 13.8544 0 13.2577V10.2577ZM2.25 9.50769C2.05109 9.50769 1.86032 9.58671 1.71967 9.72736C1.57902 9.86801 1.5 10.0588 1.5 10.2577V13.2577C1.5 13.4566 1.57902 13.6474 1.71967 13.788C1.86032 13.9287 2.05109 14.0077 2.25 14.0077H21.75C21.9489 14.0077 22.1397 13.9287 22.2803 13.788C22.421 13.6474 22.5 13.4566 22.5 13.2577V10.2577C22.5 10.0588 22.421 9.86801 22.2803 9.72736C22.1397 9.58671 21.9489 9.50769 21.75 9.50769H2.25Z"
      />
      <path
         d="M3 11.7577C3 11.5588 3.07902 11.368 3.21967 11.2274C3.36032 11.0867 3.55109 11.0077 3.75 11.0077H8.25C8.44891 11.0077 8.63968 11.0867 8.78033 11.2274C8.92098 11.368 9 11.5588 9 11.7577C9 11.9566 8.92098 12.1474 8.78033 12.288C8.63968 12.4287 8.44891 12.5077 8.25 12.5077H3.75C3.55109 12.5077 3.36032 12.4287 3.21967 12.288C3.07902 12.1474 3 11.9566 3 11.7577ZM19.2345 12.2422L18.6405 11.6482C18.5879 11.5957 18.5521 11.5289 18.5376 11.456C18.523 11.3832 18.5305 11.3077 18.5589 11.2391C18.5874 11.1705 18.6355 11.1119 18.6973 11.0707C18.7591 11.0295 18.8317 11.0076 18.906 11.0077H20.094C20.1683 11.0076 20.2409 11.0295 20.3027 11.0707C20.3645 11.1119 20.4126 11.1705 20.4411 11.2391C20.4695 11.3077 20.477 11.3832 20.4624 11.456C20.4479 11.5289 20.4121 11.5957 20.3595 11.6482L19.7655 12.2422C19.7307 12.2771 19.6893 12.3048 19.6437 12.3237C19.5982 12.3426 19.5493 12.3524 19.5 12.3524C19.4507 12.3524 19.4018 12.3426 19.3563 12.3237C19.3107 12.3048 19.2693 12.2771 19.2345 12.2422Z"
      />
      <path
         d="M0 18.2577C0 17.661 0.237053 17.0887 0.65901 16.6667C1.08097 16.2447 1.65326 16.0077 2.25 16.0077H21.75C22.3467 16.0077 22.919 16.2447 23.341 16.6667C23.7629 17.0887 24 17.661 24 18.2577V21.2577C24 21.8544 23.7629 22.4267 23.341 22.8487C22.919 23.2706 22.3467 23.5077 21.75 23.5077H2.25C1.65326 23.5077 1.08097 23.2706 0.65901 22.8487C0.237053 22.4267 0 21.8544 0 21.2577V18.2577ZM2.25 17.5077C2.05109 17.5077 1.86032 17.5867 1.71967 17.7274C1.57902 17.868 1.5 18.0588 1.5 18.2577V21.2577C1.5 21.4566 1.57902 21.6474 1.71967 21.788C1.86032 21.9287 2.05109 22.0077 2.25 22.0077H21.75C21.9489 22.0077 22.1397 21.9287 22.2803 21.788C22.421 21.6474 22.5 21.4566 22.5 21.2577V18.2577C22.5 18.0588 22.421 17.868 22.2803 17.7274C22.1397 17.5867 21.9489 17.5077 21.75 17.5077H2.25Z"
      />
      <path
         d="M3 19.7577C3 19.5588 3.07902 19.368 3.21967 19.2274C3.36032 19.0867 3.55109 19.0077 3.75 19.0077H8.25C8.44891 19.0077 8.63968 19.0867 8.78033 19.2274C8.92098 19.368 9 19.5588 9 19.7577C9 19.9566 8.92098 20.1474 8.78033 20.288C8.63968 20.4287 8.44891 20.5077 8.25 20.5077H3.75C3.55109 20.5077 3.36032 20.4287 3.21967 20.288C3.07902 20.1474 3 19.9566 3 19.7577ZM19.2345 20.2422L18.6405 19.6482C18.5879 19.5957 18.5521 19.5289 18.5376 19.456C18.523 19.3832 18.5305 19.3077 18.5589 19.2391C18.5874 19.1705 18.6355 19.1119 18.6973 19.0707C18.7591 19.0295 18.8317 19.0076 18.906 19.0077H20.094C20.1683 19.0076 20.2409 19.0295 20.3027 19.0707C20.3645 19.1119 20.4126 19.1705 20.4411 19.2391C20.4695 19.3077 20.477 19.3832 20.4624 19.456C20.4479 19.5289 20.4121 19.5957 20.3595 19.6482L19.7655 20.2422C19.7307 20.2771 19.6893 20.3048 19.6437 20.3237C19.5982 20.3426 19.5493 20.3524 19.5 20.3524C19.4507 20.3524 19.4018 20.3426 19.3563 20.3237C19.3107 20.3048 19.2693 20.2771 19.2345 20.2422Z"
      />
   </g>
</template>

<script>
export default {
   data() {
      return {
         name: 'icon-dashboard',
         viewBox: '0 0 24 25',
      };
   },
};
</script>
