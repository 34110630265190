<template>
   <b-container>
      <PageTitle title="Supplies" />

      <SectionAssignment section="supplies" />

      <ManagerMessage v-if="manager">
         <template #short>
            <span v-if="noData"> No qualified supplies expenses yet. </span>
            <span v-else-if="!completed">How much of this spending went to R&D Supplies?</span>
         </template>

         <div v-if="completed">
            <h2>This section is complete.</h2>
            <p>
               As long as everything still looks correct, you're all done here! If you need to make
               any additional changes, you'll need to unlock this section first.
            </p>
            <div class="d-flex justify-content-end">
               <b-button variant="secondary" @click="unlock">Unlock</b-button>
            </div>
         </div>
         <div v-else-if="noData">
            <h2>No qualified supplies expenses yet.</h2>

            <p class="mb-0">
               The accounts provided so far do not contain expenses for {{ study.lower }} -
               {{ study.upper }}. You can go back and continue to upload expense accounts, or update
               your answer on the first page of this section to indicate that your company made no
               qualified supplies purchases during this time period.
            </p>
         </div>
         <div v-else>
            <h2>How much of this spending went to R&D Supplies?</h2>

            <p>
               For each vendor, please enter the percentage of purchase spending that went to R&D
               Supplies.
            </p>
         </div>
      </ManagerMessage>

      <b-alert
         :show="!manager && completed"
         variant="primary"
         class="d-flex align-items-center justify-content-between mb-5"
      >
         <span>
            The supplies section has been submitted. Click <b>Unlock</b> to make additional changes.
         </span>
         <b-button variant="white" @click="unlock">Unlock</b-button>
      </b-alert>

      <CollapseCard
         v-for="year in years"
         :key="year"
         :ident="year"
         :title="year"
         :subtitle="`${accountCount(year)} accounts`"
      >
         <template #title>
            <div class="d-flex align-items-center">
               <small>
                  <RdigStatusPill
                     :ident="`yr-${year}`"
                     class="mr-2"
                     :status="accountStatus[year].status"
                  />
               </small>
               <h2 class="mb-0 mt-1">{{ year }}</h2>
            </div>
         </template>

         <hr class="mb-4" />

         <div
            v-for="(account, accountIndex) in sortedAccounts(year)"
            :key="`${year}:${account}`"
            class="account-section"
         >
            <div class="d-flex align-items-center mb-4">
               <RdigStatusPill
                  :ident="`acc-${year}-${accountIndex + 1}`"
                  class="mr-2"
                  :status="accountStatus[year].accounts[account]"
               />
               <h2 :id="`account-name-${year}-${accountIndex + 1}`" class="mb-0 mt-1">
                  Account: {{ account }}
               </h2>
            </div>
            <div class="account-data">
               <div class="flex-grow">
                  <b-table
                     :id="`table-supplies-vendors-${year}-${accountIndex + 1}`"
                     :fields="vendorFields"
                     sort-by="vendor"
                     :items="sortedVendors(year, account)"
                  >
                     <template #cell(vendor)="data">
                        <b>{{ data.value }}</b>
                     </template>
                     <template #cell(totalDebits)="data">
                        {{ formatMoney(data.value) }}
                     </template>
                     <template #cell(totalCredits)="data">
                        {{ formatMoney(data.value) }}
                     </template>
                     <template #cell(netAmount)="data">
                        {{ formatMoney(data.value.value) }}
                     </template>
                     <template #cell(input)="data">
                        <div class="d-flex align-items-center justify-content-between">
                           <SuppliesVendorPercentageInput
                              :ident="data.index"
                              :year="year"
                              :account="account"
                              :vendorId="data.item.id"
                              :location="data.item.location"
                              :disabled="completed || setAllInput[`${year}:${account}`]"
                           />
                        </div>
                     </template>
                  </b-table>
               </div>
               <div class="right-col">
                  <b-form-checkbox
                     :id="`checkbox-same-percentages-${year}-${accountIndex + 1}`"
                     class="mb-3"
                     @change="(val) => selectSetAll(year, account, val)"
                     :disabled="completed"
                  >
                     Set all vendors to the same qualifying percent
                  </b-form-checkbox>
                  <SuppliesVendorPercentageInput
                     v-if="setAllInput[`${year}:${account}`]"
                     ident="all"
                     :year="year"
                     :account="account"
                     style="margin-left: 1.5rem; max-width: 8rem"
                  />
               </div>
            </div>
         </div>

         <div v-if="adHocVendors(year).length > 0" class="account-section">
            <div class="d-flex align-items-center mb-4">
               <RdigStatusPill
                  :ident="`acc-${year}-manual`"
                  class="mr-2"
                  :status="accountStatus[year].adHocStatus"
               />
               <h2 :id="`account-name-${year}-manual`" class="mb-0 mt-1">Manually-Added Vendors</h2>
            </div>
            <div class="account-data">
               <div class="flex-grow">
                  <b-table
                     :id="`table-supplies-vendors-${year}-manual`"
                     :fields="adHocVendorFields"
                     sort-by="vendor"
                     :items="adHocVendors(year)"
                  >
                     <template #cell(vendor)="data">
                        <b>{{ data.value }}</b>
                     </template>
                     <template #cell(netAmount)="data">
                        <SuppliesVendorAmountInput
                           :ident="data.index"
                           :year="year"
                           :vendorId="data.item.id"
                           :disabled="completed"
                        />
                     </template>
                     <template #cell(input)="data">
                        <SuppliesVendorPercentageInput
                           :ident="data.index"
                           :year="year"
                           :account="null"
                           :vendorId="data.item.id"
                           :disabled="completed || setAllInput[`${year}:manual`]"
                        />
                     </template>
                  </b-table>
               </div>
               <div class="right-col">
                  <b-form-checkbox
                     :id="`checkbox-same-percentages-${year}-manual`"
                     class="mb-3"
                     @change="(val) => selectSetAll(year, 'manual', val)"
                     :disabled="completed"
                  >
                     Set all vendors to the same qualifying percent
                  </b-form-checkbox>
                  <SuppliesVendorPercentageInput
                     v-if="setAllInput[`${year}:manual`]"
                     ident="all"
                     :year="year"
                     :account="null"
                     style="margin-left: 1.5rem; max-width: 8rem"
                  />
               </div>
            </div>
         </div>
      </CollapseCard>

      <div class="d-flex align-items-center justify-content-between">
         <b-button
            class="d-flex align-items-center"
            :to="$clientStaffRoute({name: 'supplies-vendors'})"
         >
            <b-icon-arrow-left-short class="mr-1" />
            Back
         </b-button>

         <b-button
            id="btn-review-supplies"
            v-if="!noData"
            class="d-flex align-items-center"
            variant="primary"
            :to="$clientStaffRoute({name: 'supplies-review'})"
         >
            Next
            <b-icon-arrow-right-short class="ml-1" />
         </b-button>
      </div>
   </b-container>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import {FieldStatus} from '@/store/utils';
import {SuppliesDecl} from '@/store/modules/supplies';
import SectionAssignment from '../components/SectionAssignment';
import SuppliesVendorPercentageInput from './widgets/SuppliesVendorPercentageInput';
import SuppliesVendorAmountInput from './widgets/SuppliesVendorAmountInput';
import {sortBy} from '@/helpers/utils';

export default {
   components: {
      SectionAssignment,
      SuppliesVendorAmountInput,
      SuppliesVendorPercentageInput,
   },

   data() {
      return {
         FieldStatus,
         StatusType: this.$constants().StatusType,
         setAllInput: {},
      };
   },

   computed: {
      ...mapGetters({
         manager: 'manager',
         study: 'study',
         usStates: 'usStates',
         completed: 'supplies/isCompleted',
         declaration: 'supplies/declaration',
         data: 'supplies/data',
         adHocData: 'supplies/adHocData',
      }),

      noData() {
         return Object.keys(this.data).length === 0 && Object.keys(this.adHocData).length === 0;
      },

      years() {
         return Object.keys({
            ...this.data,
            ...this.adHocData,
         });
      },

      vendorFields() {
         const fields = [{key: 'vendor', label: 'Name', sortable: true}];

         if (this.declaration === SuppliesDecl.MANY) {
            fields.push({
               key: 'location',
               sortable: true,
               formatter: (val) => this.usStates[val],
               sortByFormatted: true,
            });
         }

         fields.push(
            ...[
               {key: 'totalDebits', label: 'Total Debits', sortable: true},
               {key: 'totalCredits', label: 'Total Credits', sortable: true},
               {key: 'netAmount', label: 'Net Amount', sortable: true},
               {key: 'input', label: ''},
            ]
         );

         return fields;
      },

      adHocVendorFields() {
         const fields = [{key: 'vendor', label: 'Name', sortable: true}];

         if (this.declaration === SuppliesDecl.MANY) {
            fields.push({
               key: 'location',
               sortable: true,
               formatter: (val) => this.usStates[val],
               sortByFormatted: true,
            });
         }

         fields.push(
            ...[
               {key: 'netAmount', label: 'Net Amount', sortable: true},
               {key: 'input', label: ''},
            ]
         );

         return fields;
      },

      accountStatus() {
         const status = {};

         this.years.forEach((year) => {
            const accounts = {};

            const accountStatus = (vendorData) => {
               let accountStatus;
               if (Object.values(vendorData).every((item) => item.percentage.value === null)) {
                  accountStatus = this.StatusType.INCOMPLETE;
               } else if (
                  Object.values(vendorData).every((item) => item.percentage.value !== null)
               ) {
                  accountStatus = this.StatusType.COMPLETE;
               } else {
                  accountStatus = this.StatusType.IN_PROGRESS;
               }
               return accountStatus;
            };

            let importedStatus = this.StatusType.NA;
            if (year in this.data) {
               Object.keys(this.data[year]).forEach((account) => {
                  const vendorData = this.data[year][account];
                  accounts[account] = accountStatus(vendorData);
               });

               if (Object.values(accounts).every((item) => item === this.StatusType.COMPLETE)) {
                  importedStatus = this.StatusType.COMPLETE;
               } else if (
                  Object.values(accounts).every((item) => item === this.StatusType.INCOMPLETE)
               ) {
                  importedStatus = this.StatusType.INCOMPLETE;
               } else {
                  importedStatus = this.StatusType.IN_PROGRESS;
               }
            }

            let adHocStatus = this.StatusType.NA;
            if (year in this.adHocData) {
               adHocStatus = accountStatus(this.adHocData[year]);
            }

            let yearStatus;
            if (adHocStatus === this.StatusType.NA) {
               yearStatus = importedStatus;
            } else if (importedStatus === this.StatusType.NA) {
               yearStatus = adHocStatus;
            } else if (
               importedStatus === this.StatusType.COMPLETE &&
               adHocStatus === this.StatusType.COMPLETE
            ) {
               yearStatus = this.StatusType.COMPLETE;
            } else if (
               importedStatus === this.StatusType.INCOMPLETE &&
               adHocStatus === this.StatusType.INCOMPLETE
            ) {
               yearStatus = this.StatusType.INCOMPLETE;
            } else {
               yearStatus = this.StatusType.IN_PROGRESS;
            }

            status[year] = {
               accounts,
               status: yearStatus,
               adHocStatus,
            };
         });

         return status;
      },
   },

   methods: {
      /** Account names of a given year, sorted alphabetically */
      sortedAccounts(year) {
         return year in this.data ? Object.keys(this.data[year]).sort() : [];
      },

      /** Vendors of a given year and account, sorted by vendor name */
      sortedVendors(year, account) {
         return Object.values(this.data[year][account]).sort(sortBy('vendor'));
      },

      adHocVendors(year) {
         return this.adHocData[year]
            ? Object.values(this.adHocData[year]).sort(sortBy('vendor'))
            : [];
      },

      accountCount(year) {
         let count = this.sortedAccounts(year).length;
         if (this.adHocVendors(year).length > 0) {
            count++;
         }
         return count;
      },

      /** Format cents as USD */
      formatMoney(cents) {
         const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
         });
         return formatter.format(cents / 100);
      },

      selectSetAll(year, account, val) {
         Vue.set(this.setAllInput, `${year}:${account}`, val);
      },

      /** Unlock the supplies section */
      async unlock() {
         const msg =
            "Unlocking this section may delay your credit calculation. You will have to assign users again and will have to mark the section complete again once you're done.";
         const title = 'Unlock this section?';
         const proceed = await this.$bvModal.msgBoxConfirm(msg, {
            title,
            centered: true,
         });
         if (proceed) {
            await this.blockingRequest('supplies/completeSupplies', {
               companyId: this.$companyId,
               completed: false,
            });
         }
      },
   },

   async created() {
      this.$store.commit('supplies/clearSuppliesData');

      const requests = [
         this.$store.dispatch('supplies/loadSupplies', {
            companyId: this.$companyId,
         }),

         // Refresh company data to update study
         this.$store.dispatch('companies/loadCompany', {companyId: this.$companyId, force: true}),
      ];
      await this.blockUntilAllSettled(requests);
   },
};
</script>

<style lang="scss" scoped>
.account-section {
   padding-left: 1rem;

   @include lg {
      padding-left: 3rem;
      margin-top: 1.5rem;
   }

   .account-data {
      display: flex;
      flex-direction: column-reverse;
      align-items: stretch;
      justify-content: space-between;

      @include lg {
         // padding-left: 3rem;
         flex-direction: row;
      }
   }
}

.right-col {
   @include lg {
      margin-top: 45px;
      margin-left: 1rem;
      padding-left: 0.75rem;
      border-left: 6px solid $gray-500;
   }
}
</style>
