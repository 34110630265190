import Vue from 'vue';
import Vuex from 'vuex';

import activities from './modules/activities';
import alerts from './modules/alerts';
import cloud from './modules/cloud';
import cloudUploads from './modules/cloud-uploads';
import companies from './modules/companies';
import contractors from './modules/contractors';
import contractorPermissions from './modules/contractor-permissions';
import contractorTime from './modules/contractor-time';
import copyCompany from './modules/copy-company';
import employees from './modules/employees';
import exports from './modules/exports';
import industries from './modules/industries';
import messaging from './modules/messaging';
import progress from './modules/progress';
import projects from './modules/projects';
import questionnaire from './modules/questionnaire';
import session from './modules/session';
import stages from './modules/stages';
import supplies from './modules/supplies';
import suppliesUploads from './modules/supplies-uploads';
import timesurvey from './modules/time-survey';
import tsAssignment from './modules/ts-assignment';
import uploads from './modules/uploads';
import users from './modules/users';
import version from './modules/version';

Vue.use(Vuex);

export default new Vuex.Store({
   modules: {
      activities,
      alerts,
      cloud,
      cloudUploads,
      companies,
      contractors,
      contractorPermissions,
      contractorTime,
      copyCompany,
      employees,
      exports,
      industries,
      messaging,
      progress,
      projects,
      questionnaire,
      session,
      stages,
      supplies,
      suppliesUploads,
      timesurvey,
      tsAssignment,
      uploads,
      users,
      version,
   },
});
