<template>
   <div>
      <b-alert
         variant="success"
         :show="alertCountdown"
         fade
         dismissible
         @dismissed="alertCountdown = 0"
         @dismiss-count-down="(val) => (alertCountdown = val)"
      >
         Saved
      </b-alert>

      <b-form-group
         v-for="(question, idx) in questions"
         :id="`question-${idx + 1}`"
         :key="question.id"
         :label="question.question"
      >
         <AnswerText :index="idx" />
      </b-form-group>

      <div class="d-flex align-items-center justify-content-between">
         <div>
            <b-button v-if="allowSkip" variant="secondary" @click="$emit('skip')">
               Skip for now</b-button
            >
         </div>
         <b-button
            variant="primary"
            id="btn-save-answers"
            :disabled="!anyAnswersToSave"
            @click="saveAnswers"
         >
            Save
         </b-button>
      </div>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AnswerText from '@/views/questionnaire/widgets/AnswerText';

export default {
   components: {
      AnswerText,
   },

   props: {
      user: {
         type: Object,
         default: null,
      },

      allowSkip: {
         type: Boolean,
         default: false,
      },

      redirectOnSave: {
         type: Boolean,
         default: false,
      },
   },

   data() {
      return {
         alertCountdown: 0,
      };
   },

   computed: {
      ...mapGetters({
         _profile: 'profile',
         isClient: 'isClient',
         questions: 'questionnaire/questions',
         questionnaireId: 'questionnaire/id',
      }),

      companyId() {
         return this.profile.companyId;
      },

      profile() {
         return this.user ? this.user : this._profile;
      },

      answers() {
         return this.questions.map((question) => question.answer);
      },

      /** Are any answers dirty? */
      anyAnswersToSave() {
         return this.answers.some((answer) => {
            return answer !== null && answer.dirty;
         });
      },

      /** Are all questions answered and not dirty? */
      allQuestionsAnswered() {
         return this.answers.every((answer) => {
            return (
               answer !== null &&
               !answer.dirty &&
               answer.answer !== null &&
               answer.answer.length > 0
            );
         });
      },
   },

   methods: {
      async saveAnswers(redirect = true) {
         const requests = [...Array(this.questions.length).keys()]
            .filter((index) => {
               // Filter out questions with no answer, or whose answer hasn't changed
               const question = this.questions[index];
               return question.answer !== null && question.answer.dirty;
            })
            .map((questionIndex) => {
               return this.blockingRequest('questionnaire/saveAnswer', {questionIndex});
            });
         await Promise.allSettled(requests);
         this.$emit('post-save');
         if (this.isClient) {
            this.$store.dispatch('progress/loadProgress', {companyId: this.companyId});
         }
         if (redirect && this.redirectOnSave) {
            this.$router.push({name: 'Home'});
         } else {
            this.alertCountdown = 5;
         }
      },

      async checkSave(redirect = true) {
         if (this.anyAnswersToSave) {
            const doSave = await this.$bvModal.msgBoxConfirm(
               'Would you like to save your work before proceeding? Any unsaved changes will be lost.',
               {
                  centered: true,
                  okTitle: 'Save',
                  cancelTitle: 'Proceed without saving',
               }
            );
            if (doSave) {
               await this.saveAnswers(redirect);
               return true;
            } else if (doSave === null) {
               return false;
            } else {
               this.$emit('revert');
               return true;
            }
         }
         return true;
      },
   },
};
</script>
