<template>
   <div>
      <b-icon v-if="bootstrapIcon" :icon="bootstrapIcon" font-scale="1.5" />
      <svg
         v-else
         :width="_width"
         :height="_height"
         :viewBox="viewBox"
         :aria-labelledby="name"
         xmlns="http://www.w3.org/2000/svg"
      >
         <title :id="name">{{ name }}</title>
         <g :fill="fill">
            <component :is="icon" ref="icon" />
         </g>
      </svg>
   </div>
</template>

<script>
export default {
   props: {
      icon: {
         type: String,
      },

      width: {
         type: [Number, String],
         default: null,
      },

      height: {
         type: [Number, String],
         default: null,
      },
   },

   data() {
      return {
         isMounted: false,
      };
   },

   computed: {
      bootstrapIcon() {
         if (this.icon.startsWith('b-')) {
            return this.icon.substring(2);
         }
         return null;
      },

      child() {
         return this.isMounted ? this.$refs.icon : null;
      },

      name() {
         return this.child?.name || '';
      },

      viewBox() {
         return this.child?.viewBox || '0 0 32 32';
      },

      fill() {
         return this.child?.fill || 'currentColor';
      },

      _width() {
         if (this.width) {
            return this.width;
         } else if (this.child?.width) {
            return this.child.width;
         }
         return 24;
      },

      _height() {
         if (this.height) {
            return this.height;
         } else if (this.child?.height) {
            return this.child.height;
         }
         return 24;
      },
   },

   mounted() {
      this.isMounted = true;
   },
};
</script>
