<template>
   <b-form-group
      :state="$v.value.$invalid ? !$v.value.$invalid : null"
      invalid-feedback="Enter an integer between 0 and 100"
      class="mb-0"
      style="min-width: 7rem"
   >
      <tcp-input-group append="%">
         <b-form-input
            :id="inputId"
            :key="inputId"
            v-model="value"
            :debounce="debounceTime"
            :formatter="formatInt"
            :state="$v.value.$invalid ? !$v.value.$invalid : null"
            :placeholder="placeholder"
            :disabled="disabled || zeroTotalPercentage"
            @focus="$storeValue(value)"
            @keydown.stop="$onKeydown"
         ></b-form-input>
      </tcp-input-group>

      <b-tooltip :disabled="!showTooltip" :target="inputId" triggers="focus">
         <ProjectSumMessage
            :ident="employeeIndex"
            :employeeId="employeeId"
            :employee-validation="employeeValidation"
         />
      </b-tooltip>
   </b-form-group>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {integer, minValue, maxValue} from 'vuelidate/lib/validators';

import {CellType} from '@/store/modules/time-survey';
import {formatInt} from '@/helpers/utils';
import EmployeeTimeData from '../mixins/employeeTimeData';
import EmployeeTimeInput from '../mixins/employeeTimeInput';
import ProjectSumMessage from './ProjectSumMessage.vue';

export default {
   components: {
      ProjectSumMessage,
   },

   mixins: [EmployeeTimeData, EmployeeTimeInput],

   props: {
      ident: {
         type: String,
         default: '',
      },
      employeeIndex: {
         type: Number,
         default: null,
      },
      projectIndex: Number,
      employeeId: [String, Number],
      employees: Array,
      projectId: [String, Number],
      state: Boolean,
      disabled: {
         type: Boolean,
         default: false,
      },
      tableView: {
         type: Boolean,
         default: false,
      },
      employeeValidation: {
         type: Object,
      },
   },

   data() {
      return {
         debounceTime: 500,
         storedValue: null,
      };
   },

   computed: {
      ...mapGetters({
         projects: 'timesurvey/projects',
         _projectSumEqualsTotal: 'timesurvey/projectSumEqualsTotal',
      }),

      value: {
         get() {
            return this.$employeeData.projects[this.projectId];
         },
         set(value) {
            this.setProject({
               employeeId: this.employeeId,
               projectId: this.projectId,
               value,
            });
            this.saveProjectPercentage(this.projectId, this.value);
         },
      },

      inputId() {
         return this.$projectPercentageInputId(this.ident, this.projectIndex, this.employeeIndex);
      },

      placeholder() {
         if (this.projectId in this.$refProjectPercentages) {
            const ref = this.$refProjectPercentages[this.projectId];
            if (ref !== null) {
               return ref.toString();
            }
         }
         return null;
      },

      zeroTotalPercentage() {
         return this.$percentage === 0;
      },

      /** The total percentage field is empty */
      noTotalPercentage() {
         return this.$percentage === null || this.$percentage === '';
      },

      projectSumEqualsTotal() {
         return this._projectSumEqualsTotal(this.employeeId);
      },

      showTooltip() {
         return (
            !this.noTotalPercentage &&
            !this.employeeValidation?.$invalid &&
            !this.projectSumEqualsTotal
         );
      },
   },

   methods: {
      ...mapMutations({
         setProject: 'timesurvey/setProject',
      }),

      formatInt,

      restoreValue(value) {
         this.value = value;
      },

      useRefValue() {
         if (this.$refProjectPercentages[this.projectId] !== undefined) {
            this.value = this.$refProjectPercentages[this.projectId];
         }
      },

      nextVerticalInputId(offset, wrap = false) {
         let nextId;
         if (this.tableView) {
            const employeeCount = this.employees.length;
            let nextIndex = this.employeeIndex + offset;

            // Modulo arithmatic to wrap around between first and last employee
            if (wrap) {
               nextIndex = ((nextIndex % employeeCount) + employeeCount) % employeeCount;
            }

            nextId = this.$projectPercentageInputId(this.ident, this.projectIndex, nextIndex);
         } else {
            const projCount = this.projects.length;
            let nextIndex = this.projectIndex + offset;

            if ((wrap && nextIndex >= projCount) || nextIndex < 0) {
               nextId = this.$totalPercentageInputId(this.ident);
            } else {
               nextId = this.$projectPercentageInputId(this.ident, nextIndex);
            }
         }
         return nextId;
      },

      async saveProjectPercentage(id, value) {
         if (this.employeeValidation.projects.$each[id].$invalid) {
            value = null;
         }
         if (value === '') {
            value = null;
         }

         try {
            await this.$store.dispatch('timesurvey/updateCell', {
               employeeId: this.employeeId,
               kind: CellType.PROJECT,
               id,
               value,
            });
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Failed to save employee data',
               seconds: 5,
            });
         }
      },
   },

   watch: {
      /** Manually show/hide the tooltip when the tooltip is enabled/disabled */
      showTooltip(value) {
         if (value) {
            const activeElementId = document.activeElement.id;
            if (activeElementId === this.inputId) {
               this.$root.$emit('bv::show::tooltip', this.inputId);
            }
         } else {
            this.$root.$emit('bv::hide::tooltip', this.inputId);
         }
      },
   },

   validations() {
      return {
         value: {
            integer,
            minValue: minValue(0),
            maxValue: maxValue(100),
         },
      };
   },
};
</script>
