<template>
   <g>
      <path
         d="M5 3.00769C5 2.74247 5.10536 2.48812 5.29289 2.30058C5.48043 2.11305 5.73478 2.00769 6 2.00769H20C20.2652 2.00769 20.5196 2.11305 20.7071 2.30058C20.8946 2.48812 21 2.74247 21 3.00769V21.0077C21 21.2729 20.8946 21.5273 20.7071 21.7148C20.5196 21.9023 20.2652 22.0077 20 22.0077H6C5.73478 22.0077 5.48043 21.9023 5.29289 21.7148C5.10536 21.5273 5 21.2729 5 21.0077V3.00769Z"
         stroke="currentColor"
         stroke-width="2"
         stroke-linejoin="round"
      />
      <path
         d="M17 3.00769V21.0077M3 7.00769H7M3 12.0077H7M3 17.0077H7M13.5 2.00769H19.5M13.5 22.0077H19.5"
         stroke="currentColor"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
      />
   </g>
</template>

<script>
export default {
   data() {
      return {
         name: 'icon-supplies',
         viewBox: '0 0 24 25',
         fill: 'none',
      };
   },
};
</script>
