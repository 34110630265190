<template>
   <b-form-group
      :state="employeeValidation.percentage.$invalid && !noTotalPercentage ? false : null"
      :invalid-feedback="noTotalPercentage ? null : 'Enter an integer between 0 and 100'"
      style="min-width: 6rem"
      class="mb-0"
   >
      <tcp-input-group append="%">
         <b-form-input
            :key="inputId"
            :id="inputId"
            :class="{'input-warning': noTotalPercentage}"
            ref="input"
            number
            :value="percentage"
            @update="onChange"
            :debounce="debounceTime"
            :formatter="formatInt"
            :state="employeeValidation.percentage.$invalid && !noTotalPercentage ? false : null"
            :placeholder="placeholder"
            :disabled="disabled"
            @focus="$storeValue(percentage)"
            @keydown.stop="$onKeydown"
         ></b-form-input>
      </tcp-input-group>

      <b-tooltip :disabled="!showTooltip" :target="inputId" triggers="focus">
         <ProjectSumMessage
            :ident="employeeIndex"
            :employeeId="employeeId"
            :employee-validation="employeeValidation"
         />
      </b-tooltip>
   </b-form-group>
</template>

<script>
import {mapGetters} from 'vuex';

import {CellType} from '@/store/modules/time-survey';
import {formatInt} from '@/helpers/utils';
import EmployeeTimeData from '../mixins/employeeTimeData';
import EmployeeTimeInput from '../mixins/employeeTimeInput';
import ProjectSumMessage from './ProjectSumMessage.vue';

export default {
   mixins: [EmployeeTimeData, EmployeeTimeInput],

   components: {
      ProjectSumMessage,
   },

   props: {
      ident: {
         type: String,
         default: '',
      },
      employeeIndex: {
         type: Number,
         default: null,
      },
      employeeId: {
         type: [String, Number],
         default: null,
      },
      disabled: {
         type: Boolean,
         default: false,
      },
      employees: Array,
      tableView: {
         type: Boolean,
         default: false,
      },
      employeeValidation: {
         type: Object,
      },
   },

   data() {
      return {
         debounceTime: 500,
      };
   },

   computed: {
      ...mapGetters({
         projects: 'timesurvey/projects',
         _projectSumEqualsTotal: 'timesurvey/projectSumEqualsTotal',
      }),

      inputId() {
         return this.$totalPercentageInputId(this.ident, this.employeeIndex);
      },

      /** Total qualifying R&D percentage */
      percentage: {
         get() {
            return this.$employeeData.percentage;
         },
         async set(value) {
            this.$store.commit('timesurvey/setPercentage', {
               employeeId: this.employeeId,
               value,
            });
            this.saveTotalPercentage();
         },
      },

      /** The total percentage field is empty */
      noTotalPercentage() {
         return this.percentage === null || this.percentage === '';
      },

      placeholder() {
         const ref = this.$refTotalPercentage;
         if (ref !== null) {
            return ref.toString();
         }
         return ref;
      },

      projectSumEqualsTotal() {
         return this._projectSumEqualsTotal(this.employeeId);
      },

      showTooltip() {
         return (
            !this.noTotalPercentage &&
            !this.employeeValidation?.$invalid &&
            !this.projectSumEqualsTotal
         );
      },
   },

   methods: {
      formatInt,

      restoreValue(value) {
         this.percentage = value;
      },

      useRefValue() {
         this.percentage = this.$refTotalPercentage;
      },

      nextVerticalInputId(offset, wrap = false) {
         let nextId;
         if (this.tableView) {
            const employeeCount = this.employees.length;
            let nextIndex = this.employeeIndex + offset;

            // Modulo arithmatic to wrap around between first and last employee
            if (wrap) {
               nextIndex = ((nextIndex % employeeCount) + employeeCount) % employeeCount;
            }

            nextId = this.$totalPercentageInputId(this.ident, nextIndex);
         } else {
            const nextIndex = offset > 0 ? 0 : wrap ? this.projects.length - 1 : null;
            nextId = this.$projectPercentageInputId(this.ident, nextIndex);
         }
         return nextId;
      },

      onChange(value) {
         if (value === '') {
            value = null;
         }

         if (this.percentage === 0) {
            // Percentage was 0, so clear the project percentages
            const projectIds = Object.keys(this.$store.getters['timesurvey/projectMap']);
            projectIds.forEach((projectId) => {
               this.$store.commit('timesurvey/setProject', {
                  employeeId: this.employeeId,
                  projectId,
                  value: null,
               });
            });
            this.$store.commit('timesurvey/setPercentage', {
               employeeId: this.employeeId,
               value,
            });
            this.saveEmployee();
         } else if (value === 0) {
            // Setting percentage to 0, so set all project percentages to 0
            const projectIds = Object.keys(this.$store.getters['timesurvey/projectMap']);
            const activityIds = Object.keys(this.$store.getters['timesurvey/activities']);
            projectIds.forEach((projectId) => {
               this.$store.commit('timesurvey/setProject', {
                  employeeId: this.employeeId,
                  projectId,
                  value: 0,
               });
            });
            activityIds.forEach((activityId) => {
               this.$store.commit('timesurvey/setActivity', {
                  employeeId: this.employeeId,
                  activityId,
                  value: false,
               });
            });
            this.$store.commit('timesurvey/setPercentage', {
               employeeId: this.employeeId,
               value,
            });
            this.saveEmployee();
         } else {
            this.percentage = value;
         }
      },

      async saveTotalPercentage() {
         let value = this.$percentage;
         if (this.employeeValidation.percentage.$invalid) {
            value = null;
         }
         if (value === '') {
            value = null;
         }

         try {
            await this.$store.dispatch('timesurvey/updateCell', {
               employeeId: this.employeeId,
               kind: CellType.PERCENTAGE,
               value,
            });
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Failed to save employee data',
               seconds: 5,
            });
         }
      },

      async saveEmployee() {
         if (this.employeeValidation.$invalid) {
            return;
         }

         try {
            await this.$store.dispatch('timesurvey/saveEmployee', {employeeId: this.employeeId});
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Failed to save employee data',
               seconds: 5,
            });
         }
      },
   },

   watch: {
      /** Manually show/hide the tooltip when the tooltip is enabled/disabled */
      showTooltip(value) {
         if (value) {
            const activeElementId = document.activeElement.id;
            if (activeElementId === this.inputId) {
               this.$root.$emit('bv::show::tooltip', this.inputId);
            }
         } else {
            this.$root.$emit('bv::hide::tooltip', this.inputId);
         }
      },
   },
};
</script>
<style lang="scss" scoped>
.percentage-input {
   width: 7.5rem;
}
</style>
