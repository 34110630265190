<template>
   <div>
      <b-form-group :invalidFeedback="invalidFeedback" v-if="!readonly">
         <b-form-input
            v-model="answerValue"
            id="input-answer"
            :state="dirty ? !$v.answerValue.$invalid : null"
         ></b-form-input>
      </b-form-group>
      <div v-else>
         <div v-if="answerValue !== null" :id="`answer-${index + 1}`">
            {{ answerValue }}
         </div>
         <div v-else :id="`answer-${index + 1}`">
            <b>Not Answered</b>
         </div>
      </div>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {AnswerTypes} from '@/helpers/types';

export default {
   name: 'AnswerNumber',

   props: {
      index: Number,
      readonly: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      ...mapGetters({
         questions: 'questionnaire/questions',
      }),

      question() {
         return this.questions[this.index];
      },

      answer() {
         return this.question.answer;
      },

      validationMinimum() {
         return this.question.validation.minimum;
      },

      validationMaximum() {
         return this.question.validation.maximum;
      },

      invalidFeedback() {
         if (!this.$v.answerValue.isNumber) {
            return 'Answer must be a number';
         } else if (!this.$v.answerValue.isInteger) {
            return 'Answer must be an integer';
         } else if (!this.$v.answerValue.minValue) {
            return `Answer must be greater than ${this.validationMinimum}`;
         } else if (!this.$v.answerValue.maxValue) {
            return `Answer must be less than ${this.validationMaximum}`;
         }
         return '';
      },

      dirty() {
         if (this.answer) {
            return this.answer.dirty;
         } else {
            return false;
         }
      },

      answerValue: {
         get() {
            if (this.answer) {
               return this.answer.answer;
            } else {
               return null;
            }
         },
         set(value) {
            this.$store.commit('questionnaire/setAnswerValue', {
               questionIndex: this.index,
               value,
            });
         },
      },
   },

   validations: {
      answerValue: {
         isNumber(value) {
            return !isNaN(+value);
         },
         isInteger(value) {
            if (this.question.ansType === AnswerTypes.INTEGER) {
               return Number.isInteger(+value);
            } else {
               return true;
            }
         },
         minValue(value) {
            let valid = true;
            if (null !== this.validationMinimum) {
               valid = valid && value >= this.validationMinimum;
            }
            return valid;
         },
         maxValue(value) {
            let valid = true;
            if (null !== this.validationMaximum) {
               valid = valid && value <= this.validationMaximum;
            }
            return valid;
         },
      },
   },

   watch: {
      answerValue: {
         immediate: true,
         handler() {
            this.$emit('validate', !this.$v.$invalid);
         },
      },
   },
};
</script>
