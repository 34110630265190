<template>
   <div v-if="loaded">
      <b-container>
         <PageTitle title="Employee Time" />

         <template v-if="manager">
            <!-- ASSIGNMENTS_INVALID -->
            <ManagerMessage v-if="currentTimesurveyState === TsStates.ASSIGNMENTS_INVALID">
               <template #short>Take another look at your assigned employees.</template>

               <h2>Take another look at your assigned employees.</h2>

               <p class="mb-0">
                  Some of the employees assigned to you have invalid data. Please make sure the
                  total percentage of each employee's time spent on R&D is equal to the sum of their
                  time spent on R&D projects.
               </p>
            </ManagerMessage>

            <!-- ASSIGNMENTS_INCOMPLETE -->
            <ManagerMessage v-if="currentTimesurveyState === TsStates.ASSIGNMENTS_INCOMPLETE">
               <template #short>Please tell us about these employees' R&D time.</template>

               <h2>Please tell us about these employees' R&D time.</h2>

               <p class="mb-0">
                  The employees listed below are assigned to you so you can tell us about their R&D
                  time. Questions? Please feel free to ask with the messaging feature in the top
                  right corner.
               </p>
            </ManagerMessage>

            <!-- TS_COMPLETE -->
            <ManagerMessage v-if="currentTimesurveyState === TsStates.ASSIGNMENTS_COMPLETE">
               <template #short> When you're ready, click <b>Done</b> below. </template>

               <h2>When you're ready, click <b>Done</b> below.</h2>

               <p class="mb-0">
                  All of your assigned employees have valid data. If you're confident all the
                  information you've entered is accurate, please click
                  <b>Done</b> below to let us know you're done entering data.
               </p>
            </ManagerMessage>

            <!-- TS_INVALID -->
            <ManagerMessage v-if="currentTimesurveyState === TsStates.TS_INVALID">
               <template #short>Some of this data doesn't look quite right.</template>

               <h2>Some of this data doesn't look quite right.</h2>

               <p>
                  Some of the data that has been entered in this time survey is invalid. Please make
                  sure the total percentage of each employee's time spent on R&D is equal to the sum
                  of their time spent on R&D projects.
               </p>

               <p class="mb-0">
                  The invalid data must be corrected before this time survey can be submitted.
               </p>
            </ManagerMessage>

            <!-- TS_INCOMPLETE -->
            <ManagerMessage v-if="currentTimesurveyState === TsStates.TS_INCOMPLETE">
               <template #short
                  >You're done, but we're waiting on others. Check back later.</template
               >

               <h2>You're done, but we're waiting on others. Check back later.</h2>

               <p class="mb-0">
                  You're all done here, but we're waiting on other users to enter their employees'
                  time. You can see below who still has employees to enter time for. When all
                  employee time has been entered you will be able to finish this section.
               </p>
            </ManagerMessage>

            <!-- VALID - Non SME users -->
            <ManagerMessage v-if="currentTimesurveyState === TsStates.VALID && !isSME">
               <template #short>Ready to submit!</template>

               <h2>Ready to Submit!</h2>

               <p class="mb-0">
                  All employees have had their time entered for this year. Review their time below
                  and when you're ready to submit it, click <b>Complete</b> at the bottom of the
                  page.
               </p>
            </ManagerMessage>

            <!-- VALID - SME users -->
            <ManagerMessage
               v-if="
                  (currentTimesurveyState === TsStates.VALID && isSME) ||
                  currentTimesurveyState === TsStates.VALIDATED
               "
            >
               <template #short>All done with this year!</template>

               <h2>All done with this year!</h2>
               <p>
                  You've finished telling us about the R&D contributions of employees in this year.
                  Now you can return to the dashboard to see what else needs your attention.
               </p>

               <div class="d-flex align-items-center justify-content-end">
                  <b-button variant="success" class="mr-2" :to="{name: 'Home'}">
                     Dashboard
                  </b-button>
               </div>
            </ManagerMessage>
         </template>

         <div class="year-selection">
            <div class="year-selection-row">
               <div>
                  <b-button
                     id="btn-prev-year"
                     v-if="previousYear != null"
                     variant="primary"
                     class="d-flex align-items-center"
                     @click="toPreviousYear"
                  >
                     <b-icon-arrow-left-short class="mr-1" />
                     {{ previousYear }}
                  </b-button>
               </div>

               <h1 id="current-year">{{ year }}</h1>

               <b-button
                  id="btn-next-year"
                  v-if="nextYear != null"
                  variant="primary"
                  class="d-flex align-items-center"
                  @click="toNextYear"
               >
                  {{ nextYear }}
                  <b-icon-arrow-right-short class="ml-1" />
               </b-button>
            </div>
            <hr class="my-0" />
         </div>
      </b-container>

      <!-- Current user's card -->
      <TimeSurveyUserCard
         v-if="anyAssignedToMe"
         ref="myAssignments"
         id="card-my-assignments"
         ident="my-assignments"
         :user="profile"
         :state="userStateMap[profile.id]"
         :tsState="currentTimesurveyState"
         :loaded="loaded"
      />

      <!-- Employees assigned to other users -->
      <TimeSurveyUserCard
         v-for="(user, idx) in otherUsers"
         :id="`card-other-${idx + 1}`"
         :ident="`other-${idx + 1}`"
         :key="user.id"
         :user="user"
         :state="userStateMap[user.id]"
         :tsState="currentTimesurveyState"
         :loaded="loaded"
      />

      <!-- Unassigned employees -->
      <template v-if="unassignedEmployees.length > 0">
         <TimeSurveyUserCard
            id="card-unassigned"
            ident="unassigned"
            :user="{id: null}"
            :state="userStateMap[null]"
            :tsState="currentTimesurveyState"
            :loaded="loaded"
         />
      </template>

      <b-container>
         <div class="d-flex align-items-center justify-content-between">
            <div>
               <b-button
                  v-if="isClient"
                  variant="secondary"
                  :to="{
                     name: 'time-survey-years',
                     params: {id: companyId},
                  }"
               >
                  <b-icon-arrow-left-short class="mr-1" />
                  Back
               </b-button>
            </div>

            <b-button
               v-if="!isSME"
               id="btn-submit"
               variant="success"
               @click="submit"
               :disabled="currentTimesurveyState !== TsStates.VALID"
            >
               Complete <b-icon-check />
            </b-button>
         </div>
      </b-container>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

import TimeSurveyUserCard from './widgets/TimeSurveyUserCard';
import {TsStates, TsUserStates, TsEmployeeStates} from '@/helpers/constants';

export default {
   components: {
      TimeSurveyUserCard,
   },

   data() {
      return {
         loaded: false,
         TsStates,
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         isClient: 'isClient',
         isSME: 'isSME',
         manager: 'manager',
         company: 'companies/currentCompany',
         study: 'companies/study',
         projects: 'timesurvey/projects',
         assignedEmployees: 'timesurvey/assignedEmployees',
         unassignedEmployees: 'timesurvey/unassignedEmployees',
         assignmentsComplete: 'timesurvey/assignmentsComplete',
         _users: 'users/companyUsers',
         yearsData: 'timesurvey/years',
         hasAssignmentInYear: 'tsAssignment/hasAssignmentInYear',
         timesurveyState: 'timesurvey/timesurveyState',
         employeeStateMap: 'timesurvey/employeeStateMap',
      }),

      /** ID for the company this time survey belongs to */
      companyId() {
         if (this.$route.params.id) {
            return this.$route.params.id;
         } else {
            return this.company.id;
         }
      },

      /** The year of the time survey */
      year() {
         return this.$route.params.year;
      },

      yearsAvailable() {
         return this.yearsData.POSSIBLE.filter((y) => {
            if (this.isSME) {
               return this.hasAssignmentInYear(this.profile.id, y);
            }
            return true;
         });
      },

      /** The previous year of the time survey, if in range */
      previousYear() {
         const thisYear = parseInt(this.year),
            yearsAvailable = this.yearsAvailable.filter((y) => y < thisYear);

         return yearsAvailable.length > 0 ? Math.max(...yearsAvailable) : null;
      },

      /** The next year of the time survey, if in range */
      nextYear() {
         const thisYear = parseInt(this.year),
            yearsAvailable = this.yearsAvailable.filter((y) => y > thisYear);

         return yearsAvailable.length > 0 ? Math.min(...yearsAvailable) : null;
      },

      /** A list of users *with assignments* not including the current user */
      otherUsers() {
         return this.users.filter((user) => {
            return user.id !== this.profile.id && this.assignedEmployees(user.id).length > 0;
         });
      },

      anyAssignedToMe() {
         return this.assignedEmployees(this.profile.id).length > 0;
      },

      currentTimesurveyState() {
         return this.timesurveyState(this.year);
      },

      /** A list of users with the current user always first */
      users() {
         let retval = [
            ...Object.values(this._users)
               .filter((user) => this.assignedEmployees(user.id).length > 0)
               .sort((a, b) => {
                  if (a.id === this.profile.id) {
                     return -1;
                  }
                  if (b.id === this.profile.id) {
                     return 1;
                  }
                  const nameA = `${a.lastName}${a.firstName}`.toUpperCase();
                  const nameB = `${b.lastName}${b.firstName}`.toUpperCase();
                  if (nameA < nameB) {
                     return -1;
                  }
                  if (nameA > nameB) {
                     return 1;
                  }
                  return 0;
               }),
         ];
         return retval;
      },

      /**
       * Compute the state of the employees assigned to each user
       * @returns {Object} - Mapping of user IDs to the `TsUserState` of that user
       */
      userStateMap() {
         const states = {};

         [...this.users, {id: null}].forEach((user) => {
            const employees = user.id ? this.assignedEmployees(user.id) : this.unassignedEmployees;
            const employeeStates = employees.map((employee) => this.employeeStateMap[employee.id]);

            if (
               this.assignmentsComplete[user.id] &&
               employeeStates.every((state) => state === TsEmployeeStates.VALID)
            ) {
               states[user.id] = {
                  state: TsUserStates.COMPLETE,
                  variant: 'success',
                  text: 'Complete',
               };
            } else if (employeeStates.some((state) => state === TsEmployeeStates.INVALID)) {
               states[user.id] = {
                  state: TsUserStates.INVALID,
                  variant: 'danger',
                  text: 'Invalid data',
               };
            } else if (employeeStates.some((state) => state === TsEmployeeStates.INCOMPLETE)) {
               states[user.id] = {
                  state: TsUserStates.INCOMPLETE,
                  variant: 'warning',
                  text: 'Incomplete',
               };
            } else if (user.id === null) {
               states[user.id] = {
                  state: TsUserStates.COMPLETE,
                  variant: 'success',
                  text: 'Complete',
               };
            } else {
               states[user.id] = {
                  state: TsUserStates.VALID,
                  variant: 'warning',
                  text: 'Ready to mark complete',
               };
            }
         });

         return states;
      },
   },

   methods: {
      /** Display the time entry modal for the first editable employee */
      enterEmployeeTime() {
         if (this.$refs.myAssignments) {
            this.$refs.myAssignments.enterEmployeeTime();
         }
      },

      /** Submit the time survey for validation */
      async submit() {
         if (this.currentTimesurveyState !== TsStates.VALID && !this.isSME) {
            return;
         }

         await this.blockingRequest('timesurvey/validate', {
            companyId: this.companyId,
            year: this.year,
         });

         window.scrollTo({top: 0, behavior: 'smooth'});
      },

      toNextYear() {
         this.$router.push({
            name: this.$route.name,
            params: {year: this.nextYear},
         });
      },

      toPreviousYear() {
         this.$router.push({
            name: this.$route.name,
            params: {year: this.previousYear},
         });
      },

      async loadYear() {
         return this.$store.dispatch('timesurvey/loadTimeSurvey', {
            companyId: this.companyId,
            year: this.year,
            assignments: true,
            refData: true,
         });
      },
   },

   watch: {
      async year(newYear, oldYear) {
         if (newYear !== oldYear) {
            let requests = [];
            requests.push(this.loadYear());
            await this.blockUntilAllSettled(requests);
         }
      },
   },

   async created() {
      // If the company has no active study or the year is outside the current study, redirect to company detail
      if (
         this.study === null ||
         this.year < this.study.years.lower ||
         this.year > this.study.years.upper
      ) {
         this.$router.push({name: 'company-detail', params: {id: this.companyId}});
      }

      let requests = [];
      requests.push(
         this.$store.dispatch('timesurvey/loadValidatedYears', {
            companyId: this.companyId,
         })
      );

      requests.push(this.loadYear());
      requests.push(this.$store.dispatch('timesurvey/loadYears', {companyId: this.companyId}));
      requests.push(this.$store.dispatch('users/loadCompanyUsers', {companyId: this.companyId}));
      if (this.isSME) {
         requests.push(
            this.$store.dispatch('tsAssignment/loadEmployeeAssignments', {
               companyId: this.companyId,
            })
         );
      }
      await this.blockUntilAllSettled(requests);

      this.loaded = true;
   },
};
</script>

<style lang="scss" scoped>
::v-deep {
   .b-table-sticky-header {
      scroll-padding-top: 95px;
   }

   .b-table {
      font-size: 15px;
   }
}
</style>
