<template>
   <div class="msg-container" :class="{self: isMe, 'repeat-sender': isRepeatSender}">
      <div v-if="firstUnseen" class="unread">
         <hr />
         <span>Unread</span>
      </div>
      <div v-if="displayContact" class="contact">
         <img v-auth-src="imgSrc" class="profile-image" />
         <div class="user-info">
            <h6 class="username">{{ userName }}</h6>
            <span class="timestamp">{{ timestamp }}</span>
         </div>
      </div>
      <div class="content-container">
         <div class="content-bubble">
            <p v-for="(paragraph, idx) in paragraphs" :key="idx">{{ paragraph }}</p>
         </div>
      </div>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {StaffRoleMap} from '@/helpers/constants';

export default {
   props: {
      idx: {
         type: Number,
      },

      firstUnseen: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         myStaff: 'myStaff',
         messages: 'messaging/messages',
         userMap: 'users/userMap',
      }),

      message() {
         return this.messages[this.idx];
      },

      isMe() {
         return this.message.userId === this.profile.id;
      },

      user() {
         if (this.isMe) {
            return this.profile;
         } else if (this.message.userId in this.myStaff) {
            return this.myStaff[this.message.userId];
         } else if (this.message.userId in this.userMap) {
            return this.userMap[this.message.userId];
         }
         return {
            firstName: 'R&DIG Staff',
            lastName: '',
            role: null,
         };
      },

      userName() {
         let name = `${this.user.firstName} ${this.user.lastName}`;
         if (this.user.role in StaffRoleMap) {
            name += `, ${StaffRoleMap[this.user.role]}`;
         }
         return name;
      },

      timestamp() {
         return this.formatDatetime(this.message.timestamp);
      },

      paragraphs() {
         return this.message.content.split('\n');
      },

      imgSrc() {
         return {
            src: `/api/user/${this.user.id}/profile/image`,
            fallbackUrl: require('@/assets/img/avatar.svg'),
         };
      },

      displayContact() {
         if (this.isMe) {
            return false;
         }
         if (this.idx > 0) {
            return !this.isRepeatSender;
         }
         return true;
      },

      isRepeatSender() {
         if (this.idx > 0) {
            return this.messages[this.idx - 1].userId === this.message.userId;
         }
         return false;
      },
   },

   methods: {
      /** Format a date string */
      formatDatetime(str) {
         return new Date(str).toLocaleString();
      },
   },
};
</script>

<style lang="scss" scoped>
.msg-container {
   margin-bottom: 0.5rem;
   margin-top: 1rem;

   &.repeat-sender {
      margin-top: 0;
   }
}

.unread {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0.5rem 0;
   font-size: 0.825rem;
   font-style: italic;
   color: $gray-600;

   hr {
      position: absolute;
      width: 100%;
   }

   span {
      padding: 4px 8px;
      background-color: $gray-100;
      z-index: 50000;
   }
}

.content-container {
   display: flex;

   .content-bubble {
      flex-grow: 1;
      background-color: $gray-200;
      padding: 0.6rem 1rem;
      margin-right: 3.5rem;
      border-radius: 10px;
      border-top-left-radius: 0;
      max-width: 420px;

      p:last-of-type {
         margin-bottom: 0;
      }
   }
}

.self {
   .content-container {
      justify-content: end;
      .content-bubble {
         background-color: $primary;
         color: $white;
         margin-right: 0;
         margin-left: 3.5rem;
         border-top-left-radius: 10px;
         border-top-right-radius: 0;
      }
   }
}

.contact {
   display: flex;
   align-items: center;
   margin-bottom: 0.5rem;

   .profile-image {
      flex-shrink: 0;
      width: 40px;
      border-radius: 50%;
      margin-right: 0.5rem;
   }

   .user-info {
      .username {
         font-size: 0.875rem;
         font-weight: 800;
         color: $gray-800;
         margin-bottom: 0;
      }

      .timestamp {
         font-size: 0.75rem;
         color: $gray-600;
      }
   }
}
</style>
