<template>
   <g>
      <g clip-path="url(#clip0_10133_6466)">
         <path
            d="M19.6241 5.56777C17.7543 3.69417 15.3186 2.59421 12.7032 2.42658V1.41394H13.7344C14.1228 1.41394 14.4376 1.09913 14.4376 0.710815C14.4376 0.322503 14.1228 0.00769043 13.7344 0.00769043H10.2657C9.87739 0.00769043 9.56257 0.322503 9.56257 0.710815C9.56257 1.09913 9.87739 1.41394 10.2657 1.41394H11.2969V2.42658C9.50365 2.54152 7.79501 3.09488 6.29525 4.03552L5.62732 3.36624C5.30225 3.0405 4.86982 2.86116 4.40965 2.86116C3.94948 2.86116 3.51706 3.04055 3.19203 3.36624L2.1762 4.38427C1.50753 5.05425 1.50753 6.14447 2.1762 6.8145L2.84867 7.48838C1.78728 9.18671 1.21887 11.1523 1.21887 13.2055C1.21887 16.0906 2.34012 18.8031 4.37609 20.8433C6.41243 22.8839 9.12003 24.0077 12.0001 24.0077C14.8801 24.0077 17.5877 22.8839 19.6241 20.8433C21.66 18.8031 22.7813 16.0906 22.7813 13.2055C22.7813 10.3205 21.66 7.60796 19.6241 5.56777ZM3.17159 5.37764L4.18747 4.35966C4.26753 4.27946 4.36104 4.26746 4.4097 4.26746C4.45836 4.26746 4.55192 4.27941 4.63198 4.35966L5.14151 4.87022C4.87728 5.08927 4.62172 5.32172 4.37614 5.56782C4.13126 5.81321 3.89993 6.06853 3.68192 6.33253L3.17159 5.82117C3.04953 5.69888 3.04953 5.49989 3.17159 5.37764ZM12.0001 22.6014C6.8307 22.6014 2.62512 18.3864 2.62512 13.2055C2.62512 8.02463 6.8307 3.80963 12.0001 3.80963C17.1694 3.80963 21.375 8.02458 21.375 13.2055C21.375 18.3865 17.1694 22.6014 12.0001 22.6014Z"
         />
         <path
            d="M11.9999 5.12769C7.55421 5.12769 3.93738 8.7514 3.93738 13.2056C3.93738 17.6597 7.55421 21.2834 11.9999 21.2834C16.4456 21.2834 20.0625 17.6597 20.0625 13.2056C20.0625 8.7514 16.4456 5.12769 11.9999 5.12769ZM12.7031 19.84V19.0181C12.7031 18.6298 12.3882 18.315 11.9999 18.315C11.6116 18.315 11.2968 18.6298 11.2968 19.0181V19.84C8.18636 19.5111 5.70789 17.0265 5.38057 13.9087H6.18742C6.57574 13.9087 6.89055 13.5939 6.89055 13.2056C6.89055 12.8172 6.57574 12.5024 6.18742 12.5024H5.38061C5.70789 9.38464 8.18636 6.89998 11.2968 6.57115V7.39301C11.2968 7.78133 11.6116 8.09614 11.9999 8.09614C12.3882 8.09614 12.7031 7.78133 12.7031 7.39301V6.57115C15.8135 6.89998 18.292 9.38464 18.6193 12.5024H17.8124C17.4241 12.5024 17.1093 12.8172 17.1093 13.2056C17.1093 13.5939 17.4241 13.9087 17.8124 13.9087H18.6192C18.292 17.0265 15.8135 19.5111 12.7031 19.84Z"
         />
         <path
            d="M13.7345 12.7997H12.7426V9.95593C12.7426 9.56762 12.4277 9.25281 12.0394 9.25281C11.6511 9.25281 11.3363 9.56762 11.3363 9.95593V13.5028C11.3363 13.8911 11.6511 14.206 12.0394 14.206H13.7345C14.1228 14.206 14.4377 13.8911 14.4377 13.5028C14.4377 13.1145 14.1228 12.7997 13.7345 12.7997Z"
         />
      </g>
      <defs>
         <clipPath id="clip0_10133_6466">
            <rect width="24" height="24" fill="white" transform="translate(0 0.00769043)" />
         </clipPath>
      </defs>
   </g>
</template>

<script>
export default {
   data() {
      return {
         name: 'icon-chronometer',
         viewBox: '0 0 24 25',
      };
   },
};
</script>
