import Vue from 'vue';
import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

const writeMethods = ['delete', 'patch', 'post', 'put'];

const addAuthHeader = async (config) => {
   // Add auth header if a token has been obtained
   const token = await Vue.updateToken();

   if (token) {
      config.headers.Authorization = `Bearer ${token}`;
   }

   return config;
};

axios.interceptors.request.use(addAuthHeader);

// Basic configuration suitable for every request
const basicConfig = {
   headers: {
      'Content-Type': 'application/json',
      patch: {
         'Content-Type': 'application/json',
      },
      post: {
         'Content-Type': 'application/json',
      },
      put: {
         'Content-Type': 'application/json',
      },
   },
};

/** Log a user out when a response from the API has HTTP status 401 */
const forceLogoutOnUnauthorized = (error) => {
   if (error.response?.status === 401 && window.location.href !== '/') {
      Vue.prototype.logout();
   }
   throw error;
};

/** Convert request data keys from camelcase to snake case */
const formatRequestKeys = (data, headers) => {
   // NB: capitalization on 'Content-Type' matters
   if (data && headers['Content-Type'].includes('application/json')) {
      return JSON.stringify(snakecaseKeys(data, {deep: true}));
   }
   return data;
};

/** Convert response data keys from snakecase to camelcase. */
const formatResponseKeys = (data, headers) => {
   try {
      data = JSON.parse(data);
   } catch {
      /* Ignore */
   }

   if (
      data &&
      // NB: capitalization on 'content-type' matters
      headers['content-type'].includes('application/json') &&
      !('errors' in data) // Error response data needs to stay unformatted
   ) {
      return camelcaseKeys(data, {deep: true});
   }
   return data;
};

export const Plugin = {
   install(VueInstance, {store}) {
      const updateProgress = (response) => {
         if (writeMethods.includes(response.config.method)) {
            const getters = store.getters,
               companyId = getters.profile?.companyId;

            if (getters.isClient && companyId) {
               store.dispatch('startProgressInterval', {companyId});
            }
         }

         return response;
      };

      // Default axios instance, which handles converting request/response
      // keys between camelcase (used in this app) and snake case (used in
      // the python server-side app)
      const defaultInstance = axios.create({
         ...basicConfig,
         transformRequest: [formatRequestKeys],
         transformResponse: [formatResponseKeys],
      });
      defaultInstance.interceptors.request.use(addAuthHeader);
      defaultInstance.interceptors.response.use(updateProgress);
      defaultInstance.interceptors.response.use((response) => response, forceLogoutOnUnauthorized);
      VueInstance.prototype.$http = defaultInstance;

      // A simple axios instance that leaves the request/response keys alone
      const simpleInstance = axios.create(basicConfig);
      simpleInstance.interceptors.request.use(addAuthHeader);
      simpleInstance.interceptors.response.use(updateProgress);
      simpleInstance.interceptors.response.use((response) => response, forceLogoutOnUnauthorized);
      VueInstance.prototype.$httpSimple = simpleInstance;
   },
};
