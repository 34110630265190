<template>
   <b-container class="px-0" fluid v-if="loaded">
      <div class="thread-overview">
         <div class="thread-list scrollbar">
            <ul>
               <li
                  v-for="(threadSummary, idx) in threads"
                  :key="idx"
                  @click="switchThread(threadSummary)"
                  :class="{
                     selected: threadId === threadSummary.thread.id,
                     unread: threadSummary.unread,
                  }"
                  class="thread-item"
               >
                  <div class="d-flex align-items-center justify-content-between">
                     <div class="name line-clamp-1">
                        {{ getFullNameFromThread(threadSummary) }},
                        {{ getCompanyNameFromThread(threadSummary) }}
                     </div>

                     <div class="unread">
                        <span v-if="threadSummary.unread" class="badge badge-danger">
                           {{ threadSummary.unread }}
                        </span>
                     </div>
                  </div>
                  <span class="date">{{ getTsFromThread(threadSummary) }}</span>
               </li>
            </ul>
         </div>
         <div class="thread">
            <template v-if="currentThread != null">
               <div class="header">
                  <img class="avatar" src="@/assets/img/avatar.svg" />
                  <h3
                     id="thread-user-header"
                     class="d-inline-block mb-0"
                     @click="userInfoShowing = true"
                  >
                     {{ getFullNameFromThread(currentThread) }},
                     {{ getCompanyNameFromThread(currentThread) }}
                  </h3>
               </div>
               <div class="thread-container">
                  <MessageThread ref="thread" />
               </div>
            </template>
         </div>
         <div class="user-info" v-if="currentThread != null && userInfoShowing">
            <b-row class="close-button-row">
               <b-col class="text-right">
                  <button id="btn-close-user-info" class="btn" @click="userInfoShowing = false">
                     ✕
                  </button>
               </b-col>
            </b-row>
            <b-row class="user-overview">
               <b-col class="text-center">
                  <img class="avatar" src="@/assets/img/avatar.svg" />
                  <h2 id="user-info-name" class="user-name mb-0">
                     {{ getFullNameFromThread(currentThread) }}
                  </h2>
                  <h3 id="user-info-company" class="user-company">
                     {{ getCompanyNameFromThread(currentThread) }}
                  </h3>
               </b-col>
            </b-row>
            <b-row class="user-info-field">
               <b-col>
                  <h3>Email</h3>
                  <div id="user-info-email" class="user-info-data">
                     {{ getUserInfoFromThread(currentThread).email }}
                  </div>
               </b-col>
            </b-row>
            <b-row class="user-info-field">
               <b-col>
                  <h3>Job Title</h3>
                  <div id="user-info-title" class="user-info-data">
                     {{ getUserInfoFromThread(currentThread).title }}
                  </div>
               </b-col>
            </b-row>
            <b-row class="user-info-field">
               <b-col>
                  <h3>Department</h3>
                  <div id="user-info-department" class="user-info-data">
                     {{ getUserInfoFromThread(currentThread).department }}
                  </div>
               </b-col>
            </b-row>
         </div>
      </div>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import MessageThread from '@/views/messaging/MessageThread';

export default {
   components: {
      MessageThread,
   },

   data() {
      return {
         loaded: false,
         userInfoShowing: false,
      };
   },

   computed: {
      ...mapGetters({
         companyMap: 'companies/companyMap',
         currentThread: 'messaging/currentThread',
         messages: 'messaging/messages',
         threads: 'messaging/threads',
         threadId: 'messaging/threadId',
         userMap: 'users/userMap',
      }),
   },

   methods: {
      getCompanyNameFromThread(threadSummary) {
         const {companyId} = this.getUserInfoFromThread(threadSummary);

         return this.companyMap[companyId].companyName;
      },

      getFullNameFromThread(threadSummary) {
         const {firstName, lastName} = this.getUserInfoFromThread(threadSummary);

         if (lastName) {
            return `${firstName} ${lastName}`;
         } else {
            return firstName;
         }
      },

      getUserInfoFromThread(threadSummary) {
         const userId = threadSummary.thread.userId;

         return this.userMap[userId];
      },

      getTsFromThread(threadSummary) {
         return new Date(threadSummary.latestMsgTs).toLocaleString();
      },

      switchThread(threadSummary) {
         const threadId = threadSummary.thread.id;

         this.$store.commit('messaging/setCurrentThread', {threadId});
         this.$store.dispatch('messaging/loadMessages', {threadId});
      },
   },

   watch: {
      /**
       * After the message list updates and those messages have been rendered,
       * check for cases where the onScroll behavior would not mark messages seen.
       * Then, set the initial scroll position.
       */
      messages() {
         // nextTick so that this occurs after the message window re-renders
         this.$nextTick(() => {
            if (this.currentThread.unread && !this.$refs.thread.isScrollable()) {
               this.$store.dispatch('messaging/seeAllMessages');
            }
            this.$refs.thread.setInitialScroll();
         });
      },
   },

   async created() {
      const requests = [
         this.$store.dispatch('users/loadUsers'),
         this.$store.dispatch('companies/loadCompanies'),
      ];

      await this.blockUntilAllSettled(requests);
      this.loaded = true;
   },
};
</script>

<style lang="scss" scoped>
$headerFooterHeight: 172px;
$lightDivider: #eee;
$mediumDivider: #ccc;

.thread-overview {
   display: flex;
   justify-content: space-between;
   border-top: 1px solid $mediumDivider;
   border-bottom: 1px solid $mediumDivider;

   & > div + div {
      border-left: 1px solid $mediumDivider;
   }

   height: calc(100vh - #{$headerFooterHeight});
}

.thread-list {
   padding: 0;
   overflow-y: auto;
   width: 296px;
   flex-shrink: 0;

   ul {
      margin: 0;
      padding: 0;

      li {
         border-bottom: 1px solid $lightDivider;
         padding: 1.2em 1em;
         cursor: pointer;
         transition: all 0.3s ease;
         color: $gray-650;

         .name {
            font-weight: 800;
            padding-right: 0.25rem;
         }

         .date {
            font-size: 0.825rem;
         }

         &.unread {
            color: $gray-800;
         }

         &.selected {
            background-color: $secondary;
            color: $white;
         }

         .badge {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50rem;
         }
      }
   }
}

.thread {
   padding: 0;
   flex-shrink: 2;
   flex-grow: 1;
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   .header {
      flex-shrink: 0;
      color: $gray-800;
      padding: 1.25rem;
      display: flex;
      align-items: center;
      background-color: $white;
      border-bottom: 1px solid $lightDivider;

      .avatar {
         height: 40px;
         width: 40px;
         margin-right: 0.4em;
      }

      h3 {
         font-weight: 800;
         cursor: pointer;
         transition: color 0.3s ease;

         &:hover {
            color: $secondary;
         }
      }
   }

   .thread-container {
      min-height: 0;
      flex-shrink: 2;
      flex-grow: 1;
   }
}

.user-info {
   flex-shrink: 0;
   width: 350px;
   padding-left: 30px;
   padding-right: 30px;
   display: flex;
   flex-direction: column;
   min-height: 0;

   .row {
      margin-left: 0;
      margin-right: 0;
   }

   .col {
      padding: 0;
   }

   .close-button-row {
      padding-top: 1.5em;
      font-size: larger;

      .btn {
         padding: 0;
      }
   }

   .user-overview {
      padding-top: 0;

      .avatar {
         height: 150px;
         width: 150px;
         margin-bottom: 1rem;
      }

      .user-name {
         color: $gray-800;
         font-size: 2rem;
      }

      .user-company {
         color: $gray-600;
         font-size: 1.125rem;
      }
   }

   .user-info-field {
      padding: 1.7em 0 0 0;

      h3 {
         color: $gray-600;
         margin-bottom: 0;
      }

      .user-info-data {
         color: $primary;
         font-weight: 500;
      }

      h3,
      div {
         font-size: 1.125rem;
         min-height: 24px;
      }
   }

   .shared-media {
      & > div {
         padding: 1em 0 0 0;
      }

      .media-placeholder {
         background-color: #aaa;
         border-radius: 12px;
         height: 100px;
         width: 100px;
      }
   }
}

.thread-list,
.user-info {
   background-color: $white;
}

img.avatar {
   border-radius: 50%;
   text-align: middle;
}
</style>
