/**
 * Shared utils for working with an employee's time data.
 * Usage:
 *    The component importing this mixin must define `employeeId`
 */
export default {
   computed: {
      /** The current employee's data */
      $employeeData() {
         return {
            id: this.employeeId,
            ...this.$store.getters['timesurvey/data'][this.employeeId],
         };
      },

      /** Total qualifying R&D percentage */
      $percentage() {
         return this.$employeeData.percentage;
      },

      $refTotalPercentage() {
         const refData = this.$employeeData.ref;
         if (refData) {
            return refData.percentage;
         }
         return null;
      },

      $refProjectPercentages() {
         const refData = this.$employeeData.ref;
         if (refData) {
            return refData.projects;
         }
         return {};
      },

      $employeeState() {
         return this.$store.getters['timesurvey/employeeStateMap'][this.employeeId];
      },
   },
};
