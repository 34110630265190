<template>
   <div>
      <h2 v-if="uploadedFiles.length > 0" class="mb-3">Imported Accounts</h2>

      <CollapseCard
         class="mb-3"
         v-for="(file, fileIndex) in uploadedFiles"
         :key="file.id"
         :title="file.description"
         :ident="`imported-${fileIndex + 1}`"
      >
         <template #subtitle>
            <b-icon
               icon="exclamation-circle-fill"
               variant="warning"
               v-if="file.validation.post.skipped > 0"
               font-scale="1.2"
            ></b-icon>
         </template>

         <b-row class="mb-1">
            <b-col cols="4" lg="2">
               <b>Uploaded at</b>
            </b-col>
            <b-col cols="8" lg="10">{{ formatDatetime(file.uploadedAt) }}</b-col>
         </b-row>
         <b-row class="mb-1">
            <b-col cols="4" lg="2">
               <b>Uploaded by</b>
            </b-col>
            <b-col :id="`uploaded-by-${fileIndex + 1}`" cols="8" lg="10">{{
               userName(file.uploadedBy)
            }}</b-col>
         </b-row>
         <b-row class="mb-1">
            <b-col cols="4" lg="2">
               <b>Records</b>
            </b-col>
            <b-col :id="`record-count-${fileIndex + 1}`" cols="8" lg="10">
               {{ file.validation.post.records }}
            </b-col>
         </b-row>

         <b-alert variant="warning" :show="file.validation.post.skipped > 0">
            {{ file.validation.post.skipped }} records were ignored due to having a purchase date
            outside of {{ study.years.lower }} - {{ study.years.upper }}.
         </b-alert>

         <div class="d-flex align-items-center justify-content-end">
            <button
               :id="`btn-del-acct-${fileIndex + 1}`"
               class="icon-btn icon-btn-danger"
               @click="deleteAccount(file.id)"
               :disabled="isCompleted"
            >
               <b-icon-trash />
            </button>
         </div>
      </CollapseCard>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   props: {
      section: String,
   },

   computed: {
      ...mapGetters({
         study: 'companies/study',
         users: 'users/companyUsers',
      }),

      uploadModule() {
         return `${this.section}Uploads`;
      },

      dataModule() {
         return this.section;
      },

      uploadedFiles() {
         return this.$store.getters[`${this.uploadModule}/uploadedFiles`];
      },

      isCompleted() {
         return this.$store.getters[`${this.dataModule}/isCompleted`];
      },
   },

   methods: {
      /** Format a timestamp */
      formatDatetime(date) {
         return new Date(date).toLocaleString();
      },

      /** The name of the user with the given id */
      userName(id) {
         if (id in this.users) {
            const user = this.users[id];
            return `${user.firstName} ${user.lastName}`;
         }
         return 'R&D Incentives Group';
      },

      /** Delete an uploaded account */
      async deleteAccount(fileId) {
         const proceed = await this.$bvModal.msgBoxConfirm(
            'All purchase data contained in this uploaded file will be deleted.',
            {
               id: 'modal-confirm-delete',
               title: 'Delete this account?',
               centered: true,
               okVariant: 'danger',
            }
         );
         if (proceed) {
            await this.blockingRequest(`${this.uploadModule}/deleteFile`, {fileId});
         }
      },
   },
};
</script>
