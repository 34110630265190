<template>
   <b-container>
      <PageTitle title="Cloud Computing" />

      <SectionAssignment section="cloud" />

      <ManagerMessage v-if="manager">
         <template #short>
            <span v-if="noData"> No qualified cloud computing expenses yet. </span>
            <span v-else-if="!completed">How much of this spending went to cloud computing?</span>
         </template>

         <div v-if="completed">
            <h2>This section is complete.</h2>
            <p>
               As long as everything still looks correct, you're all done here! If you need to make
               any additional changes, you'll need to unlock this section first.
            </p>
            <div class="d-flex justify-content-end">
               <b-button variant="secondary" @click="unlock">Unlock</b-button>
            </div>
         </div>
         <div v-else-if="noData">
            <h2>No qualified cloud computing expenses yet.</h2>

            <p class="mb-0">
               The accounts provided so far do not contain expenses for {{ study.lower }} -
               {{ study.upper }}. You can go back and continue to upload expense accounts, or update
               your answer on the first page of this section to indicate that your company made no
               qualified cloud computing purchases during this time period.
            </p>
         </div>
         <div v-else>
            <h2>How much of this spending went to cloud computing?</h2>

            <p>
               For each vendor, please enter the percentage of purchase spending that went to cloud
               computing.
            </p>
         </div>
      </ManagerMessage>

      <b-alert
         :show="!manager && completed"
         variant="primary"
         class="d-flex align-items-center justify-content-between mb-5"
      >
         <span>
            The cloud computing section has been submitted. Click <b>Unlock</b> to make additional
            changes.
         </span>
         <b-button variant="white" @click="unlock">Unlock</b-button>
      </b-alert>

      <CollapseCard
         v-for="year in years"
         :key="year"
         :ident="year"
         :title="year"
         :subtitle="`${accountCount(year)} accounts`"
      >
         <template #title>
            <div class="d-flex align-items-center">
               <small>
                  <RdigStatusPill
                     :ident="`yr-${year}`"
                     class="mr-2"
                     :status="accountStatus[year].status"
                  />
               </small>
               <h2 class="mb-0 mt-1">{{ year }}</h2>
            </div>
         </template>

         <hr class="mb-4" />

         <div
            v-for="(account, accountIndex) in sortedAccounts(year)"
            :key="`${year}:${account}`"
            class="account-section"
         >
            <div class="d-flex align-items-center mb-4">
               <RdigStatusPill
                  :ident="`acc-${year}-${accountIndex + 1}`"
                  class="mr-2"
                  :status="accountStatus[year].accounts[account]"
               />
               <h2 :id="`account-name-${year}-${accountIndex + 1}`" class="mb-0 mt-1">
                  Account: {{ account }}
               </h2>
            </div>
            <div class="account-data">
               <div class="flex-grow">
                  <b-table
                     :id="`table-cloud-vendors-${year}-${accountIndex + 1}`"
                     :fields="vendorFields"
                     sort-by="vendor"
                     :items="sortedVendors(year, account)"
                  >
                     <template #cell(vendor)="data">
                        <b>{{ data.value }}</b>
                     </template>
                     <template #cell(totalDebits)="data">
                        {{ formatMoney(data.value) }}
                     </template>
                     <template #cell(totalCredits)="data">
                        {{ formatMoney(data.value) }}
                     </template>
                     <template #cell(netAmount)="data">
                        {{ formatMoney(data.value.value) }}
                     </template>
                     <template #cell(input)="data">
                        <div class="d-flex align-items-center justify-content-between">
                           <CloudVendorPercentageInput
                              :ident="data.index"
                              :year="year"
                              :account="account"
                              :vendorId="data.item.id"
                              :disabled="completed || setAllInput[`${year}:${account}`]"
                           />
                        </div>
                     </template>
                  </b-table>
               </div>
               <div class="right-col">
                  <b-form-checkbox
                     :id="`checkbox-same-percentages-${year}-${accountIndex + 1}`"
                     class="mb-3"
                     @change="(val) => selectSetAll(year, account, val)"
                     :disabled="completed"
                  >
                     Set all vendors to the same qualifying percent
                  </b-form-checkbox>
                  <CloudVendorPercentageInput
                     v-if="setAllInput[`${year}:${account}`]"
                     ident="all"
                     :year="year"
                     :account="account"
                     style="margin-left: 1.5rem; max-width: 8rem"
                  />
               </div>
            </div>
         </div>

         <div v-if="adHocVendors(year).length > 0" class="account-section">
            <div class="d-flex align-items-center mb-4">
               <RdigStatusPill
                  :ident="`acc-${year}-manual`"
                  class="mr-2"
                  :status="accountStatus[year].adHocStatus"
               />
               <h2 :id="`account-name-${year}-manual`" class="mb-0 mt-1">Manually-Added Vendors</h2>
            </div>
            <div class="account-data">
               <div class="flex-grow">
                  <b-table
                     :id="`table-cloud-vendors-${year}-manual`"
                     :fields="adHocVendorFields"
                     sort-by="vendor"
                     :items="adHocVendors(year)"
                  >
                     <template #cell(vendor)="data">
                        <b>{{ data.value }}</b>
                     </template>
                     <template #cell(netAmount)="data">
                        <CloudVendorAmountInput
                           :ident="data.index"
                           :year="year"
                           :account="null"
                           :vendorId="data.item.id"
                           :disabled="completed"
                        />
                     </template>
                     <template #cell(input)="data">
                        <CloudVendorPercentageInput
                           :ident="data.index"
                           :year="year"
                           :account="null"
                           :vendorId="data.item.id"
                           :disabled="completed || setAllInput[`${year}:manual`]"
                        />
                     </template>
                  </b-table>
               </div>
               <div class="right-col">
                  <b-form-checkbox
                     :id="`checkbox-same-percentages-${year}-manual`"
                     class="mb-3"
                     @change="(val) => selectSetAll(year, 'manual', val)"
                     :disabled="completed"
                  >
                     Set all vendors to the same qualifying percent
                  </b-form-checkbox>
                  <CloudVendorPercentageInput
                     v-if="setAllInput[`${year}:manual`]"
                     ident="all"
                     :year="year"
                     :account="null"
                     style="margin-left: 1.5rem; max-width: 8rem"
                  />
               </div>
            </div>
         </div>
      </CollapseCard>

      <div class="d-flex align-items-center justify-content-between">
         <b-button
            class="d-flex align-items-center"
            :to="$clientStaffRoute({name: 'cloud-vendors'})"
         >
            <b-icon-arrow-left-short class="mr-1" />
            Back
         </b-button>

         <b-button
            id="btn-review-cloud"
            v-if="!noData"
            class="d-flex align-items-center"
            variant="primary"
            :to="$clientStaffRoute({name: 'cloud-review'})"
         >
            Next
            <b-icon-arrow-right-short class="ml-1" />
         </b-button>
      </div>
   </b-container>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import {FieldStatus} from '@/store/utils';
import SectionAssignment from '../components/SectionAssignment';
import CloudVendorPercentageInput from './widgets/CloudVendorPercentageInput';
import CloudVendorAmountInput from './widgets/CloudVendorAmountInput';
import {sortBy} from '@/helpers/utils';

export default {
   components: {
      SectionAssignment,
      CloudVendorAmountInput,
      CloudVendorPercentageInput,
   },

   data() {
      return {
         FieldStatus,
         StatusType: this.$constants().StatusType,

         vendorFields: [
            {key: 'vendor', label: 'Name', sortable: true},
            {key: 'totalDebits', label: 'Total Debits', sortable: true},
            {key: 'totalCredits', label: 'Total Credits', sortable: true},
            {key: 'netAmount', label: 'Net Amount', sortable: true},
            {key: 'input', label: ''},
         ],
         adHocVendorFields: [
            {key: 'vendor', label: 'Name', sortable: true},
            {key: 'netAmount', label: 'Net Amount', sortable: true},
            {key: 'input', label: ''},
         ],
         setAllInput: {},
      };
   },

   computed: {
      ...mapGetters({
         manager: 'manager',
         study: 'study',
         usStates: 'usStates',
         completed: 'cloud/isCompleted',
         declaration: 'cloud/declaration',
         accountData: 'cloud/accountData',
         adHocData: 'cloud/adHocData',
      }),

      noData() {
         return (
            Object.keys(this.accountData).length === 0 && Object.keys(this.adHocData).length === 0
         );
      },

      years() {
         return Object.keys({
            ...this.accountData,
            ...this.adHocData,
         });
      },

      accountStatus() {
         const status = {};

         this.years.forEach((year) => {
            const accounts = {};

            const accountStatus = (vendorData) => {
               let accountStatus;
               if (vendorData.every((item) => item.percentage.value === null)) {
                  accountStatus = this.StatusType.INCOMPLETE;
               } else if (vendorData.every((item) => item.percentage.value !== null)) {
                  accountStatus = this.StatusType.COMPLETE;
               } else {
                  accountStatus = this.StatusType.IN_PROGRESS;
               }
               return accountStatus;
            };

            let importedStatus = this.StatusType.NA;
            if (year in this.accountData) {
               Object.keys(this.accountData[year]).forEach((account) => {
                  const vendorData = this.accountData[year][account];
                  accounts[account] = accountStatus(vendorData);
               });

               if (Object.values(accounts).every((item) => item === this.StatusType.COMPLETE)) {
                  importedStatus = this.StatusType.COMPLETE;
               } else if (
                  Object.values(accounts).every((item) => item === this.StatusType.INCOMPLETE)
               ) {
                  importedStatus = this.StatusType.INCOMPLETE;
               } else {
                  importedStatus = this.StatusType.IN_PROGRESS;
               }
            }

            let adHocStatus = this.StatusType.NA;
            if (year in this.adHocData) {
               adHocStatus = accountStatus(this.adHocData[year]);
            }

            let yearStatus;
            if (adHocStatus === this.StatusType.NA) {
               yearStatus = importedStatus;
            } else if (importedStatus === this.StatusType.NA) {
               yearStatus = adHocStatus;
            } else if (
               importedStatus === this.StatusType.COMPLETE &&
               adHocStatus === this.StatusType.COMPLETE
            ) {
               yearStatus = this.StatusType.COMPLETE;
            } else if (
               importedStatus === this.StatusType.INCOMPLETE &&
               adHocStatus === this.StatusType.INCOMPLETE
            ) {
               yearStatus = this.StatusType.INCOMPLETE;
            } else {
               yearStatus = this.StatusType.IN_PROGRESS;
            }

            status[year] = {
               accounts,
               status: yearStatus,
               adHocStatus,
            };
         });

         return status;
      },
   },

   methods: {
      /** Account names of a given year, sorted alphabetically */
      sortedAccounts(year) {
         return year in this.accountData ? Object.keys(this.accountData[year]).sort() : [];
      },

      /** Vendors of a given year and account, sorted by vendor name */
      sortedVendors(year, account) {
         return Object.values(this.accountData[year][account]).sort(sortBy('vendor'));
      },

      adHocVendors(year) {
         return this.adHocData[year]
            ? Object.values(this.adHocData[year]).sort(sortBy('vendor'))
            : [];
      },

      accountCount(year) {
         let count = this.sortedAccounts(year).length;
         if (this.adHocVendors(year).length > 0) {
            count++;
         }
         return count;
      },

      /** Format cents as USD */
      formatMoney(cents) {
         const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
         });
         return formatter.format(cents / 100);
      },

      selectSetAll(year, account, val) {
         Vue.set(this.setAllInput, `${year}:${account}`, val);
      },

      /** Unlock the section */
      async unlock() {
         const msg =
            "Unlocking this section may delay your credit calculation. You will have to assign users again and will have to mark the section complete again once you're done.";
         const title = 'Unlock this section?';
         const proceed = await this.$bvModal.msgBoxConfirm(msg, {
            title,
            centered: true,
         });
         if (proceed) {
            await this.blockingRequest('cloud/unlock', {
               companyId: this.$companyId,
            });
         }
      },
   },

   async created() {
      const requests = [
         this.$store.dispatch('cloud/loadData', {
            companyId: this.$companyId,
         }),

         // Refresh company data to update study
         this.$store.dispatch('companies/loadCompany', {companyId: this.$companyId, force: true}),
      ];
      await this.blockUntilAllSettled(requests);
   },
};
</script>

<style lang="scss" scoped>
.account-section {
   padding-left: 1rem;

   @include lg {
      padding-left: 3rem;
      margin-top: 1.5rem;
   }

   .account-data {
      display: flex;
      flex-direction: column-reverse;
      align-items: stretch;
      justify-content: space-between;

      @include lg {
         flex-direction: row;
      }
   }
}

.right-col {
   @include lg {
      margin-top: 45px;
      margin-left: 1rem;
      padding-left: 0.75rem;
      border-left: 6px solid $gray-500;
   }
}
</style>
