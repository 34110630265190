<template>
   <div>
      <b-card>
         <h2>Template Configuration</h2>

         <TemplateConfigForm />
      </b-card>

      <QuestionnaireForm is-template @submit="submit" :parent-valid="!$v.$invalid" />
   </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import QuestionnaireForm from './QuestionnaireForm';
import TemplateConfigForm from './TemplateConfigForm';

export default {
   components: {
      QuestionnaireForm,
      TemplateConfigForm,
   },

   data() {
      return {
         template: {
            name: null,
            industries: [],
            qtype: null,
         },
         templateTypeOptions: [
            {value: 'COMPANY', text: 'Company'},
            {value: 'PROJECT', text: 'Project'},
         ],
      };
   },

   computed: {
      ...mapGetters({
         industries: 'industries/industries',
         industryMap: 'industries/industryMap',
         templateConfig: 'questionnaire/templateConfig',
      }),

      industryOptions() {
         return this.industries
            .filter((industry) => this.template.industries.indexOf(industry.id) === -1)
            .map((industry) => {
               return {text: industry.name, value: industry.id};
            });
      },
   },

   methods: {
      onIndustrySelect(industryId) {
         this.template.industries.push(this.industryMap[industryId].id);
         this.$nextTick(() => {
            this.$refs.industrySelect.localValue = null;
         });
      },

      removeIndustry(industryId) {
         const idx = this.template.industries.indexOf(industryId);
         if (idx > -1) {
            this.template.industries.splice(idx, 1);
         }
      },

      submit() {
         this.$emit('submit');
      },
   },

   validations: {
      templateConfig: {
         name: {
            required,
         },
         qtype: {
            required,
         },
      },
   },

   async created() {
      await this.blockingRequest('industries/loadIndustries');
   },
};
</script>
