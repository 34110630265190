<template>
   <g>
      <g clip-path="url(#clip0_10133_6480)">
         <path
            d="M20.6666 5.34106H15.3333V6.6744H20.6666V20.6744H15.3333V22.0077H22V6.6744C22 6.32078 21.8595 5.98164 21.6095 5.73159C21.3594 5.48154 21.0203 5.34106 20.6666 5.34106Z"
         />
         <path
            d="M13.2534 2.00769H4.08002C3.70518 2.00769 3.34569 2.15659 3.08064 2.42165C2.81559 2.6867 2.66669 3.04618 2.66669 3.42102V22.0077H14.6667V3.42102C14.6667 3.04618 14.5178 2.6867 14.2527 2.42165C13.9877 2.15659 13.6282 2.00769 13.2534 2.00769ZM13.3334 20.6744H11.3334V18.6744H6.00002V20.6744H4.00002V3.42102C4.00002 3.41052 4.00209 3.40012 4.00611 3.39041C4.01013 3.3807 4.01602 3.37188 4.02345 3.36446C4.03088 3.35703 4.0397 3.35113 4.04941 3.34711C4.05911 3.34309 4.06951 3.34102 4.08002 3.34102H13.2534C13.2639 3.34102 13.2743 3.34309 13.284 3.34711C13.2937 3.35113 13.3025 3.35703 13.3099 3.36446C13.3174 3.37188 13.3232 3.3807 13.3273 3.39041C13.3313 3.40012 13.3334 3.41052 13.3334 3.42102V20.6744Z"
         />
         <path d="M5.33331 5.34106H6.66665V6.6744H5.33331V5.34106Z" />
         <path d="M8 5.34106H9.33333V6.6744H8V5.34106Z" />
         <path d="M10.6667 5.34106H12V6.6744H10.6667V5.34106Z" />
         <path d="M5.33331 8.67432H6.66665V10.0076H5.33331V8.67432Z" />
         <path d="M8 8.67432H9.33333V10.0076H8V8.67432Z" />
         <path d="M10.6667 8.67432H12V10.0076H10.6667V8.67432Z" />
         <path d="M5.33331 12.0077H6.66665V13.341H5.33331V12.0077Z" />
         <path d="M8 12.0077H9.33333V13.341H8V12.0077Z" />
         <path d="M10.6667 12.0077H12V13.341H10.6667V12.0077Z" />
         <path d="M5.33331 15.3411H6.66665V16.6744H5.33331V15.3411Z" />
         <path d="M8 15.3411H9.33333V16.6744H8V15.3411Z" />
         <path d="M10.6667 15.3411H12V16.6744H10.6667V15.3411Z" />
         <path d="M15.3333 8.67432H16.6666V10.0076H15.3333V8.67432Z" />
         <path d="M18 8.67432H19.3333V10.0076H18V8.67432Z" />
         <path d="M15.3333 12.0077H16.6666V13.341H15.3333V12.0077Z" />
         <path d="M18 12.0077H19.3333V13.341H18V12.0077Z" />
         <path d="M15.3333 15.3411H16.6666V16.6744H15.3333V15.3411Z" />
         <path d="M18 15.3411H19.3333V16.6744H18V15.3411Z" />
      </g>
      <defs>
         <clipPath id="clip0_10133_6480">
            <rect width="24" height="24" transform="translate(0 0.00769043)" />
         </clipPath>
      </defs>
   </g>
</template>

<script>
export default {
   data() {
      return {
         name: 'icon-office-building',
         viewBox: '0 0 24 25',
      };
   },
};
</script>
