<template>
   <div>
      <b-container class="fluid-transition" :fluid="hExpanded">
         <CollapseCard :ident="user.id" v-model="vExpanded">
            <template #title>
               <div class="d-flex align-items-center">
                  <small>
                     <RdigStatusPill :ident="ident" class="mr-2" :status="status" />
                  </small>
                  {{ user.id === profile.id ? 'Assigned to Me' : userCardTitle(user) }}
               </div>
            </template>
            <template #subtitle> {{ assignments.length }} employees </template>

            <p v-if="canEdit">
               You can use your keyboard's arrow keys to move up and down between employees, and tab
               or shift+tab to move right and left between fields.
            </p>
            <p v-if="canEdit && refYear">
               Placeholder values are values we found in your {{ refYear }} study, provided for your
               reference. To use a field's reference value again in this year, press shift+enter.
            </p>

            <div class="d-flex align-items-center justify-content-between">
               <div class="d-flex align-items-center">
                  <button
                     v-if="canEdit && anyEmployeesSelected"
                     class="icon-btn icon-btn-secondary mr-3"
                     v-b-tooltip="'Unselect All'"
                     @click="unselectAll(user.id)"
                  >
                     <b-icon-dash-square />
                  </button>
               </div>

               <div class="d-flex align-items-center">
                  <b-button
                     v-if="
                        (canEdit || user.id === null) &&
                        (anyEmployeesSelected || incompleteEmployees.length > 0)
                     "
                     id="btn-zero-time"
                     variant="secondary"
                     size="sm"
                     class="ml-2"
                     @click="$bvModal.show(setZeroModalId)"
                  >
                     {{
                        anyEmployeesSelected
                           ? "Set selected employees' contributions to 0%"
                           : "Set all incomplete employees' contributions to 0%"
                     }}
                  </b-button>
               </div>
            </div>

            <b-table
               :id="`table-${ident}`"
               :fields="tableFields"
               :sort-by.sync="sortBy"
               :sort-desc.sync="sortDesc"
               no-local-sorting
               :items="tableItems"
               class="my-2 scrollbar"
               small
               responsive
               no-border-collapse
               sticky-header="550px"
               @sort-changed="onSortChanged"
            >
               <template #head(percentage)="data">
                  <div style="min-width: 7rem">
                     {{ data.label }}
                  </div>
               </template>

               <template v-for="{slot: ahSlot, key} in tableHeadSlotsActivities" #[ahSlot]="data">
                  <span :key="key">
                     {{ data.label }}
                     <b-icon
                        :id="`tooltip-${key}`"
                        icon="question-circle"
                        v-b-tooltip.bottom="data.field.description"
                     ></b-icon>
                  </span>
               </template>

               <template #cell(name)="data">
                  <div class="d-flex align-items-center">
                     <b-form-checkbox
                        v-if="canEdit && employeeSelection"
                        tabindex="-1"
                        size="lg"
                        v-model="employeeSelection[data.item.id]"
                        :value="true"
                        @change="employeeSelectionChanged"
                     ></b-form-checkbox>

                     <b>{{ data.value }}</b>
                  </div>
               </template>

               <template #cell(status)="data">
                  <div>
                     <RdigStatusPill
                        :ident="`emp-${data.index}`"
                        :status="TsEmployeeStateToStatusType[employeeStateMap[data.item.id]]"
                     />
                  </div>
               </template>

               <template #cell(percentage)="data">
                  <TimePercentageInput
                     :ident="ident"
                     :employee-index="data.index"
                     :employeeId="data.item.id"
                     :disabled="!canEdit"
                     :projects="sortedProjects"
                     :employees="tableItems"
                     :employee-validation="$v.data.$each[data.item.id]"
                     table-view
                  />
               </template>

               <template
                  v-for="({slot: pcSlot, key}, pIdx) in tableCellSlotsProjects"
                  #[pcSlot]="data"
               >
                  <PercentageInput
                     :ident="ident"
                     :key="key"
                     :employee-index="data.index"
                     :project-index="pIdx"
                     :employeeId="data.item.id"
                     :projects="sortedProjects"
                     :projectId="data.field.projectId"
                     :disabled="!canEdit"
                     :employees="assignments"
                     :employee-validation="$v.data.$each[data.item.id]"
                     table-view
                  ></PercentageInput>
               </template>

               <template
                  v-for="({slot: acSlot, key}, aIdx) in tableCellSlotsActivities"
                  #[acSlot]="data"
               >
                  <ActivityInput
                     :ident="ident"
                     :key="key"
                     :employee-index="data.index"
                     :activity-index="aIdx"
                     :employeeId="data.item.id"
                     :activityId="data.field.activityId"
                     checkbox-only
                     style="min-width: 6rem"
                     :disabled="!canEdit"
                     :employees="assignments"
                     table-view
                  ></ActivityInput>
               </template>
            </b-table>

            <div
               v-if="isCurrentUser && !assignmentsComplete"
               class="d-flex justify-content-end mt-3 px-3"
            >
               <b-button
                  id="btn-complete-assignments"
                  variant="success"
                  @click="markAssignmentsComplete"
                  :disabled="state.state !== TsUserStates.VALID"
               >
                  Done <b-icon-check />
               </b-button>
            </div>
         </CollapseCard>
      </b-container>

      <b-modal
         :id="setZeroModalId"
         title="Set contributions to zero?"
         centered
         @ok="onSetContributionsZero"
      >
         <p v-if="anyEmployeesSelected">
            Are you sure you want to set the {{ this.selectedEmployeeIds.length }} selected
            employees' R&D contributions to zero? This will overwrite any existing data entered for
            these employees.
         </p>
         <p v-else>
            Are you sure you want to set
            {{ this.incompleteEmployees.length }} incomplete employees' R&D contributions to zero?
            This will overwrite any existing data entered for these employees.
         </p>

         <b-alert variant="danger" :show="tsState === TsStates.VALIDATED" class="mb-4">
            <h6>This time survey has already been submitted</h6>

            <p class="mb-0">
               Time data for this year has already been submitted to R&D Incentives Group. Changing
               this information will delay our ability to calculate your R&D tax credit. Please be
               absolutely certain you need to make a correction before proceeding. Contact us
               securely using the messaging feature in the upper right corner.
            </p>
         </b-alert>

         <b-alert
            variant="danger"
            :show="assignmentsComplete && user.id && tsState !== TsStates.VALIDATED"
         >
            Your assigned employees have been marked complete. Following this action, you will be
            required to click <b>Done</b> again to confirm you're done entering employee time for
            your assigned employees.
         </b-alert>
      </b-modal>
   </div>
</template>

<script>
import Vue from 'vue';

import ActivityInput from './ActivityInput';
import CollapseCard from '@/components/CollapseCard';
import PercentageInput from './PercentageInput';
import TimePercentageInput from './TimePercentageInput';

import {mapGetters} from 'vuex';
import {
   TsStates,
   TsUserStates,
   TsEmployeeStates,
   TsEmployeeStateToStatusType,
} from '@/helpers/constants';
import {employeeTimeValidation} from '@/helpers/validation';

import {sortBy} from '../../../helpers/utils';

export default {
   components: {
      ActivityInput,
      CollapseCard,
      PercentageInput,
      TimePercentageInput,
   },

   props: {
      loaded: Boolean,
      user: Object,
      state: Object,
      tsState: String,
      /** Unique identifier for the employee table on this card. */
      ident: {
         type: String,
         default: 'employees',
      },
   },

   data() {
      return {
         mounted: false,
         employeeSelection: {},
         TsStates,
         TsUserStates,
         TsEmployeeStateToStatusType,
         tableData: [],
         sortBy: 'name',
         sortDesc: false,
         hExpanded: false,
         vExpanded: false,
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         isRndig: 'isRndig',
         refYear: 'timesurvey/refYear',
         projectMap: 'timesurvey/projectMap',
         activities: 'timesurvey/activities',
         employeeMap: 'timesurvey/employees',
         assignedEmployees: 'timesurvey/assignedEmployees',
         unassignedEmployees: 'timesurvey/unassignedEmployees',
         assignedData: 'timesurvey/assignedData',
         unassignedData: 'timesurvey/unassignedData',
         currentEmployeeId: 'timesurvey/currentEmployeeId',
         _assignmentsComplete: 'timesurvey/assignmentsComplete',
         projectSumEqualsTotal: 'timesurvey/projectSumEqualsTotal',
         employeeStateMap: 'timesurvey/employeeStateMap',
      }),

      data() {
         return this.user.id ? this.assignedData(this.user.id) : this.unassignedData;
      },

      /** The year of the time survey */
      year() {
         return this.$route.params.year;
      },

      /** Is this card for the current user's assignments? */
      isCurrentUser() {
         return this.user.id === this.profile.id;
      },

      /** Can the current user edit these employees? */
      canEdit() {
         return this.isCurrentUser || this.isRndig;
      },

      /** Have the assigned employees been marked complete? */
      assignmentsComplete() {
         return this._assignmentsComplete[this.user.id];
      },

      /** ID for the zero contribution confirmation modal */
      setZeroModalId() {
         return `modal-zero-contributions-${this.user.id}`;
      },

      /** Map TsUserState to Status */
      status() {
         switch (this.state.state) {
            case this.$constants().TsUserStates.INVALID:
            case this.$constants().TsUserStates.VALID:
               return this.$constants().StatusType.IN_PROGRESS;
            case this.$constants().TsUserStates.INCOMPLETE:
               return this.$constants().StatusType.INCOMPLETE;
            case this.$constants().TsUserStates.COMPLETE:
               return this.$constants().StatusType.COMPLETE;
         }
         return null;
      },

      /** Map responsive data onto table data */
      tableItems() {
         return this.tableData.map((employee) => {
            const status = this.employeeStateMap[employee.id];
            return {
               ...employee,
               status,
            };
         });
      },

      /** Employees assigned to this user */
      assignments() {
         return this.user.id ? this.assignedEmployees(this.user.id) : this.unassignedEmployees;
      },

      /** Activities sorted by name */
      sortedActivities() {
         return Object.values(this.activities)
            .sort(sortBy('name'))
            .map((activity) => ({
               key: `activity-${activity.id}`,
               ...activity,
            }));
      },

      /** Projects sorted by name */
      sortedProjects() {
         return this.sortedProjectValues(this.projectMap).map(([key, val]) => ({
            key: `project-${key}`,
            ...val,
         }));
      },

      /** Scoped slot metadata for activities **/
      tableHeadSlotsActivities() {
         return this.sortedActivities.map((activity) => ({
            slot: `head(${activity.key})`,
            key: activity.key,
         }));
      },

      tableCellSlotsActivities() {
         return this.sortedActivities.map((i) => ({
            slot: `cell(${i.key})`,
            key: i.key,
         }));
      },

      /** Scoped slot metadata for projects **/
      tableCellSlotsProjects() {
         return this.sortedProjects.map((i) => ({
            slot: `cell(${i.key})`,
            key: i.key,
         }));
      },

      /** Table fields defining the basic layout of the table */
      tableFields() {
         return [
            ...this.tableFieldsBasic,
            ...this.tableFieldsProjects,
            ...this.tableFieldsActivities,
         ];
      },

      tableFieldsBasic() {
         return [
            {key: 'name', stickyColumn: true, sortable: true},
            {key: 'title', label: 'Job Title', sortable: true},
            {key: 'department', sortable: true},
            {key: 'status', sortable: true},
            {
               key: 'percentage',
               label: 'Total R&D Time',
               variant: 'gray-light',
               thClass: 'text-dark',
               sortable: true,
            },
         ];
      },

      tableFieldsActivities() {
         return this.sortedActivities.map((activity) => ({
            key: activity.key,
            label: activity.name,
            sortable: false,
            activityId: activity.id,
            description: activity.description,
         }));
      },

      tableFieldsProjects() {
         return this.sortedProjects.map((activity) => ({
            key: activity.key,
            label: activity.name,
            sortable: false,
            projectId: activity.id,
         }));
      },

      /** An array of employees with incomplete data assigned to the current user */
      incompleteEmployees() {
         return this.assignments.filter((employee) => {
            return this.employeeStateMap[employee.id] === TsEmployeeStates.INCOMPLETE;
         });
      },

      /** An array of IDs of employees selected for editing */
      selectedEmployeeIds() {
         if (this.anyEmployeesSelected) {
            return Object.entries(this.employeeSelection)
               .filter((entry) => {
                  const selected = entry[1];
                  return selected;
               })
               .map((entry) => entry[0]);
         } else {
            return this.assignments.map((e) => e.id);
         }
      },

      /** True if any employees are selected for editing */
      anyEmployeesSelected() {
         return Object.values(this.employeeSelection).some((val) => val);
      },

      /** True if more than one employees are selected for editing */
      multipleEmployeesSelected() {
         return Object.values(this.employeeSelection).filter((val) => val).length > 1;
      },

      /** The employee currently being edited */
      currentEmployee() {
         if (this.currentEmployeeId) {
            return this.employeeMap[this.currentEmployeeId];
         } else {
            return null;
         }
      },
   },

   methods: {
      /**
       * Formats a card title for an employee
       * @param {Object} user - a user object
       */
      userCardTitle(user) {
         if (user.id === null) {
            return 'Unassigned';
         }
         let title = `Assigned to ${user.firstName} ${user.lastName}`;

         if (user.title && user.department) {
            title += ` (${user.title} - ${user.department})`;
         } else if (user.title || user.department) {
            title += ` (${user.title || user.department})`;
         }
         return title;
      },

      /** Table items for the employees assigned to a user */
      mapTableItems(items) {
         return items.map((employee) => {
            return {
               id: employee.id,
               name: employee.fullname,
               department: employee.department,
               title: employee.title,
            };
         });
      },

      /** Handle the `sort-changed` event from the table */
      onSortChanged(ctx) {
         this.sortTableBy(ctx.sortBy, ctx.sortDesc);
      },

      /** Sort the table data */
      sortTableBy(fieldname, desc) {
         const [before, after] = desc ? [-1, 1] : [1, -1];

         const compare = (a, b) => {
            if (fieldname === 'name' && Object.prototype.hasOwnProperty.call(a, 'localeCompare')) {
               return a.localeCompare(b) > 0;
            } else {
               return a > b;
            }
         };

         const getData = (fieldname, employee) => {
            let val;
            switch (fieldname) {
               case 'percentage':
                  val = this.data[employee.id].percentage;
                  if (val === null) {
                     val = -1;
                  }
                  break;
               case 'status':
                  switch (this.employeeStateMap[employee.id]) {
                     case TsEmployeeStates.INCOMPLETE:
                        val = 0;
                        break;
                     case TsEmployeeStates.INVALID:
                        val = 10;
                        break;
                     case TsEmployeeStates.VALID:
                        val = 20;
                  }
                  break;
               default:
                  val = employee[fieldname];
            }
            return val;
         };

         const getRefData = (id) => {
            let val = null;
            const refData = this.data[id].ref;
            if (refData) {
               val = refData.percentage;
            }
            return val === null ? -1 : val;
         };

         this.tableData.sort((a, b) => {
            let valA = getData(fieldname, a);
            let valB = getData(fieldname, b);

            // First, sort by cell value
            if (compare(valA, valB)) {
               return before;
            } else if (compare(valB, valA)) {
               return after;
            }

            // Second, sort by percentage (unless the cell value is the percentage)
            if (fieldname !== 'percentage') {
               const percentageA = getData('percentage', a);
               const percentageB = getData('percentage', b);
               if (compare(percentageA, percentageB)) {
                  return -1;
               } else if (compare(percentageB, percentageA)) {
                  return 1;
               }
            }

            // Finally, sort by ref data value
            const refA = getRefData(a.id);
            const refB = getRefData(b.id);
            if (compare(refA, refB)) {
               return fieldname === 'percentage' ? before : -1;
            } else if (compare(refB, refA)) {
               return fieldname === 'percentage' ? after : 1;
            }

            return 0;
         });
      },

      /**
       * Converts a project value map to a sorted array of projects
       * @param {Object} projectValueMap - A project map, keyed on the project id
       */
      sortedProjectValues(projectValueMap) {
         return Object.entries(projectValueMap).sort((a, b) => {
            const aName = this.projectMap[a[0]].name;
            const bName = this.projectMap[b[0]].name;
            if (aName < bName) {
               return -1;
            } else if (aName > bName) {
               return 1;
            }
            return 0;
         });
      },

      /** Update the vuex employee selection state */
      employeeSelectionChanged() {
         this.$store.commit('timesurvey/setSelectedEmployees', {
            employeeIds: this.selectedEmployeeIds,
         });
      },

      /** Unselect all employees */
      unselectAll() {
         Object.keys(this.employeeSelection).forEach((id) => {
            this.employeeSelection[id] = false;
         });
         this.employeeSelectionChanged();
      },

      /** Display the time entry modal for the first editable employee */
      enterEmployeeTime() {
         this.editEmployee(this.selectedEmployeeIds[0]);
      },

      /** `ok` handler for the confirmation modal to set employee contributions to zero */
      async onSetContributionsZero() {
         let employeeIds = [];
         if (this.anyEmployeesSelected) {
            employeeIds = this.selectedEmployeeIds;
         } else {
            employeeIds = this.incompleteEmployees.map((e) => e.id);
         }

         let requests = [];

         employeeIds.forEach((id) => {
            // Set all activities to false
            Object.keys(this.activities).forEach((activityId) => {
               this.$store.commit('timesurvey/setActivity', {
                  employeeId: id,
                  activityId,
                  value: false,
               });
            });

            // Set all projects to 0
            Object.keys(this.projectMap).forEach((projectId) => {
               this.$store.commit('timesurvey/setProject', {
                  employeeId: id,
                  projectId,
                  value: 0,
               });
            });

            // Set total percentage 0
            this.$store.commit('timesurvey/setPercentage', {
               employeeId: id,
               value: 0,
            });

            // Save data
            requests.push(this.$store.dispatch('timesurvey/saveEmployee', {employeeId: id}));
         });

         await this.blockUntilAllSettled(requests);
      },

      async markAssignmentsComplete() {
         await this.blockingRequest('timesurvey/markAssignmentsComplete', {
            userId: this.user.id,
            year: this.year,
         });

         window.scrollTo({top: 0, behavior: 'smooth'});
      },
   },

   validations() {
      return {
         data: {
            $each: employeeTimeValidation(),
         },
      };
   },

   watch: {
      loaded(val) {
         if (val) {
            this.tableData = this.mapTableItems(this.assignments);
            this.sortTableBy(this.sortBy, this.sortDesc);
         }
      },

      vExpanded(value) {
         this.hExpanded = value;
      },
   },

   created() {
      // Initialize employee selection
      this.assignments.forEach((employee) => {
         Vue.set(this.employeeSelection, employee.id, false);
      });
   },

   mounted() {
      this.mounted = true;
      if (this.loaded) {
         this.tableData = this.mapTableItems(this.assignments);
         this.sortTableBy(this.sortBy, this.sortDesc);
      }
      this.vExpanded = this.user.id === this.profile.id;
   },
};
</script>

<style lang="scss" scoped>
.fluid-transition {
   transition: max-width 0.5s;

   &.container-fluid {
      max-width: 100%;
   }
}

.breakdown-head {
   min-width: 20rem;
}

.big-status-icon {
   z-index: 51;
}

.modal-body.tight-modal {
   padding-top: 0;
   padding-bottom: 0;
}

.btn-expand {
   flex-shrink: 0;
   border-radius: 50%;
   width: 2rem;
   height: 2rem;
   padding: 0;
   display: flex;
   align-items: center;
   justify-content: center;

   @media (max-width: 1425px) {
      display: none;
   }
}
</style>
