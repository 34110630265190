<template>
   <b-card>
      <div class="d-flex align-items-center justify-content-between mb-3">
         <h4 class="mb-0">Question {{ index + 1 }}</h4>
         <button
            size="sm"
            class="icon-btn icon-btn-danger"
            variant="outline-danger"
            @click="deleteQuestion()"
            :disabled="deleteDisabled"
            type="button"
         >
            <b-icon-x-square style="font-size: 20px"></b-icon-x-square>
         </button>
      </div>
      <b-form-group invalidFeedback="Required">
         <b-form-textarea
            :id="`textarea-question-${index + 1}`"
            v-model="questionText"
            :state="!$v.questionText.$invalid"
            ref="text"
         ></b-form-textarea>
      </b-form-group>

      <b-form-group label="Answer Type" label-for="select-answer-type">
         <b-form-select
            id="select-answer-type"
            :options="ansTypeOptions"
            v-model="ansType"
         ></b-form-select>
      </b-form-group>

      <div v-if="question.ansType == AnswerTypes.DATE">
         <h4>Validation</h4>
         <div class="d-flex align-items-center justify-content-between">
            <b-form-group label="Minimum Date" :label-for="`input-minimum-date-${index + 1}`">
               <v-date-picker
                  v-model="validationMinimum"
                  :max-date="validationMaximum"
                  :input-debounce="500"
                  :popover="{placement: 'top'}"
               >
                  <template v-slot="{inputValue, inputEvents}">
                     <input
                        :id="`input-minimum-date-${index + 1}`"
                        class="form-control"
                        :value="inputValue"
                        v-on="inputEvents"
                        placeholder="mm/dd/yyyy"
                     />
                  </template>
               </v-date-picker>
            </b-form-group>
            <b-icon-arrow-right class="h4 mb-0 mx-3 mt-3"></b-icon-arrow-right>
            <b-form-group label="Maximum Date" :label-for="`input-maximum-date-${index + 1}`">
               <v-date-picker
                  v-model="validationMaximum"
                  :min-date="validationMinimum"
                  :input-debounce="500"
                  :popover="{placement: 'top'}"
               >
                  <template v-slot="{inputValue, inputEvents}">
                     <input
                        :id="`input-maximum-date-${index + 1}`"
                        class="form-control"
                        :value="inputValue"
                        v-on="inputEvents"
                        placeholder="mm/dd/yyyy"
                     />
                  </template>
               </v-date-picker>
            </b-form-group>
         </div>
      </div>

      <div v-else-if="question.ansType == AnswerTypes.INTEGER">
         <h4 class="mb-3">Validation</h4>
         <b-form-row>
            <b-col cols="6">
               <b-form-group
                  label="Minimum"
                  :label-for="`input-minimum-integer-${index + 1}`"
                  :invalid-feedback="minValidationFeedback"
               >
                  <b-form-input
                     :id="`input-minimum-integer-${index + 1}`"
                     v-model="validationMinimum"
                     number
                     :state="validationMinimum ? !this.$v.validationMinimum.$invalid : null"
                  ></b-form-input>
               </b-form-group>
            </b-col>

            <b-col cols="6">
               <b-form-group
                  label="Maximum"
                  :label-for="`input-maximum-integer-${index + 1}`"
                  :invalid-feedback="maxValidationFeedback"
               >
                  <b-form-input
                     :id="`input-maximum-integer-${index + 1}`"
                     v-model="validationMaximum"
                     number
                     :state="validationMaximum ? !this.$v.validationMaximum.$invalid : null"
                  ></b-form-input>
               </b-form-group>
            </b-col>
         </b-form-row>
      </div>

      <div v-else-if="question.ansType == AnswerTypes.NUMBER">
         <h4 class="mb-3">Validation</h4>
         <b-form-row>
            <b-col cols="6">
               <b-form-group
                  label="Minimum"
                  :label-for="`input-minimum-number-${index + 1}`"
                  :invalid-feedback="minValidationFeedback"
               >
                  <b-form-input
                     :id="`input-minimum-number-${index + 1}`"
                     v-model="validationMinimum"
                     :state="validationMinimum ? !this.$v.validationMinimum.$invalid : null"
                  ></b-form-input>
               </b-form-group>
            </b-col>

            <b-col cols="6">
               <b-form-group
                  label="Maximum"
                  :label-for="`input-maximum-number-${index + 1}`"
                  :invalid-feedback="maxValidationFeedback"
               >
                  <b-form-input
                     :id="`input-maximum-number-${index + 1}`"
                     v-model="validationMaximum"
                     :state="validationMaximum ? !this.$v.validationMaximum.$invalid : null"
                  ></b-form-input>
               </b-form-group>
            </b-col>
         </b-form-row>
      </div>

      <div v-else-if="question.ansType == AnswerTypes.CHOICE">
         <b-form-checkbox
            class="mb-3"
            :id="`checkbox-allow-multiple-${index + 1}`"
            v-model="multipleAnswers"
         >
            Allow multiple answers
         </b-form-checkbox>
         <h4 class="mb-3">Options</h4>
         <div
            class="d-flex align-items-center mb-2"
            v-for="(option, optionIndex) in question.validation.options"
            :key="option.key"
         >
            <b-form-input
               :id="`input-option-${index + 1}-${optionIndex + 1}`"
               :value="option.value"
               @input="(value) => onInputOption(optionIndex, value)"
            ></b-form-input>

            <b-button-group class="ml-2">
               <b-button size="sm" variant="outline-primary" @click="addOption(optionIndex)">
                  <b-icon-plus id="btn-add-option"></b-icon-plus>
               </b-button>
               <b-button
                  id="btn-delete-option"
                  size="sm"
                  variant="outline-danger"
                  @click="deleteOption(optionIndex)"
                  :disabled="question.validation.options.length <= 1"
               >
                  <b-icon-x></b-icon-x>
               </b-button>
            </b-button-group>
         </div>
      </div>

      <b-form-group label="Help Text" :label-for="`textarea-help-text-${index + 1}`">
         <b-form-textarea
            :id="`textarea-help-text-${index + 1}`"
            v-model="helpText"
         ></b-form-textarea>
      </b-form-group>
   </b-card>
</template>

<script>
import {AnswerTypes} from '@/helpers/types';
import {required, decimal} from 'vuelidate/lib/validators';

export default {
   name: 'QuestionForm',

   props: {
      question: Object,
      index: Number,
      deleteDisabled: Boolean,
   },

   mounted() {
      if (this.index > 0) {
         this.$refs.text.focus();
      }
   },

   data() {
      return {
         AnswerTypes,
         ansTypeOptions: [
            {value: null, text: 'Please select one'},
            {value: AnswerTypes.TEXT, text: 'Text'},
            {value: AnswerTypes.EMAIL, text: 'Email'},
            {value: AnswerTypes.DATE, text: 'Date'},
            {value: AnswerTypes.INTEGER, text: 'Integer'},
            {value: AnswerTypes.NUMBER, text: 'Number'},
            {value: AnswerTypes.BOOLEAN, text: 'Yes or No'},
            {value: AnswerTypes.CHOICE, text: 'Multiple Choice'},
         ],
      };
   },

   computed: {
      questionText: {
         get() {
            return this.question.question;
         },
         set(value) {
            this.$store.commit('questionnaire/setQuestionText', {index: this.index, value});
         },
      },

      ansType: {
         get() {
            return this.question.ansType;
         },
         set(value) {
            this.$store.commit('questionnaire/setAnswerType', {index: this.index, value});
            if (AnswerTypes.CHOICE === value) {
               while (this.question.validation.options.length < 2) {
                  this.addOption(0);
               }
            }
            this.validationMinimum = null;
            this.validationMaximum = null;
         },
      },

      helpText: {
         get() {
            return this.question.helpText;
         },
         set(value) {
            this.$store.commit('questionnaire/setHelpText', {index: this.index, value});
         },
      },

      multipleAnswers: {
         get() {
            return this.question.validation.multiple;
         },
         set(value) {
            this.$store.commit('questionnaire/updateValidation', {
               index: this.index,
               value: {multiple: value},
            });
         },
      },

      validationMinimum: {
         get() {
            return this.question.validation.minimum;
         },
         set(value) {
            this.$store.commit('questionnaire/updateValidation', {
               index: this.index,
               value: {minimum: value},
            });
         },
      },

      validationMaximum: {
         get() {
            return this.question.validation.maximum;
         },
         set(value) {
            this.$store.commit('questionnaire/updateValidation', {
               index: this.index,
               value: {maximum: value},
            });
         },
      },

      minValidationFeedback() {
         if (!this.$v.validationMinimum.isInteger) {
            return 'Must be an integer';
         } else if (!this.$v.validationMinimum.lessThanMax) {
            return 'Must be less than maximum';
         } else {
            return '';
         }
      },

      maxValidationFeedback() {
         if (!this.$v.validationMaximum.isInteger) {
            return 'Must be an integer';
         } else if (!this.$v.validationMaximum.greaterThanMin) {
            return 'Must be greater than minimum';
         } else {
            return '';
         }
      },
   },

   methods: {
      /** Delete this question */
      deleteQuestion() {
         this.$store.commit('questionnaire/deleteQuestion', this.index);
      },

      /** Add an option to a multiple choice question */
      addOption(index) {
         this.$store.commit('questionnaire/addOption', {
            questionIndex: this.index,
            optionIndex: index,
         });
      },

      /** Delete a multiple choice option */
      deleteOption(index) {
         this.$store.commit('questionnaire/deleteOption', {
            questionIndex: this.index,
            optionIndex: index,
         });
      },

      /** Handle an input event on an option input */
      onInputOption(optionIndex, value) {
         this.$store.commit('questionnaire/setOption', {
            questionIndex: this.index,
            optionIndex,
            value,
         });
      },
   },

   validations() {
      const v = {
         questionText: {
            required,
         },
         validationMinimum: {
            decimal,
            lessThanMax(value) {
               let max = this.validationMaximum;
               if (value && max) {
                  return value < max;
               }
               return true;
            },
            isInteger(value) {
               if (AnswerTypes.INTEGER === this.ansType) {
                  return Number.isInteger(value);
               } else {
                  return true;
               }
            },
         },
         validationMaximum: {
            decimal,
            greaterThanMin(value) {
               let min = this.validationMinimum;
               if (value && min) {
                  return value > min;
               }
               return true;
            },
            isInteger(value) {
               if (AnswerTypes.INTEGER === this.ansType) {
                  return Number.isInteger(value);
               } else {
                  return true;
               }
            },
         },
      };

      return v;
   },
};
</script>
