<template>
   <b-container>
      <h1>Edit Questionnaire Template</h1>

      <QuestionnaireTemplateForm @submit="submit" />
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import QuestionnaireTemplateForm from '@/views/questionnaire/widgets/QuestionnaireTemplateForm';

export default {
   components: {
      QuestionnaireTemplateForm,
   },

   computed: {
      ...mapGetters({
         questions: 'questionnaire/questions',
         dirty: 'questionnaire/dirty',
      }),

      templateId() {
         return this.$route.params.templateId;
      },
   },

   methods: {
      async submit() {
         await this.blockingRequest('questionnaire/editTemplate');
         this.$router.push({name: 'qtemplates'});
      },
   },

   async created() {
      this.$store.commit('questionnaire/clearTemplate');
      await this.blockingRequest('questionnaire/loadTemplate', {templateId: this.templateId});
   },

   /** Before leaving the route, check for any unsaved changes */
   async beforeRouteLeave(to, from, next) {
      if (this.dirty) {
         const proceed = await this.$bvModal.msgBoxConfirm(
            'You have unsaved changes. Would you like to stay on this page to save your work, or proceed and discard any unsaved changes?',
            {
               id: 'modal-proceed',
               centered: true,
               title: 'Proceed without saving?',
               okTitle: 'Proceed without saving',
               cancelTitle: 'Stay',
            }
         );
         if (!proceed) {
            // Cancel the navigation
            return next(false);
         }
      }
      next();
   },
};
</script>
