<template>
   <b-container class="get-started-container">
      <!-- Section intro -- only show once -->
      <div v-if="!seenIntro || page === 0">
         <div>
            <h1 class="text-dark-gray mb-4">
               Welcome to
               <img src="@/assets/img/logo/logo-dark.svg" alt="Onboard.tax" class="welcome-logo" />!
            </h1>

            <ManagerMessage v-if="manager" key="welcome-mm" :value="true">
               <template #short>You can expand and collapse my messages to you.</template>

               <h2>Welcome, {{ profile.firstName }}!</h2>

               <p class="mb-0">
                  I'm <b>{{ manager.firstName }} {{ manager.lastName }}</b
                  >, your {{ managerRole }} at
                  <a href="https://www.rdincentivesgroup.com/">R&amp;D Incentives Group</a>. I'm
                  here to guide you through providing the info we need to calculate and substantiate
                  your R&D credit. Look for help in messages like these as you use Onboard. And if
                  you have any questions, please don't hesitate to contact me and the rest of your
                  credit study team using the secure messaging feature in the top right corner.
               </p>
            </ManagerMessage>

            <b-row class="my-5">
               <b-col
                  cols="12"
                  md="5"
                  class="text-center d-flex flex-column align-items-center justify-content-center"
               >
                  <h1 class="text-primary">About the R&amp;D Credit</h1>
                  <p>
                     Research and Development (R&D) tax credits are permanent U.S. federal and state
                     tax incentives designed to reward innovative companies for developing or
                     improving products and/or processes within U.S. territory. The credit is based
                     on the amount of
                     <strong>Qualified Research Expenditures (QREs)</strong> incurred by your
                     company's R&amp;D initiatives in four groups:
                     <strong
                        >employee wages, materials/supplies costs, contract research costs,</strong
                     >
                     and <strong>cloud computing costs.</strong>
                  </p>
               </b-col>
               <b-col cols="12" md="7">
                  <div class="welcome-img welcome-img-1"></div>
               </b-col>
            </b-row>

            <b-row class="my-5">
               <b-col cols="12" md="5" order="2" order-md="1">
                  <div class="welcome-img welcome-img-2"></div>
               </b-col>
               <b-col
                  cols="12"
                  md="7"
                  order="1"
                  order-md="2"
                  class="text-center d-flex flex-column align-items-center justify-content-center"
               >
                  <h1 class="text-primary">Making it Simple for You</h1>
                  <p>
                     We are your R&D credit team, engaged by your company to calculate its R&D tax
                     credit. That process starts with collecting information about your qualified
                     expenses, and that's why we built Onboard <em>for you</em>. Once your company
                     has provided all the info we need, we will apply our expertise and decades of
                     experience to ensure your company receives the tax credit you're due, and will
                     support you in the event of any audit. Let's begin!
                  </p>
               </b-col>
            </b-row>
         </div>
         <div class="d-flex justify-content-end mt-4">
            <div>
               <b-button id="btn-begin" variant="success" size="" @click="leaveIntro">
                  Begin
                  <b-icon-arrow-right-short />
               </b-button>
            </div>
         </div>
      </div>

      <div v-else>
         <!-- Page 1 - Overview -->
         <div v-if="page === 1">
            <h1 class="text-dark-gray mb-4">Introduction: The Four-Part Test</h1>

            <ManagerMessage key="4pt-mm">
               <p>
                  To determine whether a project or development initiative qualifies for the R&amp;D
                  credit, Congress has defined <b>a four-part test</b>. Please keep in mind as you
                  use Onboard that
                  <b
                     >all four parts of the four-part test must be satisfied for any project or
                     initiative to qualify for the R&D tax credits.</b
                  >
               </p>
               <p>Let's briefly go through each part of the test:</p>
            </ManagerMessage>

            <TestCards class="mb-4" @selection="cardSelected" />
         </div>

         <!-- Page 2 - Permitted Purpose -->
         <div v-if="page === 2">
            <h1 class="text-dark-gray mb-4">Permitted Purpose</h1>

            <ManagerMessage key="4pt-mm">
               <p>
                  To qualify for R&amp;D credit, the purpose of the effort must be to achieve a new
                  or improved product or process. The product or process must
                  <b
                     >provide new or improved functionality, performance, reliability,
                     scalability,</b
                  >
                  and/or overall <b>increase in quality</b>. Efforts with this purpose will qualify,
                  whereas simple aesthetic changes, for example, will not.
               </p>
            </ManagerMessage>

            <TestCards class="mb-4" :active="0" @selection="cardSelected" />
         </div>

         <!-- Page 3 - Technological in Nature -->
         <div v-if="page === 3">
            <h1 class="text-dark-gray mb-4">Technological in Nature</h1>

            <ManagerMessage key="4pt-mm">
               <p>
                  Your company's development efforts must
                  <b>fundamentally rely on a hard science</b> to qualify as research &amp;
                  development. Efforts that apply engineering disciplines, physics, chemistry,
                  biological science, software engineering, material science, food science, etc.
                  will qualify, whereas soft sciences such as marketing research, sociology,
                  psychology will not.
               </p>
            </ManagerMessage>

            <TestCards class="mb-4" :active="1" @selection="cardSelected" />
         </div>

         <!-- Page 4 - Elimination of Uncertainty -->
         <div v-if="page === 4">
            <h1 class="text-dark-gray mb-4">Elimination of Uncertainty</h1>

            <ManagerMessage key="4pt-mm">
               <p>
                  In order to meet the <b>Elimination of Uncertainty</b> criteria, there must be
                  <b
                     >technical uncertainties or challenges encountered at the onset or throughout
                     the initiative</b
                  >. Uncertainties concerning capability, optimal methodology, or appropriate design
                  of the development or improvement initiatives will likely qualify. Simple fixes,
                  routine repairs &amp; maintenance, or routine production will not.
               </p>
            </ManagerMessage>

            <TestCards class="mb-4" :active="2" @selection="cardSelected" />
         </div>

         <!-- Page 5 - Process of Experimentation -->
         <div v-if="page === 5">
            <h1 class="text-dark-gray mb-4">Process of Experimentation</h1>

            <ManagerMessage key="4pt-mm">
               <p>
                  Finally, your research &amp; development team must be able to demonstrate that the
                  R&amp;D efforts utilized
                  <b
                     >a process which included the evaluation of potential alternatives to achieve
                     the desired outcome</b
                  >. This could include conducting systematic trial-and-error, prototyping and first
                  article development, modeling, or testing to validate design hypotheses and
                  concepts in order to arrive at a final design. A process that did not involve
                  experimentation will not qualify.
               </p>
            </ManagerMessage>

            <TestCards class="mb-4" :active="3" @selection="cardSelected" />
         </div>

         <!-- Page 6 - Conclusion -->
         <div v-if="page === 6">
            <h1 class="text-dark-gray mb-4">Conclusion</h1>
            <ManagerMessage v-if="manager">
               <p v-if="isAppConfigured" class="mb-0">
                  Now that you understand what qualifies as R&amp;D, head over to the
                  <b>Onboard Dashboard</b>, your hub for seeing what tasks you have left to complete
                  in the process of providing your company's R&amp;D information. You can come back
                  to this section any time for a refresher on the Four-Part Test.
               </p>
               <p v-else class="mb-0">
                  Now that you understand what qualifies as R&amp;D, let's take you to the
                  <b>Documents</b> section, where you can upload the documents we have requested
                  from you in order for us to begin configuring your tax credit study.
               </p>
            </ManagerMessage>
         </div>

         <div class="d-flex justify-content-between mt-4">
            <div>
               <b-button variant="secondary" size="" @click="prevPage" v-if="page === 1">
                  <b-icon-arrow-left-short />
                  View Introduction
               </b-button>
               <b-button variant="secondary" size="" @click="prevPage" v-else-if="page > 1">
                  <b-icon-arrow-left-short />
                  Back
               </b-button>
            </div>
            <b-button
               id="btn-next"
               :variant="page === 6 ? 'success' : 'primary'"
               size=""
               @click="nextPage"
            >
               Next
               <b-icon-arrow-right-short />
            </b-button>
         </div>
      </div>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {SidebarStates} from '@/store/modules/session';

import TestCards from './widgets/TestCards';

export default {
   components: {
      TestCards,
   },

   data() {
      return {
         SidebarStates,
      };
   },

   computed: {
      ...mapGetters({
         clientState: 'clientState',
         profile: 'profile',
         manager: 'manager',
         greeted: 'greeted',
         isAppConfigured: 'isAppConfigured',
         isCustomer: 'isCustomer',
         isSME: 'isSME',
         priorityActionItems: 'progress/priorityActionItems',
         isProfileQuestionnaireComplete: 'progress/isProfileQuestionnaireComplete',
      }),

      companyId() {
         return this.profile.companyId;
      },

      managerRole() {
         if (this.manager === null) {
            return '';
         }
         switch (this.manager.role) {
            case 'PM':
               return 'Project Manager';
            case 'BM':
               return 'Business Manager';
            case 'PA':
               return 'Project Associate';
            default:
               return 'Project Manager';
         }
      },

      page() {
         let page = parseInt(this?.$route?.query?.page);
         page = page > -1 ? page : 1;
         return page;
      },

      /** Has the user seen the intro for this section? */
      seenIntro() {
         return this.clientState.sectionIntros.whatQualifies;
      },
   },

   methods: {
      /** When a card for one of the four-part-test parts is selected, jump to that page */
      cardSelected(index) {
         this.gotoPage(index + 2);
      },

      gotoPage(pageNum) {
         this.$router.push({name: 'get-started-stage', query: {page: pageNum}});
      },

      leaveIntro() {
         if (!this.seenIntro) {
            this.$store.dispatch('setSectionIntro', {
               section: 'whatQualifies',
               value: true,
            });
         }
         this.gotoPage(1);
      },

      /** Select the next page, or take the user to the next thing they need to do */
      async nextPage() {
         if (this.page === 6) {
            if (!this.greeted) {
               this.$store.dispatch('returnGreeting', {value: true});
               this.$store.commit('setSidebar', {sidebar: SidebarStates.EXPANDED});
            }
            this.$router.push({name: 'Home'});
         } else {
            this.gotoPage(this.page + 1);
         }
      },

      async prevPage() {
         this.gotoPage(this.page - 1);
      },
   },

   async created() {
      if (this.isSME) {
         await this.blockingRequest('progress/loadProgress', {companyId: this.companyId});
      }
      // Keeping this around for debuging purposes. Uncomment this to set the user back to the ungreeted state
      // await this.$store.dispatch('returnGreeting', {value: false});
   },
};
</script>

<style lang="scss" scoped>
.welcome-logo {
   display: inline;
   height: 76px;
   margin-left: -16px;
   margin-right: -18px;
}

.welcome-img {
   min-height: 320px;

   @include md-max {
      min-height: 240px;
   }

   &.welcome-img-1 {
      @include stylized-image('../../assets/img/team-color.jpg', $pos-y: 20%);
   }

   &.welcome-img-2 {
      @include stylized-image('../../assets/img/accountant.png');

      @include md-max {
         @include stylized-image('../../assets/img/accountant.png', $pos-y: 15%);
      }
   }
}

::v-deep .test-card-row {
   padding-left: 8px;
   padding-right: 8px;

   > div {
      padding-left: 8px;
      padding-right: 8px;

      @for $i from 0 through 3 {
         &:nth-child(#{$i + 1}) {
            .test-part-card {
               @include gradient-blue-green(3, $i);
            }
         }
      }

      @media (max-width: map-get($container-max-widths, 'xl')) {
         @for $i from 0 through 3 {
            &:nth-child(#{$i + 1}) {
               .test-part-card {
                  @include gradient-blue-green(2, $i % 2);
               }
            }
         }
      }

      .test-part-card {
         height: 100px;
         color: $white;
         cursor: pointer;

         .card-body {
            padding: 1rem;
            display: flex;
            align-items: center;
            position: relative;

            .icon {
               transition: transform 0.2s;
               color: rgba(255, 255, 255, 0.2);
               position: absolute;
               top: 16px;
               right: 16px;
               bottom: 16px;
               height: 68px;
               width: 68px;
            }
         }

         &:hover {
            .icon {
               transform: scale(1.1);
            }
         }

         &.inactive {
            background: $gray-800;
         }
      }
   }
}
</style>
