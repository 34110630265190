<template>
   <div v-if="contractor" class="form-section">
      <b-form-row>
         <b-col cols="12" lg="4">
            <b-form-group
               :label-for="`input-name-${edit ? 1 : contractorId}`"
               :invalid-feedback="nameInvalidFeedback"
            >
               <template #label> Contract Researcher Name </template>
               <b-form-input
                  :id="`input-name-${edit ? 1 : contractorId}`"
                  ref="input-name"
                  :state="validation.fullname.$invalid ? false : null"
                  :value="contractor.fullname"
                  @input="
                     (value) =>
                        updateContractor({
                           contractorId,
                           field: 'fullname',
                           value,
                        })
                  "
                  :disabled="disableInputs"
               ></b-form-input>
            </b-form-group>
         </b-col>

         <b-col cols="12" lg="4">
            <b-form-group>
               <label :for="`select-type-${edit ? 1 : contractorId}`">
                  Fee Type
                  <b-icon id="icon-tooltip-contract-type" :icon="icon.contractType"></b-icon>
                  <b-popover
                     target="icon-tooltip-contract-type"
                     triggers="hover focus"
                     placement="right"
                     @show="popoverActive.contractType = true"
                     @hidden="popoverActive.contractType = false"
                  >
                     Was the contractor paid for their time on an hourly or monthly basis (time and
                     material), or did the contractor receive lump sum payments regardless of their
                     hours worked (fixed fee)?
                  </b-popover>
               </label>
               <b-form-select
                  :id="`select-type-${edit ? 1 : contractorId}`"
                  :class="{'input-warning': edit ? !contractor.contractType : false}"
                  :value="contractor.contractType"
                  :options="typeOptions"
                  @change="
                     (value) =>
                        updateContractor({
                           contractorId,
                           field: 'contractType',
                           value,
                        })
                  "
                  :disabled="disableInputs"
               >
                  <template #first>
                     <b-form-select-option :value="null"></b-form-select-option>
                  </template>
               </b-form-select>
            </b-form-group>
         </b-col>

         <b-col cols="12" lg="4">
            <b-form-group>
               <label :for="`select-location-${edit ? 1 : contractorId}`">
                  Location
                  <b-icon id="icon-tooltip-location" :icon="icon.location"></b-icon>
                  <b-popover
                     target="icon-tooltip-location"
                     triggers="hover focus"
                     placement="right"
                     @show="popoverActive.location = true"
                     @hidden="popoverActive.location = false"
                  >
                     In which state did the contractor perform the qualified R&D activity?
                  </b-popover>
               </label>
               <b-form-select
                  :id="`select-location-${edit ? 1 : contractorId}`"
                  :class="{'input-warning': edit ? !contractor.location : false}"
                  :options="stateOptions"
                  :value="contractor.location"
                  @input="
                     (value) =>
                        updateContractor({
                           contractorId,
                           field: 'location',
                           value,
                        })
                  "
                  :disabled="disableInputs"
               >
                  <template #first>
                     <b-form-select-option :value="null"> </b-form-select-option>
                  </template>
               </b-form-select>
            </b-form-group>
         </b-col>
      </b-form-row>

      <b-form-row>
         <b-col cols="12" lg="6">
            <b-form-group
               label="Contract Documents"
               :label-for="`file-contracts-${edit ? 1 : contractorId}`"
               class="mb-0"
            >
               <b-form-file
                  :id="`file-contracts-${edit ? 1 : contractorId}`"
                  :class="{
                     'input-warning': edit
                        ? contracts.length === 0 && !contractor.contractOptOut
                        : false,
                  }"
                  v-model="contractor.uploadFiles"
                  placeholder=""
                  multiple
                  :disabled="contractor.contractOptOut || disableInputs"
               >
               </b-form-file>
            </b-form-group>
            <div class="d-flex align-items-center justify-content-between mb-2">
               <small :class="[fileSizeExceedsLimit ? 'text-danger' : 'text-muted']">
                  {{ formatBytes(uploadSize) }} Selected / Limit
                  {{ formatBytes(MAX_INTERNAL_FILE_SIZE) }}
               </small>
            </div>
         </b-col>
         <b-col cols="12" lg="6" class="contract-right-side">
            <div v-if="contracts.length === 0">
               <b-form-checkbox
                  :id="`checkbox-no-document-${edit ? 1 : contractorId}`"
                  :checked="contractor.contractOptOut"
                  @change="
                     (value) =>
                        updateContractor({
                           contractorId,
                           field: 'contractOptOut',
                           value,
                        })
                  "
                  :disabled="contractor.uploadFiles.length > 0 || disableInputs"
               >
                  There is no contract document available for this contract researcher.
               </b-form-checkbox>
            </div>
         </b-col>
      </b-form-row>

      <!-- CONTRACT FILES -->
      <b-collapse
         :id="`collapse-contracts-${edit ? 1 : contractorId}`"
         :visible="contracts.length > 0"
      >
         <b-card class="mb-3">
            <b-table
               :fields="contractFields"
               sort-by="uploadedAt"
               :items="contracts"
               class="scrollbar mb-0"
               small
               sticky-header="150px"
            >
               <template #cell(name)="data">
                  <b>{{ data.value }}</b>
               </template>
               <template #cell(uploadedAt)="data">
                  {{ formatDatetime(data.value) }}
               </template>
               <template #cell(uploadedBy)="data">
                  {{ userName(data.value) }}
               </template>
               <template #cell(actions)="data">
                  <div class="cell-w-buttons justify-content-end">
                     <b-button
                        v-if="!isSME && !isComplete"
                        variant="danger"
                        size="sm"
                        @click="deleteFile(data.item.id)"
                        :disabled="disableInputs"
                     >
                        Delete
                     </b-button>
                  </div>
               </template>
            </b-table>
         </b-card>
      </b-collapse>

      <b-form-row>
         <b-col cols="12" lg="6" order="2" order-lg="1">
            <b-form-group
               label="Describe the service provided by this contract researcher."
               :label-for="`text-description-${edit ? 1 : contractorId}`"
               class="mb-0"
            >
               <b-form-textarea
                  :id="`text-description-${edit ? 1 : contractorId}`"
                  :class="{'input-warning': edit ? !contractor.description : false}"
                  rows="4"
                  :value="contractor.description"
                  @input="
                     (value) =>
                        updateContractor({
                           contractorId,
                           field: 'description',
                           value,
                        })
                  "
                  :disabled="disableInputs"
               ></b-form-textarea>
            </b-form-group>
         </b-col>
         <b-col cols="12" lg="6" order="1" order-lg="2" v-if="contractor.contractOptOut">
            <label :for="`radio-ip-rights-${edit ? 1 : contractorId}`" class="d-block">
               IP rights belong to {{ company.companyName }}
               <b-icon id="icon-tooltip-ip-rights" :icon="icon.companyIpRights"></b-icon>
               <b-popover
                  target="icon-tooltip-ip-rights"
                  triggers="hover focus"
                  placement="right"
                  @show="popoverActive.companyIpRights = true"
                  @hidden="popoverActive.companyIpRights = false"
               >
                  Did your company retain the rights of the contractor’s R&D results, such as the
                  ability to reuse any technology, process, and/or other notable breakthroughs for
                  future R&D efforts?
               </b-popover>
            </label>
            <div class="d-flex align-items-center justify-content-between mb-2">
               <b-form-radio-group
                  :id="`radio-ip-rights-${edit ? 1 : contractorId}`"
                  :class="{'input-warning': edit ? contractor.companyIpRights === null : false}"
                  :checked="contractor.companyIpRights"
                  :options="[
                     {text: 'Yes', value: true},
                     {text: 'No', value: false},
                  ]"
                  @change="
                     (value) =>
                        updateContractor({
                           contractorId,
                           field: 'companyIpRights',
                           value,
                        })
                  "
                  size="sm"
                  buttons
                  button-variant="outline-primary"
                  :disabled="disableInputs"
               ></b-form-radio-group>
               <b-button
                  :id="`btn-clear-ip-rights-${edit ? 1 : contractorId}`"
                  variant="secondary"
                  size="sm"
                  @click="
                     updateContractor({
                        contractorId,
                        field: 'companyIpRights',
                        value: null,
                     })
                  "
                  :disabled="disableInputs"
               >
                  Clear
               </b-button>
            </div>

            <label :for="`radio-eco-risk-${edit ? 1 : contractorId}`" class="d-block">
               Economic risk taken by {{ company.companyName }}
               <b-icon id="icon-tooltip-economic-risk" :icon="icon.economicRisk"></b-icon>
               <b-popover
                  target="icon-tooltip-economic-risk"
                  triggers="hover focus"
                  placement="right"
                  @show="popoverActive.economicRisk = true"
                  @hidden="popoverActive.economicRisk = false"
               >
                  Did your company bear the economic risk of the work performed by the contractor,
                  regardless of whether it's successful or not?
               </b-popover>
            </label>
            <div class="d-flex align-items-center justify-content-between mb-2">
               <b-form-radio-group
                  :id="`radio-eco-risk-${edit ? 1 : contractorId}`"
                  :class="{'input-warning': edit ? contractor.economicRisk === null : false}"
                  :checked="contractor.economicRisk"
                  :options="[
                     {text: 'Yes', value: true},
                     {text: 'No', value: false},
                  ]"
                  @change="
                     (value) =>
                        updateContractor({
                           contractorId,
                           field: 'economicRisk',
                           value,
                        })
                  "
                  size="sm"
                  buttons
                  button-variant="outline-primary"
                  :disabled="disableInputs"
               ></b-form-radio-group>
               <b-button
                  :id="`btn-clear-eco-risk-${edit ? 1 : contractorId}`"
                  variant="secondary"
                  size="sm"
                  @click="
                     updateContractor({
                        contractorId,
                        field: 'economicRisk',
                        value: null,
                     })
                  "
                  :disabled="disableInputs"
               >
                  Clear
               </b-button>
            </div>
         </b-col>
      </b-form-row>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {ContractTypes} from '@/store/modules/contractors';
import {MAX_INTERNAL_FILE_SIZE} from '@/helpers/constants';
import {formatBytes} from '@/helpers/utils';

export default {
   props: {
      contractorId: {
         type: [String, Number],
         default: null,
      },
      edit: {
         type: Boolean,
         default: false,
      },
      validation: {
         type: Object,
      },
      waiting: {
         type: Boolean,
         default: false,
      },
   },

   data() {
      return {
         MAX_INTERNAL_FILE_SIZE,
         filesVisible: false,
         contractFields: [
            {key: 'name', label: 'Contract File Name', sortable: true},
            {key: 'uploadedAt', sortable: true},
            {key: 'uploadedBy', sortable: true},
            {key: 'actions', label: ''},
         ],
         popoverActive: {
            companyIpRights: false,
            economicRisk: false,
            contractType: false,
            location: false,
         },
      };
   },

   computed: {
      ...mapGetters({
         isSME: 'isSME',
         usStates: 'usStatesOutsideUS',
         study: 'companies/study',
         company: 'companies/currentCompany',
         users: 'users/companyUsers',
         isComplete: 'contractors/isComplete',
         newContractorMap: 'contractors/newContractorMap',
         newContractors: 'contractors/newContractors',
         existingContractors: 'contractors/contractors',
         editContractor: 'contractors/editContractor',
         contractorContracts: 'contractors/contractorContracts',
      }),

      contractor() {
         if (this.edit) {
            return this.editContractor;
         }
         return this.newContractorMap[this.contractorId];
      },

      disableInputs() {
         return this.waiting || this.isComplete;
      },

      icon() {
         const icons = {};
         for (const [k, v] of Object.entries(this.popoverActive)) {
            icons[k] = v ? 'question-circle-fill' : 'question-circle';
         }
         return icons;
      },

      /** Options for the fee type selector */
      typeOptions() {
         return Object.entries(ContractTypes).map(([key, value]) => {
            return {value: key, text: value};
         });
      },

      /** US States formatted for a b-form-select options attribute */
      stateOptions() {
         const stateOptions = Object.entries(this.usStates)
            .sort((a, b) => {
               const [aKey, aName] = a;
               const [bKey, bName] = b;

               if (aKey === 'XX') {
                  return -1;
               } else if (bKey === 'XX') {
                  return 1;
               }

               if (aName < bName) {
                  return 1;
               } else if (aName < bName) {
                  return -1;
               }
               return 0;
            })
            .map((entry) => {
               const [key, value] = entry;
               return {text: value, value: key};
            });
         return stateOptions;
      },

      /** Invalid feedback for the name input */
      nameInvalidFeedback() {
         if (!this.validation.fullname.required) {
            return 'Required';
         } else if (!this.validation.fullname.unique) {
            return 'Duplicate name';
         } else if (!this.validation.fullname.minLength) {
            return 'Enter at least 3 characters';
         }
         return '';
      },

      /** Total size of selected files for upload */
      uploadSize() {
         if (this.contractor.uploadFiles.length > 0) {
            return this.contractor.uploadFiles
               .map((file) => file.size)
               .reduce((sum, item) => sum + item);
         }
         return 0;
      },

      /** Do the selected files exceed the max file size for upload */
      fileSizeExceedsLimit() {
         return this.uploadSize >= MAX_INTERNAL_FILE_SIZE;
      },

      /** Uploaded contracts for the current contractor */
      contracts() {
         if (this.contractorContracts === null) {
            return [];
         }
         if (this.contractorId in this.contractorContracts) {
            return this.contractorContracts[this.contractorId];
         }
         return [];
      },
   },

   methods: {
      formatBytes,

      /** Format a date string */
      formatDatetime(str) {
         return new Date(str).toLocaleString();
      },

      /** The name of the user with the given id */
      userName(id) {
         if (id in this.users) {
            const user = this.users[id];
            return `${user.firstName} ${user.lastName}`;
         }
         return 'R&D Incentives Group';
      },

      /** Set the focus on the fullname input */
      focusName() {
         this.$refs['input-name'].focus();
      },

      /** Update a field on a contractor */
      updateContractor({contractorId, field, value}) {
         const mutation = this.edit ? 'updateEditContractor' : 'updateNewContractor';
         this.$store.commit(`contractors/${mutation}`, {contractorId, field, value});
      },

      /** Delete a contract file */
      async deleteFile(fileId) {
         const proceed = await this.$bvModal.msgBoxConfirm(
            'Are you sure you want to delete this file?',
            {
               title: 'Delete this file?',
               centered: true,
            }
         );
         if (proceed) {
            await this.blockingRequest('contractors/deleteContractFile', {fileId, force: true});
         }
      },
   },

   mounted() {
      this.focusName();
   },
};
</script>

<style lang="scss" scoped>
.contract-right-side {
   padding-top: 2rem;
   margin-bottom: 1rem;
}
</style>
