<template>
   <div>
      <b-table
         :fields="fields"
         :items="filteredCategories"
         ref="uploadTable"
         sort-by="name"
         id="table-upload"
         class="mb-0"
      >
         <template #cell(name)="data">
            <b>{{ data.value }}</b>
         </template>

         <template #cell(status)="data">
            <RdigStatusPill :ident="data.index" :status="data.value" />
         </template>

         <template #cell(actions)="data">
            <div class="d-flex justify-content-end">
               <b-button
                  v-if="null === data.item.completedAt && data.item.summary.length > 0 && !isSME"
                  :id="`btn-lock-category-${data.index}`"
                  variant="dark-gray"
                  size="sm"
                  class="mr-3"
                  @click="showUploadsCompleteModal(data.item)"
               >
                  <b-icon-unlock></b-icon-unlock>
               </b-button>
               <b-button
                  v-if="null === data.item.completedAt"
                  variant="primary"
                  size="sm"
                  class="mr-3"
                  @click="showUploadModal(data.item)"
               >
                  <b-icon-upload />
               </b-button>
               <button
                  :id="data.detailsShowing ? 'btn-chevron-up' : 'btn-chevron-down'"
                  class="icon-btn icon-btn-secondary"
                  @click="data.toggleDetails"
               >
                  <custom-icon
                     :icon="data.detailsShowing ? 'icon-chevron-up' : 'icon-chevron-down'"
                  />
               </button>
            </div>
         </template>
         <template #row-details="row">
            <b-row v-if="row.item.summary.length > 0" class="mb-2">
               <b-col lg="2" cols="3">
                  <h5 class="text-center">Year</h5>
               </b-col>
               <b-col lg="10" cols="9">
                  <h5>Files</h5>
               </b-col>
            </b-row>

            <transition-group name="list">
               <b-row
                  v-for="(item, index) in summaryItems[row.item.id]"
                  :key="`${item.id}`"
                  :class="{
                     'mb-4':
                        index < summaryItems[row.item.id].length - 1 &&
                        item.year !== summaryItems[row.item.id][index + 1].year,
                  }"
               >
                  <b-col lg="2" cols="3" class="d-flex align-items-center justify-content-center">
                     <YearSelect
                        :value="item.year"
                        @input="(value) => onUpdateYear(item, value)"
                        tax-years
                        allow-empty
                        empty-text="None"
                     />
                  </b-col>
                  <b-col lg="10" cols="9">
                     <b-list-group-item>
                        <div class="d-flex align-items-center justify-content-between mb-1">
                           <div class="d-flex align-items-center">
                              <span :id="`file-name-${index}`" class="font-weight-bold">
                                 {{ item.name }}
                                 <span
                                    v-if="item.year"
                                    :id="`file-year-${index}`"
                                    class="text-muted"
                                 >
                                    ({{ item.year }})
                                 </span>
                              </span>
                              <b-badge
                                 class="ml-2 mt-1 font-weight-normal"
                                 :variant="item.isLatest ? 'primary' : 'gray'"
                                 pill
                              >
                                 v{{ item.version }}
                              </b-badge>
                           </div>
                           <small class="text-muted">{{ formatBytes(item.size) }}</small>
                        </div>
                        <div :id="`file-description-${index}`">
                           <small v-if="!!item.description">{{ item.description }}</small>
                           <small v-else class="text-muted">No description</small>
                           <b-button
                              :id="`btn-edit-description-${index}`"
                              class="ml-2 pt-1"
                              size="xs"
                              @click="showEditDescriptionModal(item)"
                           >
                              <b-icon-pencil-square></b-icon-pencil-square>
                           </b-button>
                        </div>
                        <small class="text-muted">
                           Uploaded {{ formatDatetime(item.uploadedAt) }}
                        </small>
                     </b-list-group-item>
                  </b-col>
               </b-row>
            </transition-group>

            <b-alert variant="primary" :show="row.item.summary.length < 1">
               No files have been uploaded to this category.
            </b-alert>
         </template>
      </b-table>

      <!-- MODALS -->
      <b-modal
         id="modal-upload-file"
         title="Upload Documents"
         centered
         size="lg"
         ok-title="Upload"
         ok-only
         ok-variant="success"
         :ok-disabled="$v.uploads.$invalid || uploads.uploading"
         :no-close-on-esc="uploads.uploading"
         :no-close-on-backdrop="uploads.uploading"
         :hide-header-close="uploads.uploading"
         @ok="uploadFiles($event)"
      >
         <h4 v-if="uploads.category" class="mb-3">{{ uploads.category.name }}</h4>
         <p>
            Please select the documents you'd like to upload to this category. You may select a year
            to associate with all of the files you select, or you can associate them each a year
            individually after they've been uploaded.
         </p>

         <b-alert
            variant="danger"
            :show="!$v.uploads.files.totalUploadSize && uploads.files.length > 1"
         >
            The total size of the selected files is larger than what we can accept in a single
            upload. Try uploading your files in smaller batches, or compressing your files before
            uploading them.
         </b-alert>

         <b-alert
            v-for="v in $v.uploads.files.$each.$iter"
            variant="danger"
            :key="v.$model.name"
            :show="!v.fileSize"
         >
            Your file <b>{{ v.$model.name }}</b> is larger than what we can accept in a single
            upload. Please try again with a compressed or smaller version of this file.
         </b-alert>

         <b-alert variant="primary" :show="uploads.uploading">
            <b-progress variant="primary" :max="uploadProgress.total">
               <b-progress-bar animated :value="uploadProgress.loaded">
                  <span
                     >{{ formatBytes(uploadProgress.loaded) }} /
                     {{ formatBytes(uploadProgress.total) }}</span
                  >
               </b-progress-bar>
            </b-progress>
            Your document upload is in progress. Please don't navigate away until the upload is
            complete.
         </b-alert>

         <YearSelect
            index="upload"
            tax-years
            v-model="uploads.year"
            :disabled="uploads.uploading"
            empty-text="I'll assign a year later"
            allow-empty
         />
         <b-form-file
            placeholder="Choose a file or drop it here"
            drop-placeholder="Drop file here"
            multiple
            :disabled="uploads.uploading"
            v-model="uploads.files"
         ></b-form-file>
         <div class="d-flex mb-2 align-items-center justify-content-between">
            <b-form-text>{{ formatBytes(uploadSize) }} Selected</b-form-text>
            <b-form-text>Limit {{ formatBytes(MAX_UPLOAD_SIZE) }}</b-form-text>
         </div>
         <b-form-input
            id="input-description"
            v-model="uploads.description"
            placeholder="Enter a description"
         ></b-form-input>
      </b-modal>

      <b-modal
         id="modal-uploads-complete"
         title="Complete This Category?"
         centered
         @ok="markUploadsComplete"
         ok-variant="success"
      >
         <p>
            After marking this category complete, users from your company will no longer be able to
            upload files to it.
         </p>
      </b-modal>

      <b-modal
         id="modal-edit-description"
         :title="`Edit Description for ${editDescriptionTarget && editDescriptionTarget.name}`"
         centered
         ok-title="Save"
         @ok="() => onUpdateDescription(editDescriptionTarget, this.updatedDescription)"
      >
         <b-form-input
            type="text"
            id="input-description"
            v-model="updatedDescription"
         ></b-form-input>
      </b-modal>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import {MAX_UPLOAD_SIZE} from '@/helpers/constants';
import {formatBytes} from '@/helpers/utils';

import YearSelect from '@/components/forms/YearSelect';

export default {
   components: {
      YearSelect,
   },

   props: {
      projectId: {
         type: [String, Number],
         default: null,
      },
   },

   data() {
      return {
         fields: [
            {key: 'name', sortable: true},
            {
               key: 'status',
               class: 'no-wrap',
               formatter: (value, key, item) => this.uploadStatus(item),
               sortable: true,
               sortByFormatted: true,
            },
            {key: 'actions', label: ''},
         ],
         uploads: {
            year: null,
            category: null,
            files: [],
            uploading: false,
         },
         MAX_UPLOAD_SIZE,
         editDescriptionTarget: null,
         updatedDescription: null,
         StatusType: this.$constants().StatusType,
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         isCustomer: 'isCustomer',
         isSME: 'isSME',
         categories: 'uploads/uploadCategories',
         uploadProgress: 'uploads/progress',
      }),

      /**
       * An object, keyed on category IDs, mapped to file summaries. Summaries are sorted by
       * year, and filtered to only display the latest version of a file.
       */
      summaryItems() {
         return this.filteredCategories.reduce((obj, category) => {
            const seen = {};
            const items = [...category.summary]
               .sort((a, b) => {
                  // Convert null years to infinity so they're always listed first
                  const aYear = parseInt(a.year, 10) || Infinity;
                  const bYear = parseInt(b.year, 10) || Infinity;
                  const aUploaded = a.uploadedAt;
                  const bUploaded = b.uploadedAt;

                  // Sort by year, descending
                  if (aYear < bYear) {
                     return 1;
                  } else if (aYear > bYear) {
                     return -1;
                  }

                  // Sort by upload date, descending
                  if (aUploaded < bUploaded) {
                     return 1;
                  } else if (aUploaded > bUploaded) {
                     return -1;
                  }

                  return 0;
               })
               .map((item) => {
                  const ident = `${item.name}::${item.year}`;
                  const wasSeen = seen[ident] || false;
                  seen[ident] = true;
                  return Object.assign({isLatest: !wasSeen}, item);
               })
               .filter((item) => item.isLatest);
            obj[category.id] = items;
            return obj;
         }, {});
      },

      filteredCategories() {
         let categories = this.categories.filter((category) => !category.internal);
         if (this.projectId) {
            return categories.filter((category) => {
               return category.projects.some((project) => project.id === this.projectId);
            });
         } else {
            return categories;
         }
      },

      // Total size of selected files for upload
      uploadSize() {
         if (this.uploads.files.length > 0) {
            return this.uploads.files.map((file) => file.size).reduce((sum, item) => sum + item);
         }
         return 0;
      },
   },

   methods: {
      formatBytes,

      // Format a timestamp
      formatDatetime(str) {
         return new Date(str).toLocaleString();
      },

      // Determines the status of an upload category.
      uploadStatus(item) {
         if (item.completedAt) {
            return this.StatusType.COMPLETE;
         } else if (item.summary.length > 0) {
            return this.StatusType.IN_PROGRESS;
         } else {
            return this.StatusType.INCOMPLETE;
         }
      },

      // Display the modal for editing descriptions on uploads
      showEditDescriptionModal(item) {
         this.editDescriptionTarget = item;
         this.updatedDescription = item.description;
         this.$bvModal.show('modal-edit-description');
      },

      // Reset the input data and display the upload modal
      showUploadModal(item) {
         this.uploads.category = item;
         this.uploads.files = [];
         this.uploads.year = null;
         this.uploads.description = null;
         this.$bvModal.show('modal-upload-file');
      },

      // Display the modal for marking an upload category complete
      showUploadsCompleteModal(item) {
         this.uploads.category = item;
         this.$bvModal.show('modal-uploads-complete');
      },

      /** Update the description on a file */
      async onUpdateDescription(summaryItem, newDescription) {
         const {uploadCategoryId, id, year} = summaryItem;

         await this.$store.dispatch('uploads/updateFileDetails', {
            uploadCategoryId,
            fileId: id,
            year,
            description: newDescription,
         });
      },

      /** Update the year on a file */
      async onUpdateYear(summaryItem, newYear) {
         const {uploadCategoryId, name, year, description} = summaryItem;

         await this.$store.dispatch('uploads/updateFileDetails', {
            uploadCategoryId,
            name,
            currentYear: year,
            year: newYear,
            description,
         });
      },

      // Send a file upload request
      async uploadFiles(bvModalEvent) {
         // Prevent the modal from closing immediately
         bvModalEvent.preventDefault();

         if (this.isSme) {
            console.log('Subject Matter Experts may not upload files.');
            return;
         }

         this.uploads.uploading = true;
         try {
            await this.blockingRequest('uploads/uploadFiles', {
               uploadCategoryId: this.uploads.category.id,
               files: this.uploads.files,
               year: this.uploads.year,
               description: this.uploads.description,
            });
            this.$bvModal.hide('modal-upload-file');
         } catch (err) {
            if (413 === err.response.status) {
               this.$store.commit('showAlert', {
                  msg: 'Upload exceeded maximum file size limit.',
                  seconds: 5,
               });
            } else {
               this.$store.commit('showAlert', {
                  msg: 'File upload failed.',
                  seconds: 5,
               });
            }
         } finally {
            this.uploads.uploading = false;
         }
      },

      // Send the request marking an upload category complete
      async markUploadsComplete() {
         if (this.isSME) {
            console.log('Subject Matter Experts may not mark categories complete.');
            return;
         }
         try {
            await this.blockingRequest('uploads/markCategoryComplete', {
               id: this.uploads.category.id,
            });
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Submission failed',
               seconds: 5,
            });
         }
      },
   },

   validations: {
      uploads: {
         files: {
            required,
            totalUploadSize(files) {
               if (files.length > 0) {
                  const total = files.map((file) => file.size).reduce((sum, size) => sum + size);
                  return total <= MAX_UPLOAD_SIZE;
               }
               return true;
            },
            $each: {
               fileSize(file) {
                  return file.size <= MAX_UPLOAD_SIZE;
               },
            },
         },
      },
   },
};
</script>
