import axios from 'axios';
import Vue from 'vue';

/**
 * v-auth-src
 *
 * Use in place of an <img> element's `src` attribute when the image source requires
 * authentication. The request will be made using axios, which should be configured
 * to add any auth headers required by the server to the request.
 *
 * @param {*} el - An <img> element
 * @param {Object} binding - The directive's binding
 * @param {String} binding.src - The URL from which to fetch the image
 * @param {String} binding.fallbackUrl - If the GET request to the image src fails, use
 *                                       this URL as the <img> element's src attribute.
 */
const setImgSrc = (el, binding) => {
   if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
      const imageUrl = binding.value.src;
      const fallbackUrl = binding.value.fallbackUrl;

      axios({
         method: 'get',
         url: imageUrl,
         responseType: 'arraybuffer',
      })
         .then((response) => {
            const mimeType = response.headers['content-type'].toLowerCase();
            const imgBase64 = Buffer.from(response.data, 'binary').toString('base64');
            el.src = `data:${mimeType};base64,${imgBase64}`;
         })
         .catch(() => {
            el.src = fallbackUrl;
         });
   }
};

Vue.directive('auth-src', {
   bind: setImgSrc,
   componentUpdated: setImgSrc,
});
