<template>
   <div>
      <b-form-group invalid-feedback="Required" v-if="!readonly">
         <b-form-textarea
            :id="`textarea-answer-${index + 1}`"
            v-model="answerValue"
            rows="8"
            :state="dirty ? !$v.answerValue.$invalid : null"
         ></b-form-textarea>
      </b-form-group>
      <div v-else :id="`answer-${index + 1}`">
         <div v-if="answerValue !== null" class="answer-value">
            <pre class="raw-answer">{{ answerValue }}</pre>
         </div>
         <div v-else>
            <b>Not Answered</b>
         </div>
      </div>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   name: 'AnswerText',

   props: {
      index: Number,
      readonly: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      ...mapGetters({
         questions: 'questionnaire/questions',
      }),

      question() {
         return this.questions[this.index];
      },

      answer() {
         return this.question.answer;
      },

      dirty() {
         if (this.answer) {
            return this.answer.dirty;
         } else {
            return false;
         }
      },

      answerValue: {
         get() {
            if (this.answer) {
               return this.answer.answer;
            } else {
               return null;
            }
         },
         set(value) {
            this.$store.commit('questionnaire/setAnswerValue', {
               questionIndex: this.index,
               value,
            });
         },
      },
   },

   validations: {
      answerValue: {},
   },

   watch: {
      answerValue: {
         immediate: true,
         handler() {
            this.$emit('validate', !this.$v.$invalid);
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.raw-answer {
   white-space: pre-wrap;
   font-family: inherit;
   margin-bottom: 0;
}
</style>
