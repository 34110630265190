/* eslint-disable no-empty-pattern */
import Vue from 'vue';
import {UploadCategory} from '@/helpers/types';
import ErrorCodes from '@/helpers/errorCodes';
import {downloadFile, sortBy} from '../../helpers/utils';

export const SpecialCategories = Object.freeze({
   CLOUDCOMPUTING: 'CLOUDCOMPUTING',
   CLOUDCOMPUTING_DOCS: 'CLOUDCOMPUTING_DOCS',
   CONTRACTOR_DOCS: 'CONTRACTOR_DOCS',
   CONTRACTOR_FINANCIAL_DOCS: 'CONTRACTOR_FINANCIAL_DOCS',
   EMPLOYEES: 'EMPLOYEES',
   SUPPLIES: 'SUPPLIES',
   SUPPLIES_DOCS: 'SUPPLIES_DOCS',
});

const state = () => ({
   uploadCategories: {},
   companyId: null,
   progress: {
      loaded: 0,
      total: 0,
   },
});

const getters = {
   uploadCategories: (state) => Object.values(state.uploadCategories).sort(sortBy('name')),
   uploadCategoryMap: (state) => state.uploadCategories,

   externalUploadCategories: (state, getters) => {
      return getters.uploadCategories.filter((category) => !category.internal);
   },

   internalUploadCategories: (state, getters) => {
      return getters.uploadCategories.filter((category) => category.internal);
   },

   progress: (state) => state.progress,
};

const mutations = {
   /** Clear the uploadCategories state object */
   clearUploadCategories: (state) => {
      state.uploadCategories = {};
   },

   // Update an upload category
   updateUploadCategory: (state, {uploadCategory}) => {
      if (uploadCategory.id in state.uploadCategories) {
         Object.assign(state.uploadCategories[uploadCategory.id], uploadCategory);
      } else {
         Vue.set(state.uploadCategories, uploadCategory.id, new UploadCategory(uploadCategory));
      }
   },

   // Set upload categories from a list
   setUploadCategories: (state, {uploadCategories}) => {
      uploadCategories.forEach((category) => {
         Vue.set(state.uploadCategories, category.id, new UploadCategory(category));
      });
   },

   /** Recompute the file versions within a category based on upload date */
   recomputeFileVersions: (state, {uploadCategoryId}) => {
      const summary = state.uploadCategories[uploadCategoryId].summary;

      const fileGroups = summary.reduce((obj, file) => {
         const ident = `${file.name}::${file.year}`;
         if (ident in obj) {
            obj[ident].push(file);
         } else {
            obj[ident] = [file];
         }
         return obj;
      }, {});

      Object.values(fileGroups).forEach((group) => {
         group.sort(sortBy('uploadedAt')).forEach((item, idx) => {
            Vue.set(item, 'version', idx + 1);
         });
      });
   },

   /** Remove an upload category from state */
   deleteUploadCategory: (state, {uploadCategoryId}) => {
      if (uploadCategoryId in state.uploadCategories) {
         Vue.delete(state.uploadCategories, uploadCategoryId);
      }
   },

   /** Remove a file from an upload category */
   deleteFile: (state, {uploadCategoryId, fileId}) => {
      if (uploadCategoryId in state.uploadCategories) {
         state.uploadCategories[uploadCategoryId].summary = state.uploadCategories[
            uploadCategoryId
         ].summary.filter((file) => {
            return file.id !== fileId;
         });
      }
   },

   // Set an upload summary list for an upload category
   setUploadSummary: (state, {uploadCategoryId, summary}) => {
      if (uploadCategoryId in state.uploadCategories) {
         state.uploadCategories[uploadCategoryId].summary = summary;
      } else {
         console.log(`No upload category currently loaded with id ${uploadCategoryId}`);
      }
   },

   // Update the file upload progress
   setProgress: (state, {loaded, total}) => {
      state.progress = {
         loaded,
         total,
      };
   },
};

const actions = {
   // Fetch and return the upload categories for a company
   async fetchCompanyUploadCategories({}, {companyId, summary = false}) {
      const response = await this._vm.$http.get(`/api/company/${companyId}/uploadcategory`, {
         params: {summary},
      });
      return response.data.results;
   },

   // Fetch the upload categories for a company and load them into the state
   async loadCompanyUploadCategories(
      {state, commit, dispatch},
      {companyId, summary = false, force = true}
   ) {
      // Only load categories if they haven't already been loaded
      // or the force flag is true.
      if (companyId === state.companyId && !force) {
         return state.uploadCategories;
      }

      // Send the request
      const categories = await dispatch('fetchCompanyUploadCategories', {companyId, summary});

      // Update the state
      commit('clearUploadCategories');
      commit('setUploadCategories', {uploadCategories: categories});
      state.companyId = companyId;
      return categories;
   },

   /** Load a special uploadCategory */
   async loadSpecialCategory({commit}, {category, companyId, summary = true}) {
      const params = {summary};
      const response = await this._vm.$http.get(
         `/api/company/${companyId}/uploadcategory/${SpecialCategories[category]}`,
         {params}
      );
      const uploadCategory = response.data;
      commit('updateUploadCategory', {uploadCategory});
      return uploadCategory;
   },

   // Add a new upload category and update the state
   async saveNewCategory({commit}, {name, projectIds, companyId}) {
      // Send the request
      const response = await this._vm.$http.post('/api/uploadcategory', {
         name,
         projectIds,
         companyId,
      });
      const uploadCategory = response.data;

      // Update state
      commit('updateUploadCategory', {uploadCategory});
      return uploadCategory;
   },

   // Edit a category and update the state
   async editCategory({state, commit}, {id, name, projectIds, companyId}) {
      const uploadCategory = state.uploadCategories[id];

      if (uploadCategory.internal) {
         await this._vm.$bvModal.msgBoxOk(
            'This is a protected upload category and cannot be modified.',
            {
               title: 'Protected Upload Category',
               centered: true,
            }
         );
         return;
      }

      // Send the request
      const response = await this._vm.$http.put(`/api/uploadcategory/${id}`, {
         name,
         companyId,
         projectIds,
      });
      let updatedCategory = response.data;

      updatedCategory = (({id, name, projects, completedAt, completedBy}) => ({
         id,
         name,
         projects,
         completedAt,
         completedBy,
      }))(updatedCategory);

      // Update state
      commit('updateUploadCategory', {uploadCategory: updatedCategory});
      return updatedCategory;
   },

   /** Delete an upload category */
   async deleteUploadCategory({state, commit}, {uploadCategoryId, force = false}) {
      const uploadCategory = state.uploadCategories[uploadCategoryId];

      if (uploadCategory.internal) {
         await this._vm.$bvModal.msgBoxOk(
            'This is a protected upload category and cannot be deleted.',
            {
               title: 'Protected Upload Category',
               centered: true,
            }
         );
         throw 'Cannot delete protected upload category.';
      }

      const params = {force};
      try {
         await this._vm.$http.delete(`/api/uploadcategory/${uploadCategoryId}`, {params});
      } catch (err) {
         const errCode = err.response ? err.response.data.errors[0].code : null;
         if (errCode === ErrorCodes.CONFIRMATION_REQUIRED) {
            return err.response.data.errors[0].detail;
         }
         throw err;
      }
      commit('deleteUploadCategory', {uploadCategoryId});
   },

   // Mark an upload category as complete
   async markCategoryComplete({commit}, {id}) {
      const response = await this._vm.$http.post(`/api/uploadcategory/${id}/completed`);
      let category = response.data;

      category = (({id, completedAt, completedBy}) => ({id, completedAt, completedBy}))(category);

      // Update state
      commit('updateUploadCategory', {
         uploadCategory: category,
      });
      return category;
   },

   // Unmark an upload category as complete
   async unmarkCategoryComplete({commit}, {id}) {
      const response = await this._vm.$http.delete(`/api/uploadcategory/${id}/completed`);
      let category = response.data;

      category = (({id, completedAt, completedBy}) => ({id, completedAt, completedBy}))(category);

      // Update state
      commit('updateUploadCategory', {
         uploadCategory: category,
      });
      return category;
   },

   // Upload a file to an upload category
   async uploadFiles(
      {commit},
      {uploadCategoryId, files, year, description = null, validation = null, doSave = true}
   ) {
      if (files.length < 1) {
         return;
      }

      // Format the form data
      let formData = new FormData();
      files.forEach((file) => {
         formData.append('files', file);
      });

      if (year) {
         formData.append('year', year);
      }

      if (description) {
         formData.append('description', description);
      }

      if (validation) {
         const validationStr = JSON.stringify(validation);
         formData.append('validation', validationStr);
      }

      commit('setProgress', {loaded: 0, total: 0});

      let response;
      // Send the request, setting content-type headers
      try {
         response = await this._vm.$http.post(
            `/api/uploadcategory/${uploadCategoryId}/upload`,
            formData,
            {
               headers: {'Content-Type': 'multipart/form-data'},
               onUploadProgress: function (event) {
                  commit('setProgress', {loaded: event.loaded, total: event.total});
               },
            }
         );
      } catch (err) {
         const code = err.response.data.errors[0].code;
         if (code === ErrorCodes.CLOSED_STUDY) {
            await this._vm.$bvModal.msgBoxOk(
               'The selected year is part of a study which has been closed. No new files may be uploaded for the selected year.',
               {
                  title: 'Closed Study',
                  centered: true,
               }
            );
            throw err;
         } else {
            throw err;
         }
      }
      const summary = response.data.results;

      // Update state
      if (doSave) {
         commit('setUploadSummary', {uploadCategoryId, summary});
      }
      return summary;
   },

   /**
    * Delete an uploaded file
    * @param {string|number} fileId - ID of the file to delete
    * @param {string|number} [uploadCategoryId] - Upload category the file belongs to. If provided, the file will be removed in the app state
    * @param {boolean} [force=false] - Force the operation
    */
   async deleteFile({commit}, {fileId, uploadCategoryId = null, force = false}) {
      const params = {force};
      await this._vm.$http.delete(`/api/uploadfile/${fileId}`, {params});
      if (uploadCategoryId !== null) {
         commit('deleteFile', {uploadCategoryId, fileId});
         commit('recomputeFileVersions', {uploadCategoryId});
      }
   },

   /**
    * Send a request to update the year and description on a file or group of files. Either a
    * name and year, or a fileId may be used to identify the files to be updated. If name and
    * currentYear are provided, all files in the same category with matching name and year
    * will be updated.
    *
    * @param {string} uploadCategoryId - The ID of the upload category the file belongs to.
    * @param {string, number} year - The year to be assigned to the file.
    * @param {string} [name] - The name of a file. Must also provide `currentYear`.
    * @param {string} [currentYear] - The current year of the file. Must also provide `name`.
    * @param {string} [fileId] - The ID of an uploaded file
    */
   async updateFileDetails(
      {state, commit},
      {uploadCategoryId, year, description, name = null, currentYear = 0, fileId = null}
   ) {
      const summary = state.uploadCategories[uploadCategoryId].summary;
      let files;

      if (name && currentYear !== 0) {
         // Collect all versions of the given file name
         files = summary.filter((item) => item.name === name && item.year === currentYear);
      } else if (fileId) {
         files = summary.filter((item) => item.id === fileId);
      } else {
         // No identifier, so do nothing
         return;
      }

      const requests = files.map((file) => {
         this._vm.$http.put(`/api/uploadfile/${file.id}`, {year, description});
      });
      await Promise.all(requests);

      // Update each file
      files.forEach((file) => {
         Vue.set(file, 'year', year);
         Vue.set(file, 'description', description);
      });

      commit('recomputeFileVersions', {uploadCategoryId});
   },

   async downloadFile({}, {fileId}) {
      const response = await this._vm.$http.get(`/api/uploadfile/${fileId}`, {
         responseType: 'blob',
      });
      downloadFile(response);
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
