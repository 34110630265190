import {clientStaffRoutes} from '../utils';

import CloudDeclaration from '@/views/expenses/cloud/CloudDeclaration';
import CloudUploads from '@/views/expenses/cloud/CloudUploads';
import CloudVendors from '@/views/expenses/cloud/CloudVendors';
import CloudPercentages from '@/views/expenses/cloud/CloudPercentages';
import CloudReview from '@/views/expenses/cloud/CloudReview';

export default [
   ...clientStaffRoutes({
      name: 'cloud-declaration',
      path: '/cloud-computing',
      component: CloudDeclaration,
      stage: 'cloud',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Cloud Computing'},
      ],
   }),
   ...clientStaffRoutes({
      name: 'cloud-uploads',
      path: '/cloud-computing/uploads',
      component: CloudUploads,
      stage: 'cloud',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Cloud Computing', to: {name: 'cloud-uploads-staff', params: {id: 'FILL'}}},
         {text: 'Import'},
      ],
   }),
   ...clientStaffRoutes({
      name: 'cloud-vendors',
      path: '/cloud-computing/vendors',
      component: CloudVendors,
      stage: 'cloud',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Cloud Computing', to: {name: 'cloud-declaration-staff', params: {id: 'FILL'}}},
         {text: 'Vendors'},
      ],
   }),
   ...clientStaffRoutes({
      name: 'cloud-percentages',
      path: '/cloud-computing/percentages',
      component: CloudPercentages,
      stage: 'cloud',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Cloud Computing', to: {name: 'cloud-declaration-staff', params: {id: 'FILL'}}},
         {text: 'Percentages'},
      ],
   }),
   ...clientStaffRoutes({
      name: 'cloud-review',
      path: '/cloud-computing/review',
      component: CloudReview,
      stage: 'cloud',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Cloud Computing', to: {name: 'cloud-declaration-staff', params: {id: 'FILL'}}},
         {text: 'Review'},
      ],
   }),
];
