import Vue from 'vue';

let EMPLOYEE_SET_COUNT = 0;
const newSetId = () => parseInt(EMPLOYEE_SET_COUNT++, 10);

const state = () => ({
   employeeSets: {},
});

const getters = {
   employeeSets: (state) =>
      Object.values(state.employeeSets).sort((a, b) => {
         const aStr = `${a.fullname}:${a.department}`;
         const bStr = `${b.fullname}:${b.department}`;
         if (aStr < bStr) {
            return -1;
         }
         if (aStr > bStr) {
            return 1;
         }
         return 0;
      }),

   assignedEmployeeSets: (state, getters) => (assigneeId) => {
      return getters.employeeSets.filter((set) => set.assignee === assigneeId);
   },

   allAssignmentsCompleteInYear: (state, getters) => (assigneeId, year) => {
      const employeeSets = getters.assignedEmployeeSets(assigneeId);
      let hasAssignment = false;

      const allCompleted = employeeSets.every((set) => {
         const employeesByYear = set.employees.filter((employee) => employee.year === year);
         return employeesByYear.every((employee) => {
            hasAssignment = true;
            return !!employee.assignmentCompleted;
         });
      });

      return hasAssignment && allCompleted;
   },

   /** Does the user have an assignment in year? */
   hasAssignmentInYear: (state, getters) => (assigneeId, year) => {
      const employeeSets = getters.assignedEmployeeSets(assigneeId);
      return employeeSets.some((set) => {
         return set.employees.some((employee) => employee.year === year);
      });
   },
};

const mutations = {
   initEmployeeSets: (state, {employees, assignments}) => {
      // Sort employees by name, dept, year
      employees = employees.sort((a, b) => {
         if (a.fullname < b.fullname) {
            return -1;
         } else if (a.fullname > b.fullname) {
            return 1;
         }

         if (a.department < b.department) {
            return -1;
         } else if (a.department > b.department) {
            return 1;
         }

         if (a.year < b.year) {
            return -1;
         } else if (a.year > b.year) {
            return 1;
         }

         return 0;
      });

      // Build employee sets
      const employeeSets = [];
      employees.forEach((employee) => {
         let nameMatch = false;
         let contiguous = false;
         let assigneeMatch = false;
         let previousSet = null;

         // Does this employee match the previous employee's name and dept?
         if (employeeSets.length > 0) {
            // employees are sorted by name and dept, so just check the previous set
            previousSet = employeeSets[employeeSets.length - 1];
            nameMatch =
               previousSet.fullname === employee.fullname &&
               previousSet.department === employee.department;
         }

         // Is the employee included in the previous year?
         if (nameMatch) {
            // employees are sorted by year, so just check the previous year
            const previousEmployees = previousSet.employees;
            const previousYear = previousEmployees[previousEmployees.length - 1].year;
            contiguous = previousYear === employee.year - 1;
         }

         // Is the employee assigned to the same user (including no user)
         const assigment = assignments.find((a) => a.employeeId === employee.id);
         const assignee = assigment ? assigment.assigneeId : null;
         if (contiguous) {
            assigneeMatch = previousSet.assignee === assignee;
         }

         // If this employee is a match of the previous employee, push its data onto
         // the previous employeeSet. Otherwise, push a new employeeSet onto the array.
         const employeeData = (({id, title, year}, assignment) => ({
            id,
            title,
            year,
            assignerId: assignment ? assignment.assignerId : null,
            assignmentCompleted: assignment ? assignment.completed : null,
         }))(employee, assigment);

         if (nameMatch && contiguous && assigneeMatch) {
            previousSet.employees.push(employeeData);
         } else {
            employeeSets.push({
               setId: newSetId(),
               fullname: employee.fullname,
               department: employee.department,
               assignee,
               employees: [employeeData],
            });
         }
      });

      // Clear state, then store sets in an object keyed on the setId
      state.employeeSets = {};
      employeeSets.forEach((set) => {
         Vue.set(state.employeeSets, set.setId, set);
      });
   },

   /** Update the asignee on an employee set */
   assignSet: (state, {setId, assigneeId}) => {
      state.employeeSets[setId].assignee = assigneeId;
   },

   /** Split an employee set into two year ranges */
   splitEmployeeSet: (state, {setId, year}) => {
      const employeeSet = state.employeeSets[setId];

      // Split the employees into two arrays. The year on which we're splitting goes
      // into the upper array.
      const lowerEmployees = employeeSet.employees.filter((employee) => employee.year < year);
      const upperEmployees = employeeSet.employees.filter((employee) => employee.year >= year);

      // Build new employee sets
      const lowerSet = Object.assign({}, employeeSet, {
         setId: newSetId(),
         employees: lowerEmployees,
      });
      const upperSet = Object.assign({}, employeeSet, {
         setId: newSetId(),
         employees: upperEmployees,
      });

      // Add the new employee sets to the state, and remove the old one
      Vue.set(state.employeeSets, lowerSet.setId, lowerSet);
      Vue.set(state.employeeSets, upperSet.setId, upperSet);
      Vue.delete(state.employeeSets, setId);
   },
};

const actions = {
   /**
    * Load all employees and assignments in the current study, then
    * initialize employee sets
    */
   async loadEmployeeAssignments({commit, dispatch, rootGetters}, {companyId}) {
      const employeePromise = dispatch('employees/fetchEmployees', {companyId}, {root: true});
      const years = rootGetters['companies/studyYears'] || rootGetters['studyYears'] || [];

      const assignmentRequests = years.map((year) => {
         return dispatch(
            'timesurvey/loadTimeSurveyAssignments',
            {companyId, year, save: false},
            {root: true}
         );
      });

      const employees = await employeePromise;
      const assignments = (await Promise.all(assignmentRequests)).flat();

      commit('initEmployeeSets', {employees, assignments});
   },

   async assignEmployees({state, commit}, {setIds, assigneeId, assignerId, companyId}) {
      let assignments = [];
      setIds.forEach((setId) => {
         const set = state.employeeSets[setId];
         set.employees.forEach((employee) => {
            assignments.push({
               year: employee.year,
               employeeId: employee.id,
               assigneeId,
            });
         });
      });

      await this._vm.$http.post(`/api/user/${assignerId}/timesurvey/assignment`, {
         companyId,
         assignments,
      });

      setIds.forEach((setId) => {
         commit('assignSet', {setId, assigneeId});
      });
   },

   /**
    * Bulk unassign a list of employee sets
    * @param {string} companyId - ID of the company the employees belong to
    * @param {Number[]} setIds - A list of employee set IDs
    */
   async unassignEmployees({state, commit}, {companyId, setIds, assignerId}) {
      if (setIds.length === 0) {
         return;
      }

      let assignments = [];
      setIds.forEach((setId) => {
         const set = state.employeeSets[setId];
         set.employees.forEach((employee) => {
            assignments.push({
               companyId,
               year: employee.year,
               employeeId: employee.id,
               assigneeId: set.assignee,
               assignerId: assignerId,
            });
         });
      });

      await this._vm.$http.delete(`/api/user/${assignerId}/timesurvey/assignment`, {
         data: {
            companyId,
            assignments,
         },
      });

      setIds.forEach((setId) => {
         commit('assignSet', {setId, assigneeId: null});
      });
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
