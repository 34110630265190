/* eslint-disable no-empty-pattern */
import Vue from 'vue';
import ErrorCodes from '@/helpers/errorCodes';

import {range} from 'lodash';

export const PromptTypes = Object.freeze({
   supplies: {
      title: 'Supplies Instructions',
      description:
         'This text is included on the first page of the supplies section to help the user understand the types of qualifying supplies purchases their company might have made.',
   },
   uploads: {
      title: 'Supporting Document Examples',
      description: "Provide examples of supporting documentation we'd like the user to upload.",
   },
});

const state = () => ({
   currentCompany: null,
   companies: {},
   studies: {},
});

const getters = {
   currentCompany: (state) => state.currentCompany,
   companies: (state) => Object.values(state.companies),
   companyMap: (state) => state.companies,
   study: (state) => (state.currentCompany ? state.currentCompany.study : null),
   studies: (state) => Object.values(state.studies),
   studyMap: (state) => state.studies,

   studyYears: (state, getters) => {
      const study = getters['study'];
      if (study) {
         return range(study.years.lower, study.years.upper + 1);
      }
      return [];
   },

   prompts: (state) => state.currentCompany?.prompts,
};

const mutations = {
   setCurrentCompany(state, company) {
      state.currentCompany = company;
   },

   /** Clear the companies state object */
   clearCompanies(state) {
      state.companies = {};
   },

   /** Store an array of companies as an object keyed on the company ID */
   setCompanies(state, {companies}) {
      companies.forEach((company) => {
         Vue.set(state.companies, company.id, company);
      });
   },

   /** Remove a company from the companies state object */
   deleteCompany(state, {companyId}) {
      if (companyId in state.companies) {
         Vue.delete(state.companies, companyId);
      }
   },

   /** Update the study on the current company */
   setStudy(state, {study}) {
      Vue.set(state.currentCompany, 'study', study);
   },

   /** Update the supplies state on the current company */
   setSupplies(state, {supplies}) {
      Vue.set(state.currentCompany.study, 'supplies', supplies);
   },

   /** Update contractor state on the study */
   setContractors(state, {companies}) {
      Vue.set(state.currentCompany.study, 'contractors', companies);
   },

   /** Update contractor assignments on the study */
   setContractorAssignments(state, {assignments}) {
      Vue.set(state.currentCompany.study, 'contractorAssignments', assignments);
   },

   /** Update cloud computing state on the study */
   setCloudComputing(state, {cloudcomputing}) {
      Vue.set(state.currentCompany.study, 'cloudcomputing', cloudcomputing);
   },

   /** Update cloud computing state on the study */
   setCloudComputingAssignments(state, {assignments}) {
      Vue.set(state.currentCompany.study, 'cloudcomputingAssignments', assignments);
   },

   /** Store an array of past and present studies as an object keyed on the study id */
   setStudies(state, {studies}) {
      state.studies = {};
      studies.forEach((study) => {
         Vue.set(state.studies, study.id, study);
      });
   },

   /** Add a study to the list of past/present studies */
   addStudy(state, {study}) {
      Vue.set(state.studies, study.id, study);
   },
};

// Format a company recieved from the server
const formatFromServer = (company) => {
   // Format FEIN
   if (company.fein) {
      company.fein = `${company.fein.substring(0, 2)}-${company.fein.substring(2)}`;
   }

   // Format federal credit years
   if (!company.federalCreditYears.years) {
      company.federalCreditYears.years = [];
   }

   // Format state credit years
   let stateCreditYears = [];
   for (let s in company.stateCreditYears) {
      stateCreditYears.push({
         state: s.toUpperCase(),
         years: company.stateCreditYears[s].years,
      });
   }
   company.stateCreditYears = stateCreditYears;

   // Format date fields
   ['incorporated', 'filingDeadline', 'taxYearEnd'].forEach((key) => {
      if (company[key]) {
         let [year, month, day] = company[key].split('-');
         month = +month - 1; // convert to 0-based index
         company[key] = new Date(year, month, day);
      }
   });

   return company;
};

// Format a company to be sent to the server
const formatForServer = (company) => {
   // Format FEIN
   if (company.fein) {
      company.fein = company.fein.replace(/-/g, '');
   } else if ('' === company.fein) {
      company.fein = null;
   }

   // Format state credit years
   let stateCreditYears = {};

   company.stateCreditYears.forEach((scy) => {
      stateCreditYears[scy.state] = {years: scy.years};
   });
   company.stateCreditYears = stateCreditYears;

   // Format date fields
   ['incorporated', 'filingDeadline', 'taxYearEnd'].forEach((key) => {
      if (company[key] && typeof company[key] === 'object') {
         // convert to UTC so we can use toISOString without potentially rolling over a day
         const localDate = company[key];
         const offset = localDate.getTimezoneOffset() * 60 * 1000; // minutes -> milliseconds
         const utcDate = new Date(localDate.getTime() - offset);
         company[key] = utcDate.toISOString().split('T')[0];
      }
   });

   return company;
};

const actions = {
   /** Fetch all companies */
   async fetchCompanies() {
      const companies = (await this._vm.$http.get('/api/company')).data;
      return companies.map((company) => formatFromServer(company));
   },

   /** Load all companies */
   async loadCompanies({commit}) {
      const companies = (await this._vm.$http.get('/api/company')).data;
      const formattedCompanies = companies.map((company) => formatFromServer(company));
      commit('setCompanies', {companies: formattedCompanies});
   },

   /**
    * Fetch a company
    * @param {string} companyId - The ID of the company to fetch
    */
   async fetchCompany({}, {companyId}) {
      const company = (await this._vm.$http.get(`/api/company/${companyId}`)).data;
      return formatFromServer(company);
   },

   /** Load a company to the state's currentCompany */
   async loadCompany({state, commit}, {companyId, force = false, detail = false}) {
      // If we already have the company data, just return it unless force === true
      if (state.currentCompany && state.currentCompany.id == companyId && !force) {
         // Also check if we need to fetch additional details
         if (!detail || state.currentCompany.titles !== null) {
            return state.currentCompany;
         }
      }

      const params = {detail};

      const companyData = (await this._vm.$http.get(`/api/company/${companyId}`, {params})).data;
      const company = formatFromServer(companyData);

      commit('setCurrentCompany', company);
      if (company.study !== null) {
         commit('supplies/setAssignments', company.study.suppliesAssignments, {root: true});
      }
      return company;
   },

   /** Create a company */
   async createCompany({}, payload) {
      const company = formatForServer(payload);
      return await this._vm.$http.post('/api/company', company);
   },

   /** Edit a company */
   async updateCompany({commit}, {id, company}) {
      const formattedCompany = formatForServer(company);
      const companyData = (await this._vm.$http.put(`/api/company/${id}`, formattedCompany)).data;
      const updatedCompany = formatFromServer(companyData);
      commit('setCurrentCompany', updatedCompany);
      return updatedCompany;
   },

   /** Delete a company */
   async deleteCompany({commit}, {companyId, force = false}) {
      const params = {force};
      try {
         await this._vm.$http.delete(`/api/company/${companyId}`, {params});
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.CONFIRMATION_REQUIRED) {
            return err.response.data.errors[0].detail;
         }
         throw err;
      }
      commit('deleteCompany', {companyId});
   },

   /** Open a new study for a company */
   async openStudy({commit}, {companyId, years, description}) {
      const study = (
         await this._vm.$http.post(`/api/company/${companyId}/study`, {years, description})
      ).data;

      commit('setStudy', {study});
      commit('addStudy', {study});
   },

   /** Close the current study for the company */
   async closeStudy({commit}, {companyId}) {
      await this._vm.$http.delete(`/api/company/${companyId}/study`);
      commit('setStudy', {study: null});
   },

   /** Load all past and present studies for a company */
   async loadStudies({commit}, {companyId}) {
      const studies = (await this._vm.$http.get(`/api/company/${companyId}/study`)).data.results;
      commit('setStudies', {studies});
   },

   /** Assign a user to a role with a company */
   async addStaffAssignment({}, payload) {
      const companyId = payload.companyId;
      const userId = payload.userId;
      const role = payload.role;
      return await this._vm.$http.post(`/api/company/${companyId}/role/${userId}`, {role});
   },

   /** Unassign a user from a role with a company */
   async removeStaffAssignment({}, payload) {
      const companyId = payload.companyId;
      const userId = payload.userId;
      return await this._vm.$http.delete(`/api/company/${companyId}/role/${userId}`);
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
