<template>
   <b-container v-if="loaded">
      <b-card class="mb-4">
         <h2 class="text-secondary mb-3">Getting Started</h2>
         <p>
            We need some financial and project documentation to get started generating your maximum
            R&D tax credit.
         </p>
      </b-card>

      <b-card @drop.prevent @dragenter.prevent @dragover.prevent class="mb-4">
         <h3 class="text-secondary mb-3">Documents</h3>

         <p>
            You should have received an email from us detailing the documents we need to configure
            your company. If you have any questions, please feel free to contact us.
         </p>

         <UploadTable v-if="uploadCategories.length > 0" key="companyUploads" />

         <b-alert variant="primary" class="mb-0 mt-3" :show="uploadCategories.length === 0">
            You don't have any document upload categories assigned to you.
         </b-alert>
      </b-card>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import UploadTable from '@/views/uploads/widgets/UploadTable';

export default {
   components: {
      UploadTable,
   },

   data() {
      return {
         loaded: false,
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         userInfo: 'userInfo',
         uploadCategories: 'uploads/uploadCategories',
      }),

      // The company ID for the logged in user
      companyId() {
         return this.profile.companyId;
      },
   },

   async created() {
      let requests = [];

      // load upload categories
      requests.push(
         this.$store
            .dispatch('uploads/loadCompanyUploadCategories', {
               companyId: this.companyId,
               summary: true,
               force: true,
            })
            .catch((err) => {
               this.$store.commit('showAlert', {
                  response: err.response,
                  fallbackMsg: 'Failed to load file upload categories',
               });
            })
      );

      try {
         await this.blockUntilAllSettled(requests);
      } finally {
         this.loaded = true;
      }
   },
};
</script>
