import Vue from 'vue';
import {range} from 'lodash';

export const SidebarStates = Object.freeze({
   EXPANDED: 'EXPANDED',
   COLLAPSED: 'COLLAPSED',
   HIDDEN: 'HIDDEN',
});

export const UserGroups = Object.freeze({
   ADMIN: 'ADMIN',
   STAFF: 'STAFF',
   CUSTOMER: 'CUSTOMER',
   SME: 'SME',
});

const state = () => ({
   profile: null,
   userInfo: {
      mystaff: [],
      isTimesurveyConfigured: false,
      study: null,
   },
   breadCrumb: null,
   imgCacheKey: 0,
   sidebar: SidebarStates.EXPANDED,
   constants: {},
   minProgressInterval: 5000, // 5 seconds
   maxProgressInterval: 60000, // 60 seconds
   progressDebounceId: null,
   progressTimeoutId: null,
   wsReadyState: WebSocket.CONNECTING,
});

const getters = {
   /** The top level user profile */
   profile: (state) => state.profile,

   // Returns true if the logged in user is an admin user
   isAdmin: (state) => {
      return state.profile.groups.includes(UserGroups.ADMIN);
   },

   // Returns true if the logged in user is a staff user
   isStaff: (state) => {
      return state.profile.groups.includes(UserGroups.STAFF);
   },

   // Returns true if the logged in user is an admin or staff user
   isRndig: (state) => {
      return (
         state.profile.groups.includes(UserGroups.STAFF) ||
         state.profile.groups.includes(UserGroups.ADMIN)
      );
   },

   // Returns true if the logged in user is a customer user
   isCustomer: (state) => {
      return state.profile.groups.includes(UserGroups.CUSTOMER);
   },

   // Returns true if the logged in user is an SME user
   isSME: (state) => {
      return state.profile.groups.includes(UserGroups.SME);
   },

   // Returns true if the logged in user is a Customer or SME user
   isClient: (state) => {
      return (
         state.profile.groups.includes(UserGroups.CUSTOMER) ||
         state.profile.groups.includes(UserGroups.SME)
      );
   },

   /** A breadcrumb object, used to display a breadcrumb in the staff/admin UI */
   breadCrumb: (state) => state.breadCrumb,

   /** URL to the current user's profile image, returned as an object for use with v-auth-src. */
   profileImageUrl: (state) => {
      return {
         src: `/api/user/${state.profile.id}/profile/image?cache=${state.imgCacheKey}`,
         fallbackUrl: require('@/assets/img/avatar.svg'),
      };
   },

   /** Return this users userInfo. For client users, this contains info regarding the status of their company configuration */
   userInfo: (state) => state.userInfo,

   /** The current study object if it exists, else null */
   study: (state) => {
      return state.userInfo.study ? state.userInfo.study : null;
   },

   /** The current user's project manager */
   manager: (state) => {
      if (state.userInfo.mystaff.length > 0) {
         return state.userInfo.mystaff.sort((a, b) => {
            if (a.role === 'PM') {
               return -1;
            } else if (b.role === 'PM') {
               return 1;
            }
            return 0;
         })[0];
      }
      return null;
   },

   myStaff: (state) => {
      return state.userInfo.mystaff.reduce((obj, user) => {
         obj[user.id] = user;
         return obj;
      }, {});
   },

   /** The state in which to display the sidebar */
   sidebar: (state, getters) => {
      if (!getters.isAppConfigured) {
         return SidebarStates.HIDDEN;
      }
      return state.sidebar;
   },

   clientState: (state) => {
      return state.profile.profile.client.spa;
   },

   /** Has the current user clicked through the welcome message? */
   greeted: (state, getters) => {
      return getters['clientState'].greeted;
   },

   /** Is the app configured and ready for a customer user? */
   isAppConfigured: (state, getters) => {
      if (getters['isClient']) {
         const userInfo = getters['userInfo'];
         return (
            userInfo.mystaff.length > 0 &&
            userInfo.isTimesurveyConfigured &&
            userInfo.study !== null
         );
      }
      return true;
   },

   /** An array of years in the current study */
   studyYears: (state, getters) => {
      const userInfo = getters['userInfo'];
      if (userInfo.study) {
         return range(userInfo.study.lower, userInfo.study.upper + 1);
      }
      return [];
   },

   /** True when the About You modal hasn't been seen and closed in the current study */
   showAboutYouModal: (state, getters) => {
      const study = getters.userInfo.study;
      if (!study) {
         return false;
      }
      const encoded = `${study.lower}::${study.upper}`;
      return getters.clientState.seenProfileQuestionnaire !== encoded;
   },

   // CONSTANTS
   /** US State abbreviations */
   usStates: (state) => {
      const states = Object.entries({...state.constants.stateAbbrevs}).reduce((obj, entry) => {
         const [abrev, name] = entry;
         obj[abrev.toUpperCase()] = name;
         return obj;
      }, {});
      delete states.US;
      delete states.XX;
      return states;
   },

   /** US State abbreviations including 'Outside the US' */
   usStatesOutsideUS: (state) => {
      const states = Object.entries({...state.constants.stateAbbrevs}).reduce((obj, entry) => {
         const [abrev, name] = entry;
         obj[abrev.toUpperCase()] = name;
         return obj;
      }, {});
      delete states.US;
      return states;
   },

   wsOpen: (state) => WebSocket.OPEN === state.wsReadyState,
};

const mutations = {
   setProfile(state, profile) {
      const clientState = formatClientState(profile);
      profile.profile.client.spa = clientState;
      state.profile = profile;
   },

   setBreadcrumb(state, breadCrumb) {
      state.breadCrumb = breadCrumb;
   },

   /** Increment the cache key to force a refresh on the profile image */
   refreshProfileImage(state) {
      state.imgCacheKey++;
   },

   setUserInfo(state, {info}) {
      Object.assign(state.userInfo, info);
   },

   setGreeted(state, {value}) {
      state.profile.profile.client.spa.greeted = value;
   },

   setSeenProfileQuestionnaire(state) {
      const study = state.userInfo.study;
      const encoded = `${study.lower}::${study.upper}`;
      state.profile.profile.client.spa.seenProfileQuestionnaire = encoded;
   },

   setSidebar(state, {sidebar}) {
      state.sidebar = sidebar;
   },

   setSectionIntro(state, {section, value}) {
      state.profile.profile.client.spa.sectionIntros[section] = value;
   },

   setConstants(state, {constants}) {
      Vue.set(state, 'constants', constants);
   },

   setWsReadyState(state, ws) {
      state.wsReadyState = ws.readyState;
   },
};

const actions = {
   /** Fetch any data needed before the app loads */
   async fetchUserSession({commit, getters, dispatch}) {
      let requests = [];
      const id = this._vm.$keycloak.subject;

      let profile;
      try {
         profile = (await this._vm.$http.get(`/api/user/${id}`)).data;
         commit('setProfile', profile);
      } catch (err) {
         await this._vm.$bvModal.msgBoxOk(
            'We were unable to retrieve your user profile. You can log out and try again, or contact R&D Incentives Group for further assistance.',
            {
               title: 'Failed to retrieve user profile',
               centered: true,
               okTitle: 'Logout',
               noCloseOnEsc: true,
               noCloseOnBackdrop: true,
            }
         );
         this._vm.logout();
         return;
      }

      requests.push(dispatch('loadConstants'));

      if (profile.companyId) {
         requests.push(
            dispatch(
               'companies/loadCompany',
               {
                  companyId: profile.companyId,
                  force: false,
               },
               {root: true}
            )
         );

         requests.push(
            this._vm.$http.get(`/api/user/info`).then((response) => {
               const info = response.data;
               commit('setUserInfo', {info});
            })
         );
      }

      this._vm.$websocket.connect();

      try {
         await Promise.all(requests);
      } catch (err) {
         await this._vm.$bvModal.msgBoxOk(
            'We were unable to retrieve your user information. You can log out and try again, or contact R&D Incentives Group for further assistance.',
            {
               title: 'Failed to retrieve user information',
               centered: true,
               okTitle: 'Logout',
               noCloseOnEsc: true,
               noCloseOnBackdrop: true,
            }
         );
         this._vm.logout();
         return;
      }

      dispatch('messaging/launch', {}, {root: true});

      if (getters.isClient) {
         // Use `immediate = false` here because a route guard ensures that progress is loaded before
         // we reach the initial route. This avoids calling the progress API twice on app load.
         await dispatch('startProgressInterval', {companyId: profile.companyId, immediate: false});
      }
   },

   /** Start periodically fetching progress */
   startProgressInterval({state, getters, dispatch}, {companyId, immediate = true}) {
      if (getters.isAppConfigured && immediate) {
         if (state.progressDebounceId) {
            clearTimeout(state.progressDebounceId);
         }

         // Queue the progress fetch
         state.progressDebounceId = setTimeout(() => {
            this._vm.$websocket.progress();
         }, state.minProgressInterval);
      }

      if (state.progressTimeoutId) {
         // Restart the progress interval if one already exists
         clearTimeout(state.progressTimeoutId);
      }

      state.progressTimeoutId = setTimeout(() => {
         dispatch('startProgressInterval', {companyId});
      }, state.maxProgressInterval);
   },

   /** Load constants defined on the server */
   async loadConstants({commit}) {
      const response = await this._vm.$http.get('/api/constants');
      commit('setConstants', {constants: response.data});
   },

   /** Store the fact that the user has walked through the 'get started' section of the app */
   async returnGreeting({state, commit, dispatch}, {value = true}) {
      const id = this._vm.$keycloak.subject;
      commit('setGreeted', {value});
      await dispatch('users/updateProfile', {id, profile: state.profile}, {root: true});
   },

   /** Store the fact that the user has seen the profile questionnaire for this study */
   async seenProfileQuestionnaire({state, commit, dispatch}) {
      commit('setSeenProfileQuestionnaire');
      await dispatch(
         'users/updateProfile',
         {id: state.profile.id, profile: state.profile},
         {root: true}
      );
   },

   /** Store the fact that the user has seen and dismissed the intro to a section of the app */
   async setSectionIntro({state, commit, dispatch}, {section, value}) {
      commit('setSectionIntro', {section, value});
      await dispatch('users/updateProfile', {id: state.profile.id, profile: state.profile});
   },

   /** Reset the client state variable (ie. flags for section intros) */
   async resetClientState({state, dispatch}, {greeted = true}) {
      let profile = state.profile;
      Object.assign(profile.profile.client.spa, defaultClientState());
      profile.profile.client.spa.greeted = greeted;
      await dispatch('users/updateProfile', {id: state.profile.id, profile: state.profile});
   },

   /** Update the interval between fetching progress and restart the setInterval */
   async setProgressInterval({state, dispatch}, interval) {
      state.maxProgressInterval = interval;
      if (state.profile.companyId) {
         dispatch('startProgressInterval', {companyId: state.profile.companyId});
      }
   },
};

const clientStateVersion = 2;

const defaultClientState = () => ({
   version: clientStateVersion,
   greeted: false,
   seenProfileQuestionnaire: null,
   sectionIntros: {
      timeSurvey: false,
      cloud: false,
      company: false,
      project: false,
      uploads: false,
      contractors: false,
      supplies: false,
   },
});

const ClientStateUpgrades = {
   1: (clientState) => {
      clientState.sectionIntros.contractors = false;
      clientState.sectionIntros.supplies = false;
      clientState.version = 2;
      return clientState;
   },
};

/**
 * Ensure the users client state object is properly formatted.
 * @param {Object} profile - A user profile object
 */
const formatClientState = (profile) => {
   let clientState = {...profile.profile.client.spa};

   if (!Object.prototype.hasOwnProperty.call(clientState, 'version')) {
      clientState = Object.assign(defaultClientState(), clientState);
   } else if (clientState.version < clientStateVersion) {
      while (clientState.version !== clientStateVersion) {
         clientState = ClientStateUpgrades[clientState.version](clientState);
      }
   }

   return clientState;
};

export default {
   state,
   getters,
   mutations,
   actions,
};
