/* eslint-disable no-empty-pattern */
import Vue from 'vue';
import ErrorCodes from '@/helpers/errorCodes';
import {sortBy} from '../../helpers/utils';

const state = () => ({
   projects: {},
});

const getters = {
   projects: (state) => Object.values(state.projects).sort(sortBy('name')),

   projectMap: (state) => state.projects,

   /** Projects that overlap the current study */
   projectsInStudy: (state, getters, rootState, rootGetters) => {
      const study = rootGetters['companies/study'];
      if (study === null) {
         return [];
      }
      return getters.projects.filter((project) => {
         const startsBeforeStudyEnds = project.years.lower <= study.years.upper;
         const endsAfterStudyStarts = project.years.upper
            ? project.years.upper >= study.years.lower
            : true;
         return startsBeforeStudyEnds && endsAfterStudyStarts;
      });
   },

   /** Returns a method that returns projects that overlap a given year */
   projectsInYear: (state, getters) => {
      return (year) => {
         return getters.projects.filter((project) => {
            return (
               project.years.lower <= year &&
               (project.years.upper === null || project.years.upper >= year)
            );
         });
      };
   },

   // Years with valid time surveys, based on project year ranges and company.taxYearEnd
   timeSurveyYears: (state, getters, rootState, rootGetters) => {
      let seen = {};
      const currentYear = new Date().getFullYear();

      Object.values(state.projects).forEach((project) => {
         const start = project.years.lower;
         let end = project.years.upper;
         if (!end) {
            end = currentYear;
         }

         for (let i = start; i <= end; i++) {
            seen[i] = true;
         }
      });

      let years = Object.keys(seen).map((year) => +year);

      const now = new Date();
      const company = rootGetters['companies/currentCompany'];

      // Encode dates as integers MMDD for easy comparison
      const today = now.getMonth() * 100 + now.getDate();
      // Default to 12/31, override by company.taxYearEnd if set
      let taxYearEnd = 1231;
      if (company && company.taxYearEnd) {
         taxYearEnd = company.taxYearEnd.getMonth() * 100 + company.taxYearEnd.getDate();
      }
      let maxYear = currentYear - 1;
      if (today > taxYearEnd) {
         maxYear++;
      }
      years = years.filter((year) => +year <= maxYear);

      return years;
   },
};

const mutations = {
   // Clear the projects state
   clear: (state) => {
      state.projects = {};
   },

   // Set the projects in the state
   setProjects: (state, {projects}) => {
      projects.forEach((project) => {
         Vue.set(state.projects, project.id, project);
      });
   },

   // Update or add a project
   updateProject: (state, {project}) => {
      Vue.set(state.projects, project.id, project);
   },

   deleteProject: (state, {projectId}) => {
      if (projectId in state.projects) {
         Vue.delete(state.projects, projectId);
      }
   },
};

const actions = {
   // Create a project
   async createProject({commit}, {project, companyId}) {
      let newProject;
      try {
         newProject = (await this._vm.$http.post('/api/project', {...project, companyId})).data;
      } catch (err) {
         const code = err.response.data.errors[0].code;
         if (code === 120) {
            const force = await this._vm.$bvModal.msgBoxConfirm(
               'Creating this project would invalidate a time survey that has already been submitted. Would you like to proceed anyways?',
               {
                  centered: true,
               }
            );
            if (force) {
               newProject = (
                  await this._vm.$http.post(
                     '/api/project',
                     {...project, companyId},
                     {params: {force}}
                  )
               ).data;
            } else {
               throw err;
            }
         } else {
            throw err;
         }
      }
      commit('updateProject', {project: newProject});
   },

   // Edit a project
   async editProject({commit}, {project}) {
      let newProject;
      try {
         newProject = (await this._vm.$http.put(`/api/project/${project.id}`, project)).data;
      } catch (err) {
         const code = err.response.data.errors[0].code;
         if (code === 120) {
            const force = await this._vm.$bvModal.msgBoxConfirm(
               'Updating this project would invalidate a time survey that has already been submitted. Would you like to proceed anyways?',
               {
                  centered: true,
               }
            );
            if (force) {
               newProject = (await this._vm.$http.put(`/api/project/${project.id}`, project),
               {params: {force}}).data;
            } else {
               throw err;
            }
         } else {
            throw err;
         }
      }
      commit('updateProject', {project: newProject});
   },

   // fetch and return a project
   async fetchProject({}, {projectId}) {
      const response = await this._vm.$http.get(`/api/project/${projectId}`);
      return response.data;
   },

   /** Load a single project */
   async loadProject({dispatch, commit}, {projectId}) {
      const project = await dispatch('fetchProject', {projectId});
      commit('setProjects', {projects: [project]});
   },

   // fetch and return all projects for a company
   async fetchProjectsForCompany({}, {companyId}) {
      return (await this._vm.$http.get(`/api/company/${companyId}/project`)).data.results;
   },

   // Load all projects for a company
   async loadProjects({commit, dispatch, getters}, {companyId}) {
      const projects = await dispatch('fetchProjectsForCompany', {companyId});
      commit('clear');
      commit('setProjects', {projects});
      return getters.projects;
   },

   /** Delete a project */
   async deleteProject({commit}, {projectId, force = false}) {
      const params = {force};
      try {
         await this._vm.$http.delete(`/api/project/${projectId}`, {params});
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.CONFIRMATION_REQUIRED) {
            return err.response.data.errors[0].detail;
         }
         throw err;
      }
      commit('deleteProject', {projectId});
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
