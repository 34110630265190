<template>
   <b-container class="pt-4">
      <h1 class="mb-4">Administration</h1>

      <b-card class="mb-4">
         <div class="d-flex align-items-center justify-content-between mb-3">
            <h3 class="text-secondary mb-0">Companies</h3>
            <b-button id="btn-add-company" variant="primary" :to="{name: 'company-create'}">
               Add Company
            </b-button>
         </div>
         <b-table
            id="table-companies"
            :items="companies"
            :fields="[
               {key: 'companyName', sortable: true},
               {key: 'created', sortable: true, formatter: (val) => new Date(val).toLocaleString()},
               {key: 'actions', label: ''},
            ]"
            sort-by="companyName"
            sort-icon-left
            class="mb-0"
            responsive
            sticky-header="500px"
         >
            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button
                     :id="`btn-staff-company-${data.index}`"
                     size="sm"
                     class="mr-2"
                     :to="{name: 'company-detail', params: {id: data.item.id}}"
                  >
                     Staff View
                  </b-button>
                  <b-button
                     :id="`btn-manage-company-${data.index}`"
                     size="sm"
                     :to="{name: 'admin-company', params: {id: data.item.id}}"
                  >
                     Manage
                  </b-button>
               </div>
            </template>
         </b-table>
      </b-card>

      <b-card class="mb-4">
         <h3 class="text-secondary mb-3">App Configuration</h3>
         <div class="d-flex align-items-center justify-content-between mb-2">
            <b>Industries</b>
            <b-button
               id="btn-configure-industries"
               variant="secondary"
               size="sm"
               :to="{name: 'admin-industries'}"
            >
               Configure
            </b-button>
         </div>
         <div class="d-flex align-items-center justify-content-between mb-2">
            <b>Questionnaire Templates</b>
            <b-button variant="secondary" size="sm" :to="{name: 'qtemplates'}">
               Configure
            </b-button>
         </div>
      </b-card>

      <!-- ADMIN USERS -->
      <b-card class="mb-4">
         <div class="d-flex align-items-center justify-content-between mb-3">
            <h3 class="text-secondary mb-0">Admin Users</h3>
            <b-button id="btn-add-admin" variant="primary" :to="{name: 'user-create-admin'}">
               Add User
            </b-button>
         </div>
         <b-table
            id="table-user-admin"
            class="mb-0"
            :fields="rdigUserFields"
            :items="adminUsers"
            :tbody-tr-class="highlightDisabledRows"
            sort-by="firstName"
            sort-icon-left
            responsive
            sticky-header="500px"
         >
            <template #cell(enabled)="data">
               <b-icon v-if="!data.value" icon="x-circle"></b-icon>
            </template>
            <template #cell(email)="data"> {{ clarifyEmail(data.item) }} </template>
            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button
                     :id="`btn-edit-user-admin-${data.index}`"
                     :to="{name: 'user-edit', params: {id: data.item.id}}"
                     size="sm"
                  >
                     Edit
                  </b-button>
                  <b-button
                     v-if="data.item.enabled"
                     :id="`btn-disable-user-admin-${data.index}`"
                     class="ml-2"
                     size="sm"
                     variant="danger"
                     @click="disableUser(data.item)"
                     :disabled="data.item.id === profile.id"
                     v-b-tooltip.hover
                     :title="data.item.id === profile.id ? 'You cannot disable yourself' : ''"
                  >
                     Disable
                  </b-button>
                  <b-button
                     class="ml-2"
                     v-else
                     :id="`btn-enable-user-admin-${data.index}`"
                     size="sm"
                     variant="success"
                     @click="enableUser(data.item)"
                  >
                     Enable
                  </b-button>
               </div>
            </template>
         </b-table>
      </b-card>

      <!-- STAFF USERS -->
      <b-card class="mb-4">
         <div class="d-flex align-items-center justify-content-between mb-3">
            <h3 class="text-secondary mb-0">Staff Users</h3>
            <b-button id="btn-add-staff" variant="primary" :to="{name: 'user-create-staff'}">
               Add User
            </b-button>
         </div>
         <b-table
            id="table-user-staff"
            class="mb-0"
            :fields="rdigUserFields"
            :items="staffUsers"
            :tbody-tr-class="highlightDisabledRows"
            sort-by="firstName"
            sort-icon-left
            responsive
            sticky-header="500px"
         >
            <template #cell(enabled)="data">
               <b-icon v-if="!data.value" icon="x-circle"></b-icon>
            </template>
            <template #cell(email)="data"> {{ clarifyEmail(data.item) }} </template>
            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button
                     :id="`btn-edit-user-staff-${data.index}`"
                     :to="{name: 'user-edit', params: {id: data.item.id}}"
                     size="sm"
                  >
                     Edit
                  </b-button>
                  <b-button
                     v-if="data.item.enabled"
                     :id="`btn-disable-user-staff-${data.index}`"
                     class="ml-2"
                     size="sm"
                     variant="danger"
                     @click="disableUser(data.item)"
                  >
                     Disable
                  </b-button>
                  <b-button
                     class="ml-2"
                     v-else
                     :id="`btn-enable-user-staff-${data.index}`"
                     size="sm"
                     variant="success"
                     @click="enableUser(data.item)"
                  >
                     Enable
                  </b-button>
               </div>
            </template>
         </b-table>
      </b-card>

      <!-- CUSTOMER USERS -->
      <b-card class="mb-4">
         <div class="d-flex align-items-center justify-content-between mb-3">
            <h3 class="text-secondary mb-0">Customer Users</h3>
            <b-button id="btn-add-customer" variant="primary" :to="{name: 'user-create-customer'}">
               Add User
            </b-button>
         </div>
         <b-table
            id="table-user-customer"
            class="mb-0"
            :fields="clientUserFields"
            :items="customerUsers"
            :tbody-tr-class="highlightDisabledRows"
            sort-by="firstName"
            sort-icon-left
            responsive
            sticky-header="500px"
         >
            <template #cell(enabled)="data">
               <b-icon v-if="!data.value" icon="x-circle"></b-icon>
            </template>
            <template #cell(email)="data"> {{ clarifyEmail(data.item) }}</template>
            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button
                     :id="`btn-edit-user-customer-${data.index}`"
                     :to="{name: 'user-edit', params: {id: data.item.id}}"
                     size="sm"
                  >
                     Edit
                  </b-button>
                  <b-button
                     v-if="data.item.enabled"
                     :id="`btn-disable-user-customer-${data.index}`"
                     class="ml-2"
                     size="sm"
                     variant="danger"
                     @click="disableUser(data.item)"
                  >
                     Disable
                  </b-button>
                  <b-button
                     class="ml-2"
                     v-else
                     :id="`btn-enable-user-customer-${data.index}`"
                     size="sm"
                     variant="success"
                     @click="enableUser(data.item)"
                  >
                     Enable
                  </b-button>
               </div>
            </template>
            <template #cell(companyId)="data">
               <span :class="[data.value in companyMap ? '' : 'text-danger']">
                  {{ data.value in companyMap ? companyMap[data.value].companyName : 'Deleted' }}
               </span>
            </template>
         </b-table>
      </b-card>

      <!-- SMEs -->
      <b-card class="mb-4">
         <div class="d-flex align-items-center justify-content-between mb-3">
            <h3 class="text-secondary mb-0">SME Users</h3>
            <b-button id="btn-add-sme" variant="primary" :to="{name: 'user-create-sme'}">
               Add User
            </b-button>
         </div>
         <b-table
            id="table-user-sme"
            class="mb-0"
            :fields="clientUserFields"
            :items="smeUsers"
            :tbody-tr-class="highlightDisabledRows"
            sort-by="firstName"
            sort-icon-left
            responsive
            sticky-header="500px"
         >
            <template #cell(enabled)="data">
               <b-icon v-if="!data.value" icon="x-circle"></b-icon>
            </template>
            <template #cell(email)="data"> {{ clarifyEmail(data.item) }} </template>

            <template #cell(actions)="data">
               <div class="d-flex align-items-center justify-content-end cell-w-buttons">
                  <b-button
                     :id="`btn-edit-user-sme-${data.index}`"
                     :to="{name: 'user-edit', params: {id: data.item.id}}"
                     size="sm"
                  >
                     Edit
                  </b-button>
                  <b-button
                     v-if="data.item.enabled"
                     :id="`btn-disable-user-sme-${data.index}`"
                     class="ml-2"
                     size="sm"
                     variant="danger"
                     @click="disableUser(data.item)"
                  >
                     Disable
                  </b-button>
                  <b-button
                     class="ml-2"
                     v-else
                     :id="`btn-enable-user-sme-${data.index}`"
                     size="sm"
                     variant="success"
                     @click="enableUser(data.item)"
                  >
                     Enable
                  </b-button>
               </div>
            </template>
            <template #cell(companyId)="data">
               <span :class="[data.value in companyMap ? '' : 'text-danger']">
                  {{ data.value in companyMap ? companyMap[data.value].companyName : 'Deleted' }}
               </span>
            </template>
         </b-table>
      </b-card>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import ErrorCodes from '@/helpers/errorCodes';
import {unmungeEmail} from '@/helpers/utils';

export default {
   data() {
      return {
         rdigUserFields: [
            {key: 'enabled', label: '', sortable: false, thStyle: {width: '3em'}},
            {key: 'firstName', sortable: true},
            {key: 'lastName', sortable: true},
            {key: 'email', sortable: true},
            {key: 'actions', label: ''},
         ],
         clientUserFields: [
            {key: 'enabled', label: '', sortable: false, thStyle: {width: '3em'}},
            {key: 'firstName', sortable: true},
            {key: 'lastName', sortable: true},
            {key: 'email', sortable: true},
            {key: 'title', label: 'Job Title', sortable: true},
            {key: 'department', sortable: true},
            {key: 'companyId', label: 'Company', sortable: true},
            {key: 'subsidiary', sortable: true},
            {key: 'actions', label: ''},
         ],
         clarifyEmail: ({enabled, email}) => (enabled ? email : unmungeEmail(email)),
         highlightDisabledRows: (item, type) => ({
            'disabled-user': type === 'row' && item?.enabled === false,
         }),
      };
   },

   computed: {
      ...mapGetters({
         companies: 'companies/companies',
         companyMap: 'companies/companyMap',
         adminUsers: 'users/adminUsers',
         staffUsers: 'users/staffUsers',
         customerUsers: 'users/customerUsers',
         smeUsers: 'users/smeUsers',
         users: 'users/users',
         userMap: 'users/userMap',
         profile: 'profile',
      }),
   },

   methods: {
      async disableUser({id}) {
         let proceed = await this.$bvModal.msgBoxConfirm(
            'Are you sure you want to disable this user?',
            {
               centered: true,
               title: 'Disable this user?',
            }
         );

         if (!proceed) return;

         const potentiallyUnconfigured = [];
         for (const company of this.companies) {
            const companyStaff = [
               ...company.businessManagers,
               ...company.projectAssociates,
               ...company.projectManagers,
            ];

            if (
               companyStaff.includes(id) &&
               companyStaff.every((s) => s === id || !this.userMap[s].enabled)
            ) {
               potentiallyUnconfigured.push(company.companyName);
            }
         }

         const companyName = potentiallyUnconfigured.shift();
         if (companyName) {
            const extraCount = potentiallyUnconfigured.length,
               longDesc = `${companyName} ${
                  extraCount === 0
                     ? ''
                     : `and ${extraCount} other compan${extraCount === 1 ? 'y' : 'ies'}`
               }`,
               shortDesc = extraCount === 0 ? companyName : 'these companies';

            const h = this.$createElement,
               msgVNodes = h('div', [
                  `Disabling this user will leave `,
                  h('b', [longDesc]),
                  ` with no Staff assigned. Users at ${shortDesc} will be unable to enter study data until a Staff user is assigned again. You should ensure there is another Staff member assigned to ${shortDesc} before doing this. Are you sure you want to disable this Staff user and thereby put ${shortDesc} in an unconfigured state?`,
               ]);

            proceed = await this.$bvModal.msgBoxConfirm(msgVNodes, {
               centered: true,
               title: 'Warning',
               okVariant: 'danger',
               okTitle: 'Disable',
               cancelVariant: 'secondary',
            });

            if (!proceed) return;
         }

         if (proceed) {
            await this.$store.dispatch('users/disableUser', {
               id,
            });
         }
      },

      async enableUser({id, ...user}) {
         try {
            await this.$store.dispatch('users/enableUser', {
               id,
            });
         } catch (err) {
            const errCode = err.response.data.errors[0].code;
            if (errCode === ErrorCodes.CONFLICTING_EMAIL) {
               const fullName = `${user.firstName} ${user.lastName}`,
                  conflictEmail = unmungeEmail(user.email),
                  otherUser = this.users.find((u) => u.email === conflictEmail);

               await this.$bvModal.msgBoxOk(
                  `Cannot enable user ${fullName} <${conflictEmail}> because that email address is in-use by ` +
                     (otherUser == null
                        ? 'another user'
                        : `user ${otherUser.firstName} ${otherUser.lastName}`) +
                     `. Disable the account using this email address, or create a new account for ${fullName} with a unique email.`
               );
            } else {
               throw err;
            }
         }
      },
   },

   async created() {
      let requests = [];
      requests.push(this.$store.dispatch('companies/loadCompanies'));
      requests.push(this.$store.dispatch('users/loadUsers'));
      requests.push(this.$store.dispatch('companies/loadCompanies'));

      await this.blockUntilAllSettled(requests);
   },
};
</script>

<style lang="scss" scoped>
::v-deep tr.disabled-user {
   color: $danger;
}
</style>
