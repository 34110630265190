import {Field} from './field';

export class VendorData {
   constructor({
      id = null,
      year,
      account = null,
      vendor,
      location = null,
      totalDebits = null,
      totalCredits = null,
      netAmount = null,
      percentage = null,
   }) {
      this.id = id;
      this.year = year;
      this.account = account;
      this.vendor = vendor;
      this.location = location;
      this.totalDebits = totalDebits;
      this.totalCredits = totalCredits;
      this.netAmount = new Field({value: netAmount});
      this.percentage = new Field({value: percentage});
   }

   get name() {
      return this.vendor;
   }

   set name(val) {
      this.vendor = val;
   }
}
