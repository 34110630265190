<template>
   <div>
      <b-row class="mb-3">
         <b-col>
            <b-input-group>
               <b-form-input id="input-search" v-model="filterArgs.search"></b-form-input>

               <b-input-group-append>
                  <b-input-group-text>
                     <b-icon-search />
                  </b-input-group-text>
               </b-input-group-append>
            </b-input-group>
         </b-col>
         <b-col>
            <b-form-select
               id="select-industry"
               v-model="filterArgs.industry"
               :options="industryOptions"
            >
               <template #first>
                  <option :value="null">Filter by industry</option>
               </template>
            </b-form-select>
         </b-col>
         <b-col>
            <b-form-select
               id="select-qtype"
               v-model="filterArgs.qtype"
               :options="templateTypeOptions"
            >
               <template #first>
                  <option :value="null">Filter by type</option>
               </template>
            </b-form-select>
         </b-col>
      </b-row>

      <b-table
         id="table-qtemplates"
         :fields="fields"
         sort-by="name"
         :items="templates"
         :filter="filterArgs"
         :filter-function="filter"
         small
      >
         <template #head(qtype)="data">
            <div style="min-width: 7rem">
               {{ data.label }}
            </div>
         </template>

         <template #cell(name)="data">
            <b>{{ data.value }}</b>
         </template>

         <template #cell(industries)="data">
            <b-badge
               v-for="(industryId, index) in data.value"
               :id="`tag-${data.index + 1}-${index + 1}`"
               pill
               variant="secondary"
               :key="industryId"
               class="ml-1"
               @click="filterArgs.industry = industryId"
               href="#"
            >
               {{ industryMap[industryId].name }}
            </b-badge>
         </template>

         <template #cell(actions)="data">
            <slot name="actions" :templateId="data.item.id">
               <div class="d-flex align-items-center justify-content-end">
                  <button
                     :id="`btn-edit-${data.index + 1}`"
                     class="icon-btn icon-btn-primary mr-2"
                     type="button"
                     @click="
                        $router.push({name: 'qtemplate-edit', params: {templateId: data.item.id}})
                     "
                  >
                     <b-icon-pencil-square />
                  </button>

                  <button
                     v-if="isAdmin"
                     :id="`btn-delete-${data.index + 1}`"
                     class="icon-btn icon-btn-danger"
                     type="button"
                     @click="deleteTemplate(data.item.id)"
                  >
                     <b-icon-trash></b-icon-trash>
                  </button>
               </div>
            </slot>
         </template>
      </b-table>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   props: {
      defaultFilters: {
         type: Object,
         default: null,
      },
   },

   data() {
      return {
         loaded: false,
         fields: [
            {key: 'name', sortable: true},
            {key: 'industries'},
            {key: 'qtype', label: 'Type', sortable: true},
            {key: 'actions', label: ''},
         ],
         templateTypeOptions: [
            {value: 'COMPANY', text: 'Company'},
            {value: 'PROJECT', text: 'Project'},
         ],
         filterArgs: {
            search: '',
            industry: null,
            qtype: null,
         },
      };
   },

   computed: {
      ...mapGetters({
         isAdmin: 'isAdmin',
         templates: 'questionnaire/templates',
         industryMap: 'industries/industryMap',
         industries: 'industries/industries',
      }),

      industryOptions() {
         return this.industries.map((industry) => {
            return {text: industry.name, value: industry.id};
         });
      },
   },

   methods: {
      filter(template) {
         let include = true;
         if (this.filterArgs.search) {
            include =
               include &&
               template.name.toLowerCase().includes(this.filterArgs.search.toLowerCase());
         }
         if (this.filterArgs.industry) {
            include = include && template.industries.includes(this.filterArgs.industry);
         }
         if (this.filterArgs.qtype) {
            include = include && template.qtype === this.filterArgs.qtype;
         }
         return include;
      },

      async deleteTemplate(templateId) {
         const proceed = await this.$bvModal.msgBoxConfirm(
            `Are you sure you want to delete this template?`,
            {
               id: 'modal-delete-template',
               title: 'Delete this template?',
               centered: true,
            }
         );
         if (proceed) {
            await this.blockingRequest('questionnaire/deleteTemplate', {templateId, force: true});
         }
      },
   },

   mounted() {
      if (this.defaultFilters !== null) {
         Object.assign(this.filterArgs, this.defaultFilters);
      }
   },
};
</script>
