<template>
   <div class="wrapper">
      <div class="d-flex align-items-center justify-content-between">
         <h1 class="text-dark-gray mb-2">
            <slot>{{ title }}</slot>
         </h1>
      </div>
      <hr class="my-0" />
   </div>
</template>

<script>
export default {
   props: {
      title: {
         type: String,
      },
   },
};
</script>

<style lang="scss" scoped>
.wrapper {
   margin-bottom: 2rem;
}
</style>
