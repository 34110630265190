<template>
   <b-container fluid>
      <b-row>
         <b-col class="d-flex justify-content-between align-items-center">
            <h1 class="mb-3">Employees</h1>
            <!-- This NEEDS to be v-show (not v-if) for the initial @input to be triggered -->
            <YearSelect index="main" study v-model="year" @input="loadEmployees" v-show="year" />
         </b-col>
      </b-row>

      <b-alert :show="!year" variant="primary" class="standard-width mx-auto">
         <h6 class="mb-3">Select a year to get started.</h6>
         <YearSelect index="initial" study v-model="year" emptyText="Select a year" />
      </b-alert>

      <b-row v-if="year">
         <b-col cols="4">
            <b-card>
               <div class="d-flex align-items-center justify-content-between">
                  <h2 class="mb-3">New Employee</h2>
                  <b-button
                     id="btn-import-employees"
                     size="sm"
                     variant="primary"
                     v-b-modal="'modal-import-employees'"
                  >
                     Import
                  </b-button>
               </div>
               <b-form @submit.prevent="submitNewEmployee">
                  <b-form-group
                     label="Full Name"
                     label-for="input-new-full-name"
                     invalid-feedback="Required"
                  >
                     <b-form-input
                        id="input-new-full-name"
                        v-model="newEmployee.fullname"
                        :state="
                           $v.newEmployee.fullname.$invalid
                              ? !$v.newEmployee.fullname.$invalid
                              : null
                        "
                        ref="newEmployeeName"
                     ></b-form-input>
                  </b-form-group>

                  <b-form-group
                     label="Location"
                     label-for="input-new-location"
                     invalid-feedback="Required"
                  >
                     <b-form-select
                        :id="'input-new-location'"
                        :options="stateOptions"
                        v-model="newEmployee.location"
                        :state="
                           $v.newEmployee.location.$invalid
                              ? !$v.newEmployee.location.$invalid
                              : null
                        "
                     >
                        <template #first>
                           <b-form-select-option :value="null" disabled>
                              Please select one
                           </b-form-select-option>
                        </template>
                     </b-form-select>
                  </b-form-group>

                  <b-form-group
                     label="Employee ID"
                     label-for="input-new-employee-id"
                     invalid-feedback="Required"
                  >
                     <b-form-input
                        id="input-new-employee-id"
                        v-model="newEmployee.ident"
                     ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Job Title" label-for="input-new-job-title">
                     <b-form-input
                        id="input-new-job-title"
                        v-model="newEmployee.title"
                        list="datalist-titles"
                        autocomplete="off"
                     ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Department" label-for="input-new-department">
                     <b-form-input
                        id="input-new-department"
                        v-model="newEmployee.department"
                        list="datalist-departments"
                        autocomplete="off"
                     ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Subsidiary Company Name" label-for="input-new-subsidiary">
                     <b-form-input
                        id="input-new-subsidiary"
                        v-model="newEmployee.subsidiary"
                        list="datalist-subsidiaries"
                        autocomplete="off"
                     ></b-form-input>
                  </b-form-group>

                  <b-form-row>
                     <b-col class="d-flex justify-content-end">
                        <b-button
                           id="btn-add-employee"
                           class="d-flex align-items-center"
                           variant="primary"
                           type="submit"
                           :disabled="$v.newEmployee.$invalid || newEmployee.saving"
                        >
                           <b-spinner small v-if="newEmployee.saving" class="mr-1"></b-spinner>
                           Add
                        </b-button>
                     </b-col>
                  </b-form-row>
               </b-form>
            </b-card>
         </b-col>
         <b-col cols="8">
            <b-card>
               <div v-if="isStaff" class="d-flex align-items-center justify-content-between mb-3">
                  <button
                     class="icon-btn icon-btn-secondary"
                     v-b-tooltip="anyEmployeesSelected ? 'Unselect All' : 'Select All'"
                     @click="selectAll(!anyEmployeesSelected)"
                  >
                     <b-icon :icon="anyEmployeesSelected ? 'dash-square' : 'plus-square'" />
                  </button>
                  <b-button
                     id="btn-delete-selected"
                     variant="danger"
                     :disabled="!anyEmployeesSelected"
                     @click="deleteEmployeesModal"
                  >
                     Delete Selected
                  </b-button>
               </div>

               <b-table
                  id="table-edit-employees"
                  class="mb-0 scrollbar"
                  :fields="fields"
                  sort-by="fullname"
                  :items="employees"
                  responsive
                  sticky-header="577px"
               >
                  <template #cell(fullname)="data">
                     <div class="d-flex align-items-center">
                        <b-form-checkbox
                           v-if="isStaff"
                           v-model="selection[data.item.id]"
                           :value="true"
                           size="lg"
                        ></b-form-checkbox>
                        <button
                           :id="`btn-edit-employee-${data.index + 1}`"
                           class="icon-btn icon-btn-secondary mr-3"
                           @click="editEmployeeModal(data.item)"
                           v-b-tooltip="'Edit Employee'"
                        >
                           <b-icon-list-task />
                        </button>
                        {{ data.value }}
                     </div>
                  </template>
               </b-table>
            </b-card>
         </b-col>
      </b-row>

      <b-modal
         id="modal-edit-employee"
         title="Edit Employee"
         @ok="submitEditEmployee"
         :ok-disabled="$v.editEmployee.$invalid"
         ok-title="Submit"
      >
         <b-form @submit.prevent="submitEditEmployee">
            <b-form-group
               label="Full Name"
               label-for="input-edit-full-name"
               invalid-feedback="Required"
            >
               <b-form-input
                  id="input-edit-full-name"
                  v-model="editEmployee.fullname"
                  :state="!$v.editEmployee.fullname.$invalid"
               ></b-form-input>
            </b-form-group>

            <b-form-group
               label="Location"
               label-for="input-edit-location"
               invalid-feedback="Required"
            >
               <b-form-select
                  :id="'input-edit-location'"
                  :options="stateOptions"
                  v-model="editEmployee.location"
                  :state="!$v.editEmployee.location.$invalid"
               >
                  <template #first>
                     <b-form-select-option :value="null" disabled>
                        Please select one
                     </b-form-select-option>
                  </template>
               </b-form-select>
            </b-form-group>

            <b-form-group
               label="Employee ID"
               label-for="input-edit-employee-id"
               invalid-feedback="Required"
            >
               <b-form-input
                  id="input-edit-employee-id"
                  v-model="editEmployee.ident"
               ></b-form-input>
            </b-form-group>

            <b-form-group label="Job Title" label-for="input-edit-job-title">
               <b-form-input
                  id="input-edit-job-title"
                  v-model="editEmployee.title"
                  list="datalist-titles"
                  autocomplete="off"
               ></b-form-input>
            </b-form-group>

            <b-form-group label="Department" label-for="input-edit-department">
               <b-form-input
                  id="input-edit-department"
                  v-model="editEmployee.department"
                  list="datalist-departments"
                  autocomplete="off"
               ></b-form-input>
            </b-form-group>

            <b-form-group label="Subsidiary Company Name" label-for="input-edit-subsidiary">
               <b-form-input
                  id="input-edit-subsidiary"
                  v-model="editEmployee.subsidiary"
                  list="datalist-subsidiaries"
                  autocomplete="off"
               ></b-form-input>
            </b-form-group>
         </b-form>
      </b-modal>

      <b-modal
         id="modal-import-employees"
         :title="`Import ${year} Employee CSV`"
         size="lg"
         ok-title="Submit"
         :cancel-disabled="uploads.uploading"
         :no-close-on-esc="uploads.uploading"
         :no-close-on-backdrop="uploads.uploading"
         :hide-header-close="uploads.uploading"
         :ok-disabled="$v.uploads.$invalid || uploads.uploading"
         @ok="importEmployeeCsv"
      >
         <p>Select a file to import employees from.</p>

         <p>The selected file must be formatted as CSV with the following fields:</p>

         <b-alert :show="true" class="code-box text-monospace">
            full name, U.S. state, employee ID, title, department, subsidiary company name
         </b-alert>

         <p class="mb-3">
            The <b>full name</b> and <b>U.S. state</b> fields are required. All other fields are
            optional. The CSV file should <b>not</b> include a header row. <b>U.S. State</b> can be
            either a full state name or a two-letter abbreviation.
         </p>

         <b-alert variant="warning" :show="employees.length > 0">
            <h6>Possible duplicates</h6>
            There are already employees for the chosen year. This import will <b>not</b> check for
            duplicates unless employees are provided with unique employee IDs. If this file contains
            an employee without a unique employee ID that has already been entered, that employee
            may be duplicated.
         </b-alert>

         <b-alert variant="primary" :show="uploads.uploading">
            <b-progress variant="primary" :max="uploadProgress.total">
               <b-progress-bar animated :value="uploadProgress.loaded">
                  <span
                     >{{ formatBytes(uploadProgress.loaded) }} /
                     {{ formatBytes(uploadProgress.total) }}</span
                  >
               </b-progress-bar>
            </b-progress>
            Your document upload is in progress. Please don't navigate away until the upload is
            complete.
         </b-alert>

         <b-form-file
            placeholder="Choose a file or drop it here"
            drop-placeholder="Drop file here"
            :disabled="uploads.uploading"
            v-model="uploads.file"
         ></b-form-file>
         <div class="d-flex mb-2 align-items-center justify-content-between">
            <b-form-text>{{ formatBytes(uploadSize) }} Selected</b-form-text>
            <b-form-text>Limit {{ formatBytes(MAX_INTERNAL_FILE_SIZE) }}</b-form-text>
         </div>
      </b-modal>

      <b-modal
         id="modal-confirm-delete"
         title="Delete Employees"
         centered
         ok-title="Delete"
         ok-variant="danger"
         @ok="confirmDeleteEmployees"
      >
         Are you sure you want to delete the <b>{{ selectedEmployeeIds.length }}</b> selected
         employees?
      </b-modal>

      <b-modal id="modal-cannot-delete" title="Cannot Delete Selected Employees" centered ok-only>
         <p>
            The following employees have related data and cannot be deleted by a staff user.
            Unselect these employees to proceed, or contact an administrator to delete these
            employees.
         </p>

         <ul>
            <li v-for="employee in invalidForDelete" :key="employee.id">{{ employee.fullname }}</li>
         </ul>
      </b-modal>

      <datalist id="datalist-titles">
         <option v-for="title in companyTitles" :key="title">
            {{ title }}
         </option>
      </datalist>

      <datalist id="datalist-departments">
         <option v-for="department in companyDepartments" :key="department">
            {{ department }}
         </option>
      </datalist>

      <datalist id="datalist-subsidiaries">
         <option v-for="subsidiary in companySubsidiaries" :key="subsidiary">
            {{ subsidiary }}
         </option>
      </datalist>
   </b-container>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import {MAX_INTERNAL_FILE_SIZE} from '@/helpers/constants';
import {formatBytes} from '@/helpers/utils';

import YearSelect from '@/components/forms/YearSelect';

import {clientUserSuggestions} from '@/mixins';

export default {
   components: {
      YearSelect,
   },

   mixins: [
      // Adds support for department/job title suggestions
      clientUserSuggestions,
   ],

   data() {
      return {
         year: null,
         newEmployee: {
            fullname: null,
            ident: null,
            location: null,
            title: null,
            department: null,
            subsidiary: null,
            saving: false,
         },
         editEmployee: {
            fullname: null,
            ident: null,
            location: null,
            title: null,
            department: null,
            subsidiary: null,
         },
         uploads: {
            file: null,
            uploading: false,
         },
         selection: {},
         invalidForDelete: [],
         MAX_INTERNAL_FILE_SIZE,
      };
   },

   computed: {
      ...mapGetters({
         isStaff: 'isStaff',
         profile: 'profile',
         usStates: 'usStates',
         study: 'companies/study',
         employees: 'employees/employees',
         employeeMap: 'employees/employeeMap',
         uploadCategories: 'uploads/externalUploadCategories',
         uploadProgress: 'uploads/progress',
      }),

      companyId() {
         return this.$route.params.id;
      },

      // Employee table fields
      fields() {
         let fields = [
            {key: 'fullname', label: 'Name', sortable: true, stickyColumn: true},
            {key: 'location', label: 'U.S. State', sortable: true},
            {key: 'ident', label: 'Employee ID', sortable: true},
            {key: 'title', label: 'Job Title', sortable: true},
            {key: 'department', sortable: true},
         ];

         // Only include the subsidiary col if at least one employee has one
         if (this.employees.some((employee) => employee.subsidiary)) {
            fields.push({key: 'subsidiary', sortable: true});
         }

         return fields;
      },

      /** US States formatted for a b-form-select options attribute */
      stateOptions() {
         const stateOptions = Object.entries(this.usStates).map((entry) => {
            const [key, value] = entry;
            return {text: value, value: key};
         });
         return stateOptions;
      },

      /** Have any employees been selected for deletion? */
      anyEmployeesSelected() {
         return Object.values(this.selection).includes(true);
      },

      /** An array of employee ids selected for deletion */
      selectedEmployeeIds() {
         return Object.entries(this.selection)
            .filter((entry) => entry[1])
            .map((entry) => entry[0]);
      },

      /** Total size of selected file for upload */
      uploadSize() {
         return this.uploads.file?.size || 0;
      },
   },

   methods: {
      formatBytes,

      // Load employees for the selected year
      async loadEmployees() {
         await this.blockingRequest('employees/loadEmployees', {
            companyId: this.companyId,
            year: this.year,
         });
      },

      // POST a new employee for the currently selected year
      async submitNewEmployee() {
         if (this.$v.newEmployee.$invalid) {
            return;
         }

         this.newEmployee.saving = true;
         try {
            await this.$store.dispatch('employees/createEmployee', {
               companyId: this.companyId,
               year: this.year,
               employee: this.newEmployee,
            });
            this.loadSuggestions(true);
            this.newEmployee = {
               fullname: null,
               location: null,
               ident: null,
               title: null,
               department: null,
               subsidiary: null,
            };
            this.$refs.newEmployeeName.focus();
         } catch (err) {
            const msg = err.response.data.errors[0].detail;
            const errCode = err.response.data.errors[0].code;
            if (msg.includes('UniqueViolationError')) {
               this.$store.commit('showAlert', {
                  msg: 'An employee with that Employee ID already exists.',
                  seconds: 5,
               });
            } else if (![120, 121].includes(errCode)) {
               this.$store.commit('showAlert', {
                  response: err.response,
                  fallbackMsg: 'Submission failed',
                  seconds: 5,
               });
            }
         } finally {
            this.newEmployee.saving = false;
         }
      },

      // Display the edit employee modal
      editEmployeeModal(employee) {
         this.editEmployee = {...employee};
         this.$bvModal.show('modal-edit-employee');
      },

      // Send an update employee request
      async submitEditEmployee() {
         if (this.$v.editEmployee.$invalid) {
            return;
         }

         try {
            await this.blockingRequest('employees/editEmployee', {
               employee: this.editEmployee,
            });
            this.loadSuggestions(true);
         } catch (err) {
            const msg = err.response.data.errors[0].detail;
            if (msg.includes('UniqueViolationError')) {
               this.$store.commit('showAlert', {
                  msg: 'An employee with that Employee ID already exists.',
                  seconds: 5,
               });
            } else {
               this.$store.commit('showAlert', {
                  response: err.response,
                  fallbackMsg: 'Submission failed',
                  seconds: 5,
               });
            }
         }
      },

      /** Set employee selection state to `value` for all employees */
      selectAll(value) {
         for (const id in this.selection) {
            this.selection[id] = value;
         }
      },

      async deleteEmployeesModal() {
         const responses = this.selectedEmployeeIds.map((employeeId) => {
            return this.$store.dispatch('employees/deleteEmployee', {employeeId}).then(
               () => {
                  return {id: employeeId, canDelete: true};
               },
               () => {
                  return {id: employeeId, canDelete: false};
               }
            );
         });

         this.$emit('showSpinner');
         const results = await Promise.all(responses);
         this.$emit('hideSpinner');

         // If any employees can't be deleted, reject the whole operation
         const cantDelete = results.filter((result) => !result.canDelete);
         if (cantDelete.length > 0) {
            this.invalidForDelete = cantDelete.map((result) => {
               return this.employeeMap[result.id];
            });
            this.$bvModal.show('modal-cannot-delete');
         } else {
            this.$bvModal.show('modal-confirm-delete');
         }
      },

      async confirmDeleteEmployees() {
         const responses = this.selectedEmployeeIds.map((employeeId) => {
            return this.$store.dispatch('employees/deleteEmployee', {employeeId, force: true});
         });
         await this.blockUntilAllSettled(responses);
      },

      // Send a request to import an employee CSV from an uploaded file
      async importEmployeeCsv(evt) {
         if (this.$v.uploads.$invalid) {
            return;
         }

         // Prevent the modal from closing immediately
         evt.preventDefault();

         try {
            this.uploads.uploading = true;
            await this.blockingRequest('employees/importEmployeeCsv', {
               companyId: this.companyId,
               year: this.year,
               file: this.uploads.file,
            });
         } catch (err) {
            const msg = err.response.data.errors[0].detail;
            const thisErrorCode = err.response.data.errors[0].code;
            const errCodes = this.$errorCodes();

            if (thisErrorCode === errCodes.CONFIRMATION_REQUIRED) {
               const force = await this.$bvModal.msgBoxConfirm(
                  `Importing employees will invalidate employee time data that has been submitted for ${this.year}. Would you like to proceed?`,
                  {
                     centered: true,
                     title: 'Employee Time Already Submitted',
                  }
               );
               if (force) {
                  await this.blockingRequest('employees/importEmployeeCsv', {
                     companyId: this.companyId,
                     year: this.year,
                     file: this.uploads.file,
                     force: true,
                  });
               }
            } else if (
               [errCodes.FILE_VALIDATION_FAILED, errCodes.NO_ENCODING_FOUND].includes(thisErrorCode)
            ) {
               this.$store.commit('showAlert', {
                  msg: 'The file does not contain valid CSV data.',
                  seconds: 10,
               });
            } else if (msg.includes('CardinalityViolationError')) {
               this.$store.commit('showAlert', {
                  msg: 'The CSV file contains multiple employees with the same employee ID.',
                  seconds: 10,
               });
            } else if (msg.includes('NotNullViolationError')) {
               this.$store.commit('showAlert', {
                  msg: 'The CSV file is missing required data.',
                  seconds: 10,
               });
            } else if (msg.includes('InvalidTextRepresentationError')) {
               this.$store.commit('showAlert', {
                  msg: 'The CSV file contains an invalid location.',
                  seconds: 10,
               });
            } else {
               this.$store.commit('showAlert', {
                  response: err.response,
                  fallbackMsg: 'Submission failed',
                  seconds: 5,
               });
            }
         } finally {
            this.uploads.uploading = false;
            this.$bvModal.hide('modal-import-employees');
         }
      },
   },

   validations() {
      return {
         newEmployee: {
            fullname: {required},
            location: {required},
         },
         editEmployee: {
            fullname: {required},
            location: {required},
         },
         uploads: {
            file: {
               required,
               fileSize(file) {
                  if (file) {
                     return file.size <= MAX_INTERNAL_FILE_SIZE;
                  }
                  return false;
               },
            },
         },
      };
   },

   watch: {
      employees(employees) {
         const newIds = employees.map((employee) => employee.id);
         // Clear unused selections
         Object.keys(this.selection).forEach((id) => {
            if (!newIds.includes(id)) {
               Vue.delete(this.selection, id);
            }
         });

         // Init new selections
         newIds.forEach((id) => {
            if (!(id in this.selection)) {
               Vue.set(this.selection, id, false);
            }
         });
      },

      year() {
         this.$nextTick(() => {
            this.$refs.newEmployeeName.focus();
         });
      },
   },

   async created() {
      const requests = [
         this.$store.dispatch('companies/loadCompany', {
            companyId: this.companyId,
         }),
         this.loadSuggestions(),
         this.$store.dispatch('uploads/loadCompanyUploadCategories', {
            companyId: this.companyId,
            summary: true,
            force: false,
         }),
         this.$store.dispatch('employees/loadUploadCategory', {
            companyId: this.companyId,
         }),
      ];

      await this.blockUntilAllSettled(requests);

      // Initialize selection data
      this.employees.forEach((employee) => {
         Vue.set(this.selection, employee.id, false);
      });

      // If the company has no active study, redirect to company detail
      if (this.study === null) {
         this.$router.push({name: 'company-detail', params: {id: this.companyId}});
      }

      // Check the query params for an initial year to select
      if (this.$route.query.year) {
         this.year = +this.$route.query.year;
      }
   },
};
</script>
