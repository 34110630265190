import Vue from 'vue';

export const ContractorPermissionTypes = Object.freeze({
   CREATE: 'create',
   DELETE: 'delete',
   INFO: 'update',
   TIME: 'time',
   FINANCIAL: 'fin',
});

const state = () => ({
   userPermissions: {
      userId: null,
      data: null,
      dirty: false,
   },
   usersWithPermissions: {
      companyId: null,
      users: [],
   },
});

const getters = {
   userPermissions: (state) => state.userPermissions.data,
   userPermissionsDirty: (state) => state.userPermissions.dirty,

   _checkPermission:
      (state, getters, rootState, rootGetters) =>
      (permission, contractorId = null) => {
         if (rootGetters.isSME) {
            if (getters.userPermissions?.[permission]) {
               return true;
            }
            return !!getters.userPermissions?.contractors[contractorId]?.[permission];
         }
         return true;
      },

   /** Can the current user create contractors? */
   canCreateContractors: (state, getters) => {
      return getters._checkPermission(ContractorPermissionTypes.CREATE);
   },

   /** Can the current user delete contractors? */
   canDeleteContractors: (state, getters) => {
      return getters._checkPermission(ContractorPermissionTypes.DELETE);
   },

   /** Can the current user edit a given contractor's info? */
   canEditInfo: (state, getters) => (contractorId) => {
      return getters._checkPermission(ContractorPermissionTypes.INFO, contractorId);
   },

   /** Can the current user edit a given contractor's financial data? */
   canEditFinancialData: (state, getters) => (contractorId) => {
      return getters._checkPermission(ContractorPermissionTypes.FINANCIAL, contractorId);
   },

   /** Can the current user edit the given contractor's time data? */
   canEditTimeData: (state, getters) => (contractorId) => {
      return getters._checkPermission(ContractorPermissionTypes.TIME, contractorId);
   },

   /** Partition a list of users based on whether or not the IDs are in the usersWithPermissions array. */
   makePartitionedList: (state) => (userlist, hasPermissions) => {
      return userlist.filter(
         (u) => state.usersWithPermissions.users.includes(u.id) === hasPermissions
      );
   },
};

const mutations = {
   /** Store a contractor permissions object */
   setUserPermissions(state, {userId, permissions}) {
      state.userPermissions.userId = userId;
      state.userPermissions.data = permissions;
      state.userPermissions.dirty = false;
   },

   /** Clear the stored user permissions */
   clearUserPermissions(state) {
      state.userPermissions.data = null;
      state.userPermissions.dirty = false;
   },

   /** Set user permissions partition */
   setUserPermissionsPartition(state, {partition, companyId}) {
      state.usersWithPermissions.companyId = companyId;
      state.usersWithPermissions.users = partition;
   },

   clearUserPermissionsPartition(state) {
      state.usersWithPermissions.companyId = null;
      state.usersWithPermissions.users = [];
   },

   /** Update a general permission value */
   setGeneralPermissions(state, perms, setDirty = true) {
      for (let {type, value} of perms) {
         state.userPermissions.data[type] = value;

         if (
            value &&
            [
               ContractorPermissionTypes.INFO,
               ContractorPermissionTypes.FINANCIAL,
               ContractorPermissionTypes.TIME,
            ].includes(type)
         ) {
            // When a general permission is set to true, set the corresponsing contractor permissions to false
            Object.keys(state.userPermissions.data.contractors).forEach((contractorId) => {
               Vue.set(state.userPermissions.data.contractors[contractorId], type, false);
            });
         }
      }
      if (setDirty) {
         state.userPermissions.dirty = true;
      }
   },

   /** Update a contractor-specific permission value */
   setContractorPermission(state, {contractorId, type, value}) {
      if (!(contractorId in state.userPermissions.data.contractors)) {
         Vue.set(state.userPermissions.data.contractors, contractorId, {});
      }
      Vue.set(state.userPermissions.data.contractors[contractorId], type, value);
      state.userPermissions.dirty = true;
   },
};

const actions = {
   /** Grant or revoke a user's access to a company */
   async assignUser({dispatch}, {companyId, assigneeId}) {
      await this._vm.$http.put(`/api/company/${companyId}/contractor/assignment`, {
         assigneeId,
      });
      await dispatch('loadUserPermissionsPartition', {companyId});
   },

   async unassignUser({dispatch}, {companyId, assigneeId}) {
      await this._vm.$http.delete(
         `/api/company/${companyId}/contractor/assignment?assignee=${assigneeId}`
      );
      await dispatch('loadUserPermissionsPartition', {companyId});
   },

   /** Load a list of which users have permissions */
   async loadUserPermissionsPartition({commit}, {companyId}) {
      const userIds = (
         await this._vm.$http.get(`/api/company/${companyId}/with-contractor-permissions`)
      ).data;
      commit('setUserPermissionsPartition', {partition: userIds, companyId});
   },

   /** Load a user's permissions and store the contractor section permissions */
   async loadUserPermissions({commit}, {userId}) {
      const perms = (await this._vm.$http.get(`/api/user/${userId}/permissions`)).data;
      commit('setUserPermissions', {permissions: perms.contractors, userId});
   },

   /** Save any changes to a user's contractor section permissions */
   async saveUserPermissions({state, commit, dispatch}) {
      try {
         await this._vm.$http.put(`/api/user/${state.userPermissions.userId}/permissions`, {
            contractors: state.userPermissions.data,
         });
         state.userPermissions.dirty = false;
         await dispatch('loadUserPermissionsPartition', {
            companyId: state.usersWithPermissions.companyId,
         });
      } catch (err) {
         commit(
            'showAlert',
            {
               msg: 'Failed to save user permissions',
               seconds: 5,
            },
            {root: true}
         );
         await dispatch('contractors/loadContractors', {}, {root: true});
      }
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
