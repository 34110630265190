<template>
   <b-container>
      <div class="d-flex align-items-center justify-content-between mb-5">
         <h1>Questionnaire Templates</h1>
         <b-button id="btn-create-template" :to="{name: 'qtemplate-create'}" variant="primary">
            <b-icon-plus /> Create Template
         </b-button>
      </div>

      <QTemplateSelect v-if="loaded"></QTemplateSelect>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import QTemplateSelect from './widgets/QTemplateSelect';

export default {
   components: {
      QTemplateSelect,
   },

   data() {
      return {
         loaded: false,
         fields: [
            {key: 'name', sortable: true},
            {key: 'industries'},
            {key: 'qtype', label: 'Type', sortable: true},
            {key: 'actions', label: ''},
         ],
         templateTypeOptions: [
            {value: 'COMPANY', text: 'Company'},
            {value: 'PROJECT', text: 'Project'},
         ],
         filterArgs: {
            search: '',
            industry: null,
            qtype: null,
         },
      };
   },

   computed: {
      ...mapGetters({
         isAdmin: 'isAdmin',
         templates: 'questionnaire/templates',
         industryMap: 'industries/industryMap',
         industries: 'industries/industries',
      }),

      industryOptions() {
         return this.industries.map((industry) => {
            return {text: industry.name, value: industry.id};
         });
      },
   },

   methods: {
      filter(template) {
         let include = true;
         if (this.filterArgs.search) {
            include = include && template.name.includes(this.filterArgs.search);
         }
         if (this.filterArgs.industry) {
            include = include && template.industries.includes(this.filterArgs.industry);
         }
         if (this.filterArgs.qtype) {
            include = include && template.qtype === this.filterArgs.qtype;
         }
         return include;
      },
   },

   async created() {
      const requests = [
         this.$store.dispatch('questionnaire/loadTemplates'),
         this.$store.dispatch('industries/loadIndustries'),
      ];
      await this.blockUntilAllSettled(requests);
      this.loaded = true;
   },
};
</script>
